import React from 'react'
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import SubjectRaderChart from './SubjectRaderChart'
import {post_wm} from "../../sonodalib/sl_utils/Http";
import Cookies from "js-cookie";
import {altName} from "../../alt/constants";
import Icon from "@material-ui/core/Icon";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import ExplainDialog from "./ExplainDialog";
import {urlPref} from '../../alt/constants'


const styles = {
	shikenLabel: {
		fontSize:				 20,
		fontWeight:			 'bold',
		color:					 '#666',
		paddingBottom:	 18,
		textAlign:			 'center',
	},
	tableHead: {
		backgroundColor: '#1e88e5',
		color: 					 'white',
		fontSize:				 12,
		whiteSpace:			 'nowrap',
	},
	cellHead: {
		paddingTop:			 6,
		paddingBottom:	 6,
		textAlign:			 'center',
	},
	tableRow: {
		fontSize:				 16,
		// whiteSpace:			 'nowrap',
		borderBottom:		 '1px solid #ccc',
	},
	tableCellSubject: {
		textAlign:			 'center',
		paddingLeft:		 10,
		paddingRight:		 10,
		width:					 260,
	},
	tableCell: {
		textAlign:			 'center',
	},
	detailHead: {
		backgroundColor: '#81D4FA',
		color: 					 'black',
		fontSize:				 12,
		whiteSpace:			 'nowrap',
		paddingTop:			 2,
		paddingBottom:	 2,
	},
	detailRow: {
		fontSize:				 16,
		whiteSpace:			 'nowrap',
		borderBottom:		 '1px solid #ccc',
		borderLeft:			 '1px solid #ccc',
		borderRight:		 '1px solid #ccc',
	},
	detailCell: {
		minHeight:			 36,
		lineHeight:			 '36px',
		// paddingTop:			 14,
		// paddingBottom:	 14,
		textAlign:			 'center',
	},
}

class CategoryTab extends React.Component {
	state = {
		detail_subject: null,
		detail: null,
		openMenu1: false,
		openMenuIndex: null,
		anchor1: null,
		explanation: null,
		fetchingDetail: null,
	}

	handleOpenDetail = (subject) => () => {
		const {fetchingDetail} = this.state
		if (fetchingDetail) return
		this.setState({
			fetchingDetail: subject.subject
		}, () => {
			post_wm("shindan/detail", {
				user_token:   Cookies.get(altName),
				moshi_token:  Cookies.get("wm_"+altName),
				subject_code: subject.subject,
				sum_visible:  subject.sv,
			})
			.then((ret) => {
				this.setState({
					detail_subject: 	 subject.subject,
					detail:						 ret.answer,
					detail_kogyo:			 ret.kohyo,
					hukushu_col_title: ret.hukushu_col_title,
					show_kaisetsu: 		 ret.show_kaisetsu,
				})
			})
			.catch(() => {
				alert("エラーが発生しました")
				this.props.history.push("/wm")
			})
			.finally(() => {
				this.setState({
					fetchingDetail: null
				})
			})
		})
	}

	handleCloseDetail = () => {
		this.setState({
			detail_subject: null,
			detail:					null,
		})
	}

	handleOpenMenu1 = (index, event) => {
		this.setState({
			openMenu1: true, anchor1: event.currentTarget,
			openMenuIndex: index,
		});
	};

	handleCloseMenu1 = () => {
		this.setState({
			openMenu1: false,
			openMenuIndex: null,
		});
	}

	handleSelectMenu1 = (hukushu) => () => {
		const {userState} = this.props
		this.handleCloseMenu1()
		if (userState.license !== "8") {
			// this.props.history.push("/my/hist/show/sentaku/"+hukushu)
			window.open(urlPref+"/my/hist/show/sentaku/"+hukushu)
		}
	}

	handleOpenExplaination = (question_id) => () => {
		post_wm("shindan/explain", {
			user_token:   Cookies.get(altName),
			moshi_token:  Cookies.get("wm_"+altName),
			question_id,
		})
		.then((ret) => {
			this.setState({
				explanation: ret
			})
		})
		.catch(() => {
			alert("エラーが発生しました")
			this.props.history.push("/wm")
		})
	}

	render() {
		const {data, history, printMode} = this.props
		if (!data) return null

		const {subjects, radar_chart, kohyo} = data
		const {detail_subject, detail, detail_kogyo, explanation, show_kaisetsu, hukushu_col_title, fetchingDetail} = this.state

		const catsHeadVd = (
			<Grid container style={styles.tableHead} alignItems="center">
				<Grid item style={{...styles.cellHead,width:260}}>科目名</Grid>
				<Grid item style={{...styles.cellHead,width:60}}>配点</Grid>
				<Grid item style={{...styles.cellHead,width:60}}>得点</Grid>
				<Grid item style={{...styles.cellHead,width:60}}>平均点</Grid>
				<Grid item style={{...styles.cellHead,width:60}}>判定</Grid>
				{(printMode) ? null : (
					<Grid item style={{...styles.cellHead,width:80}}>詳細</Grid>
				)}
			</Grid>
		)

		const subjectsVd = subjects.map((subject,index) => {
			let detailWidth = 500
			if (!hukushu_col_title) detailWidth -= 100
			if (!show_kaisetsu)			detailWidth -= 80
			const detailMargin = (600 - detailWidth) / 2
			const fetchingDetailVd = (!printMode && subject.subject === fetchingDetail) ? (
				<div style={{textAlign:'center'}}>
					{/*<Icon style={{color:'#aaa'}}>cloud_download</Icon>*/}
					<img src={"/imgms/loading-grey.gif"} role="presentation" alt="loading" style={{width:64,height:64}}/>
				</div>
			) : null
			const detailVd = (!printMode && detail && detail_subject && subject.subject === detail_subject) ? (
				<div>
					<div style={{marginLeft:detailMargin,marginRight:detailMargin,width:detailWidth,marginTop:10,marginBottom:10}}>
						<Grid container alignItems="center" style={styles.detailHead} key="dtl00">
							<Grid item style={{width:60,textAlign:'center'}}>問題</Grid>
							<Grid item style={{width:40,textAlign:'center'}}>正解</Grid>
							<Grid item style={{width:80,textAlign:'center'}}>あなたの解答</Grid>
							<Grid item style={{width:40,textAlign:'center'}}>正誤</Grid>
							<Grid item style={{width:80,textAlign:'center'}}>正答率</Grid>
							{(hukushu_col_title) ? (
								<Grid item style={{width:100,textAlign:'center'}}>{hukushu_col_title}</Grid>
							) : null}
							{(show_kaisetsu) ? (
								<Grid item style={{width:80,textAlign:'center'}}>解説</Grid>
							) : null}
						</Grid>
						{(detail.map((row) => {
							const hukushuVd = (!hukushu_col_title || !row.hukushus.length) ? null :
								(row.hukushus.length > 1) ? (
									<div>
										<Button
											variant="text"
											aria-label="More"
											aria-owns={this.state.openMenu1 ? 'menu' : null}
											aria-haspopup="true"
											onClick={this.handleOpenMenu1.bind(this,row.index)}
											size="small"
											// style={{paddingLeft:18,paddingRight:18}}
										>
											表示 <Icon>keyboard_arrow_down</Icon>
										</Button>
										<Menu
											id="selector-menu"
											anchorEl={this.state.anchor1}
											open={this.state.openMenu1 && this.state.openMenuIndex === row.index}
											onClose={this.handleCloseMenu1}
										>
											{row.hukushus.map((hukushu, index) => {
												return (
													<MenuItem
														key={index}
														selected={false}
														onClick={this.handleSelectMenu1(hukushu)}
													>
														{/*{hukushu}*/}
														問題 {index+1}
													</MenuItem>
												)})}
										</Menu>
									</div>
								) : (
									<Button
										variant="text"
										onClick={this.handleSelectMenu1(row.hukushus)}
										size="small"
									>
										{/*{row.hukushus}*/}
										問題
									</Button>
									// <button type="button" onClick={this.handleSelectMenu1(row.hukushus)} style={{border:'none',backgroundColor:'transparent'}}>{row.hukushus}</button>
								)
							const explainVd = (
								<Button
									variant="text"
									aria-label="Explain"
									onClick={this.handleOpenExplaination(row.question_id)}
									size="small"
								>
									<Icon>article</Icon>
								</Button>
							)
							return (
								<Grid container alignItems="center" style={styles.detailRow} key={"dtl"+row.index}>
									<Grid item style={{...styles.detailCell,width:60}}>{row.index}</Grid>
									<Grid item style={{...styles.detailCell,width:40}}>{row.correct}</Grid>
									<Grid item style={{...styles.detailCell,width:80}}>{row.answer}</Grid>
									<Grid item style={{...styles.detailCell,width:40}}>{row.seigo}</Grid>
									<Grid item style={{...styles.detailCell,width:80}}>{row.score_avg}</Grid>
									{(hukushu_col_title) ? (
										<Grid item style={{...styles.detailCell,width:100,fontSize:14}}>{hukushuVd}</Grid>
									) : null}
									{(show_kaisetsu) ? (
										<Grid item style={{...styles.detailCell,width:80}}>{explainVd}</Grid>
									) : null}
								</Grid>
							)
						}))}
					</div>
					{(detail_kogyo) ? (
						<div style={{width:600,marginTop:20,marginBottom:10,border:'1px solid #3f51b5', padding:10}}>
							<div dangerouslySetInnerHTML={{__html: detail_kogyo}}></div>
						</div>
					) : null}
				</div>
			) : null
			const subjectFontSize = (subject.name.length >= 18) ? 14 : 16
			return (
				<div key={"sbj"+index} style={styles.tableRow}>
					<Grid container alignItems="center" style={{height:50}}>
						<Grid item style={{...styles.tableCellSubject,fontSize:subjectFontSize}}>{subject.name}</Grid>
						<Grid item style={{...styles.tableCell,width:60}}>{subject.haiten}</Grid>
						<Grid item style={{...styles.tableCell,width:60}}>{subject.score}</Grid>
						<Grid item style={{...styles.tableCell,width:60}}>{subject.avg}</Grid>
						<Grid item style={{...styles.tableCell,width:60}}>{subject.hantei}</Grid>
						{(printMode) ? null : (
							<Grid item style={{...styles.tableCell,width:80}}>
								{(subject.score === "") ? null : (subject.subject === detail_subject) ? (
									<Button
										variant="text"
										style={{backgroundColor:'#f50057',color:'white'}}
										size="small"
										onClick={this.handleCloseDetail}
									>
										閉じる
									</Button>
									// <Icon
									// 	style={{height:50,lineHeight:'50px',cursor:'pointer'}}
									// 	onClick={this.handleCloseDetail}>arrow_circle_up
									// </Icon>
								) : (
									<Button
										variant="text"
										style={{border:'1px solid #f50057',color:'#f50057'}}
										size="small"
										onClick={this.handleOpenDetail(subject)}
									>
										開く
									</Button>
									// <Icon
									// 	style={{height:50,lineHeight:'50px',cursor:'pointer'}}
									// 	onClick={this.handleOpenDetail(subject)}>arrow_circle_down
									// </Icon>
								)}
							</Grid>
						)}
					</Grid>
					{fetchingDetailVd}
					{detailVd}
				</div>
			)
		})
		// const sideMargin = (printMode) ? 0 : 100
		const sideMargin = (printMode) ? 0 : 50
		const radarChartSize = (printMode) ? [500,300] : [600,400]
		return (
			<div>
				<div style={{fontWeight:600,textAlign:'center',marginTop:40}}>
					科目別成績
				</div>
				<div style={{marginTop:20,marginLeft:sideMargin,marginRight:sideMargin}}>
					{catsHeadVd}
					{subjectsVd}
				</div>
				<div style={{marginTop:40,marginLeft:sideMargin,marginRight:sideMargin}}>
					<SubjectRaderChart
						series={radar_chart.series}
						labels={radar_chart.labels}
						width={radarChartSize[0]}
						height={radarChartSize[1]}
					/>
				</div>
				{(kohyo) ? (
					<div style={{border:'1px solid #3f51b5', padding:10}}>
						<div dangerouslySetInnerHTML={{__html: kohyo}}></div>
					</div>
				) : null}
				{(printMode) ? null : (
					<ExplainDialog
						open={!!explanation}
						history={history}
						data={explanation}
						onClose={() => {
							this.setState({
								explanation: null,
							})
						}}
					/>
				)}
			</div>
		)
	}
}
export default connect(
	state => ({
		moshiState: state.moshi,
		userState: state.sl_users.state,
		printMode: state.moshi.printMode,
	})
)(withRouter(CategoryTab));
