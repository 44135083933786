import React from 'react';
import ReactDOM from 'react-dom';
import {createStore} from 'redux'
import {Provider} from 'react-redux'
import reducers from './reducers'
import Routes from './routes';
import './index.css';
import {createMuiTheme} from '@material-ui/core/styles';
import {ThemeProvider} from '@material-ui/core/styles';
import {red, deepOrange, blueGrey} from '@material-ui/core/colors'
// import CssBaseline from "@material-ui/core/CssBaseline";
// import MuiThemeProvider from '@material-ui/core/styles/MuiThemeProvider';
// import ThemeDefault from './theme-default';
import LoadingOverlay from './sonodalib/sl_utils/components/LoadingOverlay';
import SlSnackbar from './sonodalib/sl_utils/components/Snackbar';
import G from './sonodalib/SlGlobal'
import {urlPref, dbName} from './alt/constants'
// import injectTapEventPlugin from 'react-tap-event-plugin';

const store = createStore(
	reducers,
//  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
)
G.store = store
G.apiPath = "/f/fkapi/"
G.apiDbName = dbName

// injectTapEventPlugin();

// try {
// 	localStorage.setItem("test", '1');
// 	localStorage.removeItem("test");
// }
// catch (error) {
// 	alert("ブラウザの、シークレットウインドウモードやプライベートブラウジングモード等ではご利用になれません。通常のモードでご利用ください。")
// 	window.location.href = urlPref+"http://fukushi.akamaru.jp/"
// }

const styles = {
	viewRect: {
		width: '100vw',
		height: '100vh',
		position: 'relative',
		backgroundColor: '#FFF'
	},
	background: {
		width: '100vw',
		height: '100vh',
		backgroundColor: '#FBE9E7'
	}
}

const theme = createMuiTheme({
  palette: {
    primary: deepOrange,
		accent: blueGrey,
    error: red,
  },
  typography: {
    htmlFontSize: 10,
  },
  // spacing: 4,
  // props: {
	// 	MuiAvatar: {
	// 		margin: 16,
	// 	}
  // }
});

ReactDOM.render(
	<Provider store={store}>
    <ThemeProvider theme={theme}>
      {/*<CssBaseline/>*/}
			<div style={styles.background}>
				<div style={styles.viewRect}>
					<LoadingOverlay/>
					<SlSnackbar/>
					<Routes basename={urlPref}/>
				</div>
			</div>
		</ThemeProvider>
	</Provider>
	, document.getElementById('root')
);
