// セルフチェックシート印刷時の、各カテゴリの表とレーダーチャート部分のみ

import React from 'react'
import {connect} from "react-redux";
import Box from "@material-ui/core/Box";
import SubjectRaderChart from "./SubjectRaderChart";
import './categoryprint.css'

class CategoryPrint extends React.Component {
	state = {
	}

	render() {
    const {data} = this.props
    const {subjects, radar_chart} = data

    const subjectsVd = subjects.map((subject,index) => {
			return (
				<tr key={"subj"+index}>
					{(index & 1) ? (
						<>
							<td className="t-1 t-sb">{subject.name}</td>
							<td className="t-1 t-4">{subject.haiten}</td>
							<td className="t-5">{subject.score}</td>
							<td className="t-1">{subject.hantei}</td>
						</>
					) : (
						<>
							<td className="t-sb">{subject.name}</td>
							<td className="t-4">{subject.haiten}</td>
							<td className="t-6">{subject.score}</td>
							<td>{subject.hantei}</td>
						</>
					)}
				</tr>
			)
    })

    return (
      <Box width={350}>
        <Box width={330} style={{marginLeft:10, marginRight:10}}>
          <Box fontSize="1.3rem" lineHeight="32px">
            <span style={{color:'#4b95b6'}}>■</span> {data.label}
          </Box>
          <Box id="gcatprint">
            <div className="seiseki">
              <table>
                <tbody>
                  <tr>
                    <th className="t-2">科目名</th>
                    <th className="t-2">配点</th>
                    <th className="t-2">得点</th>
                    <th className="t-2">判定</th>
                  </tr>
                  {subjectsVd}
                </tbody>
              </table>
            </div>
          </Box>
        </Box>
        <div style={{width:330, marginTop:40}}>
          <SubjectRaderChart
            className="chart-canvas"
            series={radar_chart.series}
            labels={radar_chart.labels}
            width={330}
            height={320}
            labelFontSize={12}
          />
        </div>
      </Box>
    )
  }
}
export default connect(
	state => ({
		sctState: state.sct,
		// printMode: state.moshi.printMode,
	})
)(CategoryPrint);
