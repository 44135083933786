// @flow weak
import React from 'react'
import _ from 'lodash'
import { deepOrange, common } from '@material-ui/core/colors'
import '../sonodalib/sl_utils/utils.css'
import {post} from '../sonodalib/sl_utils/Http'
import { SubjectBox, QuestionBox } from '../Common'
import Moment from 'moment'
import Button from '@material-ui/core/Button'
import {urlPref} from '../alt/constants'


type Props = {
	mondaiId: string,
	onClose: () => void,
}
type State = {
	question: any,
}
class SentakuPrint extends React.Component<Props, State> {
	state = {
		question: null,
	}

	componentWillMount() {
		const mondaiId = this.props.mondaiId
		post("q/fetch/sentaku", {mondai_id: mondaiId})
			.then(ret => {
				_.forEach(ret.question.choices, choice => {
					choice.showCommentary = true
				})
				this.setState({
					question: ret.question,
				})
			})
	}

	// componentDidMount() {
	// 	setTimeout("window.print()", 2000)
	// }

	render() {
		const styles = {
			alert: {
				background: common.black,
				padding: 10,
			},
			alertP: {
				color: common.white,
				fontWeight: 'bold',
				margin: 0,
				padding: 0,
				fontSize: 14,
			},
			pullRight: {
				textAlign: 'right',
			},
			sideMargin: {
				marginLeft: 12,
				marginRight: 12,
			},
			remark: {
				marginBottom: 10,
				fontWeight: 800,
			},
		}
		const { question } = this.state
		if (!question) return null
		const { choices } = question

		const choiceVd = _.map(choices, (choice, index) => (
			<div key={_.uniqueId("fk")} style={{marginBottom: 10}}>
				{(index+1)}．{choice.text}
			</div>
		))

		const commentaryVd = _.map(choices, (choice, index) => (
			<div key={_.uniqueId("fk")} style={{marginBottom: 10}}>
				{(index+1)}．{choice.commentary}
			</div>
		))

		const answerIndices = []
		_.forEach(choices, (choice, index) => {
			if (choice.correct) {
				answerIndices.push(index+1)
			}
		})

		const currentTime = Moment(new Date()).format("YYYY-MM-DD HH:mm:ss")

		return (
			<div style={{backgroundImage: `url('${urlPref}/images/bg_print.jpg/images/bg_print.jpg')`, width: 700}}>
				<div className="no-print">
					<Button color="secondary" variant="contained" onClick={this.props.onClose}>戻る</Button>
				</div>
					<div style={styles.alert}>
						<p style={styles.alertP}>※この印刷紙面の内容について、個人の学習以外の目的で使用すること、及び無断で複製・転載することを堅く禁止致します。株式会社ジェイシー教育研究所</p>
					</div>
					<div className="pull-right" style={styles.pullRight}>
						日時：{currentTime}
					</div>
					<SubjectBox label={question.subjectLabel+"　"+question.subLabel}/>
					<QuestionBox text={question.qText}/>

					<div style={styles.sideMargin}>
						<div style={styles.remark}>［ 選択肢 ］</div>
						{choiceVd}
					</div>
					<div style={styles.sideMargin}>
						<div style={styles.remark}>［ 解説 ］</div>
						{commentaryVd}
					</div>
					<div style={styles.pullRight}>
						<span style={{color: deepOrange[600], fontWeight: 800, fontSize: 16}}>正解 {answerIndices.join("，")}番</span>
					</div>

					<div style={{...styles.pullRight, marginTop: 30}}>
						Copyright © 2017 JC Educational Institute, Inc. All Rights Reserved.
					</div>
				</div>
		)
	}
}
export default SentakuPrint
