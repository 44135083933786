import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { OxResultListItem } from './CommonParts'
import { common, pink } from '@material-ui/core/colors';
import Grid from '@material-ui/core/Grid';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List'
import FkToolbar from '../parts/FkToolbar'
import { withMemoDrawer } from '../parts/MemoDrawer'
import { withBbsDrawer } from '../parts/BbsDrawer'
import MemoIconButton from '../parts/MemoIconButton'
import OxDetailPrint from './OxDetailPrint'
import {updatePrintModeAC} from "../mypage/MyPageAction";
import G from "../sonodalib/SlGlobal";
import {urlPref} from '../alt/constants'


class OxDetail extends React.Component {
	componentDidMount() {
		if (!this.props.oxQuestions) {
			window.location.href = urlPref+"/my"
			return
		}
		const question = this.getQuestion()
		this.props.memoDrawer.load({
			mondaiId: question.MondaiID,
			marubatsuId: question.MarubatsuID,
		})
	}

	handleGoBack = () => {
		this.props.history.goBack()
	}

	getQuestion = () => {
		const { questions } = this.props.oxQuestions
		const index = parseInt(this.props.match.params.index, 10)
		return questions[index]
	}

	handlePrint = () => {
		G.store.dispatch(updatePrintModeAC(true))
		window.setTimeout(() => {
			window.print()
		}, 1500)
	}

	handleClosePrintPopout = () => {
		G.store.dispatch(updatePrintModeAC(false))
	}

	render() {
		if (!this.props.oxQuestions) return null
		const styles = {
			question: {
				border: '1px gray solid',
				padding: 8,
				margin: 8,
			},
			otherChoices: {
				margin: 8,
			},
			correctLabel: {
				marginTop: 8,
				fontSize: 16,
				color: pink[700],
				fontWeight: 600,
				textAlign: 'left',
				height: 26,
				padding: 0,
				margin: 0,
				lineHeight: "26px",
			},
			correctBorder: {
				borderStyle: 'solid',
				borderWidth: 3,
				borderColor: pink[500],
				textAlign: 'center',
			},

		}
		const question = this.getQuestion()
		const index = parseInt(this.props.match.params.index, 10)
		const answerFontName = (question.correctAnswer === 1) ? "radio_button_unchecked" : "close"

		if (this.props.printMode) {
			return (
				<OxDetailPrint
					index={index}
					result={this.props.oxResults[index]}
					question={question}
					onClose={this.handleClosePrintPopout}
				/>
			)
		}
		return (
			<div>
				<FkToolbar title="学習結果　詳細" onGoBack={this.handleGoBack.bind(this)}>
					<MemoIconButton
						handleOpenMemo={this.props.memoDrawer.open.bind(null)}
						isMemoActive={this.props.memoDrawerStatus}
					/>
					<IconButton
						style={{color:'white'}}
						aria-label="掲示板"
						onClick={() => {
							this.props.bbsDrawer.openToCreateThread(
								1,
								undefined,
								question.MondaiID,
								question.MarubatsuID,
								"",
							)
						}}
					>
						<Icon>comment</Icon>
					</IconButton>
					<IconButton style={{color: 'white'}} aria-label="印刷" onClick={this.handlePrint}>
						<Icon>printer</Icon>
					</IconButton>
				</FkToolbar>
				<div>
					<List>
						<OxResultListItem
							index={index}
							question={question}
						/>
					</List>
					<div style={styles.question}>
						{question.qText}
					</div>
				</div>
				<div style={styles.otherChoices}>
					<Grid container direction="row">
						<Grid item style={{width:80}}>
							<div style={styles.correctLabel}>正解</div>
							<div style={styles.correctBorder}>
								<Icon style={{fontSize: 58,padding: 1,color: common.black}}>{answerFontName}</Icon>
							</div>
						</Grid>
						<Grid item style={{marginTop:26-4,width:40}}>
							<Icon style={{...styles.leftIconStyle, color:pink[700]}}>school</Icon>
						</Grid>
						<Grid item xs style={{marginTop:26-4}}>
							{question.commentaryText}
						</Grid>
					</Grid>
				</div>
			</div>
		)
	}
}

export default connect(
	state => ({
		oxQuestions: state.question.oxQuestions,
		printMode: state.mypage.printMode,
		oxResults: state.question.oxResults,
	}),
	dispatch => ({
	})
)(withRouter(withMemoDrawer(withBbsDrawer(OxDetail))))
