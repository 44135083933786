import React, {Component} from 'react'
import {connect} from 'react-redux';
import {Route, Switch} from 'react-router-dom'
// import {logout} from '../sonodalib/sl_users/SlUsersAction';
import Notifications from './Notifications';
import Profile from './account/Profile';
import ApplicationPage from '../alt/ApplicationPage';
import AdditionalApplicationPage from './account/AdditionalApplicationPage';
import ChangeEmail from './account/ChangeEmail';
import ResetPassword from './account/ResetPassword';
import MyPageHeader from './MyPageHeader';
import MyPage from '../alt/MyPage';
// import PropTypes from 'prop-types';
// import {post} from '../sonodalib/sl_utils/Http'


class MyPageFrame extends Component {
	render() {
		const content = (
			<Switch>
				<Route exact path="/my/notifications" component={Notifications}/>
				<Route exact path="/my/profile" component={Profile}/>
				<Route exact path="/my/application" component={ApplicationPage}/>
				<Route exact path="/my/add-application" component={AdditionalApplicationPage}/>
				<Route exact path="/my/change-email" component={ChangeEmail}/>
				<Route exact path="/my/change-password" component={ResetPassword}/>
				<Route path="/my" component={MyPage}/>
			</Switch>
		)
		if (this.props.printMode) {
			return (
				<div>
					{content}
				</div>
			)
		} else {
			return (
				<div className="wrapper">
					<MyPageHeader/>
					<div>
						<div style={{height: 'auto'}}>
							{content}
						</div>
					</div>
					<footer className="main-footer" style={{marginTop: 100}}>
						<div className="container">
							<strong>Copyright © <a href="http://www.jc-edu.co.jp">JC Educational Institute, Inc.</a>.</strong> All
							rights reserved.
						</div>
					</footer>
				</div>
			)
		}
	}
}

export default connect(
	state => ({
		printMode: state.mypage.printMode,
		// user_name: (state.sl_users.state) ? state.sl_users.state.name : undefined,
	}),
	// dispatch => ({
	// 	handleLogout: () => {
	// 		logout(dispatch);
	// 	},
	// 	handleMyAccount: function () {
	// 		alert("未実装です。実装されるまでお待ち下さい")
	// 	},
	// 	handleChangeEmail: function () {
	// 		alert("未実装です。実装されるまでお待ち下さい")
	// 	},
	// 	handleChangePassword: function () {
	// 		alert("未実装です。実装されるまでお待ち下さい")
	// 	},
	// })
)(MyPageFrame);
