import React from "react";
import FkToolbar from './FkToolbar'
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import _ from 'lodash'

type Props = {
	title: string,
	// selections: Array<Selection>,
	labelKv: any,
	onUpdate: (string) => void,
	onCancel: () => void,
}

export default class ListSelector extends React.Component<Props> {
	render() {
		const {title, labelKv, onCancel, onUpdate} = this.props
		return (
			<div>
				<FkToolbar
					title={title}
					onGoBack={onCancel}
				/>
				<List
					component="nav"
					// subheader={<ListSubheader component="div">カテゴリーを選択して下さい</ListSubheader>}
				>
					{_.map(labelKv, (v,k) => (
						<ListItem key={k} button onClick={onUpdate.bind(this, k)}>
							<ListItemText primary={v}/>
						</ListItem>
					))}
				</List>
			</div>
		)
	}
}

