// @flow
import React from 'react';
// import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import {TextInputState} from "../sl_utils/SlForms";

const styles = theme => ({
  textFieldRoot: {
    padding: 0,
    marginTop: 2,
    // 'label + &': {
    //   marginTop: theme.spacing.unit * 3,
    // },
  },
  textFieldInput: {
    // borderRadius: 4,
    backgroundColor: theme.palette.common.white,
    border: '1px solid #ced4da',
    fontSize: 16,
		// borderColor: '#80bdff',
		borderColor: '#d2d6de',
		// boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
    padding: '5px 6px',
		display: 'block',
    // width: '100%',
    // transition: theme.transitions.create(['border-color', 'box-shadow']),
    // '&:focus': {
    //   borderColor: '#80bdff',
    //   boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
    // },
  },
  textFieldFormLabel: {
    fontSize: 18,
  },
});

type Props = {
	classes: any,
	label?: string,
	placeholder?: string,
	fullWidth?: boolean,
	state: TextInputState,
	onChange: (TextInputState) => void,
	margin?: string,
	shrink?: boolean,
	disableAnimation?: boolean,
	reserveErrorTextArea?: boolean,
}
class BsLikeTextField extends React.Component<Props> {
	static defaultProps = {
		fullWidth: true,
		margin: "none",
		shrink: true,
		disableAnimation: true,
	}

	onChange = (event:SyntheticInputEvent<*>) => {
		const {onChange, state} = this.props
		onChange(state.setValue(event.target.value))
	}

	render() {
		const {classes, label, placeholder, state, fullWidth, margin, onChange, shrink, disableAnimation, reserveErrorTextArea, ...other} = this.props

		// if (reserveErrorTextArea) {
		// 	errorTextEl = (
		// 		<div style={{marginTop:5,marginBottom:10,height:20}}>{errorTextEl}</div>
		// 	)
		// }
		return (
				<TextField
					label={label}
					placeholder={placeholder}
					fullWidth={fullWidth}
					error={state.isError()}
					helperText={state.getError()}
					value={state.getValue()}
					onChange={this.onChange}
					margin={margin}
					InputLabelProps={{
						shrink,
						disableAnimation,
					}}
					{...other}

					// fullWidth
					// defaultValue="react-bootstrap"
					// label="Bootstrap"
					// id="bootstrap-input"
					InputProps={{
						disableUnderline: true,
						classes: {
							root: classes.textFieldRoot,
							input: classes.textFieldInput,
						},
					}}
					// InputLabelProps={{
					// 	shrink: true,
					// 	className: classes.textFieldFormLabel,
					// }}
				/>
		);
	}
}
export default withStyles(styles)(BsLikeTextField);