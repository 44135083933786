// @flow
import React from 'react'
// import Button from '@material-ui/core/Button'
import Fab from '@material-ui/core/Fab';


type Props = {
	label: string,
	color?: string,
	bottom: number,
	children: any,
	onClick: () => void,
	foreColor?: string,
	backgroundColor?: string,
}
const FloatingIcon = (props: Props) => (
	<Fab
		aria-label={props.label}
		color={props.color || "default"}
		style={{
			position: "fixed",
			bottom: (props.bottom || 50) + "%",
			right: 35,
			cursor: "pointer",
			zIndex: 999,
			color: props.foreColor || 'default',
			backgroundColor: props.backgroundColor || 'default',
		}}
		onClick={props.onClick}
	>
		{props.children}
	</Fab>

)

export default FloatingIcon