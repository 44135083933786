import React from 'react'
import { connect } from 'react-redux'
import InputDigitCode from '../../parts/InputDigitCode'
import { orange, grey, common } from '@material-ui/core/colors';
import Paper from '@material-ui/core/Paper';
import FkToolbar from '../../parts/FkToolbar'
import { withRouter } from 'react-router-dom'
import { Flex, Box } from 'reflexbox'
import Button from '@material-ui/core/Button';
import SlTextField from '../../sonodalib/sl_mui/SlTextField'
import { logout } from '../../sonodalib/sl_users/SlUsersAction'
import { TextInputState } from '../../sonodalib/sl_utils/SlForms'
import Cookies from "js-cookie";
import {post} from '../../sonodalib/sl_utils/Http'
import {altName, urlPref} from '../../alt/constants'


class ChangeEmailComponent extends React.Component {
	digitsId = undefined

	state = {
		stepIndex: 0,
		emailState: new TextInputState({validations: "required,email"}),
	}

	handlePassedDigitCode = () => {
		const payload = {
			digit_id: this.digitsId,
			user_token: Cookies.get(altName),
		}
		post("user/change_email_step3", payload)
			.then(ret => {
				this.setState({stepIndex: 2})
			})
			.catch(ret => {
				alert("不明なエラーが発生しました")
			})
	}

	handleSubmitEmail = (event) => {
		event.preventDefault()
		this.setState({
			emailState: this.state.emailState.validate()
		}, () => {
			if (this.state.emailState.isValid()) {
				const self = this
				post("user/change_email_step1", {email: this.state.emailState.getValue()})
					.then(ret => {
						self.digitsId = ret.digits_id
						self.setState({stepIndex: 1})
					})
					.catch(res => {
						let errorMessage
						if (res && res.statusCode === 401) {
							errorMessage = "ご指定のメールアドレスはすでに登録されています"
						} else {
							errorMessage = "不明なエラーが発生しました"
						}
						self.setState({
							emailState: this.state.emailState.setError(errorMessage)
						})
					})
			}
		})
	}

	handleSubmitDigitCode = (event) => {
	}

	render() {
	  const styles = {
			toolbarTitle: {
				color: common.white,
			},
		  paper: {
			  padding: 20,
			  overflow: 'auto'
		  },
		  buttonsDiv: {
			  textAlign: 'center',
			  padding: 10
		  },
		  flatButton: {
			  color: grey[500]
		  },
		  checkRemember: {
			  style: {
				  float: 'left',
				  maxWidth: 180,
				  paddingTop: 5
			  },
			  labelStyle: {
				  color: grey[500]
			  },
			  iconStyle: {
				  color: grey[500],
				  borderColor: grey[500],
				  fill: grey[500]
			  }
		  },
		  loginBtn: {
			  float: 'right',
			  marginLeft: 8,
		  },
		  btn: {
			  background: '#4f81e9',
			  color: common.white,
			  padding: 7,
			  borderRadius: 2,
			  margin: 2,
			  fontSize: 13
		  },
		  btnFacebook: {
			  background: '#4f81e9'
		  },
		  btnGoogle: {
			  background: '#e14441'
		  },
		  btnSpan: {
			  marginLeft: 5
		  },
			desc: {
				fontSize: 13,
				color: grey[700],
		  },
			digitCodeDescEmail: {
				color: orange[900],
				marginLeft: 8,
				marginRight: 8,
				fontWeight: 700,
		  },
		  errorText: {
				color: 'red',
				marginBottom: 10,
		  },
		  digitWrapper: {
				width: 300,
				textAlign: "center",
		  },
	  };

		const { stepIndex, emailState } = this.state

		let content = null
		switch (stepIndex) {
			case 0:
				content = (
					<form onSubmit={this.handleSubmitEmail}>
						<Flex column>
							<Box p={2} col={12}>
								<div>
									<SlTextField
										label="新しいメールアドレス"
										placeholder="jc@example.com"
										state={emailState}
										onChange={(newState) => {this.setState({emailState: newState})}}
									/>
								</div>
							</Box>
						</Flex>
						<div className="pull-right">
							<Button
								variant="contained"
								type="submit"
								color="primary"
							>
								上記アドレスに、確認メールを送信
							</Button>
						</div>
					</form>
				)
				break

			case 1:
				content = (
					<InputDigitCode
						digitsId={this.digitsId}
						appliedEmail={this.state.emailState.getValue()}
						expiredDesc={<span>お手数ですが、<a onClick={() => {
							this.setState({stepIndex: 0})
						}}>再度確認メールを受信</a> して下さい</span>}
						onFinish={this.handlePassedDigitCode}
						onCancel={() => {
							this.setState({stepIndex: 0})
						}}
					/>
				)
				break

			default:	// means 2
				content = (
					<Paper style={styles.paper}>
						<h3>
							変更手続きが完了しました
						</h3>
						<Flex column>
							<Box p={2}>
								新しいメールアドレスで、再度ログインして下さい
							</Box>
							<Box p={2}>
								<Button
									variant="contained"
									color="primary"
									onClick={this.props.handleLogin.bind(this)}
								>
									ログイン
								</Button>
							</Box>
						</Flex>
					</Paper>
				)
				break
		}

		return (
			<div style={{maxWidth: 860}}>
				<FkToolbar
					title="メールアドレスの変更"
				/>
				{content}
			</div>
		)
	}
}

export default connect(
	state => ({}),
	dispatch => ({
		handleLogin: function() {
			logout(dispatch)
			window.location.href = urlPref+"/login"
			// this.props.history.push("/login")
		},
		handleBack: function() {
			this.props.history.goBack()
		},
	})
)(withRouter(ChangeEmailComponent))
