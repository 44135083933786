import React from 'react'
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import Button from "@material-ui/core/Button";
import Choice from './Choice'
import {common, pink} from "@material-ui/core/colors";
import ForwardIcon from "@material-ui/icons/Forward";
import FloatingIcon from './FloatingIcon'
import {CSSTransition} from "react-transition-group";
import {updateSct} from "../SctAction";
import Box from '@material-ui/core/Box';
import Test from './'


class TestQuestion extends React.Component {
	componentDidMount() {
		const {sctState} = this.props
		const {qno, questions} = sctState
		if (!sctState.testSubjectCode || !questions || qno === null) {
			this.props.history.push("/sct")
		}
	}

	// 選択肢をクリック
	handleSelect = (choice) => () => {
		const {sctState} = this.props
		const {qno, questions, choices, selectHistory} = sctState
		let newSelectHistory
		if (choice.selected) {
			newSelectHistory = selectHistory.filter((x) => (x !== choice.index))
		} else {
			const question = questions[qno]
			if (selectHistory.length >= question.anscount) {
				const index = selectHistory.shift()
				choices[index].selected = false
			}
			selectHistory.push(choice.index)
			newSelectHistory = selectHistory
		}
		choice.selected = !choice.selected
		updateSct({
			choices: choices.concat(),
			selectHistory: newSelectHistory
		})
	}

	// 次へ進むフローティングアイコンをクリック
	handleNext = () => {
		const {sctState, history} = this.props
		const {qno, questions, choices, answers, liner} = sctState
		const newQno = qno + 1
		answers[qno] = choices.filter(choice => choice.selected).map((choice) => choice.index+1).join("")
		if (!liner || newQno === questions.length) {
			updateSct({
				answers: answers,
			})
			history.push("/sct/test/list")
		} else {
			const perPageState = Test.createTestPerPageState(questions, newQno)
			updateSct({
				qno: 		 newQno,
				answers: answers,
				...perPageState
			})
		}
	}

	// あとで解答するをクリック
	handleSkip = () => {
		const {sctState, history} = this.props
		const {qno, questions, liner} = sctState
		const newQno = qno + 1
		if (!liner || newQno === questions.length) {
			history.push("/sct/test/list")
		} else {
			const perPageState = Test.createTestPerPageState(questions, newQno)
			updateSct({
				qno: 		 newQno,
				...perPageState
			})
		}
	}

	render() {
		const {sctState} = this.props
		const {qno, questions, choices, selectHistory} = sctState
		if (!questions || !choices) return null
		const question = questions[qno]
		const selectionVd = choices.map((choice) => (
			<Choice
				key={"choice"+choice.index}
				choice={choice}
				withBorder={true}
				onClick={this.handleSelect(choice)}
			/>
		))
		const nextButtonVd = (selectHistory.length !== question.anscount) ? null : (
			<FloatingIcon
				label="次"
				foreColor={common.white}
				backgroundColor={pink[400]}
				bottom={40}
				onClick={this.handleNext}
			>
				<ForwardIcon/>
			</FloatingIcon>
		)
		return (
			<div style={{
				marginTop: 20,
				marginBottom: 100,
				maxWidth: 800,
				marginLeft: "auto",
				marginRight: "auto",
			}}>
				<div style={{marginLeft: 8, marginRight: 8, fontSize:16}}>
					<div style={{marginBottom:20,paddingTop:30,paddingBottom:30,borderTop:'1px solid #ccc',borderBottom:'1px solid #ccc'}}>
						<span className="wf-roundedmplus1c" style={{fontSize:24,fontWeight:500,color:'#2080E4'}}>
							セルフチェックテスト
						</span>
					</div>
					<div>
						<Box my={4}>
							<span style={{fontSize:20, fontWeight:600, color:'#d37111'}}>■ {sctState.testSubjectName}</span>
							<span style={{fontSize:20, color:'grey', marginLeft:30}}>{qno+1}/{questions.length}問</span>
						</Box>
					</div>
					<div style={{marginTop:40}}>
						<div style={{fontWeight:'bold', letterSpacing:2}}>
							問題 {qno+1}
						</div>
						<div style={{marginTop:8}} dangerouslySetInnerHTML={{__html: question.qtext.replace(/\n/g, "<br />")}}>
						</div>
						<div style={{marginTop:28}}>
							{selectionVd}
						</div>
						<div style={{textAlign:'center', marginTop:30}}>
							<Button
								variant="text"
								style={{backgroundColor:'#269A62',color:'white',width:300}}
								onClick={this.handleSkip}
							>
								あとで解答する
							</Button>
						</div>
					</div>
				</div>
				<CSSTransition
					in={false}
					classNames="fade"
					timeout={500}
				>
					<div>
						{nextButtonVd}
					</div>
				</CSSTransition>
			</div>
		)
	}
}

export default connect(
	state => ({
		sctState: state.sct,
	})
)(withRouter(TestQuestion));
