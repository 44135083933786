import React from 'react'
import {connect} from 'react-redux'
import PropTypes from 'prop-types'
import Grid from '@material-ui/core/Grid';
import {FeatherIcon} from '../Common'
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import {common, grey, pink, blue, indigo} from '@material-ui/core/colors';
import Icon from '@material-ui/core/Icon'
import Cookies from "js-cookie";
import {post} from '../sonodalib/sl_utils/Http'
import {altName,urlPref} from '../alt/constants'


// export class FkToolbar extends React.Component {
// 	static propTypes = {
// 		title: PropTypes.string.isRequired,
// 		onGoBack: PropTypes.func,
// 	}
//
// 	render() {
// 		const styles = {
// 			appBar: {
// 				marginTop: 10,
// 			},
// 			toolBar: {
// 				minHeight: 48,
// 				backgroundColor: blue[500],
// 			},
// 			toolBarTitle: {
// 				// marginLeft: 20,
// 				color: common.white,
// 				whiteSpace: 'nowrap',
// 			},
// 		}
// 		const goBackIcon = (this.props.onGoBack) ? (
// 			<span>
// 				<IconButton style={{color:'white'}} aria-label="戻る" onClick={this.props.onGoBack.bind(this)}>
// 					<Icon>arrow_back</Icon>
// 				</IconButton>
// 			</span>
// 		) : null
// 		return (
// 			<AppBar style={styles.appBar} position="static">
// 				<Toolbar style={styles.toolBar} disableGutters={true}>
// 					<Grid container align="center">
// 						<Grid item>
// 							{goBackIcon}
// 						</Grid>
// 						<Grid item>
// 							<span style={styles.toolBarTitle}>
// 								{this.props.title}
// 							</span>
// 						</Grid>
// 						<Grid item xs style={{textAlign: 'right', paddingRight: 20}}>
// 							{this.props.children}
// 						</Grid>
// 					</Grid>
// 				</Toolbar>
// 			</AppBar>
// 		)
// 	}
// }
//

export const SentakuResultListItem = connect(
	state => ({
		sentakuResults: state.question.sentakuResults,
	}),
	dispatch => ({})
)(class SentakuResultListItem_ extends React.Component {
	state = {
		total: "",
		feather_count: 0,
	}

	static propTypes = {
		index: PropTypes.number.isRequired,
		question: PropTypes.object.isRequired,
		onClick: PropTypes.func,
	}

	componentWillMount() {
		const self = this
		const {question} = this.props
		post("result/fetch/sentaku/stats", {
			user_token: Cookies.get(altName),
			mondai_id: question.MondaiID,
		})
		.then(ret => {
			self.setState({
				total: ret.total,
				feather_count: ret.feather_count,
			})
		})
	}

	handleClick = index => {
		if (this.props.onClick) {
			this.props.onClick(index)
		}
	}

	render() {
		const styles = {
			listLeftIcon: {
				display: 'inline-block',
				color: common.white,
				width: 86,
				textAlign: 'center',
				marginLeft: 2,
				marginRight: 8,
				fontSize: 14,
				height: 36,
			},
			listInnerDiv: {
				padding: 10,
				backgroundColor: "#e8eaf6",
				height: 58,
				lineHeight: "38px",
			},
		}
		const {index, question} = this.props
		const sentakuResults = this.props.sentakuResults
		const leftIcons = [
			<span style={{...styles.listLeftIcon, backgroundColor: "#b70f0a"}}>正解</span>,
			<span style={{...styles.listLeftIcon, backgroundColor: "#2196f3"}}>不正解</span>,
			<span style={{...styles.listLeftIcon, backgroundColor: common.white, color: common.black}}>未解答</span>,
		]
		const leftIcon = leftIcons[sentakuResults[index] - 1]
		// const featherIcon = _.times(this.state.feather_count, () => <FeatherIcon key={_.uniqueId("fk")}/>)
		const featherIcon = <FeatherIcon count={this.state.feather_count}/>
		return (
			<ListItem button onClick={this.handleClick.bind(this, index)} style={{...styles.listInnerDiv, marginBottom: 4}}>
				<ListItemIcon>
					{leftIcon}
				</ListItemIcon>
				<ListItemText primary={<span>第{index + 1}問 {question.subLabel} 累計{this.state.total}回目</span>}/>
				<ListItemSecondaryAction>
					<div style={{marginTop: 8, marginRight: 10}}>
						{featherIcon}
					</div>
				</ListItemSecondaryAction>
			</ListItem>
		)
	}
})


export const OxResultListItem = connect(
	state => ({
		oxResults: state.question.oxResults,
	}),
	dispatch => ({})
)(class extends React.Component {
	state = {
		total: "",
		feather_count: 0,
	}

	static propTypes = {
		index: PropTypes.number.isRequired,
		question: PropTypes.object.isRequired,
		onClick: PropTypes.func,
	}

	componentWillMount() {
		const self = this
		const {question} = this.props
		post("result/fetch/ox/stats", {
			user_token: Cookies.get(altName),
			marubatsu_id: question.MarubatsuID,
		})
		.then(ret => {
			self.setState({
				total: ret.total,
				feather_count: ret.feather_count,
			})
		})
	}

	handleClick = index => {
		if (this.props.onClick) {
			this.props.onClick(index)
		}
	}

	render() {
		const styles = {
			listLeftIcon: {
				display: 'inline-block',
				color: common.white,
				width: 86,
				textAlign: 'center',
				marginLeft: 2,
				marginRight: 8,
				fontSize: 14,
				height: 36,
			},
			listInnerDiv: {
				padding: 10,
				backgroundColor: "#e8eaf6",
				height: 58,
				lineHeight: "38px",
			},
		}
		const {index, question} = this.props
		const oxResults = this.props.oxResults
		const leftIcons = [
			<span style={{...styles.listLeftIcon, backgroundColor: "#b70f0a"}}>正解</span>,
			<span style={{...styles.listLeftIcon, backgroundColor: "#2196f3"}}>不正解</span>,
			<span style={{...styles.listLeftIcon, backgroundColor: common.white, color: common.black}}>未解答</span>,
		]
		const leftIcon = leftIcons[oxResults[index] - 1]
		// const featherIcon = _.times(this.state.feather_count, () => <FeatherIcon key={_.uniqueId("fk")}/>)
		const featherIcon = <FeatherIcon count={this.state.feather_count}/>
		return (
			<ListItem button onClick={this.handleClick.bind(this, index)} style={{...styles.listInnerDiv, marginBottom: 4}}>
				<ListItemIcon>
					{leftIcon}
				</ListItemIcon>
				<ListItemText primary={<span>第{index + 1}問 {question.subLabel} 累計{this.state.total}回目</span>}/>
				<ListItemSecondaryAction>
					<div style={{marginTop: 8, marginRight: 10}}>
						{featherIcon}
					</div>
				</ListItemSecondaryAction>
			</ListItem>
		)
	}
})


export class SentakuChoiceResult extends React.Component {
	static propTypes = {
		choice: PropTypes.object.isRequired,
		showResult: PropTypes.number.isRequired,
		onClick: PropTypes.func,
		button: PropTypes.bool,
		withBorder: PropTypes.bool,
	}

	handleClick = choice => {
		if (this.props.onClick) {
			this.props.onClick(choice)
		}
	}

	render() {
		const styles = {
			correctBorder: {
				borderStyle: 'solid',
				borderWidth: 3,
				borderColor: pink[500],
				padding: 4,
			},
			correctLabel: {
				marginTop: 8,
				fontSize: 16,
				color: pink[700],
				// backgroundColor: pink400,
				fontWeight: 600,
				textAlign: 'left',
				height: 26,
				padding: 0,
				margin: 0,
				lineHeight: "26px",
			},
			yourAnswerBorder: {
				borderStyle: 'dashed',
				borderWidth: 3,
				borderColor: blue[600],
				padding: 4,
			},
			yourAnswerLabel: {
				marginTop: 8,
				fontSize: 16,
				color: indigo[900],
				// backgroundColor: pink400,
				fontWeight: 600,
				textAlign: 'left',
				height: 26,
				padding: 0,
				margin: 0,
				lineHeight: "26px",
			},
		}
		const {choice, showResult, withBorder} = this.props
		const iconName = ["looks_one", "looks_two", "looks_3", "looks_4", "looks_5", "looks_6"]
		const showCorrect = (showResult && choice.correct)
		// const yourAnswer = (showResult && !choice.correct && choice.selected)
		const yourAnswer = (showResult && choice.selected)
		const divStyle = (withBorder) ? {
			backgroundColor: (choice.selected) ? "#f7f6f6" : "#e8eaf6",
			borderWidth: 2,
			borderColor: (choice.selected) ? indigo[400] : "#e8eaf6",
			borderStyle: 'solid',
			boxSizing: 'border-box',
		} : {
			backgroundColor: (choice.selected) ? "#f7f6f6" : "#e8eaf6",
		}
		const listItemStyle = {
			fontWeight: (choice.selected) ? 600 : 100,
		}
		const leftIconStyle = {
			// marginTop: '-0.5em',
			left: 12,
			top: '50%',
		}
		const leftIcon = (
			<Icon style={{...leftIconStyle, color: (choice.selected) ? pink[500] : indigo[400]}}>
				{iconName[choice.index]}
			</Icon>
		)
		const isButton = !!this.props.button
		let vd = (
			<div>
				<div
					style={divStyle}
					key={choice.index}
				>
					<ListItem button={isButton} onClick={this.handleClick.bind(this, choice)} style={listItemStyle}>
						<ListItemIcon>
							{leftIcon}
						</ListItemIcon>
						<ListItemText primary={choice.text}/>
					</ListItem>
				</div>
				{(choice.showCommentary) ? (
					<CommentaryBox
						key={choice.index + 1000}
						text={choice.commentary}
					/>
				) : null}
			</div>
		)
		if (yourAnswer) {
			vd = (
				<div>
					<div style={styles.yourAnswerLabel}>あなたの回答</div>
					<div style={styles.yourAnswerBorder} key={choice.index}>
						{vd}
					</div>
				</div>
			)
		}
		if (showCorrect) {
			vd = (
				<div>
					<div style={styles.correctLabel}>正解</div>
					<div style={styles.correctBorder} key={choice.index}>
						{vd}
					</div>
				</div>
			)
		}
		vd = (
			<div style={{marginBottom: 4}} key={choice.index}>
				{vd}
			</div>
		)
		return vd
	}
}


export class CommentaryBox extends React.Component {
	static propTypes = {
		text: PropTypes.string.isRequired,
	}

	render() {
		const styles = {
			leftIconStyle: {
				marginTop: '-0.5em',
				left: 12,
				top: '50%',
			},
			commentaryBox: {
				marginTop: 4,
				fontSize: 14,
				color: grey[800],
				backgroundColor: "#f7fade",
			}
		}
		const {text, other} = this.props
		return (
			<ListItem dense={true} style={styles.commentaryBox} {...other}>
				<ListItemIcon>
					<Icon style={{...styles.leftIconStyle, color: pink[700]}}>school</Icon>
				</ListItemIcon>
				<ListItemText primary={text}/>
			</ListItem>
		)
	}
}


export class QuestionResult extends React.Component {
	static propTypes = {
		seikaiCount: PropTypes.number.isRequired,
		huseikaiCount: PropTypes.number.isRequired,
		mukaitouCount: PropTypes.number.isRequired,
		jisshi: PropTypes.number.isRequired,
		jisshiPlus: PropTypes.number.isRequired,
		shujuku: PropTypes.number.isRequired,
		shujukuPlus: PropTypes.number.isRequired,
	}

	render() {
		const styles = {
			resultCount: {
				fontSize: 24,
				fontWeight: 800,
				marginLeft: 8,
			},
			gif: {
				marginRight: 8,
			},
		}
		const {
			seikaiCount,
			huseikaiCount,
			mukaitouCount,
			// jisshi,
			// jisshiPlus,
			// shujuku,
			// shujukuPlus,
		} = this.props
		// const jisshiP =  (jisshiPlus  < 0) ? ""+jisshiPlus  : "+"+jisshiPlus
		// const shujukuP = (shujukuPlus < 0) ? ""+shujukuPlus : "+"+shujukuPlus
		return (
			<div>
				<Grid container justify="center" alignItems="center" spacing={4} style={{marginTop: 10}}>
					<Grid item>
						<span style={styles.resultCount}>
							<img src={urlPref+"/images/common/mrk_seikai.gif"} style={styles.gif} role="presentation" alt="seikai"/>
							{seikaiCount} 問
						</span>
					</Grid>
					<Grid item>
						<span style={styles.resultCount}>
							<img src={urlPref+"/images/common/mrk_fuseikai.gif"} style={styles.gif} role="presentation" alt="fuseikai"/>
							{huseikaiCount} 問
						</span>
					</Grid>
					<Grid item>
						<span style={styles.resultCount}>
							<img src={urlPref+"/images/common/mrk_not.gif"} style={styles.gif} role="presentation" alt="not"/>
							{mukaitouCount} 問
						</span>
					</Grid>
				</Grid>
				{/*<Grid container justify="flex-end">*/}
				{/*実施度 {jisshi}%({jisshiP})  習熟度 {shujuku}%({shujukuP})*/}
				{/*</Grid>*/}
			</div>
		)
	}
}
