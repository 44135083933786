// @flow weak
import React from 'react'
import _ from 'lodash'
import List from '@material-ui/core/List'
import Icon from '@material-ui/core/Icon'
import IconButton from '@material-ui/core/IconButton';
import '../sonodalib/sl_utils/utils.css'
import {post} from '../sonodalib/sl_utils/Http'
import { SentakuChoiceResult } from './CommonParts'
import FkToolbar from '../parts/FkToolbar'
import { SubjectBox, QuestionBox } from '../Common'
import MemoIconButton from '../parts/MemoIconButton'
import { withMemoDrawer } from '../parts/MemoDrawer'
import { withBbsDrawer } from '../parts/BbsDrawer'

type Props = {
	match: any,
	memoDrawer: any,
	memoDrawerStatus: boolean,
	bbsDrawer: any,
	history: any,
}
type State = {
	question: any,
}

class SentakuShowAnswer extends React.Component<Props, State> {
	state = {
		question: null,
	}

	componentWillMount() {
		const mondaiId = this.props.match.params.mondaiId
		this.props.memoDrawer.load({
			mondaiId,
			marubatsuId: 0,
		})
		post("q/fetch/sentaku", {mondai_id: mondaiId})
			.then(ret => {
				_.forEach(ret.question.choices, choice => {
					choice.showCommentary = true
				})
				this.setState({
					question: ret.question,
				})
			})
	}

	render() {
		const { question } = this.state
		if (!question) return null
		const { choices } = question
		const { mondaiId } = this.props.match.params

		const toolbarIcons = (
			<div>
				<MemoIconButton
					handleOpenMemo={() => {this.props.memoDrawer.open(null)}}
					isMemoActive={this.props.memoDrawerStatus}
				/>
				<IconButton
					style={{color:'white'}}
					aria-label="掲示板"
					onClick={() => {
						this.props.bbsDrawer.openToCreateThread(
							1,
							undefined,
							mondaiId,
							0,
							"",
						)
					}}
				>
					<Icon>comment</Icon>
				</IconButton>
			</div>
		)

		const choiceVd = _.map(choices, choice => (
			<SentakuChoiceResult
				key={_.uniqueId("fk")}
				choice={choice}
				showResult={1}
				withBorder={false}
			/>
		))

		return (
			<div>
				<FkToolbar title="問題詳細" onGoBack={() => {this.props.history.goBack()}}>
					{toolbarIcons}
				</FkToolbar>

				<SubjectBox label={question.subjectLabel+"　"+question.subLabel}/>
				<QuestionBox text={question.qText}/>

				<List style={{marginLeft:12, marginRight:12}}>
					{choiceVd}
				</List>
			</div>
		)
	}
}

export default withMemoDrawer(withBbsDrawer(SentakuShowAnswer))
