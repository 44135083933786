import React from 'react'
import { common, deepOrange } from '@material-ui/core/colors';
import PropTypes from 'prop-types'
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide'
import Button from '@material-ui/core/Button';
import Fab from '@material-ui/core/Fab';


export class TestModeLastTime extends React.Component {
	static propTypes = {
		timer: PropTypes.string.isRequired,
	}
	render() {
		const styles = {
			timer: {
				position: "fixed",
				top: 56,
				right: "10%",
				// cursor: "pointer",
				zIndex: 999,
				color: common.white,
				fontWeight: 800,
				fontSize: 16,
				backgroundColor: deepOrange[800],
			},
		}
		return (this.props.timer) ? (
			<Fab aria-label="タイマー" style={styles.timer}>
				残り<br/>{this.props.timer}
			</Fab>
 		) : null
	}
}


export class TestModeDialog extends React.Component {
	static propTypes = {
		open: PropTypes.bool.isRequired,
		onClick: PropTypes.func.isRequired,
	}

	render() {
		return (
			<Dialog open={this.props.open} transition={Slide}>
				<DialogTitle>時間切れです</DialogTitle>
				<DialogActions>
					<Button onClick={this.props.onClick}>
						結果画面へ
					</Button>
				</DialogActions>
			</Dialog>
		)
	}
}