import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { common, lightBlue, pink, deepPurple } from '@material-ui/core/colors';
import List from '@material-ui/core/List'
import ForwardIcon from '@material-ui/icons/Forward'
import _ from 'lodash'
import { CSSTransition } from 'react-transition-group';
import '../sonodalib/sl_utils/utils.css'
import Button from '@material-ui/core/Button';
import { Flex, Box } from 'reflexbox'
import { updateJireiQuestionAC } from './QuestionAction'
import G from '../sonodalib/SlGlobal'
import { SentakuChoiceResult } from './CommonParts'
import FkToolbar from '../parts/FkToolbar'
import { SubjectBox, QuestionBox } from '../Common'
import FloatingIcon from '../parts/FloatingIcon'
import {urlPref, jireiLabel} from '../alt/constants'


class JireiQuestion extends React.Component {
	static propTypes = {
		onEnd: PropTypes.func.isRequired,
	}

	// state = {
	// 	answerCount: 1,
	// 	choices: [
	// 		{
	// 			index: 0,
	// 			selected: false,
	// 			text: "ロストウ（Rostow,W.W.）によれば、社会の関心は、供給から需要、生産から消費へと移っていく。",
	// 			commentary: "５歳児のＦ男ちゃんは、弟が生まれたことをきっかけに、年齢に合わない指しゃぶりを始めたことから「退行」である。なお「昇華」とは、非社会的欲求（性欲・攻撃欲）を芸能、文化、スポーツなどの社会的に承認される行動に、振り替えることで満たそうとすることをいう。例として、失恋の悲しみを仕事に向けるなどが挙げられる。",
	// 			correct: false,
	// 			showCommentary: false,
	// 		},
	// 		{
	// 			index: 1,
	// 			selected: false,
	// 			text: "ガルブレイスの依存効果とは、消費者の欲望が自律的でなく、生産者の働きかけによって喚起される現象をいう。消費部門が生産部門に依存する依存効果が見られる。",
	// 			commentary: "自分が非常に憎んでいる父親に対し、かえって気遣いをし、過剰な配慮をする高校生のＧ男さんの行動は、抑圧するだけでは処理しがたい強力な嫌悪感や衝動を防衛するために、意識の上では正反対な行動を示す「反動形成」にあたる。よって正しい。",
	// 			correct: true,
	// 			showCommentary: false,
	// 		},
	// 	],
	// 	selectHistory: [],
	// 	showResult: 0,
	// }

	handleClickChoice = (choice) => {
		if (!this.props.jireiQuestion.showResult) {
			const { answerCount, selectHistory, choices } = this.props.jireiQuestion
			if (choice.selected) {
				const _selectHistory = _.reject(selectHistory, a => (a===choice.index))
				choice.selected = false
				G.store.dispatch(updateJireiQuestionAC({
					choices: this.props.jireiQuestion.choices,
					selectHistory: _selectHistory,
				}))
			} else {
				if (selectHistory.length >= answerCount) {
					const index = selectHistory.shift()
					choices[index].selected = false
				}
				choice.selected = true
				selectHistory.push(choice.index)
				G.store.dispatch(updateJireiQuestionAC({
					choices: this.props.jireiQuestion.choices,
					selectHistory: selectHistory,
				}))
			}
		} else {
			choice.showCommentary = !choice.showCommentary
			G.store.dispatch(updateJireiQuestionAC({
				choices: this.props.jireiQuestion.choices,
			}))
		}
	}

	handleSaiten = () => {
		// const choose = []
		const choices = this.props.jireiQuestion.choices
		let correctCount = 0
		_.forEach(choices, choice => {
			if (choice.selected) {
				// choose.push(choice.index + 1)
				if (choice.correct) {
					correctCount++
				}
			}
			choice.showCommentary = (choice.selected || choice.correct)
		})
		const showResult = (correctCount === this.props.jireiQuestion.answerCount) ? 1 : 2

		this.props.updateJireiQuestion({
			showResult: showResult,
			choices: choices,
		})
	}

	handleAllCommentary = () => {
		let show = !this.isOpenAllCommentaries()
		_.forEach(this.props.jireiQuestion.choices, choice => {
			choice.showCommentary = show
		})
		G.store.dispatch(updateJireiQuestionAC({
			choices: this.props.jireiQuestion.choices,
		}))
	}

	isOpenAllCommentaries = () => {
		return _.every(this.props.jireiQuestion.choices, choice => {
			return choice.showCommentary
		})
	}

	handleNext = result => {
		this.props.onEnd(
			result,			// 1=正解  2=不正解  3=未解答　4=中断
		)
	}

	render() {
		if (this.props.jireiQuestion === null) return null

		const styles = {
			saitenButton: {
				position: "fixed",
				bottom: "50%",
				right: 35,
				cursor: "pointer",
				zIndex: 999,
			},
			nextButton: {
				position: "fixed",
				bottom: "30%",
				right: 35,
				cursor: "pointer",
				zIndex: 999,
				color: common.white,
				backgroundColor: pink[700],
			},
			headingCorrect: {
				fontSize: 42,
				fontWeight: 700,
				color: pink[700],
			},
			headingIncorrect: {
				fontSize: 24,
				fontWeight: 700,
				color: deepPurple[400],
			},
			commandBox: {
				margin: 8,
				marginBottom: 18,
			},
			commandButton: {
				width: 200,
				marginRight: 10,
			},
		}
		const jireiQuestion = this.props.jireiQuestion
		const { selectHistory, answerCount, choices, showResult, qIndex } = jireiQuestion
		const { jireiBun } = this.props.jireiQuestions

		const choiceVd = _.map(choices, choice => (
			<SentakuChoiceResult
				key={_.uniqueId("fk")}
				choice={choice}
				showResult={showResult}
				onClick={this.handleClickChoice.bind(this)}
				withBorder={(showResult===0)}
			/>
		))

		const saitenButtonVd = (showResult || selectHistory.length < answerCount) ? null : (
			<FloatingIcon
				label="採点"
				foreColor={common.white}
				backgroundColor={pink[400]}
				onClick={this.handleSaiten}
			>
				<ForwardIcon />
			</FloatingIcon>
		)

		const nextButtonVd = (!showResult) ? null : (
			<FloatingIcon
				label="次"
				foreColor={common.white}
				backgroundColor={pink[400]}
				bottom={40}
				onClick={this.handleNext.bind(this, showResult)}
			>
				<ForwardIcon />
			</FloatingIcon>
		)

		const correctLabel = [null, "正解", "不正解"]
		const correctImage = [null, "seikai", "fuseikai"]
		const isOpenAllCommentaries = this.isOpenAllCommentaries()
		const resultVd = (!showResult) ? null : (
			<Flex align="center" justify="center">
				<Box m={1}>
					<span style={styles.headingIncorrect}>{correctLabel[showResult]}</span>
					<img src={`${urlPref}/images/eval/${correctImage[showResult]}.png`} alt="result"/>
				</Box>
				<Box ml={3}>
					<Button
						variant="contained"
						style={{BackgroundColor: lightBlue[200]}}
						onClick={this.handleAllCommentary.bind(this)}
					>
						{(isOpenAllCommentaries) ? "解説をすべて隠す" : "解説をすべて開く"}
					</Button>
				</Box>
			</Flex>
		)

		const commandBox = (showResult) ? null : (
			<div style={styles.commandBox}>
				<Button
					variant="contained"
					onClick={this.handleNext.bind(this, 3)}
					// labelColor={white}
					// backgroundColor="#2196f3"
					style={styles.commandButton}
				>
					解答しない
				</Button>
				<Button
					variant="contained"
					onClick={this.handleNext.bind(this, 4)}
					// labelColor={white}
					// backgroundColor="#2196f3"
					style={styles.commandButton}
				>
					学習を中断
				</Button>
			</div>
		)

		return (
			<div>
				<CSSTransition
					classNames="fade"
					appear={true}
					timeout={500}
				>
					<div>
						{saitenButtonVd}
						{nextButtonVd}
					</div>
				</CSSTransition>

				<FkToolbar title={`${jireiLabel} 第${qIndex+1}問 ／ 全${this.props.jireiQuestions.questions.length}問`}/>

				<div style={{margin:'5px 16px'}}>
					<SubjectBox label={jireiBun.Jirei_Title}/>
					<div dangerouslySetInnerHTML={{__html: jireiBun.Jirei_Bun.replace(/\n/g,"<br />")}}></div>
					<QuestionBox text={jireiQuestion.qText}/>

					{resultVd}
					<List style={{marginLeft:12, marginRight:12}}>
						{choiceVd}
					</List>
					{commandBox}
				</div>
			</div>
		)
	}
}

export default connect(
	state => ({
		jireiQuestion: state.question.jireiQuestion,
		jireiQuestions: state.question.jireiQuestions,
	}),
	dispatch => ({
		updateJireiQuestion: entity => {
			dispatch(updateJireiQuestionAC(entity))
		}
	})
)(JireiQuestion)
