import React from 'react'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button';
// import TextField from '@material-ui/core/TextField';
import { orange } from '@material-ui/core/colors'
import Grid from '@material-ui/core/Grid'
import SlTextField from "../sonodalib/sl_mui/SlTextField";
import { TextInputState, FormState } from '../sonodalib/sl_utils/SlForms'
import CommandBox from './CommandBox'
import {post} from '../sonodalib/sl_utils/Http'
// import _ from 'lodash'

export default class InputDigitCode extends React.Component {
	static propTypes = {
		digitsId: PropTypes.string.isRequired,
		expiredDesc: PropTypes.node,
		onFinish: PropTypes.func.isRequired,
		onCancel: PropTypes.func.isRequired,
		appliedEmail: PropTypes.string.isRequired,
	}

	state = {
		digitCode: new TextInputState({validations: "require,integer"}),
		isExpired: false,
		errorText: "",
	}

	handleSubmit = e => {
		e.preventDefault()
		this.setState(FormState.validate(this.state), () => {
			if (FormState.isValid(this.state)) {
				const payload = {
					digit_id:   this.props.digitsId,
					digit_code: this.state.digitCode.getValue(),	//.replace("-",""),
				}
				post("user/auth_digitcode", payload)
					.then(ret => {
						if (ret && ret.status === 'expired') {
							this.setState({
								isExpired: true,
							})
						} else {
							this.props.onFinish()
						}
					})
					.catch(res => {
						let errorText;
						if (res && res.statusCode === 401) {
							errorText = "確認コードが間違っているか、すでに使用済みです"
						} else {
							errorText = "不明なエラーが発生しました"
						}
						this.setState({
							errorText: errorText,
						})
					})
			}
		})
	}

	render() {
		const styles = {
		  loginBtn: {
			  float: 'right',
			  marginLeft: 8,
		  },
			loginBox: {
				marginTop: 20,
				width: 450,
			},
			inputDigitCode: {
				marginTop: 34,
				width: 340,
			},
		  errorText: {
				color: 'red',
				marginBottom: 10,
		  },
			digitCodeDescEmail: {
				color: orange[900],
				marginLeft: 8,
				marginRight: 8,
				fontWeight: 700,
		  },
		}
		const { appliedEmail, expiredDesc } = this.props
		const { digitCode, errorText, isExpired } = this.state
		const expired = (isExpired) ? (
				<div>
					ご入力の確認コードは期限が切れております。<br />
					{expiredDesc || null}
				</div>
		) : null
		return (
			<Grid container direction="column" alignItems="center">
				<CommandBox
					label="確認コード"
					desc="確認コードを入力して下さい"
				/>
				<Grid style={styles.loginBox}>
					<span style={styles.digitCodeDesc}>
						確認コードが書かれたメールを
						<span style={styles.digitCodeDescEmail}>
							{appliedEmail}
						</span>
						に送信しましたので、ご確認の上、以下ご入力下さい。
					</span>
				</Grid>
				<Grid style={styles.inputDigitCode}>
					<form onSubmit={this.handleSubmit}>
						<SlTextField
							autoFocus
							type="number"
							placeholder="数字５ケタ"
							label="確認コード（半角）"
							fullWidth={true}
							state={digitCode}
							onChange={(newState) => {this.setState({digitCode: newState})}}
						/>
						<div style={styles.errorText}>
							{expired}
							{errorText}
						</div>
						<div>
							<Button variant="contained"
								color="primary"
								style={styles.loginBtn}
								type="submit"
							>
								次へ
							</Button>
							<Button variant="contained"
								style={styles.loginBtn}
								onClick={this.props.onCancel}
							>
								キャンセル
							</Button>
						</div>
					</form>
				</Grid>
			</Grid>
		)
	}
}

