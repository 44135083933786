import React from 'react'
import {connect} from 'react-redux'
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import {withRouter} from 'react-router-dom'
import {common} from '@material-ui/core/colors'
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import FkToolbar from '../../parts/FkToolbar'
import {getPrefectures} from '../../sonodalib/sl_utils/Address'
import {FormState, SelectorInputState, TextInputState} from '../../sonodalib/sl_utils/SlForms'
import Cookies from "js-cookie";
import {post} from '../../sonodalib/sl_utils/Http'
import TextFieldSelector from '../../sonodalib/sl_mui/TextFieldSelector'
import SlTextField from '../../sonodalib/sl_mui/SlTextField'
import {scrollToElWithName, snackbar} from '../../sonodalib/sl_utils/Utils'
import {altName, licenseList} from '../../alt/constants'


const notifyMails = [
	{id: 0, label: "受信しない"},
	{id: 1, label: "受信する"},
]
const grades = [
	{id: 0, label: "その他"},
	{id: 1, label: "１年"},
	{id: 2, label: "２年"},
	{id: 3, label: "３年"},
	{id: 4, label: "４年"},
	{id: 5, label: "卒業"},
]
class ProfileComponent extends React.Component {
	state = {
		uniqueId: new TextInputState(),
		dantai_id: "",
		dantaiName: new TextInputState(),
		licenseName: new TextInputState(),
		nameState: new TextInputState({name: "nameState", validations: "zenkaku"}),
		kanaState: new TextInputState({name: "kanaState", validations: "zenkaku"}),
		zipcodeState: new TextInputState({name: "zipcodeState", validations: "zipcode"}),
		pref: new SelectorInputState({name: "pref", menuList: getPrefectures()}),
		addressCityState: new TextInputState({name: "addressCityState"}),
		telnoState: new TextInputState({name: "telnoState", validations: "telno"}),
		schoolNameState: new TextInputState({name: "schoolNameState"}),
		selectedGrade: new SelectorInputState({name: "selectedGrade", menuList: grades}),
		selectedNotifyMail: new SelectorInputState({name: "selectedNotifyMail", menuList: notifyMails}),
		// schoolCodeState: new TextInputState({name: "schoolCodeState"}),
		confirmDialogOpen: false,
	}

	componentWillMount() {
		this.open(this.props);
	}

	open = (props) => {
		const payload = {
			user_token: Cookies.get(altName),
		}
		post("user/get_profile", payload)
		.then(ret => {
			this.setState({
				uniqueId: this.state.uniqueId.setValue(ret.uniqueId || ""),
				dantai_id: ret.dantai_id,
				dantaiName: this.state.dantaiName.setValue(ret.dantai_name || "なし"),
				licenseName: this.state.licenseName.setValue(licenseList[this.props.userState.license]),
				nameState: this.state.nameState.setValue(ret.name || ""),
				kanaState: this.state.kanaState.setValue(ret.kana || ""),
				zipcodeState: this.state.zipcodeState.setValue(ret.zipcode || ""),
				pref: this.state.pref.setValue(ret.addressPref),
				addressCityState: this.state.addressCityState.setValue(ret.addressCity || ""),
				// addressStreetState: this.state.addressStreetState.setValue(ret.addressStreet || ""),
				telnoState: this.state.telnoState.setValue(ret.telno || ""),
				schoolNameState: this.state.schoolNameState.setValue(ret.schoolName || ""),
				selectedGrade: this.state.selectedGrade.setValue(ret.grade),
				selectedNotifyMail: this.state.selectedNotifyMail.setValue(ret.notifymail_bbs),
				// schoolCodeState: this.state.schoolCodeState.setValue(ret.schoolCode || ""),
			})
		}).catch(err => {
			alert("ネットワークエラーが発生しました。リロードしてお試し下さい")
		})
	}

	handleUpdate = (e) => {
		e.preventDefault()
		this.setState(FormState.validate(this.state), () => {
			if (FormState.isValid(this.state)) {
				this.setState({confirmDialogOpen: true})
			} else {
				const names = FormState.getAllInvalidElementNames(this.state)
				if (names.length) scrollToElWithName(names[0])
			}
		})
	}

	handleConfirm = () => {
		const self = this
		const st = this.state
		const obj = {
			name: st.nameState.getValue(),
			kana: st.kanaState.getValue(),
			zipcode: st.zipcodeState.getValue(),
			addressCity: st.addressCityState.getValue(),
			// addressStreet: st.addressStreetState.getValue(),
			telno: st.telnoState.getValue(),
			addressPref: st.pref.getValue(),
			schoolName: st.schoolNameState.getValue(),
			// schoolCode: st.schoolCodeState.getValue(),
			grade: st.selectedGrade.getValue(),
			notifymail_bbs: st.selectedNotifyMail.getValue(),
		}
		const payload = {
			user_token: Cookies.get(altName),
			obj,
		}
		post("user/update_profile", payload)
		.then(ret => {
			self.setState({confirmDialogOpen: false})
			snackbar("変更しました")
		})
		.catch(res => {
			self.setState({confirmDialogOpen: false})
			snackbar("不明なエラーが発生しました")
		})
	}

	handleClose = () => {
		this.setState({confirmDialogOpen: false})
	}

	render() {
		// const prefItems = buildPrefMenuItems()
		const {
			nameState, kanaState, zipcodeState, addressCityState, telnoState,
			schoolNameState, //schoolCodeState,
		} = this.state

		const dantaiNameVd = (this.state.dantai_id === "") ? (
			<SlTextField
				label="団体名（変更不可）"
				state={this.state.dantaiName}
				onChange={() => {}}
			/>
		) : (
			<SlTextField
				label="団体名（変更不可）"
				state={this.state.dantaiName}
				onChange={() => {
				}}
			/>
		)
		return (
			<div>
				<Dialog
					open={this.state.confirmDialogOpen}
				>
					<DialogContent>
						<DialogContentText>
							変更してもよろしいですか？
						</DialogContentText>
					</DialogContent>
					<DialogActions>
						<Button
							variant="contained"
							onClick={this.handleClose}
						>
							キャンセル
						</Button>
						<Button
							variant="contained"
							color="primary"
							onClick={this.handleConfirm}
						>
							変更する
						</Button>
					</DialogActions>
				</Dialog>
				<FkToolbar
					title="個人情報"
				/>
				<form onSubmit={this.handleUpdate}>
					<div style={{margin: '16px auto',maxWidth:800}}>
						<Grid container spacing={4} style={{backgroundColor: common.white}}>
							<Grid item xs={12} sm={4}>
								<SlTextField
									label="利用者ID（変更不可）"
									state={this.state.uniqueId}
									onChange={() => {}}
								/>
							</Grid>
							<Grid item xs={12} sm={4}>
								{dantaiNameVd}
							</Grid>
							<Grid item xs={12} sm={4}>
								<SlTextField
									label="現在のライセンス（変更不可）"
									state={this.state.licenseName}
									onChange={() => {}}
								/>
							</Grid>
							<Grid item xs={12} sm={6}>
								<SlTextField
									label="お名前"
									name="nameState"
									state={nameState}
									onChange={(newState) => {
										this.setState({nameState: newState})
									}}
								/>
							</Grid>
							<Grid item xs={12} sm={6}>
								<SlTextField
									label="お名前（カナ）"
									name="kanaState"
									state={kanaState}
									onChange={(newState) => {
										this.setState({kanaState: newState})
									}}
								/>
							</Grid>
							<Grid item xs={12} sm={6}>
								<SlTextField
									label="郵便番号"
									name="zipcodeState"
									state={zipcodeState}
									onChange={(newState) => {
										this.setState({zipcodeState: newState})
									}}
								/>
							</Grid>
							<Grid item xs={12} sm={6}>
								<TextFieldSelector
									label="都道府県"
									state={this.state.pref}
									name="pref"
									fullWidth={true}
									onChange={(newState: SelectorInputState) => {
										this.setState({pref: newState})
									}}
									InputLabelProps={{
										shrink: true,
										disableAnimation: true,
									}}
								/>
							</Grid>
							<Grid item xs={12} sm={12}>
								<SlTextField
									label="住所"
									name="addressCityState"
									state={addressCityState}
									onChange={(newState) => {
										this.setState({addressCityState: newState})
									}}
								/>
							</Grid>
							<Grid item xs={12} sm={6}>
								<SlTextField
									label="電話番号"
									name="telnoState"
									state={telnoState}
									onChange={(newState) => {
										this.setState({telnoState: newState})
									}}
								/>
							</Grid>
							<Grid item xs={12} sm={6}>
							</Grid>
							<Grid item xs={12} sm={6}>
								<SlTextField
									label="学校名"
									state={schoolNameState}
									onChange={(newState) => {
										this.setState({schoolNameState: newState})
									}}
								/>
							</Grid>
							<Grid item xs={12} sm={6}>
								<TextFieldSelector
									label="学年"
									name="selectedGrade"
									state={this.state.selectedGrade}
									fullWidth={true}
									onChange={(newState: SelectorInputState) => {
										this.setState({selectedGrade: newState})
									}}
									InputLabelProps={{
										shrink: true,
										disableAnimation: true,
									}}
								/>
							</Grid>
							<Grid item xs={12} sm={6}>
								<TextFieldSelector
									label="通知メールの受信"
									name="selectedNotifyMail"
									state={this.state.selectedNotifyMail}
									fullWidth={true}
									onChange={(newState: SelectorInputState) => {
										this.setState({selectedNotifyMail: newState})
									}}
									InputLabelProps={{
										shrink: true,
										disableAnimation: true,
									}}
								/>
							</Grid>
							<Grid item xs={12} sm={6}>
							</Grid>
						</Grid>
						<div className="pull-right" style={{marginTop: 20}}>
							<Button
								variant="contained"
								type="submit"
								color="primary"
							>
								変更
							</Button>
						</div>
					</div>
				</form>
			</div>
		)
	}
}

export default connect(
	state => ({
		forms: state.sl_utils.forms,
		userState: state.sl_users.state,
	}),
	dispatch => ({
		handleBack: function () {
			this.props.history.goBack()
		},
	})
)(withRouter(ProfileComponent))
