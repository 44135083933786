import React, {Component} from 'react'
import {withRouter, Route, Switch} from 'react-router-dom'
import {connect} from 'react-redux';
import {deepOrange} from '@material-ui/core/colors';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import {changeTab} from '../mypage/MyPageAction'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import Moment from 'moment'
import 'moment/locale/ja'
import _ from 'lodash'
import Icon from '@material-ui/core/Icon'
import Query from "../mypage/lesson/Query";
import History from '../history/History';
import Bbs from '../bbs/Bbs';
import Cookies from "js-cookie";
import '../mypage/mypage-top/mypage.css'
import {post} from "../sonodalib/sl_utils/Http";
import {updateSnackbarAC} from '../sonodalib/sl_utils/SlUtilsAction'
import InfoList from '../mypage/mypage-top/InfoList'
import EvalBox from '../mypage/mypage-top/EvalBox'
import VerticalCalendar from '../mypage/mypage-top/VerticalCalendar'
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import {isInvaildLicensed} from "../Common";
import Box, {BoxTitle} from '../parts/Box'
import {login} from "../sonodalib/sl_users/SlUsersAction";
import {altName} from './constants'
// import {isShakaiLicensed, isSeishinLicensed} from '../Common'
import MoshiLinks from '../mypage/mypage-top/MoshiLinks'
import {logout} from '../sonodalib/sl_users/SlUsersAction'


class _MyPageContent extends Component {
	state = {
		status: undefined,
		moshi_link_list: [],
		calendarEvents: undefined,
		dimensions: {width: 0, height: 0},
		openMoshiInvalid: null,
		openSelfCheckTestInvalid: false,
		currYear: new Date().getFullYear(),
		currMonth: new Date().getMonth() + 1,
		moshi: undefined,
	}

	componentWillMount() {
		const payload = {
			user_token: Cookies.get(altName),
		}
		if (payload.user_token) {
			post("result/fetch/mypage", payload)
			.then((ret) => {
				this.setState({
					status: ret.status,
					moshi_link_list: ret.moshi_link_list,
					calendarEvents: ret.calendar_events,
					moshi: ret.moshi,
					mla_hidden: ret.mla_hidden,
					mla_service: ret.mla_service,		// 個人用Web模試 formパラメータ
					mla_uid: ret.mla_uid,
					mla_uniq: ret.mla_uniq,
					mlb_service: ret.mlb_service,		// 個人用Web模試 formパラメータ
					mlb_uid: ret.mlb_uid,
					mlb_uniq: ret.mlb_uniq,
				})
				login(ret.jwt)
			}).catch(ret => {
				if (ret.status === 401) {
					logout()
				}
			})
		}
	}

	handleClickEvalBox = () => {
		changeTab("history", this.props.history)
	}

	buildEvalVd = () => {
		const {status} = this.state
		const vd = []
		// let count = 0
		if (status) {
			// if (status.kyotsu) count++
			// if (status.shakai) count++
			// if (status.seishin) count++

			if (status.kyotsu) {
				vd.push(
					<EvalBox
						key={_.uniqueId("evalBox")}
						iconVd={<span className="info-box-icon bg-aqua" style={{fontSize: 24}}>共通</span>}
						jisshido={status.kyotsu.Jisshido}
						shujukudo={status.kyotsu.Shujukudo}
						goukakudo={status.kyotsu.Goukakudo}
						onClick={this.handleClickEvalBox}
					/>
				)
			}
			if (status.shakai) {
				vd.push(
					<EvalBox
						key={_.uniqueId("evalBox")}
						iconVd={<span className="info-box-icon bg-green" style={{fontSize: 18}}>社会専門</span>}
						jisshido={status.shakai.Jisshido}
						shujukudo={status.shakai.Shujukudo}
						goukakudo={status.shakai.Goukakudo}
						onClick={this.handleClickEvalBox}
					/>
				)
			}
			if (status.seishin) {
				vd.push(
					<EvalBox
						key={_.uniqueId("evalBox")}
						iconVd={<span className="info-box-icon bg-orange" style={{fontSize: 18}}>精神専門</span>}
						jisshido={status.seishin.Jisshido}
						shujukudo={status.seishin.Shujukudo}
						goukakudo={status.seishin.Goukakudo}
						onClick={this.handleClickEvalBox}
					/>
				)
			}
		}
		return vd
	}

	// ===============================================================
	// セルフチェックテストへのリンク
	// ===============================================================

	// handleSelfCheckTest = (examCode, isLicensed) => {
	// 	if (isLicensed) {
	// 		const payload = {
	// 			user_token: Cookies.get(altName),
	// 		}
	// 		post("etc/fkselftest_auth", payload)
	// 		.then((ret) => {
	// 			// window.open(`http://local.fkselftest.sndpr.net:81/fkselftest/fukushi/login/${examCode}/${ret.login_id}/${ret.pwd}`)
	// 			// window.open(`http://test.akamaru.jp/fkselftest/fukushi/login/${examCode}/${ret.login_id}/${ret.pwd}`)
	// 			window.location = `http://test.akamaru.jp/fkselftest/fukushi/login/${examCode}/${ret.login_id}/${ret.pwd}`
	// 		})
	// 		.catch((ret) => {
	// 			this.props.showSelfCheckTestLoginError()
	// 		})
	// 	} else {
	// 		this.setState({
	// 			openSelfCheckTestInvalid: true,
	// 		})
	// 	}
	// }
	//
	// buildLinkToSelfCheckTest = (license) => {
	// 	const selfCheckTestBannerShakai = (
	// 		<div onClick={this.handleSelfCheckTest.bind(this, 201201, (license !== "0" && isShakaiLicensed(license)))}
	// 				 key={_.uniqueId()} className="small-box bg-green" style={{marginBottom: 8, cursor: 'pointer'}}>
	// 			<div className="inner" style={{textAlign: 'left'}}>
	// 				<span style={{fontWeight: 800, fontSize: 20}}>セルフチェックテスト</span>
	// 				<span style={{fontWeight: 800, fontSize: 14}}>＆学習ナビ</span>
	// 				<p><span style={{fontSize: 14}}>社会福祉士</span></p>
	// 			</div>
	// 			<div className="icon">
	// 				<i className="fas fa-chart-line"></i>
	// 			</div>
	// 			<span className="small-box-footer">サイトに移動 <i className="fas fa-arrow-circle-right"></i></span>
	// 		</div>
	// 	)
	//
	// 	const selfCheckTestBannerSeishin = (
	// 		<div onClick={this.handleSelfCheckTest.bind(this, 201301, (license !== "0" && isSeishinLicensed(license)))}
	// 				 key={_.uniqueId()} className="small-box bg-orange" style={{marginBottom: 8, cursor: 'pointer'}}>
	// 			<div className="inner" style={{textAlign: 'left'}}>
	// 				<span style={{fontWeight: 800, fontSize: 20}}>セルフチェックテスト</span>
	// 				<span style={{fontWeight: 800, fontSize: 14}}>＆学習ナビ</span>
	// 				<p><span style={{fontSize: 14}}>精神保健福祉士</span></p>
	// 			</div>
	// 			<div className="icon">
	// 				<i className="fas fa-chart-line"></i>
	// 			</div>
	// 			<span className="small-box-footer">サイトに移動 <i className="fas fa-arrow-circle-right"></i></span>
	// 		</div>
	// 	)
	//
	// 	const list = []
	// 	if (license !== "0") {
	// 		if (isShakaiLicensed(license)) {
	// 			list.push(selfCheckTestBannerShakai)
	// 		}
	// 		if (isSeishinLicensed(license)) {
	// 			list.push(selfCheckTestBannerSeishin)
	// 		}
	// 	} else {
	// 		list.push(selfCheckTestBannerShakai)
	// 		list.push(selfCheckTestBannerSeishin)
	// 	}
	// 	return list
	// }

	// ===============================================================
	// 個人Web模試へのリンク
	// ===============================================================
	// 課金者用
	// ===============================================================
	// buildMoshiLauncher = () => {
	// 	const onClick = () => {
	// 		if (this.state.mla_service) {
	// 			document.moshiLink.action = "http://moshi.akamaru.jp/fk/ext/fukushi-login"
	// 			// document.moshiLink.action = "http://moshi.devel.akamaru.jp/fk/ext/fukushi-login"
	// 			// document.moshiLink.action = "http://local.moshi.sndpr.net/fk/ext/fukushi-login"
	// 			document.moshiLink.method = "POST"
	// 			document.moshiLink.submit()
	// 		} else {
	// 			this.setState({
	// 				openMoshiInvalid: true,
	// 			})
	// 		}
	// 	}
	// 	return (this.state.mla_hidden) ? null : (
	// 		<div onClick={onClick} className="small-box bg-purple" style={{marginBottom: 8, cursor: 'pointer'}}>
	// 			<div className="inner" style={{textAlign: 'left'}}>
	// 				<span style={{fontSize: 20}}>Web模擬試験</span>
	// 				<p><span style={{fontSize: 14}}>　</span></p>
	// 			</div>
	// 			<div className="icon">
	// 				<i className="far fa-edit"></i>
	// 			</div>
	// 			<span className="small-box-footer"
	// 						style={{cursor: 'pointer'}}>サイトに移動 <i className="fas fa-arrow-circle-right"></i></span>
	// 		</div>
	// 	)
	// }

	// ===============================================================
	// 非課金者含め全員用
	// ===============================================================
	// buildMoshiForAllLauncher = () => {
	// 	const onClick = () => {
	// 		if (this.state.mlb_service) {
	// 			document.moshiForAllLink.action = "http://moshi.akamaru.jp/fk/ext/fukushi-login"
	// 			// document.moshiForAllLink.action = "http://moshi.devel.akamaru.jp/fk/ext/fukushi-login"
	// 			// document.moshiForAllLink.action = "http://local.moshi.sndpr.net/fk/ext/fukushi-login"
	// 			document.moshiForAllLink.method = "POST"
	// 			document.moshiForAllLink.submit()
	// 		}
	// 	}
	// 	return (!this.state.mlb_service) ? null : (
	// 		<div onClick={onClick} className="small-box bg-red" style={{marginBottom: 8, cursor: 'pointer'}}>
	// 			<div className="inner" style={{textAlign: 'left'}}>
	// 				<span style={{fontSize: 20}}>Web自動採点</span>
	// 				<p><span style={{fontSize: 14}}>　</span></p>
	// 			</div>
	// 			<div className="icon">
	// 				<i className="far fa-edit"></i>
	// 			</div>
	// 			<span className="small-box-footer"
	// 						style={{cursor: 'pointer'}}>サイトに移動 <i className="fas fa-arrow-circle-right"></i></span>
	// 		</div>
	// 	)
	// }

	// ===============================================================
	// 団体用Web模試へのリンク
	// ===============================================================
	// buildDantaiMoshiLauncher = () => {
	// 	const {moshi} = this.state
	// 	if (!moshi || !moshi.length) return null
	//
	// 	const onClick = (_moshi) => {
	// 		const url = "http://moshi.akamaru.jp/fk/ext/login/" + _moshi.service + "/" + _moshi.id + "/" + _moshi.pw
	// 		window.location = url					// window.open(url)
	// 	}
	// 	return moshi.map((row, index) => (
	// 		<div key={"moshi"+index} onClick={onClick.bind(this, row)} className="small-box bg-aqua" style={{marginBottom: 8, cursor: 'pointer'}}>
	// 			<div className="inner" style={{textAlign: 'left'}}>
	// 				<span style={{fontSize: 20}}>Web模擬試験</span>
	// 				<p><span style={{fontSize: 14}}>{row.desc}</span></p>
	// 			</div>
	// 			<div className="icon">
	// 				<i className="far fa-edit"></i>
	// 			</div>
	// 			<span className="small-box-footer"
	// 						style={{cursor: 'pointer'}}>サイトに移動 <i className="fas fa-arrow-circle-right"></i></span>
	// 		</div>
	// 	))
	// }

	handleChangeCalendar = (year, month) => {
		this.setState({
			currYear: year,
			currMonth: month,
		})
	}

	handleToiawase = () => {
		this.props.history.push("/my/bbs/contact")
	}

	render() {
		const buildCalendarEventBox = (eventDate: Date) => {
			let lastText = ""
			const ed = Moment(eventDate)
			let lastDays = ed.diff(Moment().startOf('day'), "days")
			// lastDays = lastDays + 1
			if (lastDays < 0) {
				lastText = <span><span style={{fontSize: 16, color: 'red'}}>終了しました</span><br/>受験生の皆さま、お疲れ様でした</span>
			} else {
				lastText = (
					<span>
						{ed.format("YYYY年 M月 D日")}
						まで、あと <span style={{fontSize: "2.5em", color: deepOrange[500]}}>{lastDays}</span> 日
					</span>
				)
			}
			return lastText
		}

		const {calendarEvents, openMoshiInvalid} = this.state
		const license = (this.props.userState) ? this.props.userState.license : null
		if (!license) return null

		if (isInvaildLicensed(license)) {
			return (
				<Grid container direction="column" justify="center" alignItems="center" style={{marginTop:100}}>
					<Grid item style={{height:100}}>
						<span style={{fontSize:20,fontWeight:700}}>利用制限がかかっております。詳しくはお問い合わせ下さい。</span>
					</Grid>
					<Grid item style={{height:100}}>
						<Button variant="contained" color="secondary" onClick={this.handleToiawase}>
							お問い合わせ
						</Button>
					</Grid>
				</Grid>
			)
		}

		let shakaiText = ""
		if (calendarEvents && calendarEvents.SHAKAI) {
			shakaiText = buildCalendarEventBox(calendarEvents.SHAKAI)
		}
		const shakaiBox = (calendarEvents && calendarEvents.SHAKAI) ? (
			<Box>
				<BoxTitle>
					社会福祉士国家試験
				</BoxTitle>
				<div style={{textAlign: "center"}}>
					{shakaiText}
				</div>
			</Box>
		) : null

		let seishinText = ""
		if (calendarEvents && calendarEvents.SEISHIN) {
			seishinText = buildCalendarEventBox(calendarEvents.SEISHIN)
		}
		const seishinBox = (calendarEvents && calendarEvents.SEISHIN) ? (
			<Box>
				<BoxTitle>
					精神保健福祉士国家試験
				</BoxTitle>
				<div style={{textAlign: "center"}}>
					{seishinText}
				</div>
			</Box>
		) : null

		// const moshiLinkAction = (this.state.mla_service) ? (
		// 	<form name="moshiLink" style={{display:'none'}}>
		// 		<input name="service" value={purpleMoshiServiceCode} readOnly/>
		// 		<input name="uid" value={this.state.mla_uid} readOnly/>
		// 		<input name="uniq" value={this.state.mla_uniq} readOnly/>
		// 	</form>
		// ) : null
		// const moshiForAllLinkAction = (this.state.mlb_service) ? (
		// 	<form name="moshiForAllLink" style={{display:'none'}}>
		// 		<input name="service" value={this.state.mlb_service} readOnly/>
		// 		<input name="uid" value={this.state.mlb_uid} readOnly/>
		// 		<input name="uniq" value={this.state.mlb_uniq} readOnly/>
		// 	</form>
		// ) : null

		const onLaunch = (moshiLink) => {
			this.props.history.push(moshiLink.sys === "sct" ? "/sct" : "/wm")
		}

		return (
			<div style={{
				marginTop: 20,
				marginBottom: 100,
				maxWidth: 800,
				marginLeft: "auto",
				marginRight: "auto",
			}}>
				<div style={{marginLeft: 8, marginRight: 8}}>
					<InfoList/>
					<Grid container direction="row" spacing={8}>
						<Grid item xs={12} sm={6}>
							{this.buildEvalVd()}
							<MoshiLinks
								moshi_link_list={this.state.moshi_link_list}
								onError={(text) => {
									this.setState({
										openMoshiInvalid: text,
									})
								}}
								onLaunch={onLaunch}
							/>
							{/*{this.buildMoshiLink()}*/}
							{/*{this.buildMoshiForAllLauncher()}*/}
							{/*{this.buildDantaiMoshiLauncher()}*/}
							{/*{this.buildMoshiLauncher()}*/}
							{/*{this.buildLinkToSelfCheckTest(license)}*/}
						</Grid>
						<Grid item xs={12} sm={6}>
							{shakaiBox}
							{seishinBox}
							<div>
								<VerticalCalendar
									year={this.state.currYear}
									month={this.state.currMonth}
									onChange={this.handleChangeCalendar}
								/>
							</div>
						</Grid>
					</Grid>
				</div>
				<Dialog open={this.state.openSelfCheckTestInvalid}>
					<DialogTitle>セルフチェックテストは、正規お申込み後にご利用いただけます</DialogTitle>
					<DialogActions>
						<Button
							color="primary"
							onClick={() => {
								this.setState({openSelfCheckTestInvalid: false})
							}}
						>
							閉じる
						</Button>
					</DialogActions>
				</Dialog>
				<Dialog open={(openMoshiInvalid !== null)}>
					<DialogTitle>{(openMoshiInvalid) ? openMoshiInvalid : ""}</DialogTitle>
					<DialogActions>
						<Button
							color="primary"
							onClick={() => {
								this.setState({openMoshiInvalid: null})
							}}
						>
							閉じる
						</Button>
					</DialogActions>
				</Dialog>
				{/*{moshiLinkAction}*/}
				{/*{moshiForAllLinkAction}*/}
			</div>
		)
	}
}

const MyPageContent = connect(
	state => ({
		userState: state.sl_users.state,
	}),
	dispatch => ({})
)(_MyPageContent)


class MyPage extends Component {
	handleChangeTab = (event, value) => {
		const names = ["my","lesson","history","bbs"]
		changeTab(names[value], this.props.history)
	}

	render() {
		const styles = {
			container: {
				marginLeft: 'auto',
				marginRight: 'auto',
				maxWidth: 800,
			},
			tab: {
				// color: black,
				// backgroundColor: "rgb(236, 240, 245)",
				// background: "white",
			},
			// tabContent: {
			//  borderTop: "#ddd 1px solid",
			// }
		};
		if (this.props.printMode) {
			return (
				<div>
					{this.props.children}
					<Switch>
						<Route path="/my/lesson/query" component={Query}/>
						<Route path="/my/hist" component={History}/>
						<Route path="/my/bbs" component={Bbs}/>
						<Route component={MyPageContent}/>
					</Switch>
				</div>
			)
		} else {
			return (
				<div style={styles.container}>
					<Tabs
						// fullWidth
						centered
						onChange={this.handleChangeTab}
						value={this.props.myPageTabIndex}
					>
						<Tab
							style={styles.tab}
							label="マイページ"
							icon={<Icon>account_box</Icon>}
							value={0}
						>
						</Tab>
						<Tab
							style={styles.tab}
							label="学習"
							icon={<Icon>check</Icon>}
							value={1}
						>
						</Tab>
						<Tab
							style={styles.tab}
							label="履歴"
							icon={<Icon>history</Icon>}
							value={2}
						>
						</Tab>
						<Tab
							style={styles.tab}
							label="掲示板"
							icon={<Icon>content_paste</Icon>}
							value={3}
						>
						</Tab>
					</Tabs>
					{this.props.children}
					<Switch>
						<Route path="/my/lesson/query" component={Query}/>
						<Route path="/my/hist" component={History}/>
						<Route path="/my/bbs" component={Bbs}/>
						<Route component={MyPageContent}/>
					</Switch>
				</div>
			)
		}
	}
}

export default connect(
	state => ({
		myPageTabIndex: state.mypage.myPageTabIndex,
		userState: state.sl_users.state,
		printMode: state.mypage.printMode,
	}),
	dispatch => ({
		// updateMyPageTabIndex: index => {
		// 	dispatch(updateCurrentMyPageTabAC(index))
		// },
		// handleActiveNews: function () {
		// 	this.props.history.push("/my")
		// },
		// handleActiveLesson: function () {
		// 	this.props.history.push("/my/lesson/query/select-category")
		// },
		// handleActiveHistory: function () {
		// 	this.props.history.push("/my/hist")
		// },
		// handleActiveBbs: function () {
		// 	this.props.history.push("/my/bbs")
		// },
		showSelfCheckTestLoginError: function () {
			dispatch(updateSnackbarAC("セルフチェックテストにログインできませんでした"))
		},
	})
)(withRouter(MyPage))
