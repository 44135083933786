import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import SentakuQuestion from './SentakuQuestion'
import { updateSentakuQuestionAC, updateSentakuResultsAC } from './QuestionAction'
import _ from 'lodash'
import {urlPref} from '../alt/constants'
import {gakushuFirstUrl} from '../alt/constants'


class SentakuSeries extends React.Component {
	state = {
		mondaiId: null,
	}

	componentWillMount() {
		this.qIndex = 0
		this.results = []
		if (this.props.sentakuQuestions === null) {
			window.location.href = urlPref+"/my"
			return
		}
		this.updateSentakuQuestion(this.props.sentakuQuestions)
		// const question = {
		// 	subjectLabel: "人体の構造と機能及び疾病 第２８回 問題６",
		// 	qText: "消費社会に関する代表的な社会理論についての次の記述のうち、正しいものを１つ選びなさい。",
		// 	answerCount: 1,
		// 	choices: [
		// 		{
		// 			index: 0,
		// 			selected: false,
		// 			text: "ロストウ（Rostow,W.W.）によれば、社会の関心は、供給から需要、生産から消費へと移っていく。",
		// 			commentary: "５歳児のＦ男ちゃんは、弟が生まれたことをきっかけに、年齢に合わない指しゃぶりを始めたことから「退行」である。なお「昇華」とは、非社会的欲求（性欲・攻撃欲）を芸能、文化、スポーツなどの社会的に承認される行動に、振り替えることで満たそうとすることをいう。例として、失恋の悲しみを仕事に向けるなどが挙げられる。",
		// 			correct: false,
		// 			showCommentary: false,
		// 		},
		// 		{
		// 			index: 1,
		// 			selected: false,
		// 			text: "ガルブレイスの依存効果とは、消費者の欲望が自律的でなく、生産者の働きかけによって喚起される現象をいう。消費部門が生産部門に依存する依存効果が見られる。",
		// 			commentary: "自分が非常に憎んでいる父親に対し、かえって気遣いをし、過剰な配慮をする高校生のＧ男さんの行動は、抑圧するだけでは処理しがたい強力な嫌悪感や衝動を防衛するために、意識の上では正反対な行動を示す「反動形成」にあたる。よって正しい。",
		// 			correct: true,
		// 			showCommentary: false,
		// 		},
		// 	],
		// 	selectHistory: [],
		// 	showResult: 0,
		// }
		// G.store.dispatch(updateSentakuQuestionAC(question))
	}

	// 問題のシリーズから、今回の問題を抽出して、今回の問題をrenderするための store.question.sentakuQuestion を更新する
	updateSentakuQuestion = sentakuQuestions => {
		const question = sentakuQuestions.questions[this.qIndex]
		question.qIndex = this.qIndex + 1
		question.selectHistory = []
		question.showResult = 0
		_.forEach(question.choices, choice => {
			choice.selected = false
			choice.showCommentary = false
		})
		this.props.updateSentakuQuestion(question)
		this.setState({
			mondaiId: question.MondaiID,
		})
	}

	// FP300からの戻り
	// result: 1=正解  2=不正解  3=未解答　4=中断  5=時間切れ（テストモード）
	// choose: 選択した選択肢番号のCSV  e.g.) "1,4"
	onEndQuestion = (result) => {
		if (result === 5) {																															// 時間切れ
			while (this.qIndex < this.props.sentakuQuestions.questions.length) {
				this.results[this.qIndex++] = 3
				this.props.updateSentakuResults(this.results)
				this.props.history.replace("/q/sentaku/result")
			}
		} else if (result === 4) {																											// 中断
			if (this.qIndex === 0) {
				this.props.history.replace(gakushuFirstUrl)																		// 1問目で中断
			} else {
				this.props.updateSentakuResults(this.results)																// 2問目以降で中断
				this.props.history.replace("/q/sentaku/result")
			}
		} else {
			this.results[this.qIndex++] = result																					// 正解・不正解・未解答
			if (this.qIndex < this.props.sentakuQuestions.questions.length) {
				this.updateSentakuQuestion(this.props.sentakuQuestions)
			} else {
				this.props.updateSentakuResults(this.results)
				this.props.history.replace("/q/sentaku/result")
			}
		}
	}

	render() {
		return (
			<SentakuQuestion
				mondaiId={this.state.mondaiId}
				onEnd={this.onEndQuestion.bind(this)}
			/>
		)
	}
}

export default connect(
	state => ({
		sentakuQuestions: state.question.sentakuQuestions,
	}),
	dispatch => ({
		updateSentakuResults: entity => {
			dispatch(updateSentakuResultsAC(entity))
		},
		updateSentakuQuestion: entity => {
			dispatch(updateSentakuQuestionAC(entity))
		},
	})
)(withRouter(SentakuSeries))