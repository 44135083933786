import React, {Component} from 'react'
import {connect} from 'react-redux';
import {withRouter, Route, Switch} from 'react-router-dom'
import Button from '@material-ui/core/Button'
import SctPage from './SctPage';
import Cookies from "js-cookie";
import Sct from './'
import {altName} from "../alt/constants";
import BackToSctTopButton from './parts/BackToSctTopButton'


class SctFrame extends Component {

	handleGoTop = () => {
		this.props.history.push("/my")
	}

	handleLogout = () => {
		Sct.terminateSession()
		alert("ログアウトしました")
		// this.props.history.push("/")
	}

	render() {
		const {sctState, userState} = this.props
		if (!userState) return null
		if (!sctState.serviceCode) {
			const sct_token = Cookies.get("wm_"+altName)
			if (!sct_token) {
				this.props.history.push("/my")
			} else {
				Sct.restartSession(sct_token, () => {
					// console.log("restart sct session")
				}, () => {
					this.handleGoTop()
				})
			}
		}
		const content = (
			<Switch>
				<Route path="/sct" component={SctPage}/>
			</Switch>
		)
		if (this.props.printMode) {
			return (
				<div>
					{content}
				</div>
			)
		} else {
			const isSctTop = this.props.history.location.pathname === "/sct"
			return (
				<div style={{margin:10, backgroundColor:'white'}}>
					<div style={{textAlign:'right'}}>
						{(isSctTop) ? null : (
							<BackToSctTopButton history={this.props.history}/>
						)}
						<Button
							variant="text"
							color="secondary"
							onClick={this.handleGoTop}
							style={{marginLeft:10,border:'1px solid #f50057'}}
						>
							マイページに戻る
						</Button>
					</div>
					<div>
						<div style={{height: 'auto'}}>
							{content}
						</div>
					</div>
					<footer className="main-footer" style={{marginTop: 100}}>
						<div className="container">
							<strong>Copyright © <a href="http://www.jc-edu.co.jp">JC Educational Institute, Inc.</a>.</strong> All
							rights reserved.
						</div>
					</footer>
				</div>
			)
		}
	}
}

export default connect(
	state => ({
		sctState: state.sct,
		userState: state.sl_users.state,
		printMode: state.mypage.printMode,
	}),
)(withRouter(SctFrame));
