import React from 'react'
import PropTypes from 'prop-types'
import FkToolbar from '../parts/FkToolbar'
import { withRouter } from 'react-router-dom'
import {post} from '../sonodalib/sl_utils/Http'
import _ from 'lodash'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import TableHead from '@material-ui/core/TableHead';
import Moment from 'moment'
import Cookies from "js-cookie";
import {altName} from '../alt/constants'


class BbsBoard1 extends React.Component {
	state = {
		threads: null,
	}

	componentWillMount() {
		this.fetch_threads()
	}

	fetch_threads() {
		const self = this
		const payload = {
			user_token: Cookies.get(altName),
			board_id: 1,
			ident: null,
		}
		post("bbs/fetch_threads", payload)
			.then(ret => {
				PropTypes.checkPropTypes({
					list: PropTypes.array.isRequired,
				}, ret)
				ret.list = _.uniqBy(ret.list, 'ident')
				self.setState({
					threads: ret,
				})
			})
	}

	handleClickThread = thread => {
		this.props.history.push("/my/bbs/thread1/"+thread.ident)
	}

	render() {
		const styles = {
			listInnerDiv: {
				backgroundColor:"#e8eaf6",
			},
			nowrap: {
				whiteSpace: 'nowrap',
			}
		}
		const { threads } = this.state
		const threadList = (threads === null) ? (
			<TableRow>
				<TableCell colSpan={4}>
					読み込み中・・・
				</TableCell>
			</TableRow>
		) : (threads.list.length === 0) ? (
			<TableRow>
				<TableCell colSpan={4}>
					まだ投稿はありません
				</TableCell>
			</TableRow>
		) : _.map(
			_.reverse(_.sortBy(threads.list, "updated")), thread => (
				<TableRow hover key={thread.uuid} onClick={this.handleClickThread.bind(this, thread)}>
					<TableCell padding="dense" style={styles.nowrap}>{thread.Shikenshurui + " " + thread.Shikenkaisu}</TableCell>
					<TableCell padding="dense" style={styles.nowrap}>{thread.Kamokumei}</TableCell>
					<TableCell padding="dense">{"問題 " + thread.MondaiNum}</TableCell>
					<TableCell padding="dense">{((!thread.MarubatsuID) ? "選択問題" : "◯✕問題")}</TableCell>
					<TableCell padding="dense">{Moment(thread.updated).format("YYYY/MM/DD")}</TableCell>
				</TableRow>
			)
		)

		return (
			<div>
				<FkToolbar title="学習掲示板 1" onGoBack={() => {this.props.history.goBack()}}/>
				<Table>
					<TableHead>
						<TableRow>
							<TableCell style={styles.nowrap}>試験</TableCell>
							<TableCell style={styles.nowrap}>科目</TableCell>
							<TableCell style={styles.nowrap}>問題番号</TableCell>
							<TableCell style={styles.nowrap}>問題形式</TableCell>
							<TableCell style={styles.nowrap}>更新日</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{threadList}
					</TableBody>
				</Table>
			</div>
		)
	}
}

export default withRouter(BbsBoard1)
