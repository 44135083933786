import React from 'react'
import Box from "@material-ui/core/Box";
import TextField from '@material-ui/core/TextField';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import Checkbox from '@material-ui/core/Checkbox';
import Button from "@material-ui/core/Button";

const Legent = (props) => (
  <Box my={1} letterSpacing={2} fontSize={16} fontWeight={700} style={{color:'black'}}>
    {props.text}
  </Box>
)

export default class Planning extends React.Component {
  render() {
    const {data, onChangeStartDate, onChangeReview, onChangeKur, onChangePrio, onChangeSubject, onGoSchedule} = this.props
    const {plan, categories} = data

    // 学習開始日
    const startDateVd = (
      <Box width={0.5}>
        <Legent text="学習開始日"/>
        <TextField
          id="start-date"
          type="date"
          value={plan.start_date}
          style={{width: 170}}
          InputLabelProps={{
            shrink: true,
            disableAnimation: true,
          }}
          onChange={onChangeStartDate}
        />
      </Box>
    )

    // 学習クール
    const selectKur = (
      <Box mt={4}>
        <FormControl component="fieldset">
          <Legent text="学習クール"/>
          <RadioGroup aria-label="kur" name="kur" value={plan.kur} onChange={onChangeKur}>
            <FormControlLabel value={1} control={<Radio />} label="１クール" style={{marginBottom:-4}}/>
            <FormControlLabel value={2} control={<Radio />} label="２クール" style={{marginBottom:-4}}/>
            <FormControlLabel value={3} control={<Radio />} label="３クール" />
          </RadioGroup>
        </FormControl>
      </Box>
    )

    // 総復習期間
    const selectHukushuTime = (
      <Box mt={4}>
        <FormControl component="fieldset">
          <Legent text="総復習期間"/>
          <RadioGroup aria-label="hukushu-time" name="hukushu-time" value={plan.review} onChange={onChangeReview}>
            <FormControlLabel value={1} control={<Radio />} label="要" style={{marginBottom:-4}}/>
            <FormControlLabel value={0} control={<Radio />} label="不要" />
          </RadioGroup>
        </FormControl>
      </Box>
    )

    // 学習順
    const selectPrio = (
      <Box mt={4}>
        <FormControl component="fieldset">
          <Legent text="学習順"/>
          <RadioGroup aria-label="prio" name="prio" value={plan.prio} onChange={onChangePrio}>
            <FormControlLabel value={1} control={<Radio />} label="苦手な科目を優先する" style={{marginBottom:-4}}/>
            <FormControlLabel value={2} control={<Radio />} label="得意な科目を優先する" style={{marginBottom:-4}}/>
            <FormControlLabel value={3} control={<Radio />} label="おすすめ" />
          </RadioGroup>
        </FormControl>
      </Box>
    )

    // 科目リスト
    const selectedSubjects = plan.subjects.split(",")
    const subjectsVd = categories.map(category => (
        <div key={category.label}>
          <Legent text={category.label}/>
          {(category.subjects.map(subject => (
            <div key={subject.subject}>
              <FormControlLabel
                control={<Checkbox checked={selectedSubjects.indexOf(subject.subject) !== -1} onChange={onChangeSubject} name={subject.subject} disabled={subject.score === ""}/>}
                label={subject.name}
              />
            </div>
            )
          ))}
        </div>
      )
    )


    const leftPane = (
      <Box ml={4} width={0.3} style={{borderRight:'1px solid #ccc'}}>
        {startDateVd}
        {selectKur}
        {selectHukushuTime}
        {selectPrio}
      </Box>
    )

    const rightPane = (
      <Box width={0.7} ml={4}>
        {subjectsVd}
      </Box>
    )

    const bottomPane = (
      <Box p={2} style={{backgroundColor:'#effbeb'}} textAlign="center">
        <Button
          variant="text"
          onClick={onGoSchedule}
        >
          <img src="/imgms/sct/button_schedule.png" alt="go_schedule"/>
        </Button>
      </Box>
    )

    return (
      <div>
        <Box p={2} style={{backgroundColor:'#def6d6'}} fontSize={16}>
          設定
        </Box>
        <Box p={2} display="flex" style={{backgroundColor:'#effbeb'}}>
          {leftPane}
          {rightPane}
        </Box>
        {bottomPane}
      </div>
    )
  }
}