import React from 'react'
import SougouTab from './SougouTab'
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import CategoryTab from './CategoryTab'
import {updateMoshi} from "../MoshiAction";


export default class PrintSogoCat0 extends React.Component {
	render() {
		const {data} = this.props
		if (!data) return null
		return (
			<div style={{width:1210}}>
				<Grid container style={{width:"100%"}}>
					<Grid item style={{width:600,marginRight:10}}>
						<SougouTab data={data}/>
					</Grid>
					<Grid item style={{width:600}}>
						<CategoryTab data={data.categories[0]}/>
					</Grid>
				</Grid>
				<div style={{textAlign:'right', marginTop:40}}>
					<Button
						variant="text"
						color="secondary"
						onClick={() => {
							updateMoshi({
								printMode: null
							})
						}}
						style={{border:'1px solid #f50057'}}
					>
						印刷画面を閉じる
					</Button>
				</div>
			</div>
		)
	}
}


// import BunpuChart from './BunpuChart'
// import BackToMoshiTopButton from "../parts/BackToMoshiTopButton";
//
//
// const styles = {
// 	shikenLabel: {
// 		marginTop:			 40,
// 		fontSize:				 20,
// 		fontWeight:			 'bold',
// 		color:					 '#666',
// 		paddingBottom:	 18,
// 		textAlign:			 'center',
// 	},
// 	tableHead: {
// 		backgroundColor: '#1e88e5',
// 		color: 					 'white',
// 		fontSize:				 12,
// 		whiteSpace:			 'nowrap',
// 	},
// 	cellHead: {
// 		paddingTop:			 6,
// 		paddingBottom:	 6,
// 		textAlign:			 'center',
// 	},
// 	tableRow: {
// 		fontSize:				 16,
// 		whiteSpace:			 'nowrap',
// 	},
// 	cellRow: {
// 		paddingTop:			 14,
// 		paddingBottom:	 14,
// 		textAlign:			 'center',
// 		borderBottom:		 '1px solid #ccc',
// 	},
// }
//
// export default class PrintSougouCat0 extends React.Component {
//
// 	render() {
// 		const {data, history} = this.props
// 		if (!data) return null
//
// 		const {shikens} = data
//
// 		const shikenVd = shikens.map(shiken => {
// 			const catsHeadVd = (
// 				<Grid container style={styles.tableHead} alignItems="center">
// 					<Grid item style={{...styles.cellHead,width:100}}>　</Grid>
// 					<Grid item style={{...styles.cellHead,width:80}}>配点</Grid>
// 					<Grid item style={{...styles.cellHead,width:80}}>得点</Grid>
// 					<Grid item style={{...styles.cellHead,width:80}}>偏差値</Grid>
// 					<Grid item style={{...styles.cellHead,width:80}}>受験者数</Grid>
// 					<Grid item style={{...styles.cellHead,width:80}}>平均点</Grid>
// 					<Grid item style={{...styles.cellHead,width:80}}>最高点</Grid>
// 					<Grid item style={{...styles.cellHead,width:80}}>最低点</Grid>
// 					<Grid item style={{...styles.cellHead,width:140}}>実力判定</Grid>
// 				</Grid>
// 			)
// 			const catsVd = shiken.cats.map((cat) => (
// 				<Grid container key={"cat"+cat.index} style={styles.tableRow} alignItems="center">
// 					<Grid item style={{...styles.cellRow,width:100,fontWeight:600}}>{cat.label}</Grid>
// 					<Grid item style={{...styles.cellRow,width:80}}>{cat.haiten}</Grid>
// 					<Grid item style={{...styles.cellRow,width:80}}>{cat.score}</Grid>
// 					<Grid item style={{...styles.cellRow,width:80}}>{cat.std}</Grid>
// 					<Grid item style={{...styles.cellRow,width:80}}>{cat.count}</Grid>
// 					<Grid item style={{...styles.cellRow,width:80}}>{cat.avg}</Grid>
// 					<Grid item style={{...styles.cellRow,width:80}}>{cat.max}</Grid>
// 					<Grid item style={{...styles.cellRow,width:80}}>{cat.min}</Grid>
// 					<Grid item style={{...styles.cellRow,width:140}}>{cat.hantei}</Grid>
// 				</Grid>
// 			))
// 			const labels = []
// 			if (shiken.bunpu_visible && shiken.bunpu_list.length) {
// 				let v = 0
// 				while (v < shiken.bunpu_list.length) {
// 					labels.push((v % 10 === 0) ? v+"" : "")
// 					v++
// 				}
// 			}
// 			const bunpuVd = (shiken.bunpu_visible) ? (
// 				<div style={{marginTop:40}}>
// 					<div>
// 						得点分布
// 					</div>
// 					<BunpuChart
// 						data={shiken.bunpu_list}
// 						width={800}
// 						height={160}
// 						labels={labels}
// 					/>
// 				</div>
// 			) : null
// 			const kohyoVd = (shiken.kohyo) ? (
// 				<div style={{border:'1px solid #3f51b5', padding:10}}>
// 					<div dangerouslySetInnerHTML={{__html: shiken.kohyo}}></div>
// 				</div>
// 			) : null
// 			return (
// 				<div key={shiken.index}>
// 					<div style={styles.shikenLabel}>
// 						{shiken.label}
// 					</div>
// 					<div>
// 						{catsHeadVd}
// 						{catsVd}
// 					</div>
// 					{bunpuVd}
// 					{kohyoVd}
// 				</div>
// 			)
// 		})
// 		return (
// 			<div style={{margin:10}}>
// 				<div style={{width:480}}>
// 					{shikenVd}
// 				</div>
// 				<div style={{width:480}}>
//
// 				</div>
// 				<div style={{textAlign:'right', marginTop:40}}>
// 					<Button
// 						variant="text"
// 						color="secondary"
// 						onClick={() => {
// 							G.store.dispatch(updatePrintModeAC(false))
// 						}}
// 						style={{border:'1px solid #f50057'}}
// 					>
// 						印刷画面を閉じる
// 					</Button>
// 				</div>
// 			</div>
// 		)
// 	}
// }
