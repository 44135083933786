import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import AuthRoute from './sonodalib/sl_users/AuthRouteComponent'
import UnauthRoute from './sonodalib/sl_users/UnauthRouteComponent'
import TopContainer from './top/TopContainer';
import TopContainerNFU from './top/TopContainerNFU';
import TrialRegisterContainer from './top/TrialRegisterContainer';
import LoginContainer from './top/LoginContainer';
import SuperLogin from './top/SuperLogin';
import InvitePage from './top/InvitePage';
import ResetPassPage from './top/ResetPassPage';
import MyPageFrame from './mypage/MyPageFrame';
import Question from './question/Question';
import MoshiFrame from './moshi/MoshiFrame';
import MoshiUserFrame from './moshi/MoshiUserFrame';
import SctFrame from './sct/SctFrame';
// import RootRoute from './sonodalib/sl_users/components/RootRoute'
// import NotFoundPage from './NotFoundPage'


const Routes = (props) => (
  <Router {...props}>
    <div>
      <Switch>
        <Route path="/my" component={AuthRoute}/>
        <Route path="/q" component={AuthRoute}/>
        <Route path="/wm" component={AuthRoute}/>
        <Route path="/wmu"/>
        <Route path="/sct" component={AuthRoute}/>
        <Route path="/" component={UnauthRoute}/>
      </Switch>
      <Route path="/my" component={MyPageFrame}/>
      <Route path="/q" component={Question}/>
      <Route path="/wm" component={MoshiFrame}/>
      <Route path="/wmu" component={MoshiUserFrame}/>
      <Route path="/sct" component={SctFrame}/>
      {/* add same path above. */}
      <Switch>
        <Route exact path="/" component={TopContainer}/>
        <Route exact path="/nfu" component={TopContainerNFU}/>
        <Route exact path="/trial_register/:email/:digit_id" component={TrialRegisterContainer}/>
        <Route exact path="/login" component={LoginContainer}/>
        <Route exact path="/super-login/:token" component={SuperLogin}/>
        <Route exact path="/invite" component={InvitePage}/>
      </Switch>
      <Route exact path="/top/reset-password" component={ResetPassPage}/>
    </div>
  </Router>
);

export default Routes;