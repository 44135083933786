

function createPerPageState(subject) {
	const {questions} = subject
	return questions.map(question => ({
		question,
		selectHistory: [],
		choices: Array(question.count).fill(false),
	}))
}

export default {
	createPerPageState,
}