import React from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import { common } from '@material-ui/core/colors';
import {toolbarColor} from '../alt/constants'

class FkToolbar extends React.Component {
	static propTypes = {
		title: PropTypes.string.isRequired,
		onGoBack: PropTypes.func,
	}

	render() {
		const styles = {
			appBar: {
				marginTop: 10,
				marginBottom: 6,
				// marginLeft: 8,
				backgroundColor: toolbarColor,
				boxShadow: 'none',
			},
			toolBar: {
				minHeight: 48,
				// backgroundColor: blue[500],
			},
			toolBarTitle: {
				marginLeft: 8,
				color: common.white,
				whiteSpace: 'nowrap',
			},
		}
		const goBackIcon = (this.props.onGoBack && this.props.history.length > 2) ? (
			<IconButton style={{color:'white'}} aria-label="Back" onClick={this.props.onGoBack.bind(this)}>
				<ArrowBackIcon />
			</IconButton>
		) : null
		return (
			<AppBar style={styles.appBar} position="static">
        <Toolbar style={styles.toolBar} disableGutters={true}>
					<Grid container alignItems="center">
						<Grid item>
							{goBackIcon}
						</Grid>
						<Grid item>
							<span style={styles.toolBarTitle}>{this.props.title}</span>
						</Grid>
						<Grid item xs style={{textAlign: 'right', paddingRight: 20}}>
							{this.props.children}
						</Grid>
					</Grid>
				</Toolbar>
			</AppBar>
		)
	}
}
export default withRouter(FkToolbar)