import React from 'react'
import PropTypes from 'prop-types'
// import ClassNames from 'classnames'
import _ from 'lodash'

export default class Selector extends React.Component {
	static propTypes = {
		onChange: PropTypes.func,
		// input: PropTypes.object,
		value: PropTypes.any,
		children: PropTypes.any,
		minNumber: PropTypes.number,
		maxNumber: PropTypes.number,
	}

	list = null

	handleChange = (event) => {
		event.preventDefault()
		const value = this.list[parseInt(event.target.value,10)]
    if (this.props.onChange) {
      this.props.onChange(value);
    }
    if (this.props.input) {
      this.props.input.inst.onChange(event, value);
    }
	}

	render() {
    const {
			// label,	 // eslint-disable-line no-unused-vars
			// input,	 // eslint-disable-line no-unused-vars
      children, // eslint-disable-line no-unused-vars
      // className,	 // eslint-disable-line no-unused-vars
      // disabled,	 // eslint-disable-line no-unused-vars
      // errorStyle,	 // eslint-disable-line no-unused-vars
      // errorText, // eslint-disable-line no-unused-vars
      // fullWidth, // eslint-disable-line no-unused-vars
      // hintText,
      // hintStyle,	 // eslint-disable-line no-unused-vars
      // id,	// eslint-disable-line no-unused-vars
      // inputStyle,	 // eslint-disable-line no-unused-vars
			// multiline,		 // eslint-disable-line no-unused-vars
      // onBlur, // eslint-disable-line no-unused-vars
      onChange, // eslint-disable-line no-unused-vars
      // onFocus, // eslint-disable-line no-unused-vars
			minNumber, // eslint-disable-line no-unused-vars
			maxNumber, // eslint-disable-line no-unused-vars
      // style,	 // eslint-disable-line no-unused-vars
  //    form,
			value,
      ...other
    } = this.props;
		// const errorText = input.error
		// const inputId = id || getUniqueStr()
		// const rootClass = ClassNames({
		// 	'form-group': true,
		// 	'has-error': !!errorText,
		// })
		// const labelEl = (!!label) ? (
		// 	<label className="control-label" htmlFor={inputId}>{label}</label>
		// ) : null
		// const errorTextEl = (!!errorText) ? (
		// 	<span className="help-block">{errorText}</span>
		// ) : null
		// const ref = (elem) => this.input = elem

		let _children = []
		let list = []
		let index = 0

		if ("minNumber" in this.props && "maxNumber" in this.props) {
			let _value = this.props.minNumber
			while (_value <= this.props.maxNumber) {
				_children.push(
					<option key={index} value={index}>{_value}</option>
				)
				list[index++] = _value++
			}
		}

		_.forEach(this.props.children, child => {
			if (child.type === 'option') {
				const text = child.props.children
				const _value = child.props.value
				_children.push(
					<option key={index} value={index}>{text}</option>
				)
				list[index++] = _value
			}
		})
		this.list = list
		const _value = list.indexOf(value)

		return (
			<select
				onChange={this.handleChange.bind(this)}
				className="form-control"
				// ref={ref}
				value={_value}
				{...other}
			>
				{_children}
			</select>
		)
	}
}
