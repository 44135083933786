// @flow
import React from 'react'
import {withRouter} from 'react-router-dom'
import FkToolbar from '../../parts/FkToolbar'
import Grid from '@material-ui/core/Grid'
import SlTextField from "../../sonodalib/sl_mui/SlTextField";
import {FormState, SelectorInputState, TextInputState} from "../../sonodalib/sl_utils/SlForms";
import TextFieldSelector from '../../sonodalib/sl_mui/TextFieldSelector'
import {getPrefectures} from "../../sonodalib/sl_utils/Address";
import Button from '@material-ui/core/Button'
import {post} from '../../sonodalib/sl_utils/Http'
import Cookies from "js-cookie";
import {altName} from '../../alt/constants'
import {urlPref} from '../../alt/constants'

type Props = {
	history: any,
}
type States = {
	step: 'input' | 'confirm' | 'thankyou',
	uniqueId: TextInputState,
	name: TextInputState,
	kana: TextInputState,
	zipcode: TextInputState,
	pref: SelectorInputState,
	addressCity: TextInputState,
	addressStreet: TextInputState,
	telno: TextInputState,
	postScript: TextInputState,
}
const styles = {
	queryOptionTitle: {
		fontSize: 12,
		fontWeight: 700,
		marginTop: 8,
		marginBottom: 8,
	},
	body1: {
		fontSize: 16,
	},
}

class AdditionalApplicationPage extends React.Component<Props, States> {
	state = {
		step: 'input',
		uniqueId: new TextInputState(),
		name: new TextInputState({validations: "required,zenkaku"}),
		kana: new TextInputState({validations: "required,zenkaku"}),
		zipcode: new TextInputState({validations: "required,zipcode"}),
		pref: new SelectorInputState({menuList: getPrefectures()}),
		addressCity: new TextInputState({validations: "required"}),
		addressStreet: new TextInputState({validations: "required"}),
		// telno: new TextInputState({validations: "required,telno"}),
		telno: new TextInputState({validations: "required"}),
		postScript: new TextInputState({validations: "required"}),
	}

	componentWillMount() {
		const payload = {
			user_token: Cookies.get(altName),
		}
		post("user/get_profile", payload)
			.then(ret => {
				this.setState({
				 	uniqueId: this.state.uniqueId.setValue(ret.uniqueId || ""),
				 	name: this.state.name.setValue(ret.name || ""),
					kana: this.state.kana.setValue(ret.kana || ""),
					zipcode: this.state.zipcode.setValue(ret.zipcode || ""),
					pref: this.state.pref.setValue(ret.addressPref),
					addressCity: this.state.addressCity.setValue(ret.addressCity || ""),
					addressStreet: this.state.addressStreet.setValue(ret.addressStreet || ""),
					telno: this.state.telno.setValue(ret.telno || ""),
				})
			}).catch(err => {
				alert("ネットワークエラーが発生しました。リロードしてお試し下さい")
			})
	}

	handleSubmit = (event: any) => {
		event.preventDefault()
		this.setState(FormState.validate(this.state), () => {
			if (FormState.isValid(this.state)) {
				this.setState({
					step: 'confirm',
				})
			}
		})
	}

	handleRedo = () => {
		this.setState({
			step: 'input',
		})
	}

	render() {
		switch (this.state.step) {
			case 'input':
				return this.renderInput()
			case 'confirm':
				return this.renderConfirm()
			default:	// 'thankyou'
				return this.renderThankyou()
		}
	}


	renderInput() {
		return (
			<div>
				<FkToolbar
					title="お申込み"
				/>
				<div style={{maxWidth: 800, marginTop: 20, marginLeft: 'auto', marginRight: 'auto'}}>
					<div className="box box-danger box-solid">
						<div className="box-header with-border">
							<span style={{fontSize: 16}} className="box-title">２０１８年度はコンビニ決済のご準備の都合により、４月１０日から「お申込み」受付を開始致します！</span>
						</div>
						<div className="box-body">
							１週間を目安に体験ご利用いただき、正式にご利用いただける方は、以下の「お申込み」手続きを行なって下さい。コンビニ決済が完了致しますと、自動的に正式なご利用（ご利用年度等のご利用制限が解除）に移行致します。<br/>
							※大学等団体を通じてお申込みいただき、費用もお支払いただいている方の「お申込み」は不要です。
						</div>
					</div>
				</div>
				<form onSubmit={this.handleSubmit}>
					<Grid container direction="row" justify="center"
								style={{maxWidth: 800, marginLeft: 'auto', marginRight: 'auto'}}>
						<Grid item xs={12} sm={6}>
							<SlTextField
								label="利用者ID"
								state={this.state.uniqueId}
								onChange={() => {
								}}
							/>
						</Grid>
						<Grid item xs={12} sm={6}>
						</Grid>
						<Grid item xs={12} sm={6}>
							<SlTextField
								label="お名前"
								placeholder="山田太郎"
								state={this.state.name}
								onChange={(newState: TextInputState) => {
									this.setState({name: newState})
								}}
							/>
						</Grid>
						<Grid item xs={12} sm={6}>
							<SlTextField
								label="フリガナ"
								placeholder="ヤマダタロウ"
								state={this.state.kana}
								onChange={(newState: TextInputState) => {
									this.setState({kana: newState})
								}}
							/>
						</Grid>
						<Grid item xs={12} sm={6}>
							<SlTextField
								label="郵便番号"
								placeholder="123-4567"
								state={this.state.zipcode}
								onChange={(newState) => {
									this.setState({zipcode: newState})
								}}
							/>
						</Grid>
						<Grid item xs={12} sm={6}>
							<TextFieldSelector
								label="都道府県"
								state={this.state.pref}
								fullWidth={true}
								onChange={(newState: SelectorInputState) => {
									this.setState({pref: newState})
								}}
								InputLabelProps={{
									shrink: true,
									disableAnimation: true,
								}}
							/>
						</Grid>
						<Grid item xs={12} sm={6}>
							<SlTextField
								label="市区町村"
								placeholder="千葉市中央区"
								state={this.state.addressCity}
								onChange={(newState) => {
									this.setState({addressCity: newState})
								}}
							/>
						</Grid>
						<Grid item xs={12} sm={6}>
							<SlTextField
								label="番地"
								placeholder="新田町10-15誠寿ビル2F"
								state={this.state.addressStreet}
								onChange={(newState) => {
									this.setState({addressStreet: newState})
								}}
							/>
						</Grid>
						<Grid item xs={12} sm={6}>
							<SlTextField
								label="電話番号（13文字まで）"
								placeholder="012-345-6789"
								state={this.state.telno}
								onChange={(newState) => {
									this.setState({telno: newState})
								}}
								inputProps={{
									maxLength: 13,
								}}
							/>
						</Grid>
						<Grid item xs={12} sm={6}>
						</Grid>
						<Grid item xs={12} sm={12}>
							<SlTextField
								multiline
								label="ご連絡事項"
								placeholder=""
								state={this.state.postScript}
								onChange={(newState) => {
									this.setState({postScript: newState})
								}}
							/>
						</Grid>
						<Grid item xs={12} sm={12} style={{textAlign: 'center'}}>
							<Button disabled variant="contained" type="submit" color="primary" style={{width: '40%'}}>
								確認画面へ
							</Button>
						</Grid>
					</Grid>
				</form>
			</div>
		)
	}

	handleOrder = () => {
		const st = this.state
		const obj = {
			name: st.name.getValue(),
			kana: st.kana.getValue(),
			zipcode: st.zipcode.getValue(),
			addressCity: st.addressCity.getValue(),
			addressStreet: st.addressStreet.getValue(),
			addressPref: st.pref.getValue(),
			telno: st.telno.getValue(),
		}
		const payload = {
			user_token: Cookies.get(altName),
			obj,
		}
		post("user/update_profile", payload)
			.then(() => {
				const payload = {
					user_token: Cookies.get(altName),
					post_script: this.state.postScript.getValue(),
				}
				return post("order/additional", payload)
			})
			.then((ret) => {
				this.setState({
					step: 'thankyou',
				})
			})
			.catch((ret) => {
			})
	}

	renderConfirm() {
		return (
			<div>
				<FkToolbar
					title="お申込み内容の確認"
				/>
				<div style={{maxWidth: 800, marginTop: 20, marginLeft: 'auto', marginRight: 'auto'}}>
					<div className="box box-danger box-solid">
						<div className="box-header with-border">
							<span style={{fontSize: 16}} className="box-title">２０１８年度はコンビニ決済のご準備の都合により、４月１０日から「お申込み」受付を開始致します！</span>
						</div>
						<div className="box-body">
							１週間を目安に体験ご利用いただき、正式にご利用いただける方は、以下の「お申込み」手続きを行なって下さい。コンビニ決済が完了致しますと、自動的に正式なご利用（ご利用年度等のご利用制限が解除）に移行致します。<br/>
							※大学等団体を通じてお申込みいただき、費用もお支払いただいている方の「お申込み」は不要です。
						</div>
					</div>
				</div>
				<Grid container direction="row" justify="center"
							style={{maxWidth: 800, marginLeft: 'auto', marginRight: 'auto'}}>
					<Grid item xs={12} sm={6}>
						<div key={1} style={styles.queryOptionTitle}>利用者ID</div>
						<div key={2} style={styles.body1}>
							{this.state.uniqueId.getValue()}
						</div>
					</Grid>
					<Grid item xs={12} sm={6}>
					</Grid>
					<Grid item xs={12} sm={6}>
						<div key={1} style={styles.queryOptionTitle}>お名前（全角）</div>
						<div key={2} style={styles.body1}>
							{this.state.name.getValue()}
						</div>
					</Grid>
					<Grid item xs={12} sm={6}>
						<div key={1} style={styles.queryOptionTitle}>フリガナ（全角）</div>
						<div key={2} style={styles.body1}>
							{this.state.kana.getValue()}
						</div>
					</Grid>
					<Grid item xs={12} sm={6}>
						<div key={1} style={styles.queryOptionTitle}>郵便番号</div>
						<div key={2} style={styles.body1}>
							{this.state.zipcode.getValue()}
						</div>
					</Grid>
					<Grid item xs={12} sm={6}>
						<div key={1} style={styles.queryOptionTitle}>都道府県</div>
						<div key={2} style={styles.body1}>
							{this.state.pref.getLabel()}
						</div>
					</Grid>
					<Grid item xs={12} sm={6}>
						<div key={1} style={styles.queryOptionTitle}>市区町村</div>
						<div key={2} style={styles.body1}>
							{this.state.addressCity.getValue()}
						</div>
					</Grid>
					<Grid item xs={12} sm={6}>
						<div key={1} style={styles.queryOptionTitle}>番地</div>
						<div key={2} style={styles.body1}>
							{this.state.addressStreet.getValue()}
						</div>
					</Grid>
					<Grid item xs={12} sm={6}>
						<div key={1} style={styles.queryOptionTitle}>電話番号</div>
						<div key={2} style={styles.body1}>
							{this.state.telno.getValue()}
						</div>
					</Grid>
					<Grid item xs={12} sm={6}>
					</Grid>
					<Grid item xs={12} sm={12}>
						<div key={1} style={styles.queryOptionTitle}>ご連絡事項</div>
						<div key={2} style={styles.body1} dangerouslySetInnerHTML={{__html: this.state.postScript.getValue().replace(/\n/g,"<br />")}}></div>
					</Grid>
					<Grid item xs={12} sm={12} style={{textAlign: 'center'}}>
						<Button variant="contained" onClick={this.handleRedo}>
							修正する
						</Button>
						<Button variant="contained" onClick={this.handleOrder} color="primary" style={{width: '40%', marginLeft: 20}}>
							申し込み
						</Button>
					</Grid>
				</Grid>
			</div>
		)
	}

	renderThankyou() {
		return (
			<div style={{textAlign:'center'}}>
				<div style={{marginTop:100, marginBottom:100}}>
					<h2>お申込みを受け付けました。<br/>
						後ほどメールにてご連絡致します</h2>
				</div>
				<div>
					<Button
						variant="contained"
						color="primary"
						href={urlPref+"/my"}
					>
						マイページに戻る
					</Button>
				</div>
			</div>
		)
	}

}

export default withRouter(AdditionalApplicationPage)