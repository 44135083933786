import { createAction } from 'redux-actions'
import { Record } from 'immutable'

const _QueryCondition = Record({
	subject: 		null,			// 科目
	qtype:	 		null,			// 0=選択 1=OX
	pick_type:	null,			// random=ランダム newQuestion=未解答 failedQuestion=不正解
	query_mode:	null,			// training=トレーニング test=テスト
	qcount:			null,			// 出題数
	nendo:			null,			// 年度 e.g.) [2012,2010,2008]
	keyword:		null,			// キーワード
	mondaiNum:	null,			// 問題番号
})
export class QueryCondition extends _QueryCondition {
}

// query-condition
export const UPDATE_QUERY_CONDITION = 'UPDATE_QUERY_CONDITION'
export const updateQueryConditionAC = createAction(UPDATE_QUERY_CONDITION, entity => (entity));

// sentaku-questions
export const UPDATE_SENTAKU_QUESTIONS = 'UPDATE_SENTAKU_QUESTIONS'
export const updateSentakuQuestionsAC = createAction(UPDATE_SENTAKU_QUESTIONS, entity => (entity));

// sentaku-question
export const UPDATE_SENTAKU_QUESTION = 'UPDATE_SENTAKU_QUESTION'
export const updateSentakuQuestionAC = createAction(UPDATE_SENTAKU_QUESTION, entity => (entity));

// sentaku-results
export const UPDATE_SENTAKU_RESULTS = 'UPDATE_SENTAKU_RESULTS'
export const updateSentakuResultsAC = createAction(UPDATE_SENTAKU_RESULTS, entity => (entity));

// ox-questions
export const UPDATE_OX_QUESTIONS = 'UPDATE_OX_QUESTIONS'
export const updateOxQuestionsAC = createAction(UPDATE_OX_QUESTIONS, entity => (entity));

// ox-question
export const UPDATE_OX_QUESTION = 'UPDATE_OX_QUESTION'
export const updateOxQuestionAC = createAction(UPDATE_OX_QUESTION, entity => (entity));

// ox-results
export const UPDATE_OX_RESULTS = 'UPDATE_OX_RESULTS'
export const updateOxResultsAC = createAction(UPDATE_OX_RESULTS, entity => (entity));

// jirei-questions
export const UPDATE_JIREI_QUESTIONS = 'UPDATE_JIREI_QUESTIONS'
export const updateJireiQuestionsAC = createAction(UPDATE_JIREI_QUESTIONS, entity => (entity));

// jirei-question
export const UPDATE_JIREI_QUESTION = 'UPDATE_JIREI_QUESTION'
export const updateJireiQuestionAC = createAction(UPDATE_JIREI_QUESTION, entity => (entity));
