import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom';
import TopHeader from './TopHeader'
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import {post} from '../sonodalib/sl_utils/Http'
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import SlTextField from '../sonodalib/sl_mui/SlTextField'
import InputDigitCode from '../parts/InputDigitCode'
import ConfirmPassword from '../parts/ConfirmPassword'
import { TextInputState, FormState } from '../sonodalib/sl_utils/SlForms'
import CommandBox from '../parts/CommandBox'
import {urlPref} from '../alt/constants'


class NewPassword extends Component {
	static propTypes = {
		onFinish: PropTypes.func.isRequired,
		onCancel: PropTypes.func.isRequired,
	}

	state = {
		passwordState: new TextInputState({validations:"require,password"})
	}

	handleSubmit = e => {
		e.preventDefault()
		this.setState(FormState.validate(this.state), () => {
			if (FormState.isValid(this.state)) {
				const password = this.state.passwordState.getValue()
				this.props.onFinish(password)
			}
		})
	}

	render() {
		const styles = {
		  loginBtn: {
			  float: 'right',
			  marginLeft: 8,
		  },
			loginBox: {
				marginTop: 20,
				width: 400,
			},
		}
		const { passwordState } = this.state
		return (
			<Grid container alignItems="center" direction="column">
				<CommandBox
					label="新しいパスワードを入力して下さい"
					desc="数値、英大・小文字が必要 8文字以上"
				/>
				<Grid style={styles.loginBox}>
					<form onSubmit={this.handleSubmit}>
						<SlTextField
							autoFocus
							type="password"
							placeholder="英小文字と数字の組み合わせで8文字以上"
							label="パスワード"
							state={passwordState}
							onChange={(newState) => {this.setState({passwordState: newState})}}
						/>
						<div className="pull-right" style={{marginTop:10}}>
							<Button variant="contained"
								color="primary"
								style={styles.loginBtn}
								type="submit"
							>
								次へ
							</Button>
							<Button variant="contained"
								style={styles.loginBtn}
								onClick={this.props.onCancel}
							>
								キャンセル
							</Button>
						</div>
					</form>
				</Grid>
			</Grid>
		)
	}
}


class TrialRegisterContainer extends Component {
	state = {
		page: "inputDigitCode",
		completedDialogOpen: false,
	}


  render() {
		let content = null
		switch (this.state.page) {
			case 'inputDigitCode':
				content = <InputDigitCode
										digitsId={this.props.match.params.digit_id}
										appliedEmail={this.props.match.params.email}
										expiredDesc={<span>お手数ですが、<a onClick={this.onCancel}>再度確認メールを受信</a> して下さい</span>}
										onFinish={() => {
											this.setState({page: "newPassword"})
										}}
										onCancel={() => {
											this.props.history.push("/")
										}}
									/>
				break;

			case "newPassword":
				content = <NewPassword
										onFinish={newPasswordString => {
											this.newPasswordString = newPasswordString
											this.setState({page: "confirmPassword"})
										}}
										onCancel={() => {
											this.setState({page: "inputDigitCode"})
										}}
									/>
				break;

			case "confirmPassword":
				content =
					<ConfirmPassword
						passwordString={this.newPasswordString}
						onSubmit={(ret, onError) => {
							const payload = {
								digit_id: this.props.match.params.digit_id,
								password: ret.password2,
							}
							post("user/trial_register_step3", payload)
							.then(() => {
								this.setState({completedDialogOpen: true})
							})
							.catch(res => {
								let errorMessage;
								if (res && res.statusCode === 401) {
									errorMessage = "すでに登録されているため、処理は中断されました"
								} else {
									errorMessage = "不明なエラーが発生しました"
								}
								onError(errorMessage)
								// this.password2State.error(errorMessage)
							})
						}}
						onCancel={() => {
							this.setState({page: "newPassword"})
						}}
					/>
				break;
			default:
		}

    return (
      <div className="wrapper">
				<TopHeader/>
        <div className="content-wrapper">
          <div className="container">
            <div style={{height:800}}>
							{content}
							<Dialog open={this.state.completedDialogOpen}>
								<DialogTitle>お試し登録が完了しました</DialogTitle>
								<DialogActions>
									<Button href={urlPref+"/login"}>
										ログインページへ
									</Button>
								</DialogActions>
							</Dialog>
            </div>
          </div>
        </div>
        <footer className="main-footer">
          <div className="container">
            <strong>Copyright © <a href="http://www.jc-edu.co.jp">JC Educational Institute, Inc.</a>.</strong> All rights reserved.
          </div>
        </footer>
      </div>
    )
  }
}

export default withRouter(TrialRegisterContainer)
