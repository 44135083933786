import * as Action from './QuestionAction';

const initialState = {
	queryCondition: new Action.QueryCondition(),	// 問題検索条件(QueryCondition)
	sentakuQuestions: null,				// 全問の問題データ（選択）
	sentakuQuestion:  null,				// 現在の問題
	sentakuResults:		null,				// 全問の結果
	oxQuestions: null,						// 全問の問題データ(OX)
	oxQuestion:  null,						// 現在の問題
	oxResults:		null,						// 全問の結果
	jireiQuestions: null,					// 全問の問題データ(事例)
	jireiQuestion:  null,					// 現在の問題
}

export default function myPageReducer(state = initialState, action) {
	const p = action.payload
  switch (action.type) {
    case Action.UPDATE_QUERY_CONDITION:
      return {...state, queryCondition: p}
    case Action.UPDATE_SENTAKU_QUESTIONS:
      return {...state, sentakuQuestions: p}
    case Action.UPDATE_SENTAKU_QUESTION:
      return {...state, sentakuQuestion: {...state.sentakuQuestion, ...p}}
    case Action.UPDATE_SENTAKU_RESULTS:
      return {...state, sentakuResults: p}
    case Action.UPDATE_OX_QUESTIONS:
      return {...state, oxQuestions: p}
    case Action.UPDATE_OX_QUESTION:
      return {...state, oxQuestion: {...state.oxQuestion, ...p}}
    case Action.UPDATE_OX_RESULTS:
      return {...state, oxResults: p}
    case Action.UPDATE_JIREI_QUESTIONS:
      return {...state, jireiQuestions: p}
    case Action.UPDATE_JIREI_QUESTION:
      return {...state, jireiQuestion: {...state.jireiQuestion, ...p}}
    default:
      return state
  }
}
