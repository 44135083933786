import React from 'react'
import PropTypes from 'prop-types'
import Cookies from "js-cookie";
import {connect} from 'react-redux'
import {common, lightBlue, pink, deepPurple} from '@material-ui/core/colors';
import List from '@material-ui/core/List'
import Icon from '@material-ui/core/Icon'
import ForwardIcon from '@material-ui/icons/Forward'
import _ from 'lodash'
import {CSSTransition} from 'react-transition-group';
import IconButton from '@material-ui/core/IconButton';
import '../sonodalib/sl_utils/utils.css'
import Button from '@material-ui/core/Button';
import {Flex, Box} from 'reflexbox'
import {updateSentakuQuestionAC} from './QuestionAction'
import G from '../sonodalib/SlGlobal'
import {post} from '../sonodalib/sl_utils/Http'
import {SentakuChoiceResult} from './CommonParts'
import FkToolbar from '../parts/FkToolbar'
import {SubjectBox, QuestionBox} from '../Common'
import MemoIconButton from '../parts/MemoIconButton'
import {withMemoDrawer} from '../parts/MemoDrawer'
import {withBbsDrawer} from '../parts/BbsDrawer'
import {TestModeLastTime, TestModeDialog} from "./TestMode";
import FloatingIcon from '../parts/FloatingIcon'
import {login} from "../sonodalib/sl_users/SlUsersAction";
import {altName, urlPref} from '../alt/constants'


class SentakuQuestion extends React.Component {
	static propTypes = {
		onEnd: PropTypes.func.isRequired,
	}

	state = {
		timer: "",
		timeUp: false,
	}
	mondaiId = null

	componentWillMount() {
		this.updateMondai(this.props)
		this.interval = null
		if (this.isTest()) {
			this.interval = setInterval(this.tick.bind(this), 500)
		}
	}

	componentWillUnmount() {
		if (this.interval) {
			clearInterval(this.interval)
		}
	}

	componentWillReceiveProps(props) {
		return this.updateMondai(props)
	}

	componentDidMount() {
		window.scrollTo(0, 0)
	}

	isTest() {
		return (this.props.queryCondition && this.props.queryCondition.query_mode === "test")
	}

	tick() {
		if (!this.state.timeUp) {
			const {startedTime, totalTime} = this.props.sentakuQuestions
			const lastSec = Math.max(0, totalTime - (new Date() - startedTime) / 1000)
			const min = Math.floor(lastSec / 60)
			let sec = Math.floor(lastSec % 60)
			if (sec < 10) sec = "0" + sec
			const label = min + ":" + sec
			const timeUp = (lastSec <= 0)
			this.setState({
				timer: label,
				timeUp,
			})
		}
	}

	handleTimeUpEnd = () => {
		this.props.onEnd(5)
	}

	// state = {
	// 	answerCount: 1,
	// 	choices: [
	// 		{
	// 			index: 0,
	// 			selected: false,
	// 			text: "ロストウ（Rostow,W.W.）によれば、社会の関心は、供給から需要、生産から消費へと移っていく。",
	// 			commentary: "５歳児のＦ男ちゃんは、弟が生まれたことをきっかけに、年齢に合わない指しゃぶりを始めたことから「退行」である。なお「昇華」とは、非社会的欲求（性欲・攻撃欲）を芸能、文化、スポーツなどの社会的に承認される行動に、振り替えることで満たそうとすることをいう。例として、失恋の悲しみを仕事に向けるなどが挙げられる。",
	// 			correct: false,
	// 			showCommentary: false,
	// 		},
	// 		{
	// 			index: 1,
	// 			selected: false,
	// 			text: "ガルブレイスの依存効果とは、消費者の欲望が自律的でなく、生産者の働きかけによって喚起される現象をいう。消費部門が生産部門に依存する依存効果が見られる。",
	// 			commentary: "自分が非常に憎んでいる父親に対し、かえって気遣いをし、過剰な配慮をする高校生のＧ男さんの行動は、抑圧するだけでは処理しがたい強力な嫌悪感や衝動を防衛するために、意識の上では正反対な行動を示す「反動形成」にあたる。よって正しい。",
	// 			correct: true,
	// 			showCommentary: false,
	// 		},
	// 	],
	// 	selectHistory: [],
	// 	showResult: 0,
	// }

	updateMondai(props) {
		if (this.mondaiId !== props.mondaiId) {
			this.mondaiId = props.mondaiId
			this.marubatsuId = props.marubatsuId
			// console.log("MondaiID=" + this.mondaiId)
			if (!this.isTest()) {
				props.memoDrawer.load({
					mondaiId: this.mondaiId,
					marubatsuId: 0,
				})
			}
		}
		return true
	}

	handleClickChoice = choice => {
		if (!this.props.sentakuQuestion.showResult) {
			const {answerCount, selectHistory, choices} = this.props.sentakuQuestion
			if (choice.selected) {
				const _selectHistory = _.reject(selectHistory, a => (a === choice.index))
				choice.selected = false
				G.store.dispatch(updateSentakuQuestionAC({
					choices: this.props.sentakuQuestion.choices,
					selectHistory: _selectHistory,
				}))
			} else {
				if (selectHistory.length >= answerCount) {
					const index = selectHistory.shift()
					choices[index].selected = false
				}
				choice.selected = true
				selectHistory.push(choice.index)
				G.store.dispatch(updateSentakuQuestionAC({
					choices: this.props.sentakuQuestion.choices,
					selectHistory: selectHistory,
				}))
			}
		} else {
			choice.showCommentary = !choice.showCommentary
			G.store.dispatch(updateSentakuQuestionAC({
				choices: this.props.sentakuQuestion.choices,
			}))
		}
	}

	postResult = (result, choose) => {
		const question = this.props.sentakuQuestion
		post("result/sentaku", {
			user_token: Cookies.get(altName),
			mondai_id: question.MondaiID,
			choose,
			result,
			is_test: this.isTest(),
		})
		.then((ret) => {
			login(ret.jwt)
		}).catch(() => {
			// logout()
		})
	}

	handleSaiten = () => {
		if (this.isTest()) {
			const result = this.saiten().showResult
			this.handleNext(result)
		} else {
			this.props.updateSentakuQuestion(this.saiten())
		}
	}

	saiten = () => {
		const choose = []
		const choices = this.props.sentakuQuestion.choices
		let correctCount = 0
		_.forEach(choices, choice => {
			if (choice.selected) {
				choose.push(choice.index + 1)
				if (choice.correct) {
					correctCount++
				}
			}
			choice.showCommentary = (choice.selected || choice.correct)
		})
		const showResult = (correctCount === this.props.sentakuQuestion.answerCount) ? 1 : 2
		this.postResult(showResult, choose.join(","))
		return {
			showResult,
			choices,
		}
	}

	handleAllCommentary = () => {
		let show = !this.isOpenAllCommentaries()
		_.forEach(this.props.sentakuQuestion.choices, choice => {
			choice.showCommentary = show
		})
		G.store.dispatch(updateSentakuQuestionAC({
			choices: this.props.sentakuQuestion.choices,
		}))
	}

	isOpenAllCommentaries = () => {
		return _.every(this.props.sentakuQuestion.choices, choice => {
			return choice.showCommentary
		})
	}

	handleNext = result => {
		this.props.onEnd(
			result,			// 1=正解  2=不正解  3=未解答　4=中断
		)
		window.scrollTo(0, 0)
	}

	render() {
		if (this.props.sentakuQuestion === null) return null

		const styles = {
			saitenButton: {
				position: "fixed",
				bottom: "50%",
				right: 35,
				cursor: "pointer",
				zIndex: 999,
			},
			nextButton: {
				position: "fixed",
				bottom: "30%",
				right: 35,
				cursor: "pointer",
				zIndex: 999,
				color: common.white,
				backgroundColor: pink[700],
			},
			headingIncorrect: {
				fontSize: 24,
				fontWeight: 700,
				color: deepPurple[500],
			},
			commandBox: {
				margin: 8,
				marginBottom: 18,
			},
			commandButton: {
				width: 200,
				marginRight: 10,
			},
		}
		const sentakuQuestion = this.props.sentakuQuestion
		const {selectHistory, answerCount, choices, showResult} = sentakuQuestion

		let toolbarIcons = null
		if (!this.isTest()) {
			toolbarIcons = (
				<div>
					<MemoIconButton
						handleOpenMemo={() => {
							this.props.memoDrawer.open(null)
						}}
						isMemoActive={this.props.memoDrawerStatus}
					/>
					<IconButton
						style={{color: 'white'}}
						aria-label="掲示板"
						onClick={() => {
							this.props.bbsDrawer.openToCreateThread(
								1,
								undefined,
								this.mondaiId,
								0,
								"",
							)
						}}
					>
						<Icon>comment</Icon>
					</IconButton>
				</div>
			)
		}

		const choiceVd = _.map(choices, choice => (
			<SentakuChoiceResult
				key={_.uniqueId("fk")}
				choice={choice}
				showResult={showResult}
				onClick={this.handleClickChoice.bind(this)}
				withBorder={(showResult === 0)}
			/>
		))

		const saitenButtonVd = (showResult || selectHistory.length < answerCount) ? null : (
			<FloatingIcon
				label="採点"
				foreColor={common.white}
				backgroundColor={pink[400]}
				onClick={this.handleSaiten}
			>
				<ForwardIcon/>
			</FloatingIcon>
		)

		const nextButtonVd = !(!this.isTest() && showResult) ? null : (
			<FloatingIcon
				label="次"
				foreColor={common.white}
				backgroundColor={pink[400]}
				bottom={40}
				onClick={this.handleNext.bind(this, showResult)}
			>
				<ForwardIcon/>
			</FloatingIcon>
		)

		const correctLabel = [null, "正解", "不正解"]
		const correctImage = [null, "seikai", "fuseikai"]
		const isOpenAllCommentaries = this.isOpenAllCommentaries()
		const resultVd = (!showResult) ? null : (
			<Flex align="center" justify="center">
				<Box m={1}>
					<span style={styles.headingIncorrect}>{correctLabel[showResult]}</span>
					<img src={`${urlPref}/images/eval/${correctImage[showResult]}.png`} alt="result"/>
				</Box>
				<Box ml={3}>
					<Button
						variant="contained"
						style={{BackgroundColor: lightBlue[200]}}
						onClick={this.handleAllCommentary.bind(this)}
					>
						{(isOpenAllCommentaries) ? "解説をすべて隠す" : "解説をすべて開く"}
					</Button>
				</Box>
			</Flex>
		)

		const abortButton = (this.isTest()) ? null : (
			<Button
				variant="contained"
				onClick={this.handleNext.bind(this, 4)}
				style={styles.commandButton}
			>
				学習を中断
			</Button>
		)
		const commandBox = (showResult) ? null : (
			<div style={styles.commandBox}>
				<Button
					variant="contained"
					onClick={this.handleNext.bind(this, 3)}
					// labelColor={white}
					// backgroundColor="#2196f3"
					style={styles.commandButton}
				>
					解答しない
				</Button>
				{abortButton}
			</div>
		)

		// const forceCorrect = (this.props.userState && this.props.userState.roles.indexOf("admin") !== -1) ? (
		// 	<a href="" onClick={this.handleNext.bind(this, 1)}>forceCorrect</a>
		// ) : null

		return (
			<div>
				<CSSTransition
					in={false}
					classNames="fade"
					timeout={500}
				>
					<div>
						{saitenButtonVd}
						{nextButtonVd}
						<TestModeLastTime timer={this.state.timer}/>
					</div>
				</CSSTransition>

				<FkToolbar title={`選択問題 第${sentakuQuestion.qIndex}問 ／全${this.props.sentakuQuestions.questions.length}問`}>
					{toolbarIcons}
				</FkToolbar>

				<SubjectBox label={sentakuQuestion.subjectLabel + "　" + sentakuQuestion.subLabel}/>
				<QuestionBox text={sentakuQuestion.qText}/>
				{sentakuQuestion.Chushakubun}

				{resultVd}
				<List style={{marginLeft: 12, marginRight: 12}}>
					{choiceVd}
				</List>
				{commandBox}
				<TestModeDialog open={this.state.timeUp} onClick={this.handleTimeUpEnd}/>
			</div>
		)
	}
}

export default connect(
	state => ({
		userState: state.sl_users.state,
		sentakuQuestion: state.question.sentakuQuestion,
		sentakuQuestions: state.question.sentakuQuestions,
		queryCondition: state.question.queryCondition,
	}),
	dispatch => ({
		updateSentakuQuestion: entity => {
			dispatch(updateSentakuQuestionAC(entity))
		}
	})
)(withMemoDrawer(withBbsDrawer(SentakuQuestion)))
