import React, {Component} from 'react'
import {connect} from 'react-redux';
import {withRouter, Route, Switch} from 'react-router-dom'
import Button from '@material-ui/core/Button'
import MoshiPage from './MoshiPage';
import Cookies from "js-cookie";
import Moshi from './'
import {altName} from "../alt/constants";
import BackToMoshiTopButton from './parts/BackToMoshiTopButton'


class MoshiFrame extends Component {

	handleGoTop = () => {
		this.props.history.push("/my")
	}

	handleLogout = () => {
		Moshi.terminateSession()
		alert("ログアウトしました")
		// this.props.history.push("/")
	}

	render() {
		const {moshiState, userState} = this.props
		if (!userState) return null
		if (!moshiState.serviceCode) {
			const moshi_token = Cookies.get("wm_"+altName)
			if (!moshi_token) {
				this.props.history.push("/my")
			} else {
				Moshi.restartSession(moshi_token, () => {
					// console.log("restart moshi session")
				}, () => {
					this.handleGoTop()
				})
			}
		}
		const content = (
			<Switch>
				<Route path="/wm" component={MoshiPage}/>
			</Switch>
		)
		if (this.props.printMode) {
			return (
				<div>
					{content}
				</div>
			)
		} else {
			const isMoshiTop = this.props.history.location.pathname === "/wm"
			return (
				<div style={{margin:10, backgroundColor:'white'}}>
					<div style={{textAlign:'right'}}>
						{(isMoshiTop) ? null : (
							<BackToMoshiTopButton history={this.props.history}/>
						)}
						{(userState.license !== "8") ? (
							<Button
								variant="text"
								color="secondary"
								onClick={this.handleGoTop}
								style={{marginLeft:10,border:'1px solid #f50057'}}
							>
								マイページに戻る
							</Button>
						) : (
							<Button
								variant="text"
								color="secondary"
								onClick={this.handleLogout}
								style={{marginLeft:10,border:'1px solid #f50057'}}
							>
								ログアウト
							</Button>
						)}
					</div>
					<div>
						<div style={{height: 'auto'}}>
							{content}
						</div>
					</div>
					<footer className="main-footer" style={{marginTop: 100}}>
						<div className="container">
							<strong>Copyright © <a href="http://www.jc-edu.co.jp">JC Educational Institute, Inc.</a>.</strong> All
							rights reserved.
						</div>
					</footer>
				</div>
			)
		}
	}
}

export default connect(
	state => ({
		moshiState: state.moshi,
		userState: state.sl_users.state,
		printMode: state.moshi.printMode,
	}),
)(withRouter(MoshiFrame));
