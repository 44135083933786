import React from 'react'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button';
// import TextField from '@material-ui/core/TextField';
import SlTextField from '../sonodalib/sl_mui/SlTextField'
import Grid from '@material-ui/core/Grid';
import { TextInputState } from '../sonodalib/sl_utils/SlForms'
import CommandBox from './CommandBox'

export default class ConfirmPassword extends React.Component {
	static propTypes = {
		passwordString: PropTypes.string.isRequired,
		onSubmit: PropTypes.func.isRequired,
		onCancel: PropTypes.func.isRequired,
	}

	state = {
		password2State: new TextInputState({validations: "require,password"}),
	}

	handleSubmit = e => {
		e.preventDefault()
		this.setState({
			password2State: this.state.password2State.validate()
		}, () => {
			const p2s = this.state.password2State
			if (p2s.isValid()) {
				if (p2s.getValue() !== this.props.passwordString) {
					this.setState({
						password2State: p2s.setError("入力したパスワードが間違っています"),
					})
				} else {
					this.props.onSubmit({password2:this.state.password2State.getValue()}, (errorMessage) => {
						this.setState({
							password2State: this.state.password2State.setError(errorMessage),
						})
					})
				}
			}
		})
	}

	render() {
		const styles = {
			loginBox: {
				marginTop: 20,
				width: 400,
			},
		  loginBtn: {
			  float: 'right',
			  marginLeft: 8,
		  },
		}
		const { password2State } = this.state

		return (
			<Grid container alignItems="center" direction="column">
				<CommandBox
					label="パスワード再入力"
					desc="確認のため設定したパスワードを再入力して下さい"
				/>
				<Grid style={styles.loginBox}>
					<span style={styles.digitCodeDesc}>
						わからなくなった場合は、キャンセルして再度ご入力下さい。
					</span>
					<form onSubmit={this.handleSubmit}>
						<SlTextField
							autoFocus
							placeholder="英小文字と数字の組み合わせで8文字以上"
							label="パスワード"
							state={password2State}
							onChange={(state)=>{this.setState({password2State:state})}}
							type="password"
						/>
						<div className="pull-right" style={{marginTop: 10}}>
							<Button variant="contained"
								color="primary"
								style={styles.loginBtn}
								type="submit"
							>
								次へ
							</Button>
							<Button variant="contained"
								style={styles.loginBtn}
								onClick={this.props.onCancel}
							>
								キャンセル
							</Button>
						</div>
					</form>
				</Grid>
			</Grid>
		)
	}
}
