

function createTestPerPageState(questions, qno) {
	const question = questions[qno]
	const choices = question.selection.map((row) => ({
		selected: false,
		index:    row.index-1,
		text:			row.text,
	}))
	return {
		choices,
		selectHistory: [],
	}
}

export default {
	createTestPerPageState,
}