// @flow
import React from 'react'
import TextField from '@material-ui/core/TextField'
import {TextInputState} from "../sl_utils/SlForms";

type Props = {
	label?: string,
	placeholder?: string,
	fullWidth?: boolean,
	state: TextInputState,
	onChange: (TextInputState) => void,
	margin?: string,
	shrink?: boolean,
	disableAnimation?: boolean,
}
export default class SlTextField extends React.Component<Props> {
	static defaultProps = {
		fullWidth: true,
		margin: "normal",
		shrink: true,
		disableAnimation: true,
	}

	onChange = (event:SyntheticInputEvent<*>) => {
		const {onChange, state} = this.props
		onChange(state.setValue(event.target.value))
	}

	render() {
		const {label, placeholder, state, fullWidth, margin, onChange, shrink, disableAnimation, ...other} = this.props
		return (
			<TextField
				label={label}
				placeholder={placeholder}
				fullWidth={fullWidth}
				error={state.isError()}
				helperText={state.getError()}
				value={state.getValue()}
				onChange={this.onChange}
				margin={margin}
				InputLabelProps={{
					shrink,
					disableAnimation,
				}}
				{...other}
			/>
		)
	}
}
