import React from 'react'
import { connect } from 'react-redux'
import SelectCategory from "../../alt/SelectCategory";
import SelectQType from "./SelectQType";
import SentakuQuery from './SentakuQuery'
import SearchResult from './SearchResult'
import OxQuery from './OxQuery'
import JireiQuery from './JireiQuery'
import { withRouter, Route, Switch } from 'react-router-dom'
import {urlPref} from '../../alt/constants'
import {gakushuDefaultCategory, gakushuFirstUrl} from '../../alt/constants'


class Query extends React.Component {

	state = {
		category: gakushuDefaultCategory,
		qtype: null,
	}

	handleSelectCategory = (category) => {
		this.setState({
			category,
		})
		this.props.history.push("/my/lesson/query/select-qtype")
	}

	handleSelectQType = (qtype) => {
		this.setState({
			qtype,
		})
		this.props.history.push("/my/lesson/query/"+qtype)
	}

	render() {
		const self = this
		const { category } = this.state
		// const Kamokushubetsu = (category) ? 1 : 0
		return (
			<div>
				<Switch>
					<Route exact path="/my/lesson/query/select-category" render={() => <SelectCategory onSelect={self.handleSelectCategory}/>}/>
					<Route exact path="/my/lesson/query/select-qtype" render={() => {
						if (category === null) {
							window.location.href = urlPref+gakushuFirstUrl
							// self.props.history.push(gakushuFirstUrl)
							return null
						} else {
							const onGoBack = (window.location.href.indexOf(gakushuFirstUrl) !== -1) ? null : () => {
									self.props.history.push(gakushuFirstUrl)
								}
							return (
								<SelectQType
									category={category}
									onSelect={self.handleSelectQType}
									onGoBack={onGoBack}
								/>
							)
						}
					}
					}/>
					<Route exact path="/my/lesson/query/sentaku" render={() => {
						if (category === null || self.state.qtype === null) {
							self.props.history.push(gakushuFirstUrl)
							return null
						} else {
							return (
								<SentakuQuery
									category={category}
									qtype={this.state.qtype}
									onGoBack={() => {
										self.props.history.push("/my/lesson/query/select-qtype")
									}}
								/>
							)
						}
					}
					}/>
					<Route exact path="/my/lesson/query/sentaku/result" render={() => {
						return (
							<SearchResult
								searchResult={this.props.searchResult}
							/>
						)
					}}
					/>
					<Route exact path="/my/lesson/query/ox" render={() => {
						if (category === null || self.state.qtype === null) {
							self.props.history.push(gakushuFirstUrl)
							return null
						} else {
							return (
								<OxQuery
									category={category}
									qtype={this.state.qtype}
									onGoBack={() => {
										self.props.history.push("/my/lesson/query/select-qtype")
									}}
								/>
							)
						}
					}
					}/>
					<Route exact path="/my/lesson/query/jirei" render={() => {
						if (category === null || self.state.qtype === null) {
							self.props.history.push(gakushuFirstUrl)
							return null
						} else {
							return (
								<JireiQuery
									category={category}
									qtype={this.state.qtype}
									onGoBack={() => {
										self.props.history.push("/my/lesson/query/select-qtype")
									}}
								/>
							)
						}
					}
					}/>
				</Switch>
			</div>
		)
	}
}

export default connect(
	state => ({
		searchResult: state.mypage.searchResult,
	}),
)(withRouter(Query))
