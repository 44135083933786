import React, { Component } from 'react'
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
// import RefreshIndicator from '@material-ui/core/RefreshIndicator';
import CircularProgress from '@material-ui/core/CircularProgress'

class LoadingOverlay extends Component {

	render() {
		const style = {
			center: {
				position: 'relative',
				top: "50%",
				left: "50%",
			},
			container: {
				background: 'rgba(0, 0, 0, 0.7)',
				position: 'fixed',
				top: 0,
				left: 0,
				width: '100%',
				height: '100%',
				zIndex: 999999,
				display: 'block'
			}
		};

		return (
			this.props.loading ?
	      <div style={style.container}>
	        <div style={style.center}>
	          <CircularProgress
	            size={40}
	          />
	        </div>
	      </div>
			: null
		);
	}
}

LoadingOverlay.propTypes = {
	loading: PropTypes.number.isRequired,
};
export default connect(
	state => ({
		loading: state.sl_utils.loading,
	})
)(LoadingOverlay);
