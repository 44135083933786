import React from 'react'
import PropTypes from "prop-types";
import {indigo, pink} from "@material-ui/core/colors";
import Box from "@material-ui/core/Box";
import Icon from "@material-ui/core/Icon";
// import ListItem from "@material-ui/core/List";
// import ListItemIcon from "@material-ui/core/ListItemIcon";
// import ListItemText from "@material-ui/core/ListItemText";


export default class Choice extends React.Component {
	static propTypes = {
		choice: PropTypes.object.isRequired,
		onClick: PropTypes.func,
		button: PropTypes.bool,
		withBorder: PropTypes.bool,
	}

	handleClick = choice => {
		if (this.props.onClick) {
			this.props.onClick(choice)
		}
	}

	render() {
		const {choice, withBorder} = this.props
		const iconName = ["looks_one", "looks_two", "looks_3", "looks_4", "looks_5", "looks_6"]
		const divStyle = (withBorder) ? {
			padding:10,
			backgroundColor: (choice.selected) ? "#f7f6f6" : "#e8eaf6",
			borderWidth: 2,
			borderColor: (choice.selected) ? indigo[400] : "#e8eaf6",
			borderStyle: 'solid',
			boxSizing: 'border-box',
			cursor: 'pointer',
		} : {
			padding:10,
			backgroundColor: (choice.selected) ? "#f7f6f6" : "#e8eaf6",
			cursor: 'pointer',
		}
		const listItemStyle = {
			fontWeight: (choice.selected) ? 600 : 100,
		}
		const leftIconStyle = {
			// marginTop: '-0.5em',
			left: 12,
			lineHeight:'30px',
			// top: '50%',
		}
		const leftIcon = (
			<Icon style={{...leftIconStyle, color: (choice.selected) ? pink[500] : indigo[400]}}>
				{iconName[choice.index]}
			</Icon>
		)
		// const isButton = !!this.props.button
		let vd = (
			<Box
				display="flex"
				alignItems="center"
				style={divStyle}
				key={choice.index}
				onClick={this.handleClick.bind(this, choice)}
			>
				<Box>
					{leftIcon}
				</Box>
				<Box ml={1} style={listItemStyle}>
					{choice.text}
				</Box>
				{/*<ListItem button={isButton.toString()} onClick={this.handleClick.bind(this, choice)} style={listItemStyle}>*/}
				{/*	<ListItemIcon>*/}
				{/*		{leftIcon}*/}
				{/*	</ListItemIcon>*/}
				{/*	<ListItemText primary={choice.text}/>*/}
				{/*</ListItem>*/}
			</Box>
		)
		vd = (
			<div style={{marginBottom: 4}} key={choice.index}>
				{vd}
			</div>
		)
		return vd
	}
}
