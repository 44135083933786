import React from 'react'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import {connect} from "react-redux";
import Cookies from "js-cookie";
import IconButton from '@material-ui/core/IconButton';
import {withRouter} from "react-router-dom";
import Box from "@material-ui/core/Box";
// import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import {altName} from "../../alt/constants";
import {post_wm} from "../../sonodalib/sl_utils/Http";
import Icon from '@material-ui/core/Icon'
import ExplainDialog from "./ExplainDialog";
import SubjectRaderChart from "./SubjectRaderChart";
import './categorytab.css'

// const styles = {
// 	shikenLabel: {
// 		fontSize:				 20,
// 		fontWeight:			 'bold',
// 		color:					 '#666',
// 		paddingBottom:	 18,
// 		textAlign:			 'center',
// 	},
// 	tableHead: {
// 		backgroundColor: '#1e88e5',
// 		color: 					 'white',
// 		fontSize:				 14,
// 		whiteSpace:			 'nowrap',
// 	},
// 	cellHead: {
// 		paddingTop:			 6,
// 		paddingBottom:	 6,
// 		textAlign:			 'center',
// 	},
// 	tableRow: {
// 		fontSize:				 16,
// 		// whiteSpace:			 'nowrap',
// 		borderBottom:		 '1px solid #ccc',
// 	},
// 	tableCellSubject: {
// 		textAlign:			 'center',
// 		paddingLeft:		 10,
// 		paddingRight:		 10,
// 		width:					 300,
// 	},
// 	tableCell: {
// 		textAlign:			 'center',
// 	},
// 	detailHead: {
// 		backgroundColor: '#81D4FA',
// 		color: 					 'black',
// 		fontSize:				 14,
// 		whiteSpace:			 'nowrap',
// 		paddingTop:			 6,
// 		paddingBottom:	 6,
// 	},
// 	detailRow: {
// 		fontSize:				 16,
// 		whiteSpace:			 'nowrap',
// 		borderBottom:		 '1px solid #ccc',
// 		borderLeft:			 '1px solid #ccc',
// 		borderRight:		 '1px solid #ccc',
// 	},
// 	detailCell: {
// 		minHeight:			 36,
// 		lineHeight:			 '36px',
// 		// paddingTop:			 14,
// 		// paddingBottom:	 14,
// 		textAlign:			 'center',
// 	},
// }

class CategoryTab extends React.Component {
	state = {
		dlAnchorEl: undefined,
		detail_subject: null,
		detail: null,
		openMenu1: false,
		openMenuIndex: null,
		anchor1: null,
		explanation: null,
		fetchingDetail: null,
	}

	handleOpenDlMenu = (event) => {
		this.setState({
			dlAnchorEl: event.currentTarget
		})
	}

	handleCloseDl = () => {
		this.setState({
			dlAnchorEl: undefined
		})
	}

	handleOpenDetail = (subject) => () => {
		const {fetchingDetail} = this.state
		if (fetchingDetail) return
		this.setState({
			fetchingDetail: subject.subject
		}, () => {
			post_wm("shindan/detail", {
				user_token:   Cookies.get(altName),
				moshi_token:  Cookies.get("wm_"+altName),
				subject_code: subject.subject,
				sum_visible:  subject.sv,
			})
			.then((ret) => {
				this.setState({
					detail_subject: 	 subject.subject,
					detail:						 ret.answer,
					hukushu_col_title: ret.hukushu_col_title,
					show_kaisetsu: 		 ret.show_kaisetsu,
				})
			})
			.catch(() => {
				alert("エラーが発生しました")
				this.props.history.push("/sct")
			})
			.finally(() => {
				this.setState({
					fetchingDetail: null
				})
			})
		})
	}

	handleOpenExplaination = (question_id) => () => {
		post_wm("shindan/explain", {
			user_token:   Cookies.get(altName),
			moshi_token:  Cookies.get("wm_"+altName),
			question_id,
		})
		.then((ret) => {
			this.setState({
				explanation: ret
			})
		})
		.catch(() => {
			alert("エラーが発生しました")
			this.props.history.push("/sct")
		})
	}

	render() {
    const {data, history, printMode, onDlMondai, onDlKaisetsu} = this.props

    const {subjects, radar_chart} = data
    const {dlAnchorEl, detail_subject, detail, explanation, show_kaisetsu, hukushu_col_title, fetchingDetail} = this.state

    let detailVd = null
    let fetchingDetailVd = null

    // const catsHeadVd = (
    //   <Grid container style={styles.tableHead} alignItems="center">
    //     <Grid item style={{...styles.cellHead,width:300}}>科目名</Grid>
    //     <Grid item style={{...styles.cellHead,width:60}}>配点</Grid>
    //     <Grid item style={{...styles.cellHead,width:60}}>得点</Grid>
    //     {/*<Grid item style={{...styles.cellHead,width:60}}>平均点</Grid>*/}
    //     <Grid item style={{...styles.cellHead,width:60}}>判定</Grid>
    //     {(printMode) ? null : (
    //       <Grid item style={{...styles.cellHead,width:60}}>詳細</Grid>
    //     )}
    //   </Grid>
    // )

    const subjectsVd = subjects.map((subject,index) => {
      // let detailWidth = 346
      // if (!hukushu_col_title) detailWidth -= 100
      // if (!show_kaisetsu)			detailWidth -= 80
			if (!fetchingDetailVd) {
				fetchingDetailVd = (!printMode && subject.subject === fetchingDetail) ? (
					<div style={{textAlign:'center'}}>
						<img src={"/imgms/loading-grey.gif"} role="presentation" alt="loading" style={{width:64,height:64}}/>
					</div>
				) : null
			}
			if (!detailVd) {
				detailVd = (!printMode && detail && detail_subject && subject.subject === detail_subject) ? (
					<div className="detail">
						<table>
							<tbody>
								<tr>
									<th className="t-2">問題</th>
									<th className="t-2">正解</th>
									<th className="t-2">あなたの解答</th>
									<th className="t-2">正誤</th>
									<th className="t-2">解説を見る</th>
								</tr>
								{detail.map((answer,answerIndex) => {
									const explainVd = (
										<Button
											variant="text"
											aria-label="Explain"
											onClick={this.handleOpenExplaination(answer.question_id)}
											size="small"
										>
											<img src="/imgms/sct/button_answer.png" alt="explanation"/>
										</Button>
									)
									return (
										<tr key={"ans"+answerIndex}>
											{(answerIndex & 1) ? (
												<>
													<td className="t-1">{answer.index}</td>
													<td className="t-1 t-4">{answer.correct}</td>
													<td className="t-5">{answer.answer}</td>
													<td className="t-1">{answer.seigo}</td>
													<td className="t-1">{explainVd}</td>
												</>
											) : (
												<>
													<td>{answer.index}</td>
													<td className="t-4">{answer.correct}</td>
													<td className="t-6">{answer.answer}</td>
													<td>{answer.seigo}</td>
													<td>{explainVd}</td>
												</>
											)}
										</tr>
									)
								})}
							</tbody>
						</table>
					</div>
				) : null
			}

    // const subjectsVd = subjects.map((subject,index) => {
    //   let detailWidth = 346
    //   // if (!hukushu_col_title) detailWidth -= 100
    //   // if (!show_kaisetsu)			detailWidth -= 80
		// 	if (!fetchingDetailVd) {
		// 		fetchingDetailVd = (!printMode && subject.subject === fetchingDetail) ? (
		// 			<div style={{textAlign:'center'}}>
		// 				<img src={"/imgms/loading-grey.gif"} role="presentation" alt="loading" style={{width:64,height:64}}/>
		// 			</div>
		// 		) : null
		// 	}
		// 	if (!detailVd) {
		// 		detailVd = (!printMode && detail && detail_subject && subject.subject === detail_subject) ? (
		// 			<div>
		// 				<div style={{width:detailWidth,marginTop:10,marginBottom:10}}>
		// 					<Grid container alignItems="center" style={styles.detailHead} key="dtl00">
		// 						<Grid item style={{width:60,textAlign:'center'}}>問題</Grid>
		// 						<Grid item style={{width:40,textAlign:'center'}}>正解</Grid>
		// 						<Grid item style={{width:110,textAlign:'center'}}>あなたの解答</Grid>
		// 						<Grid item style={{width:40,textAlign:'center'}}>正誤</Grid>
		// 						{/*<Grid item style={{width:80,textAlign:'center'}}>正答率</Grid>*/}
		// 						{(hukushu_col_title) ? (
		// 							<Grid item style={{width:100,textAlign:'center'}}>{hukushu_col_title}</Grid>
		// 						) : null}
		// 						{(show_kaisetsu) ? (
		// 							<Grid item style={{width:80,textAlign:'center'}}>解説</Grid>
		// 						) : null}
		// 					</Grid>
		// 					{(detail.map((row) => {
		// 						const hukushuVd = (!hukushu_col_title || !row.hukushus.length) ? null :
		// 							(row.hukushus.length > 1) ? (
		// 								<div>
		// 									<Button
		// 										variant="text"
		// 										aria-label="More"
		// 										aria-owns={this.state.openMenu1 ? 'menu' : null}
		// 										aria-haspopup="true"
		// 										onClick={this.handleOpenMenu1.bind(this,row.index)}
		// 										size="small"
		// 										// style={{paddingLeft:18,paddingRight:18}}
		// 									>
		// 										表示 <Icon>keyboard_arrow_down</Icon>
		// 									</Button>
		// 								</div>
		// 							) : (
		// 								<Button
		// 									variant="text"
		// 									onClick={this.handleSelectMenu1(row.hukushus)}
		// 									size="small"
		// 								>
		// 									{/*{row.hukushus}*/}
		// 									問題
		// 								</Button>
		// 								// <button type="button" onClick={this.handleSelectMenu1(row.hukushus)} style={{border:'none',backgroundColor:'transparent'}}>{row.hukushus}</button>
		// 							)
		// 						const explainVd = (
		// 							<Button
		// 								variant="text"
		// 								aria-label="Explain"
		// 								onClick={this.handleOpenExplaination(row.question_id)}
		// 								size="small"
		// 							>
		// 								<img src="/imgms/sct/button_answer.png"/>
		// 							</Button>
		// 						)
		// 						return (
		// 							<Grid container alignItems="center" style={styles.detailRow} key={"dtl"+row.index}>
		// 								<Grid item style={{...styles.detailCell,width:60,fontWeight:600}}>{row.index}</Grid>
		// 								<Grid item style={{...styles.detailCell,width:40}}>{row.correct}</Grid>
		// 								<Grid item style={{...styles.detailCell,width:110}}>{row.answer}</Grid>
		// 								<Grid item style={{...styles.detailCell,width:40}}>{row.seigo}</Grid>
		// 								{/*<Grid item style={{...styles.detailCell,width:80}}>{row.score_avg}</Grid>*/}
		// 								{(hukushu_col_title) ? (
		// 									<Grid item style={{...styles.detailCell,width:100,fontSize:14}}>{hukushuVd}</Grid>
		// 								) : null}
		// 								{(show_kaisetsu) ? (
		// 									<Grid item style={{...styles.detailCell,width:80}}>{explainVd}</Grid>
		// 								) : null}
		// 							</Grid>
		// 						)
		// 					}))}
		// 				</div>
		// 			</div>
		// 		) : null
		// 	}

      // const subjectFontSize = (subject.name.length >= 18) ? 14 : 16
      // return (
      //   <div key={"sbj"+index} style={styles.tableRow}>
      //     <Grid container alignItems="center" style={{height:50}}>
      //       <Grid item style={{...styles.tableCellSubject,fontSize:subjectFontSize}}>{subject.name}</Grid>
      //       <Grid item style={{...styles.tableCell,width:60}}>{subject.haiten}</Grid>
      //       <Grid item style={{...styles.tableCell,width:60}}>{subject.score}</Grid>
      //       {/*<Grid item style={{...styles.tableCell,width:60}}>{subject.avg}</Grid>*/}
      //       <Grid item style={{...styles.tableCell,width:60}}>{subject.hantei}</Grid>
      //       {(printMode) ? null : (
      //         <Grid item style={{...styles.tableCell,width:60}}>
      //           {(subject.score === "") ? null : (
			// 						<IconButton aria-label="detail" onClick={this.handleOpenDetail(subject)} style={{color:'black'}}>
			// 							<img src="/imgms/sct/button_detail.png" alt="detail"/>
			// 						</IconButton>
      //           )}
      //         </Grid>
      //       )}
      //     </Grid>
      //   </div>
      // )
			return (
				<tr key={"subj"+index} style={{height:44}}>
					{(index & 1) ? (
						<>
							<td className="t-1 t-sb">{subject.name}</td>
							<td className="t-1 t-4">{subject.haiten}</td>
							<td className="t-5">{subject.score}</td>
							<td className="t-1">{subject.hantei}</td>
							<td className="t-1">
								{(subject.score === "") ? null : (
									<IconButton aria-label="detail" onClick={this.handleOpenDetail(subject)} style={{color:'black', padding:0}}>
			 							<img src="/imgms/sct/button_detail.png" alt="detail"/>
			 						</IconButton>
								)}
							</td>
						</>
					) : (
						<>
							<td className="t-sb">{subject.name}</td>
							<td className="t-4">{subject.haiten}</td>
							<td className="t-6">{subject.score}</td>
							<td>{subject.hantei}</td>
							<td>
								{(subject.score === "") ? null : (
									<IconButton aria-label="detail" onClick={this.handleOpenDetail(subject)} style={{color:'black'}}>
			 							<img src="/imgms/sct/button_detail.png" alt="detail"/>
			 						</IconButton>
								)}
							</td>
						</>
					)}
				</tr>
			)
    })

    return (
      <Box mt={4} display="flex">
        <Box width={554+60}>
          <Box display="flex">
						<Box width={554}>
							<Box display="flex" height={36}>
								<Box fontSize={20} lineHeight="36px">
									<span style={{color:'#4b95b6'}}>■</span> {data.label}
								</Box>
								<Box textAlign="right" ml={6}>
									<Button endIcon={<Icon>get_app</Icon>} size="medium" style={{color:'grey'}} variant="outlined" onClick={this.handleOpenDlMenu}>PDFダウンロード</Button>
										<Menu
											id="download-pdf"
											anchorEl={dlAnchorEl}
											open={(dlAnchorEl !== undefined)}
											onClose={this.handleCloseDl}
										>
											<MenuItem
												key="dlm1"
												onClick={() => {
													onDlMondai()
													this.handleCloseDl()
												}}
											>
												問題PDF
											</MenuItem>
											<MenuItem
												key="dlm2"
												onClick={() => {
													onDlKaisetsu()
													this.handleCloseDl()
												}}
											>
												解答PDF
											</MenuItem>
										</Menu>
								</Box>
							</Box>
							<div id="gcateg" style={{marginTop:10}}>
								<div className="seiseki">
									<table>
										<tbody>
											<tr>
												<th className="t-2">科目名</th>
												<th className="t-2">配点</th>
												<th className="t-2">得点</th>
												<th className="t-2">判定</th>
												<th className="t-2">詳細</th>
											</tr>
											{subjectsVd}
										</tbody>
									</table>
								</div>
							</div>
						</Box>
						<Box width={60} style={{marginTop:'auto', marginBottom:'auto'}} textAlign="center">
							<img src="/imgms/sct/kamoku_arrow.png" alt="arrow"/>
						</Box>
					</Box>
					<div style={{marginTop:40}}>
						<SubjectRaderChart
							series={radar_chart.series}
							labels={radar_chart.labels}
							width={500}
							height={500}
						/>
					</div>
        </Box>
        <Box width={346}>
					<Box fontSize={20} height={36} lineHeight="36px">
						<span style={{color:'#cca746'}}>■</span> 科目詳細
					</Box>
          <Box id="gcatde" style={{marginTop:10}}>
	          {(fetchingDetailVd) ? fetchingDetailVd : detailVd}
          </Box>
        </Box>
				{(printMode) ? null : (
					<ExplainDialog
						open={!!explanation}
						history={history}
						data={explanation}
						onClose={() => {
							this.setState({
								explanation: null,
							})
						}}
					/>
				)}
      </Box>
    )
  }
}
export default connect(
	state => ({
		sctState: state.sct,
		userState: state.sl_users.state,
		printMode: state.moshi.printMode,
	})
)(withRouter(CategoryTab));
