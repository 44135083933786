// @flow
import React from 'react'
import {connect} from 'react-redux'
import {withRouter} from 'react-router-dom'
import FkToolbar from '../parts/FkToolbar'
import Accordion from '@material-ui/core/Accordion'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import {ContractText} from '../mypage/account/Contract'
import Grid from '@material-ui/core/Grid'
import SlTextField from "../sonodalib/sl_mui/SlTextField";
import {scrollToElWithName} from '../sonodalib/sl_utils/Utils'
import {FormState, SelectorInputState, TextInputState, CheckboxInputState} from "../sonodalib/sl_utils/SlForms";
import TextFieldSelector from '../sonodalib/sl_mui/TextFieldSelector'
import type {MenuRow} from '../sonodalib/sl_mui/Selector'
import {getPrefectures} from "../sonodalib/sl_utils/Address";
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import Button from '@material-ui/core/Button'
import Divider from '@material-ui/core/Divider'
import TextField from '@material-ui/core/TextField'
import _ from 'lodash'
import {post} from '../sonodalib/sl_utils/Http'
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import SlCheckbox from "../sonodalib/sl_mui/SlCheckbox";
import {changeTab} from '../mypage/MyPageAction'
import Cookies from "js-cookie";
import {altName, enquateShakaiLabel, enquateSeishinLabel} from './constants'

const grades = [
	{id: 0, label: "その他"},
	{id: 1, label: "１年"},
	{id: 2, label: "２年"},
	{id: 3, label: "３年"},
	{id: 4, label: "４年"},
	{id: 5, label: "卒業"},
]
const enquteShakaiLabels: Array<MenuRow> = [
	{id: "0", label: "選択して下さい", invalid: false},
	{id: "apply", label: enquateShakaiLabel},
	{id: "undecided", label: "未定"},
]
const enquteSeishinLabels: Array<MenuRow> = [
	{id: "0", label: "選択して下さい", invalid: false},
	{id: "apply", label: enquateSeishinLabel},
	{id: "undecided", label: "未定"},
]
const enquteWhereYouKnowLabels: Array<MenuRow> = [
	{id: "0", label: "選択して下さい", invalid: false},
	{id: "at-school", label: "学校での案内（配布物・掲示物など）"},
	{id: "at-dantai", label: "団体での案内（配布物・掲示物など）"},
	{id: "by-friends", label: "友人・知人からの紹介"},
	{id: "by-search", label: "検索サイト"},
	{id: "old-user", label: "過去に会員として利用"},
]
// const userTypes = {
// 	"1700": "d1700",
// 	"1817": "d1817",
// 	"1818": "d1818",
// 	"1819": "d1819",
// 	"1804": "d1804",
// 	"1816": "d1816",
// }
// const services: any = {
// 	"general": {
// 		"0": {"label": "選択して下さい", "desc": "", "price": 0},
// 		"shakai": {
// 			"label": "社会福祉士合格サポート",
// 			"desc": "過去問トレーニング2018年4月以降ご利用開始日から2019年合格発表までWeb模擬試験1回分受験料を含む",
// 			"price": 9000
// 		},
// 		"seishin": {
// 			"label": "精神保健福祉士合格サポート",
// 			"desc": "過去問トレーニング2018年4月以降ご利用開始日から2019年合格発表までWeb模擬試験1回分受験料を含む",
// 			"price": 9000
// 		},
// 		"shakai-senmon": {
// 			"label": "社会福祉士合格サポート（専門科目のみ）",
// 			"desc": "過去問トレーニング2018年4月以降ご利用開始日から2019年合格発表までWeb模擬試験１回分受験料を含む",
// 			"price": 5000
// 		},
// 		"seishin-senmon": {
// 			"label": "精神保健福祉士合格サポート（専門科目のみ）",
// 			"desc": "過去問トレーニング2018年4月以降ご利用開始日から2019年合格発表までWeb模擬試験１回分受験料を含む",
// 			"price": 5000
// 		},
// 		"double": {
// 			"label": "社会福祉士・精神保健福祉士（両方）合格サポート",
// 			"desc": "過去問トレーニング2018年4月以降ご利用開始から2019年合格発表までWeb模擬試験１回分受験料を含む",
// 			"price": 12000
// 		},
// 	},
// 	"d1700": {
// 		"0": {"label": "選択して下さい", "desc": "", "price": 0},
// 		"shakai": {
// 			"label": "社会福祉士合格サポート",
// 			"desc": "過去問トレーニング2018年4月以降ご利用開始日から2019年合格発表までWeb模擬試験1回分受験料を含む",
// 			"price": 6000
// 		},
// 		"seishin": {
// 			"label": "精神保健福祉士合格サポート",
// 			"desc": "過去問トレーニング2018年4月以降ご利用開始日から2019年合格発表までWeb模擬試験1回分受験料を含む",
// 			"price": 6000
// 		},
// 		"shakai-senmon": {
// 			"label": "社会福祉士合格サポート（専門科目のみ）",
// 			"desc": "過去問トレーニング2018年4月以降ご利用開始日から2019年合格発表までWeb模擬試験１回分受験料を含む",
// 			"price": 4000
// 		},
// 		"seishin-senmon": {
// 			"label": "精神保健福祉士合格サポート（専門科目のみ）",
// 			"desc": "過去問トレーニング2018年4月以降ご利用開始日から2019年合格発表までWeb模擬試験１回分受験料を含む",
// 			"price": 4000
// 		},
// 		"double": {
// 			"label": "社会福祉士・精神保健福祉士（両方）合格サポート",
// 			"desc": "過去問トレーニング2018年4月以降ご利用開始から2019年合格発表までWeb模擬試験１回分受験料を含む",
// 			"price": 9000
// 		},
// 	},
// 	"d1817": {
// 		"0": {"label": "選択して下さい", "desc": "", "price": 0},
// 		"shakai": {
// 			"label": "社会福祉士合格サポート",
// 			"desc": "過去問トレーニング2018年4月以降ご利用開始日から2019年合格発表までWeb模擬試験1回分受験料を含む",
// 			"price": 7500,
// 		},
// 		"seishin": {
// 			"label": "精神保健福祉士合格サポート",
// 			"desc": "過去問トレーニング2018年4月以降ご利用開始日から2019年合格発表までWeb模擬試験1回分受験料を含む",
// 			"price": 7500,
// 		},
// 		"shakai-senmon": {
// 			"label": "社会福祉士合格サポート（専門科目のみ）",
// 			"desc": "過去問トレーニング2018年4月以降ご利用開始日から2019年合格発表までWeb模擬試験１回分受験料を含む",
// 			"price": 4000,
// 		},
// 		"seishin-senmon": {
// 			"label": "精神保健福祉士合格サポート（専門科目のみ）",
// 			"desc": "過去問トレーニング2018年4月以降ご利用開始日から2019年合格発表までWeb模擬試験１回分受験料を含む",
// 			"price": 4000,
// 		},
// 		"double": {
// 			"label": "社会福祉士・精神保健福祉士（両方）合格サポート",
// 			"desc": "過去問トレーニング2018年4月以降ご利用開始から2019年合格発表までWeb模擬試験１回分受験料を含む",
// 			"price": 10000,
// 		},
// 	},
// 	"d1818": {
// 		"0": {"label": "選択して下さい", "desc": "", "price": 0},
// 		"shakai": {
// 			"label": "社会福祉士合格サポート",
// 			"desc": "過去問トレーニング2018年4月以降ご利用開始日から2019年合格発表までWeb模擬試験1回分受験料を含む",
// 			"price": 4000
// 		},
// 		"seishin": {
// 			"label": "精神保健福祉士合格サポート",
// 			"desc": "過去問トレーニング2018年4月以降ご利用開始日から2019年合格発表までWeb模擬試験1回分受験料を含む",
// 			"price": 4000
// 		},
// 		"shakai-senmon": {
// 			"label": "社会福祉士合格サポート（専門科目のみ）",
// 			"desc": "過去問トレーニング2018年4月以降ご利用開始日から2019年合格発表までWeb模擬試験１回分受験料を含む",
// 			"price": 4000
// 		},
// 		"seishin-senmon": {
// 			"label": "精神保健福祉士合格サポート（専門科目のみ）",
// 			"desc": "過去問トレーニング2018年4月以降ご利用開始日から2019年合格発表までWeb模擬試験１回分受験料を含む",
// 			"price": 4000
// 		},
// 		"double": {
// 			"label": "社会福祉士・精神保健福祉士（両方）合格サポート",
// 			"desc": "過去問トレーニング2018年4月以降ご利用開始から2019年合格発表までWeb模擬試験１回分受験料を含む",
// 			"price": 4500
// 		},
// 	},
// 	"d1819": {
// 		"0": {"label": "選択して下さい", "desc": "", "price": 0},
// 		"shakai": {
// 			"label": "社会福祉士合格サポート",
// 			"desc": "過去問トレーニング2018年4月以降ご利用開始日から2019年合格発表までWeb模擬試験1回分受験料を含む",
// 			"price": 6000
// 		},
// 		"seishin": {
// 			"label": "精神保健福祉士合格サポート",
// 			"desc": "過去問トレーニング2018年4月以降ご利用開始日から2019年合格発表までWeb模擬試験1回分受験料を含む",
// 			"price": 6000
// 		},
// 		"shakai-senmon": {
// 			"label": "社会福祉士合格サポート（専門科目のみ）",
// 			"desc": "過去問トレーニング2018年4月以降ご利用開始日から2019年合格発表までWeb模擬試験１回分受験料を含む",
// 			"price": 4000
// 		},
// 		"seishin-senmon": {
// 			"label": "精神保健福祉士合格サポート（専門科目のみ）",
// 			"desc": "過去問トレーニング2018年4月以降ご利用開始日から2019年合格発表までWeb模擬試験１回分受験料を含む",
// 			"price": 4000
// 		},
// 		"double": {
// 			"label": "社会福祉士・精神保健福祉士（両方）合格サポート",
// 			"desc": "過去問トレーニング2018年4月以降ご利用開始から2019年合格発表までWeb模擬試験１回分受験料を含む",
// 			"price": 9000
// 		},
// 	},
// 	"d1804": {
// 		"0": {"label": "選択して下さい", "desc": "", "price": 0},
// 		"shakai": {
// 			"label": "社会福祉士合格サポート",
// 			"desc": "過去問トレーニング2018年4月以降ご利用開始日から2019年合格発表までWeb模擬試験1回分受験料を含む",
// 			"price": 4000
// 		},
// 		"seishin": {
// 			"label": "精神保健福祉士合格サポート",
// 			"desc": "過去問トレーニング2018年4月以降ご利用開始日から2019年合格発表までWeb模擬試験1回分受験料を含む",
// 			"price": 4000
// 		},
// 		"shakai-senmon": {
// 			"label": "社会福祉士合格サポート（専門科目のみ）",
// 			"desc": "過去問トレーニング2018年4月以降ご利用開始日から2019年合格発表までWeb模擬試験１回分受験料を含む",
// 			"price": 2000
// 		},
// 		"seishin-senmon": {
// 			"label": "精神保健福祉士合格サポート（専門科目のみ）",
// 			"desc": "過去問トレーニング2018年4月以降ご利用開始日から2019年合格発表までWeb模擬試験１回分受験料を含む",
// 			"price": 2000
// 		},
// 		"double": {
// 			"label": "社会福祉士・精神保健福祉士（両方）合格サポート",
// 			"desc": "過去問トレーニング2018年4月以降ご利用開始から2019年合格発表までWeb模擬試験１回分受験料を含む",
// 			"price": 6000
// 		},
// 	},
// 	"d1816": {
// 		"0": {"label": "選択して下さい", "desc": "", "price": 0},
// 		"shakai": {
// 			"label": "社会福祉士合格サポート",
// 			"desc": "過去問トレーニング2018年4月以降ご利用開始日から2019年合格発表までWeb模擬試験1回分受験料を含む",
// 			"price": 3000
// 		},
// 		"seishin": {
// 			"label": "精神保健福祉士合格サポート",
// 			"desc": "過去問トレーニング2018年4月以降ご利用開始日から2019年合格発表までWeb模擬試験1回分受験料を含む",
// 			"price": 3000
// 		},
// 		// "shakai-senmon": {
// 		// 	"label": "社会福祉士合格サポート（専門科目のみ）",
// 		// 	"desc": "過去問トレーニング2018年4月以降ご利用開始日から2019年合格発表までWeb模擬試験１回分受験料を含む",
// 		// 	"price": 3000
// 		// },
// 		// "seishin-senmon": {
// 		// 	"label": "精神保健福祉士合格サポート（専門科目のみ）",
// 		// 	"desc": "過去問トレーニング2018年4月以降ご利用開始日から2019年合格発表までWeb模擬試験１回分受験料を含む",
// 		// 	"price": 3000
// 		// },
// 		"double": {
// 			"label": "社会福祉士・精神保健福祉士（両方）合格サポート",
// 			"desc": "過去問トレーニング2018年4月以降ご利用開始から2019年合格発表までWeb模擬試験１回分受験料を含む",
// 			"price": 4500
// 		},
// 	},
// }
type Props = {
	history: any,
	userState: any,
}
type States = {
	payValid: number,
	openPaymentDialog: boolean,
	paymentDialogStep: '' | 'connecting' | 'confirm' | 'backtotop' | 'encode_error' | 'error',
	step: 'input' | 'confirm',
	uniqueId: TextInputState,
	name: TextInputState,
	kana: TextInputState,
	zipcode: TextInputState,
	pref: SelectorInputState,
	addressCity: TextInputState,
	// addressStreet: TextInputState,
	telno: TextInputState,
	schoolNameState: TextInputState,
	selectedGrade: SelectorInputState,
	// schoolCodeState: TextInputState,
	openService: boolean,
	payment: TextInputState,
	service: string,
	serviceError: string,
	postScript: TextInputState,
	kiyaku: CheckboxInputState,
	enquteShakai: SelectorInputState,
	enquteSeishin: SelectorInputState,
	enquteWhereYouKnow: SelectorInputState,
	dantai_id: string,
	dantaiName: TextInputState,
	userType: string,
	invalidMessage: string,
	services: Array<any>,
}
const styles = {
	queryOptionTitle: {
		fontSize: 12,
		fontWeight: 700,
		marginTop: 8,
		marginBottom: 8,
	},
	price: {
		marginLeft: 50,
		fontWeight: 800,
		color: 'red',
	},
	body1: {
		fontSize: 16,
	},
	priceConfirm: {
		fontWeight: 800,
	},
}

class ApplicationPage extends React.Component<Props, States> {
	paymentUrl: string
	paymentOrderId: string
	paymentResult: string
	state = {
		payValid: 0,	// 0=loading  1=valid  2=invalid
		openPaymentDialog: false,
		paymentDialogStep: "",
		step: 'input',
		uniqueId: new TextInputState(),
		name: new TextInputState({name: "name", validations: "required,zenkaku2"}),
		kana: new TextInputState({name: "kana", validations: "required,zenkaku2"}),
		zipcode: new TextInputState({name: "zipcode", validations: "required,zipcode"}),
		pref: new SelectorInputState({name: "pref", menuList: getPrefectures()}),
		addressCity: new TextInputState({validations: "required"}),
		telno: new TextInputState({name: "telno", validations: "required,telno"}),
		// telno: new TextInputState({name: "telno", validations: "required"}),
		schoolNameState: new TextInputState({name: "schoolNameState"}),
		selectedGrade: new SelectorInputState({name: "selectedGrade", menuList: grades}),
		// schoolCodeState: new TextInputState({name: "schoolCodeState"}),
		openService: false,
		payment: new TextInputState({value: "コンビニ決済（変更できません）"}),
		service: "0",
		serviceError: "",
		postScript: new TextInputState(),
		kiyaku: new CheckboxInputState({validations: "required"}),
		enquteShakai: new SelectorInputState({menuList: enquteShakaiLabels}),
		enquteSeishin: new SelectorInputState({menuList: enquteSeishinLabels}),
		enquteWhereYouKnow: new SelectorInputState({menuList: enquteWhereYouKnowLabels}),
		dantai_id: "",
		dantaiName: new TextInputState(),
		userType: "",
		invalidMessage: "",
		services: [],
	}

	componentWillMount() {
		const payload = {
			user_token: Cookies.get(altName),
		}
		post("user/get_profile", payload)
		.then(ret => {
			// const userType = userTypes[ret.dantai_id] || "general"
			this.setState({
				uniqueId: this.state.uniqueId.setValue(ret.uniqueId || ""),
				name: this.state.name.setValue(ret.name || ""),
				kana: this.state.kana.setValue(ret.kana || ""),
				zipcode: this.state.zipcode.setValue(ret.zipcode || ""),
				pref: this.state.pref.setValue(ret.addressPref),
				addressCity: this.state.addressCity.setValue(ret.addressCity || ""),
				// addressStreet: this.state.addressStreet.setValue(ret.addressStreet || ""),
				telno: this.state.telno.setValue(ret.telno || ""),
				schoolNameState: this.state.schoolNameState.setValue(ret.schoolName || ""),
				selectedGrade: this.state.selectedGrade.setValue(ret.grade),
				// schoolCodeState: this.state.schoolCodeState.setValue(ret.schoolCode || ""),
				dantai_id: ret.dantai_id,
				dantaiName: this.state.dantaiName.setValue(ret.dantai_name || "なし"),
				// userType: userType,
			})
			return post("order/fetch_services", {
				user_token: Cookies.get(altName),
			})
		}).then(ret => {
			this.setState({
				services: ret.services,
				userType: ret.user_type,
				payValid: (ret.disabled_order) ? 2 : 1
			})
		}).catch(err => {
			alert("ネットワークエラーが発生しました。リロードしてお試し下さい")
		})
	}

	// handleClickKiyaku = (event: any) => {
	// 	const checked = event.target.checked
	// 	// alert(checked)
	// 	this.setState({
	// 		kiyaku: checked,
	// 	})
	// }

	handleSubmit = (event: any) => {
		event.preventDefault()
		this.setState({...FormState.validate(this.state), serviceError: "", invalidMessage: ""}, () => {
			let valid = FormState.isValid(this.state)
			if (this.state.service === "0") {
				this.setState({
					serviceError: "ご希望のサービスを選択して下さい",
				})
				valid = false
			}
			// if (!this.state.kiyaku) {
			// 	this.setState({
			// 		kiyakuError: "利用規約に同意が無いと申し込めません",
			// 	})
			// 	valid = false
			// } else
			if (valid) {
				this.setState({
					step: 'confirm',
				})
			} else {
				const names = FormState.getAllInvalidElementNames(this.state)
				if (names.length) scrollToElWithName(names[0])
				this.setState({
					invalidMessage: "記入モレがあります",
				})
			}
		})
	}

	handleRedo = () => {
		this.setState({
			step: 'input',
		})
	}

	// test = (event: any) => {
	// 	event.preventDefault()
	// 	if (this.props.userState && this.props.userState.roles.indexOf("admin") !== -1) {
	// 		this.setState({
	// 			name: this.state.name.setValue("名前"),
	// 			kana: this.state.kana.setValue("カナ"),
	// 			zipcode: this.state.zipcode.setValue("123-1234"),
	// 			pref: this.state.pref.setValue(5),
	// 			addressCity: this.state.addressCity.setValue(_.uniqueId("addressCity_")),
	// 			// addressStreet: this.state.addressStreet.setValue(_.uniqueId("addressStreet_")),
	// 			telno: this.state.telno.setValue("999-999"),
	// 			service: "seishin-senmon",
	// 			postScript: this.state.postScript.setValue(_.uniqueId("postScript_")),
	// 			kiyaku: this.state.kiyaku.setValue(true),
	// 			enquteShakai: this.state.enquteShakai.setValue("apply"),
	// 			enquteSeishin: this.state.enquteSeishin.setValue("undecided"),
	// 			enquteWhereYouKnow: this.state.enquteWhereYouKnow.setValue("at-school"),
	// 		})
	// 	}
	// }

	render() {
		if (!this.state.userType) return null
		if (this.state.step === 'input') return this.renderInput()
		else return this.renderConfirm()
	}

	renderInput() {
		const {services, service, payValid} = this.state
		if (this.state.openService) {
			const onUpdate = (value: string) => () => {
				this.setState({
					openService: false,
					service: value,
				})
			}
			const vd = []
			_.orderBy(services,"order").forEach((service) => {
			// _.forEach(services[this.state.userType], (service, k) => {
				vd.push(
					<ListItem key={"service_" + service.service} button onClick={onUpdate(service.service)}>
						<ListItemText
							primary={service.label}
							secondary={<span>{service.desc}<br/><span style={styles.price}>{service.price}円（税込）</span></span>}
						/>
					</ListItem>
				)
				vd.push(<Divider key={"service_d_" + service.service}/>)
			})
			return (
				<div>
					<FkToolbar
						title="ご利用サービス"
						onGoBack={() => {
							this.setState({
								openService: false,
							})
						}}
					/>
					<List component="nav">
						<Divider/>
						{vd}
					</List>
				</div>
			)
		}
		// const disableApplyButton = false
		const disableApplyButton = (payValid !== 1)
		// let disableApplyButton = true
		// if (this.props.userState && this.props.userState.roles.indexOf("admin") !== -1) disableApplyButton = false

		const dantaiNameVd = (this.state.dantai_id === "") ? (
			<SlTextField
				label="団体名（変更不可）"
				state={this.state.dantaiName}
				onChange={() => {
				}}
			/>
		) : (
			<SlTextField
				label="団体名（変更不可）"
				state={this.state.dantaiName}
				onChange={() => {
				}}
			/>
		)

		const _service = _.find(services, {service})
		const serviceLabel = (!_service) ? "" : _service.label	//value={services[this.state.userType][this.state.service].label}
		return (
			<div>
				<FkToolbar
					title="お申込み"
				/>
				<div style={{margin: "10px auto", maxWidth: 800}}>
					<div>
						<div className="box box-success box-solid">
							<div className="box-header with-border">
								<span style={{fontSize: 16}}
											className="box-title">こちらから「お申込み」受付させていただきます。<br/>以下の手順で「お申込み」が完了しますと正規ご利用となります。</span>
							</div>
							<div className="box-body">
								※大学等団体を通じてお申込みいただき、費用もお支払いただいている方の「お申込み」は不要です。<br/>
								①以下の必要事項を記入「確認画面へ」進む<br/>
								（記入モレや、利用規約に同意する□にチェックが入っていないと進みません）<br/>
								②お申込み内容の確認を行なっていただき、必要なら修正後「申し込みを確定する」を押して下さい。<br/>
								③「決済サービスに接続しています→決済ページの準備ができました」と表示されたら「決済ページを開く」を押して、ご利用いただくコンビニを選択して下さい。<br/>
								（一度選択したコンビニから画面を戻して別のコンビニを選択しても構いません）<br/>
								④ご利用いただくコンビニのお支払方法を確認して、画面を印刷またはスクリーンショットで保存して下さい。<br/>
								（コンビニによっては、必要な番号をお控えいただき店頭にお持ちいただけば手続きできるものもあります）<br/>
								⑤コンビニでお支払後、およそ１時間もすれば「お申込み」手続きが完了し正規ご利用に移行します。<br/>
							</div>
						</div>
					</div>
					<form onSubmit={this.handleSubmit}>
						<Grid container spacing={8} direction="row" justify="center">
							<Grid item xs={12} sm={6}>
								<SlTextField
									label="利用者ID（変更不可）"
									state={this.state.uniqueId}
									onChange={() => {
									}}
								/>
							</Grid>
							<Grid item xs={12} sm={6}>
								{dantaiNameVd}
							</Grid>
							<Grid item xs={12} sm={6}>
								<SlTextField
									name="name"
									label="お名前（必須）"
									state={this.state.name}
									onChange={(newState: TextInputState) => {
										this.setState({name: newState})
									}}
								/>
							</Grid>
							<Grid item xs={12} sm={6}>
								<SlTextField
									name="kana"
									label="フリガナ（必須）"
									state={this.state.kana}
									onChange={(newState: TextInputState) => {
										this.setState({kana: newState})
									}}
								/>
							</Grid>
							<Grid item xs={12} sm={6}>
								<SlTextField
									name="zipcode"
									label="郵便番号（必須）"
									state={this.state.zipcode}
									onChange={(newState) => {
										this.setState({zipcode: newState})
									}}
								/>
							</Grid>
							<Grid item xs={12} sm={6}>
								<TextFieldSelector
									name="pref"
									label="都道府県（必須）"
									state={this.state.pref}
									fullWidth={true}
									onChange={(newState: SelectorInputState) => {
										this.setState({pref: newState})
									}}
									InputLabelProps={{
										shrink: true,
										disableAnimation: true,
									}}
								/>
							</Grid>
							<Grid item xs={12} sm={12}>
								<SlTextField
									label="住所（必須）"
									state={this.state.addressCity}
									onChange={(newState) => {
										this.setState({addressCity: newState})
									}}
								/>
							</Grid>
							<Grid item xs={12} sm={6}>
								<SlTextField
									name="telno"
									label="電話番号（必須）"
									state={this.state.telno}
									onChange={(newState) => {
										this.setState({telno: newState})
									}}
									inputProps={{
										maxLength: 13,
									}}
								/>
							</Grid>
							<Grid item xs={12} sm={6}>
								<SlTextField
									label="お支払い方法"
									state={this.state.payment}
									onChange={() => {
									}}
								/>
							</Grid>
							<Grid item xs={12} sm={6}>
								<SlTextField
									label="学校名"
									state={this.state.schoolNameState}
									onChange={(newState) => {
										this.setState({schoolNameState: newState})
									}}
								/>
							</Grid>
							<Grid item xs={12} sm={6}>
								<TextFieldSelector
									label="学年"
									name="selectedGrade"
									state={this.state.selectedGrade}
									fullWidth={true}
									onChange={(newState: SelectorInputState) => {
										this.setState({selectedGrade: newState})
									}}
									InputLabelProps={{
										shrink: true,
										disableAnimation: true,
									}}
								/>
							</Grid>
							<Grid item xs={12} sm={12}>
								<TextField
									label="ご利用サービス（必須）"
									placeholder="選択して下さい"
									fullWidth={true}
									value={serviceLabel}
									// onChange={this.onChange}
									error={(this.state.serviceError.length > 0)}
									helperText={this.state.serviceError}
									onClick={() => {
										this.setState({openService: true}, () => {
											window.scrollTo(0, 0)
										})
									}}
									// normal="normal"
									InputLabelProps={{
										shrink: true,
										disableAnimation: true,
									}}
								/>
							</Grid>
							<Grid item xs={12} sm={12}>
								<SlTextField
									multiline
									label="ご連絡事項"
									state={this.state.postScript}
									onChange={(newState) => {
										this.setState({postScript: newState})
									}}
								/>
							</Grid>
							<Grid item xs={12} sm={12}>
								<div style={styles.queryOptionTitle}>アンケート</div>
								<div>
									受験の予定をお聞かせ下さい
								</div>
							</Grid>
							<Grid item xs={12} sm={6}>
								<TextFieldSelector
									label="社会福祉士"
									state={this.state.enquteShakai}
									fullWidth={true}
									onChange={(newState: SelectorInputState) => {
										this.setState({enquteShakai: newState})
									}}
									InputLabelProps={{
										shrink: true,
										disableAnimation: true,
									}}
								/>
							</Grid>
							<Grid item xs={12} sm={6}>
								<TextFieldSelector
									label="精神保健福祉士"
									state={this.state.enquteSeishin}
									fullWidth={true}
									onChange={(newState: SelectorInputState) => {
										this.setState({enquteSeishin: newState})
									}}
									InputLabelProps={{
										shrink: true,
										disableAnimation: true,
									}}
								/>
							</Grid>
							<Grid item xs={12} sm={6}>
								<TextFieldSelector
									label="赤マル福祉をどこで知りましたか？"
									state={this.state.enquteWhereYouKnow}
									fullWidth={true}
									onChange={(newState: SelectorInputState) => {
										this.setState({enquteWhereYouKnow: newState})
									}}
									InputLabelProps={{
										shrink: true,
										disableAnimation: true,
									}}
								/>
							</Grid>
							<Grid item xs={12} sm={6}>
							</Grid>
							<Grid item xs={12} sm={12}>
								<div style={styles.queryOptionTitle}>「利用規約」をご確認下さい</div>
								<div>
									<Accordion>
										<AccordionSummary expandIcon={<ExpandMoreIcon/>}>
											利用規約をご確認ください
										</AccordionSummary>
										<AccordionDetails>
											<ContractText/>
										</AccordionDetails>
									</Accordion>
								</div>
								<div>
									<SlCheckbox
										label="利用規約に同意する（必須）"
										state={this.state.kiyaku}
										onChange={(newState) => {
											this.setState({kiyaku: newState})
										}}
									/>
								</div>
							</Grid>
							<Grid item xs={12} sm={12} style={{textAlign: 'center'}}>
								<Button disabled={disableApplyButton} variant="contained" type="submit" color="primary"
												style={{width: '40%'}}>
									確認画面へ
								</Button><br/>
								<span style={{marginTop: 16, color: 'red', fontWeight: 700}}>
									{this.state.invalidMessage}
								</span>
							</Grid>
						</Grid>
					</form>
				</div>
				<Dialog
					open={(this.state.payValid === 2)}
					aria-labelledby="payinvalid-title"
					// aria-describedby="payinvalid-description"
				>
					<DialogTitle id="payinvalid-title">現在、お申込みは受け付けておりません</DialogTitle>
					{/*<DialogContent>*/}
					{/*	<DialogContentText id="alert-dialog-description">*/}
					{/*		決済システムの応答を待っています・・・*/}
					{/*	</DialogContentText>*/}
					{/*</DialogContent>*/}
					<DialogActions key={2}>
						<Button onClick={() => {
							this.props.history.goBack()
						}} color="primary" autoFocus>
							戻る
						</Button>
					</DialogActions>
				</Dialog>
			</div>
		)
	}

	handleOrder = () => {
		this.setState({
			openPaymentDialog: true,
			paymentDialogStep: "connecting",
		})
		const st = this.state
		const obj = {
			name: st.name.getValue(),
			kana: st.kana.getValue(),
			zipcode: st.zipcode.getValue(),
			addressCity: st.addressCity.getValue(),
			// addressStreet: st.addressStreet.getValue(),
			addressPref: st.pref.getValue(),
			telno: st.telno.getValue().trim(),
			schoolName: st.schoolNameState.getValue(),
			// schoolCode: st.schoolCodeState.getValue(),
			grade: st.selectedGrade.getValue(),
		}
		const payload = {
			user_token: Cookies.get(altName),
			obj,
		}
		post("user/update_profile", payload)
		.then(() => {
			const {services, service} = this.state
			const _service = _.find(services, {service})
			const payload = {
				user_token: Cookies.get(altName),
				name: this.state.name.getValue().substring(0,10),
				telno: this.state.telno.getValue().trim(),
				value: _service.price,
				service: this.state.userType + ":" + this.state.service,
				post_script: this.state.postScript.getValue(),
				enquete: JSON.stringify({
					enquteShakai: this.state.enquteShakai.getValue(),
					enquteSeishin: this.state.enquteSeishin.getValue(),
					enquteWhereYouKnow: this.state.enquteWhereYouKnow.getValue(),
				})
			}
			return post("order/post_request", payload)
		})
		.then((ret) => {
			if (ret.error && ret.error === "encode_error") {
				this.setState({
					openPaymentDialog: true,
					paymentDialogStep: "encode_error",
				})
			} else {
				this.paymentUrl = ret.url
				this.paymentOrderId = ret.order_id
				this.paymentResult = ret.result
				this.setState({
					paymentDialogStep: "confirm",
				})
			}
		})
		.catch((ret) => {
			this.setState({
				openPaymentDialog: true,
				paymentDialogStep: "error",
			})
		})
	}

	handleCancelPayment = () => {
		this.setState({
			openPaymentDialog: false,
		})
		const payload = {
			user_token: Cookies.get(altName),
			receiptno: this.paymentResult,
			order_id: this.paymentOrderId,
		}
		post("order/post_cancel_order", payload)
	}

	handleOpenPaymentPage = () => {
		window.open(this.paymentUrl, "payment")
		this.setState({
			paymentDialogStep: "backtotop",
		})
	}

	handleRedo = () => {
		this.setState({
			openPaymentDialog: false,
			step: 'input',
		})
	}

	handleClosePaymentDialog = () => {
		this.setState({
			openPaymentDialog: false,
		})
		changeTab("my", this.props.history)
	}

	renderConfirm() {
		const paymentDialog = (!this.state.openPaymentDialog) ? null :
			(this.state.paymentDialogStep === "connecting") ? (
				<DialogContent>
					<DialogContentText id="alert-dialog-description">
						決済システムの応答を待っています・・・
					</DialogContentText>
				</DialogContent>
			) : (this.state.paymentDialogStep === "confirm") ? [(
				<DialogContent key={1}>
					<DialogContentText id="alert-dialog-description">
						決済ページの準備ができました
					</DialogContentText>
				</DialogContent>
			), (
				<DialogActions key={2}>
					<Button onClick={this.handleCancelPayment} color="primary">
						キャンセル
					</Button>
					<Button onClick={this.handleOpenPaymentPage} color="primary" autoFocus>
						決済ページを開く
					</Button>
				</DialogActions>
			)] : (this.state.paymentDialogStep === "backtotop") ? [(
				<DialogContent key={1}>
					<DialogContentText id="alert-dialog-description">
						決済手続きが終了したら、マイページに戻るボタンを押して下さい
					</DialogContentText>
				</DialogContent>
			), (
				<DialogActions key={2}>
					<Button onClick={this.handleClosePaymentDialog} color="primary" autoFocus>
						マイページに戻る
					</Button>
				</DialogActions>
			)] : (this.state.paymentDialogStep === "encode_error") ? [(
				<DialogContent key={1}>
					<DialogContentText id="alert-dialog-description">
						名前に使用できない文字（ハシゴの高など）が含まれています
					</DialogContentText>
				</DialogContent>
			), (
				<DialogActions key={2}>
					<Button onClick={this.handleRedo} color="primary" autoFocus>
						戻る
					</Button>
				</DialogActions>
			)] : [(			// ERROR
				<DialogContent key={1}>
					<DialogContentText id="alert-dialog-description">
						エラーが発生し、処理が中断しました<br/>
						電話番号が正しいか、ご確認ください
					</DialogContentText>
				</DialogContent>
			), (
				<DialogActions key={2}>
					<Button onClick={this.handleClosePaymentDialog} color="primary" autoFocus>
						マイページに戻る
					</Button>
				</DialogActions>
			)]

		const {services, service} = this.state
		const _service = _.find(services, {service})
		// let serviceLabel = "選択して下さい"
		// if (service !== "0") {
		// 	const _service = _.find(services, {service})
		// 	serviceLabel = _service.label
		// }
		return (
			<div>
				<FkToolbar
					title="お申込み内容の確認"
				/>
				<Grid container spacing={8} direction="row" justify="center"
							style={{maxWidth: 800, marginLeft: 'auto', marginRight: 'auto'}}>
					<Grid item xs={12} sm={6}>
						<div key={1} style={styles.queryOptionTitle}>利用者ID</div>
						<div key={2} style={styles.body1}>
							{this.state.uniqueId.getValue()}
						</div>
					</Grid>
					<Grid item xs={12} sm={6}>
					</Grid>
					<Grid item xs={12} sm={6}>
						<div key={1} style={styles.queryOptionTitle}>お名前（全角）</div>
						<div key={2} style={styles.body1}>
							{this.state.name.getValue()}
						</div>
					</Grid>
					<Grid item xs={12} sm={6}>
						<div key={1} style={styles.queryOptionTitle}>フリガナ（全角）</div>
						<div key={2} style={styles.body1}>
							{this.state.kana.getValue()}
						</div>
					</Grid>
					<Grid item xs={12} sm={6}>
						<div key={1} style={styles.queryOptionTitle}>郵便番号</div>
						<div key={2} style={styles.body1}>
							{this.state.zipcode.getValue()}
						</div>
					</Grid>
					<Grid item xs={12} sm={6}>
						<div key={1} style={styles.queryOptionTitle}>都道府県</div>
						<div key={2} style={styles.body1}>
							{this.state.pref.getLabel()}
						</div>
					</Grid>
					<Grid item xs={12} sm={12}>
						<div key={1} style={styles.queryOptionTitle}>住所</div>
						<div key={2} style={styles.body1}>
							{this.state.addressCity.getValue()}
						</div>
					</Grid>
					<Grid item xs={12} sm={6}>
						<div key={1} style={styles.queryOptionTitle}>電話番号</div>
						<div key={2} style={styles.body1}>
							{this.state.telno.getValue()}
						</div>
					</Grid>
					<Grid item xs={12} sm={6}>
						<div key={1} style={styles.queryOptionTitle}>お支払い方法</div>
						<div key={2} style={styles.body1}>
							{this.state.payment.getValue()}
						</div>
					</Grid>
					<Grid item xs={12} sm={6}>
						<div key={1} style={styles.queryOptionTitle}>学校名</div>
						<div key={2} style={styles.body1}>
							{this.state.schoolNameState.getValue()}
						</div>
					</Grid>
					{/*<Grid item xs={12} sm={6}>*/}
					{/*<div key={1} style={styles.queryOptionTitle}>学校コード</div>*/}
					{/*<div key={2} style={styles.body1}>*/}
					{/*{this.state.schoolCodeState.getValue()}*/}
					{/*</div>*/}
					{/*</Grid>*/}
					<Grid item xs={12} sm={6}>
						<div key={1} style={styles.queryOptionTitle}>学年</div>
						<div key={2} style={styles.body1}>
							{grades[this.state.selectedGrade.getValue()].label}
						</div>
					</Grid>
					<Grid item xs={12} sm={12}>
						<div key={1} style={styles.queryOptionTitle}>ご利用サービス</div>
						<div key={2} style={styles.body1}>
							{_service.label}
						</div>
					</Grid>
					<Grid item xs={12} sm={12}>
						<div key={1} style={styles.queryOptionTitle}>ご連絡事項</div>
						<div key={2} style={styles.body1}>
							{this.state.postScript.getValue()}
						</div>
					</Grid>
					<Grid item xs={12} sm={12}>
						<div style={styles.queryOptionTitle}>アンケート</div>
						<div>
							受験の予定
						</div>
					</Grid>
					<Grid item xs={12} sm={6}>
						<div style={styles.queryOptionTitle}>社会福祉士</div>
						<div key={2} style={styles.body1}>
							{this.state.enquteShakai.getLabel()}
						</div>
					</Grid>
					<Grid item xs={12} sm={6}>
						<div style={styles.queryOptionTitle}>精神保健福祉士</div>
						<div key={2} style={styles.body1}>
							{this.state.enquteSeishin.getLabel()}
						</div>
					</Grid>
					<Grid item xs={12} sm={6}>
						<div style={styles.queryOptionTitle}>赤マル福祉をどこで知りましたか</div>
						<div key={2} style={styles.body1}>
							{this.state.enquteWhereYouKnow.getLabel()}
						</div>
					</Grid>
					<Grid item xs={12} sm={6}>
					</Grid>
					<Grid item xs={12} sm={6}>
						<div style={styles.queryOptionTitle}>利用規約</div>
						<div key={2} style={styles.body1}>
							同意する
						</div>
					</Grid>
					<Grid item xs={12} sm={6}>
						<div style={styles.queryOptionTitle}>お支払金額（税込）</div>
						<div key={2} style={styles.priceConfirm}>
							{_service.price}円
						</div>
					</Grid>
					<Grid item xs={12} sm={12} style={{textAlign: 'center'}}>
						<Button variant="contained" onClick={this.handleRedo}>
							修正する
						</Button>
						<Button variant="contained" onClick={this.handleOrder} color="primary" style={{width: '40%', marginLeft: 20}}>
							申し込みを確定する
						</Button>
					</Grid>
				</Grid>
				<Dialog
					open={this.state.openPaymentDialog}
					aria-labelledby="alert-dialog-title"
					aria-describedby="alert-dialog-description"
				>
					<DialogTitle id="alert-dialog-title">決済サービスに接続しています</DialogTitle>
					{paymentDialog}
				</Dialog>
			</div>
		)
	}
}
export default connect(
	state => ({
		userState: state.sl_users.state,
	})
)(withRouter(ApplicationPage))