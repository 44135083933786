import { createAction } from 'redux-actions'

export const LOADING_VISIBILITY = 'LOADING_VISIBILITY'
export const loadingVisibilityAC = createAction(LOADING_VISIBILITY, loading => ({loading}));

export const UPDATE_SNACKBAR = 'UPDATE_SNACKBAR'
export const updateSnackbarAC = createAction(UPDATE_SNACKBAR, text => ({text}));

export const UPDATE_FORM = 'UPDATE_FORM'
export const updateFormAC = createAction(UPDATE_FORM, (formName, entity) => ({formName, entity}));
