import React from 'react'
import { withRouter } from 'react-router-dom'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import TableHead from '@material-ui/core/TableHead';
import FkToolbar from '../parts/FkToolbar'
import {post} from '../sonodalib/sl_utils/Http'
import _ from 'lodash'
import Moment from 'moment'
import { withBbsDrawer } from '../parts/BbsDrawer'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import Icon from '@material-ui/core/Icon'
import { common } from '@material-ui/core/colors'
import Cookies from "js-cookie";
import {altName} from '../alt/constants'


class BbsThread extends React.Component {
	state = {
		threads: null,
	}

	componentWillMount() {
		const boardId = parseInt(this.props.match.params.boardId,10)
		this.fetch_threads(boardId)
	}

	fetch_threads(boardId) {
		const self = this
		const payload = {
			user_token: Cookies.get(altName),
			board_id: boardId,
			ident: null,
		}
		post("bbs/fetch_threads", payload)
			.then(ret => {
				self.setState({
					threads: _.orderBy(ret.list,["updated"],["desc"]),
				})
			})
	}

	handleClickThread = thread => {
		this.props.history.push("/my/bbs/posts/"+thread.uuid)
	}

	handleCreateThread = () => {
		const boardId = parseInt(this.props.match.params.boardId,10)
		this.props.bbsDrawer.openToCreateThread(
			boardId,
			更新 => {
				this.fetch_threads(boardId)
			},
			0,
			0,
			""
		)
	}

	render() {
		const boardId = parseInt(this.props.match.params.boardId,10)
		const titles = [null, "学習掲示板 1", "学習掲示板 2", "交流掲示板", "よくある質問"]
		const title = (boardId < titles.length) ? titles[boardId] : ""
		const { threads } = this.state
		const create_thread_vd = (boardId === 4) ? null : (
			<Grid container style={{marginTop: 10}}>
				<Grid item xs={6} style={{padding: 10}}>
					<Button
						style={{width:'100%', color: common.white, backgroundColor: "#229cfc"}}
						variant="contained"
						onClick={this.handleCreateThread.bind(this)}
					>
						新しい投稿
					</Button>
				</Grid>
			</Grid>
		)
		const threadList = (threads === null) ? (
			<TableRow>
				<TableCell colSpan={4}>
					読み込み中・・・
				</TableCell>
			</TableRow>
		) : (threads.length === 0) ? (
			<TableRow>
				<TableCell colSpan={4}>
					まだ投稿はありません
				</TableCell>
			</TableRow>
		) : _.map(
			threads, thread => (
				<TableRow hover key={thread.uuid} onClick={this.handleClickThread.bind(this, thread)}>
					<TableCell padding="dense">{thread.title} {(thread.read_date && thread.read_date < thread.updated) ? <Icon color="primary">sms_failed</Icon> : null}</TableCell>
					<TableCell padding="dense">{thread.handle_name}</TableCell>
					<TableCell padding="dense" numeric>{thread.comment_count}</TableCell>
					<TableCell padding="dense">{Moment(thread.updated).format("YYYY/MM/DD")}</TableCell>
				</TableRow>
			)
		)
		return (
			<div>
				<FkToolbar title={title} onGoBack={() => {this.props.history.goBack()}}/>
				<Table>
					<TableHead>
						<TableRow>
							<TableCell padding="dense">タイトル</TableCell>
							<TableCell padding="dense">投稿者</TableCell>
							<TableCell padding="dense" numeric>返信数</TableCell>
							<TableCell padding="dense">更新日</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{threadList}
					</TableBody>
				</Table>
				{create_thread_vd}
			</div>
		)
	}
}

export default withRouter(withBbsDrawer(BbsThread))
