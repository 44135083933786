import React from 'react'
import { withRouter } from 'react-router-dom'
import Cookies from "js-cookie";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import TableHead from '@material-ui/core/TableHead';
import FkToolbar from '../parts/FkToolbar'
import {post} from '../sonodalib/sl_utils/Http'
import _ from 'lodash'
import Moment from 'moment'
import Icon from '@material-ui/core/Icon'
import {altName} from '../alt/constants'


class BbsThread1 extends React.Component {
	state = {
		threads: null,
	}

	componentWillMount() {
		const ident = this.props.match.params.ident
		this.fetch_threads(ident)
	}

	fetch_threads(ident) {
		const self = this
		const payload = {
			user_token: Cookies.get(altName),
			board_id: 1,
			ident,
		}
		post("bbs/fetch_threads", payload)
			.then(ret => {
				self.setState({
					threads: ret,
				})
			})
	}

	handleClickThread = thread => {
		this.props.history.push("/my/bbs/posts/"+thread.uuid)
	}

	render() {
		const { threads } = this.state
		let mondaiName = ""
		if (threads && threads.list.length) {
			const row = threads.list[0]
			mondaiName = (row.Shikenshurui + " " + row.Shikenkaisu + " " + row.Kamokumei + " 問題" + row.MondaiNum)
		}
		const threadList = (threads === null) ? (
			<TableRow>
				<TableCell colSpan={4}>
					読み込み中・・・
				</TableCell>
			</TableRow>
		) : (threads.list.length === 0) ? (
			<TableRow>
				<TableCell colSpan={4}>
					まだ投稿はありません
				</TableCell>
			</TableRow>
		) : _.map(
			threads.list, thread => {
				//if (thread.read_date) debugger
				return (
					<TableRow hover key={thread.uuid} onClick={this.handleClickThread.bind(this, thread)}>
						<TableCell padding="dense">{thread.title} {(thread.read_date && thread.read_date < thread.updated) ? <Icon color="primary">sms_failed</Icon> : null}</TableCell>
						<TableCell padding="dense">{thread.handle_name}</TableCell>
						<TableCell padding="dense" numeric>{thread.comment_count}</TableCell>
						<TableCell padding="dense">{Moment(thread.updated).format("YYYY/MM/DD")}</TableCell>
					</TableRow>
				)
			}
		)
		return (
			<div>
				<FkToolbar title={mondaiName} onGoBack={() => {this.props.history.goBack()}}/>
				<Table>
					<TableHead>
						<TableRow>
							<TableCell padding="dense">タイトル</TableCell>
							<TableCell padding="dense">投稿者</TableCell>
							<TableCell padding="dense" numeric>返信数</TableCell>
							<TableCell padding="dense">更新日</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{threadList}
					</TableBody>
				</Table>
			</div>
		)
	}
}

export default withRouter(BbsThread1)
