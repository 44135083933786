import React from 'react'
import MenuItem from '@material-ui/core/MenuItem'
import _ from 'lodash'

export function buildPrefMenuItems() {
	return _.map(getPrefectures(), pref => {
			return <MenuItem key={pref.id} value={pref.id} primaryText={pref.label}/>
	})
}

export function getPrefectures() {
	return [
		{id: 0, label: "（無指定）", invalid:true},
		{id: 1, label: "北海道", en: "Hokkaido"},
		{id: 2, label: "青森県", en: "Aomori"},
		{id: 3, label: "岩手県", en: "Iwate"},
		{id: 4, label: "宮城県", en: "Miyagi"},
		{id: 5, label: "秋田県", en: "Akita"},
		{id: 6, label: "山形県", en: "Yamagata"},
		{id: 7, label: "福島県", en: "Fukushima"},
		{id: 8, label: "茨城県", en: "Ibaraki"},
		{id: 9, label: "栃木県", en: "Tochigi"},
		{id: 10, label: "群馬県", en: "Gunma"},
		{id: 11, label: "埼玉県", en: "Saitama"},
		{id: 12, label: "千葉県", en: "Chiba"},
		{id: 13, label: "東京都", en: "Tokyo"},
		{id: 14, label: "神奈川県", en: "Kanagawa"},
		{id: 15, label: "新潟県", en: "Niigata"},
		{id: 16, label: "富山県", en: "Toyama"},
		{id: 17, label: "石川県", en: "Ishikawa"},
		{id: 18, label: "福井県", en: "Fukui"},
		{id: 19, label: "山梨県", en: "Yamanashi"},
		{id: 20, label: "長野県", en: "Nagano"},
		{id: 21, label: "岐阜県", en: "Gifu"},
		{id: 22, label: "静岡県", en: "Shizuoka"},
		{id: 23, label: "愛知県", en: "Aichi"},
		{id: 24, label: "三重県", en: "Mie"},
		{id: 25, label: "滋賀県", en: "Shiga"},
		{id: 26, label: "京都府", en: "Kyoto"},
		{id: 27, label: "大阪府", en: "Osaka"},
		{id: 28, label: "兵庫県", en: "Hyogo"},
		{id: 29, label: "奈良県", en: "Nara"},
		{id: 30, label: "和歌山県", en: "Wakayama"},
		{id: 31, label: "鳥取県", en: "Tottori"},
		{id: 32, label: "島根県", en: "Shimane"},
		{id: 33, label: "岡山県", en: "Okayama"},
		{id: 34, label: "広島県", en: "Hiroshima"},
		{id: 35, label: "山口県", en: "Yamaguchi"},
		{id: 36, label: "徳島県", en: "Tokushima"},
		{id: 37, label: "香川県", en: "Kagawa"},
		{id: 38, label: "愛媛県", en: "Ehime"},
		{id: 39, label: "高知県", en: "Kochi"},
		{id: 40, label: "福岡県", en: "Fukuoka"},
		{id: 41, label: "佐賀県", en: "Saga"},
		{id: 42, label: "長崎県", en: "Nagasaki"},
		{id: 43, label: "熊本県", en: "Kumamoto"},
		{id: 44, label: "大分県", en: "Oita"},
		{id: 45, label: "宮崎県", en: "Miyazaki"},
		{id: 46, label: "鹿児島県", en: "Kagoshima"},
		{id: 47, label: "沖縄県", en: "Okinawa"},
	]
}
