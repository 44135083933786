// @flow
import React from 'react'
import {logout,login} from '../sonodalib/sl_users/SlUsersAction'
// import Grid from '@material-ui/core/Grid'
import {post} from '../sonodalib/sl_utils/Http'
// import { Flex, Box } from 'reflexbox'


type Props = {
	match: any,
}
export default class SuperLogin extends React.Component<Props> {

	componentWillMount() {
		logout()
		const {token} = this.props.match.params
		post("user/super-login", {
			token,
		})
		.then(ret => {
			login(ret)
		})
	}

	render() {
		return null
	}
}
