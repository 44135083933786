import React from 'react'
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";


export default function ExplainDialog(props) {
	if (!props.open) return null
	const {data, onClose} = props
	const {orderno, qtext, explanation, correct, selections} = data
	return (
		<Dialog
			open={true}
			onClose={onClose}
			fullWidth={true}
			maxWidth="md"
		>
			<DialogContent>
				<div style={{maxHeight:800, margin:10,lineHeight:2}}>
					<div style={{backgroundColor:'#f5ecd4',padding:18}}>
						<div style={{fontSize:16, fontWeight:700}}>
							問題 {orderno}
						</div>
						<div style={{marginBottom:20}} dangerouslySetInnerHTML={{__html: qtext}}></div>
						{selections.map((selection,index) => (
							<div key={"sel"+index}>
								<span style={{marginRight:20}}>{index+1}</span>
								<span dangerouslySetInnerHTML={{__html: selection.replace(/\n/g,"<br />")}}></span>
							</div>
							)
						)}
					</div>
					<div style={{padding:18}}>
						<div style={{fontSize:16, fontWeight:700}}>
							解説
						</div>
						<div>
							【正解 <span style={{color:'red',fontWeight:'bold'}}>{correct}</span>】
						</div>
						<div dangerouslySetInnerHTML={{__html: explanation}}></div>
					</div>
				</div>
			</DialogContent>
			<DialogActions>
				<Button
					variant="text"
					color="secondary"
					onClick={onClose}
					style={{border:'1px solid #f50057'}}
				>
					閉じる
				</Button>
			</DialogActions>
		</Dialog>
	)
}
