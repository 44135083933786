import React from 'react'
// import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import SlTextField from "../../sonodalib/sl_mui/SlTextField";
import {FormState, TextInputState} from "../../sonodalib/sl_utils/SlForms";
import {post} from '../../sonodalib/sl_utils/Http';
import UserCommon from './Common'


class RegisterPage extends React.Component {
	state = {
		loginId:   	 new TextInputState({validations: ["required","alpdigit",{max:32},{min:5}]}),
		password:  	 new TextInputState({validations: "required,password"}),
		password2: 	 new TextInputState({validations: "required,password"}),
		serviceName: "",
		confirm:   	 false,
	}

	componentDidMount() {
		UserCommon.validateServiceCode(this.props)
		.then(ret => {
			this.setState({
				serviceName: ret.name
			})
		})
	}

	getServiceCode = (props) => {
		try {
			return parseInt(props.match.params.serviceCode)
		} catch (e) {
			location.href = "/"
		}
	}

	handleErrFromSignup = (ret, loginId) => {
		if (ret.err === "bad-login-id") {
			alert("IDは、英文字、数字で、3〜32文字で入力してください")
		} else if (ret.err === "user-exists") {
			alert(loginId + " は、すでに使われています。別の ID を入力してください")
		} else {
			alert("不明なエラーです")
		}
	}

	handleSubmit = (event: any) => {
		event.preventDefault()
		const serviceCode = this.getServiceCode(this.props)
		this.setState(FormState.validate(this.state), () => {
			if (FormState.isValid(this.state)) {
				const {loginId, password, password2} = this.state
				if (password.getValue() !== password2.getValue()) {
					alert("２つのパスワードが異なっています")
				} else {
					post("user/wm/signup-dry", {
						service: serviceCode,
						login_id: loginId.getValue(),
					})
					.then(ret => {
						if (ret.err) {
							this.handleErrFromSignup(ret, loginId.getValue())
						} else {
							this.setState({
								confirm: true,
							})
						}
					})
				}
			}
		})
	}

	handleConfirmed = () => {
		const {history} = this.props
		const serviceCode = this.getServiceCode(this.props)
		const {loginId, password} = this.state
		post("user/wm/signup", {
			service: serviceCode,
			login_id: loginId.getValue(),
			password:	password.getValue(),
		})
		.then(ret => {
			if (ret.err) {
				this.handleErrFromSignup(ret, loginId.getValue())
			} else {
				alert("利用登録が完了しました。次のページで、ログインしてご利用ください")
				history.push("/wmu/signin/" + serviceCode)
			}
		})
	}

	handleModify = () => {
		this.setState({
			confirm: false,
		})
	}


	render() {
		// const {moshiState} = this.props
		const {loginId, password, password2, serviceName,  confirm} = this.state

		const content = (!confirm) ? (
			<form onSubmit={this.handleSubmit}>
				<Box display="flex" flexDirection="column" alignItems="center" justifyContent="center">
					<Box width={0.7} height={90}>
						<SlTextField
							autoFocus
							name="login_id"
							label="ID　英小文字 または 数字 5〜32文字"
							state={loginId}
							onChange={(newState: TextInputState) => {
								this.setState({loginId: newState})
							}}
						/>
					</Box>
					<Box width={0.7} height={90}>
						<SlTextField
							name="password"
							type="password"
							label="パスワード"
							state={password}
							onChange={(newState: TextInputState) => {
								this.setState({password: newState})
							}}
						/>
					</Box>
					<Box width={0.7} height={90}>
						<SlTextField
							name="password2"
							type="password"
							label="パスワード（再入力）"
							state={password2}
							onChange={(newState: TextInputState) => {
								this.setState({password2: newState})
							}}
						/>
					</Box>
					<Box width={0.4} height={100}>
						<Button variant="contained" type="submit" color="primary" style={{width: '100%'}}>
							確認画面へ
						</Button>
					</Box>
				</Box>
			</form>
		) : (
			<Box display="flex" flexDirection="column" alignItems="center" justifyContent="center">
				<Box width={0.7} height={90}>
					<Box>
						ID
					</Box>
					<Box fontWeight="bold" color="red" fontSize={24}>
						{loginId.getValue()}
					</Box>
				</Box>
				<Box width={0.7} height={90}>
					<Box>
						パスワード
					</Box>
					<Box fontWeight="bold" color="red" fontSize={24}>
						{password.getValue()}
					</Box>
				</Box>
				<Box width={0.7} height={100} textAlign="center">
					<Button variant="contained" color="primary" style={{width: 200}} onClick={this.handleConfirmed}>
						登録
					</Button>
					<Button variant="contained" color="default" style={{width: 100, marginLeft:20}} onClick={this.handleModify}>
						修正
					</Button>
				</Box>
			</Box>
		)

		return (
			<div style={{
				marginTop: 20,
				marginBottom: 100,
				maxWidth: 700,
				marginLeft: "auto",
				marginRight: "auto",
			}}>
				<div style={{marginLeft:8, marginRight:8, marginBottom:38}}>
					<div style={{marginBottom:20,textAlign:'center',borderTop:'1px solid #ccc',borderBottom:'1px solid #ccc'}}>
						<Box m={2}>
							<span style={{fontSize:26, fontWeight:'bold'}}>赤マル福祉 Web自動採点</span>
						</Box>
						<Box m={2}>
							<span style={{fontSize:22, fontWeight:200}}>{serviceName}</span>
						</Box>
					</div>
					<Box my={4} p={1} textAlign="center" fontSize={24} letterSpacing={2} style={{backgroundColor:'#eee'}}>
						利用登録
					</Box>
					{content}
				</div>
			</div>
		)
	}
}
export default (withRouter(RegisterPage));