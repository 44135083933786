import React from 'react'
import Button from '@material-ui/core/Button'
import {connect} from 'react-redux'
import {QuestionResult, SentakuResultListItem} from './CommonParts'
import FkToolbar from '../parts/FkToolbar'
import {common} from '@material-ui/core/colors';
import Icon from '@material-ui/core/Icon';
import Cookies from "js-cookie";
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List'
import _ from 'lodash'
import {withRouter} from 'react-router-dom'
import {OkCancelModal} from "../sonodalib/sl_adminlte/Modals";
import {post} from '../sonodalib/sl_utils/Http'
import {startSentakuQuestions} from '../question/Question'
import SentakuResultPrint from './SentakuResultPrint'
import {updatePrintModeAC} from "../mypage/MyPageAction";
import G from "../sonodalib/SlGlobal";
import {altName, urlPref} from '../alt/constants'


class SentakuResult extends React.Component {
	state = {
		showErrorModal: false,
	}

	componentWillMount() {
		if (!this.props.sentakuQuestions) {
			window.location.href = urlPref+"/my"
			return
		}
	}

	handleDetail = index => {
		this.props.history.push("/q/sentaku/detail/" + index)
	}

	handleBackToMenu = () => {
		// this.props.history.push("/my/lesson/query/select-category")
		// this.props.history.go(-2)
		this.props.history.goBack()
	}

	handleContinue = () => {
		const payload = this.props.queryCondition.toJS()
		payload.user_token = Cookies.get(altName)
		post("q/search", payload)
		.then(ret => {
			if (ret.questions.length === 0) {
				this.setState({
					showErrorModal: true,
				})
			} else {
				this.props.startSentakuQuestions(this.props.history, ret.questions)
			}
		})
	}

	handleOkErorModal = () => {
		this.setState({
			showErrorModal: false,
		})
	}

	handlePrint = () => {
		G.store.dispatch(updatePrintModeAC(true))
		window.setTimeout(() => {
			window.print()
		}, 1500)
	}

	handleClosePrintPopout = () => {
		G.store.dispatch(updatePrintModeAC(false))
	}

	render() {
		if (!this.props.sentakuQuestions) return null
		const styles = {
			resultCount: {
				fontSize: 24,
				fontWeight: 800,
				marginLeft: 8,
			},
			commandBox: {
				margin: 8,
				marginBottom: 18,
			},
			commandButton: {
				width: 220,
				marginRight: 10,
			},
			question: {
				border: '1px gray solid',
				padding: 8,
				marginTop: 8,
				marginBottom: 8,
			},
		}
		const {questions, repeat} = this.props.sentakuQuestions
		const sentakuResults = this.props.sentakuResults
		const resultCounts = _.countBy(sentakuResults, result => (result))
		const list = _.map(questions.slice(0, sentakuResults.length), (question, index) => {
			return (
				<SentakuResultListItem
					key={index}
					index={index}
					question={question}
					onClick={this.handleDetail.bind(this)}
				/>
			)
		})

		const repeatButtonVd = (!repeat) ? null : (
			<Button variant="contained"
							onClick={this.handleContinue.bind(this)}
							style={{...styles.commandButton, color: common.white, backgroundColor: "#ff8351"}}
			>
				学習を続ける
			</Button>
		)

		if (this.props.printMode) return <SentakuResultPrint onClose={this.handleClosePrintPopout}/>

		return (
			<div>
				<FkToolbar title="学習結果">
					<IconButton style={{color: 'white'}} aria-label="印刷" onClick={this.handlePrint}>
						<Icon>printer</Icon>
					</IconButton>
				</FkToolbar>
				<div style={{maxWidth: 800, marginLeft: 'auto', marginRight: 'auto'}}>
					<div>
						<QuestionResult
							seikaiCount={resultCounts[1] || 0}
							huseikaiCount={resultCounts[2] || 0}
							mukaitouCount={resultCounts[3] || 0}
							jisshi={1}
							jisshiPlus={2}
							shujuku={3}
							shujukuPlus={-4}
						/>
						<div>
							<List>
								{list}
							</List>
						</div>
						<div style={styles.commandBox}>
							<Button variant="contained"
											onClick={this.handleBackToMenu.bind(this)}
											style={{...styles.commandButton, color: common.white, backgroundColor: "#2196f3"}}
							>
								学習メニューに戻る
							</Button>
							{repeatButtonVd}
						</div>
					</div>
				</div>
				<OkCancelModal
					show={this.state.showErrorModal}
					okText="OK"
					bodyText="現在の条件に合う問題は見つかりませんでした"
					onOk={this.handleOkErorModal.bind(this)}
				/>
			</div>
		)
	}
}

export default connect(
	state => ({
		queryCondition: state.question.queryCondition,
		sentakuQuestions: state.question.sentakuQuestions,
		sentakuResults: state.question.sentakuResults,
		printMode: state.mypage.printMode,
	}),
	dispatch => ({
		startSentakuQuestions: function (history, questions) {
			startSentakuQuestions({
				dispatch,
				history,
				questions,
				repeat: true,
			})
		}
	})
)(withRouter(SentakuResult))
