import React from 'react'
import PropTypes from 'prop-types'
// import { Flex, Box } from 'reflexbox'
// import { CategoryButton, QTypeButton, SelectedBox } from './CommonParts'
import FkToolbar from '../../parts/FkToolbar'
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import {questionTypeLabels} from "../../Common";
import {categoryJireiFlags} from '../../alt/constants'

class SelectQType extends React.Component {
	static propTypes = {
		category: PropTypes.number.isRequired,
		onSelect: PropTypes.func.isRequired,
		// onGoBack: PropTypes.func.isRequired,
	}

	handleSubmit = (qtype) => {
		this.props.onSelect(qtype)
	}

	render() {
		// return this.renderPC()
		return this.renderMobile()
	}

	// renderPC() {
	// 	const styles = {
	// 		selectedCategory: {
	// 			margin: 40,
	// 			textAlign: 'left',
	// 		},
	// 		guide: {
	// 			fontWeight: 'bold',
	// 			margin: 30,
	// 			textAlign: 'center',
	// 		},
	// 		categoryButton: {
	// 			display: 'inline-block',
	// 			width: 320,
	// 		},
	// 	}
	// 	const { category } = this.props
	// 	const jireiVd = (categoryJireiFlags[category]) ? (
	// 		<Box p={1}>
	// 			<QTypeButton
	// 				qtype="jirei"
	// 				onClick={this.handleSubmit.bind(this, 'jirei')}
	// 			/>
	// 		</Box>
	// 	) : null
	// 	return (
	// 		<div>
	// 			<FkToolbar
	// 				title="問題形式の選択"
	// 				onGoBack={this.props.onGoBack}
	// 			/>
	// 			<SelectedBox
	// 				leftContent={
	// 					<div>
	// 						選択したカテゴリー<br />
	// 						<CategoryButton
	// 							category={category}
	// 							// onClick={this.props.onGoBack.bind(this, category)}
	// 						/>
	// 					</div>
	// 				}
	// 			/>
	// 			<Flex column align="center">
	// 				<Box>
	// 					<div style={styles.guide}>
	// 						問題形式を選択して下さい
	// 					</div>
	// 				</Box>
	// 				<Box p={1}>
	// 					<QTypeButton
	// 						qtype="sentaku"
	// 						onClick={this.handleSubmit.bind(this, 'sentaku')}
	// 					/>
	// 				</Box>
	// 				<Box p={1}>
	// 					<QTypeButton
	// 						qtype="ox"
	// 						onClick={this.handleSubmit.bind(this, 'ox')}
	// 					/>
	// 				</Box>
	// 				{jireiVd}
	// 			</Flex>
	// 		</div>
	// 	)
	// }

	renderMobile() {
		const { category } = this.props
		const jireiVd = (categoryJireiFlags[category]) ? (
			<ListItem button onClick={this.handleSubmit.bind(this, 'jirei')}>
				<ListItemText primary={questionTypeLabels.jirei} />
			</ListItem>
		) : null

		return (
			<div>
				<FkToolbar
					// title={categoryLabels[category]}
					title="出題形式"
					onGoBack={this.props.onGoBack}
				/>
				<List
          component="nav"
          // subheader={<ListSubheader component="div">カテゴリーを選択して下さい</ListSubheader>}
        >
					<ListItem button onClick={this.handleSubmit.bind(this, 'sentaku')}>
						<ListItemText primary={questionTypeLabels.sentaku} />
					</ListItem>
					<ListItem button onClick={this.handleSubmit.bind(this, 'ox')}>
						<ListItemText primary={questionTypeLabels.ox} />
					</ListItem>
					{jireiVd}
				</List>
			</div>
		)
	}
}

export default SelectQType
