import React from 'react'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import FkToolbar from '../../parts/FkToolbar'
import { common, amber, grey } from '@material-ui/core/colors';
import { Flex, Box } from 'reflexbox'
import {jireiLabel} from '../../alt/constants'


export class CategoryButton extends React.Component {
	static propTypes = {
		category: PropTypes.number.isRequired,
		onClick:  PropTypes.func,
	}

	render() {
		const labels = {
			0: "共通科目",
			1: "社会福祉士・専門",
			2: "精神保健福祉士・専門",
		}
		const styles = {
			categoryButton: {
				// display: 'inline',
				width: 320,
				// marginLeft: 'auto',
				// marginRight: 'auto',
				textAlign: 'left',
			},
		}
		return (
			<Button variant="contained"
				style={styles.categoryButton}
				color="primary"
				onClick={this.props.onClick}
			>
				{labels[this.props.category]}
			</Button>
		)
	}
}

export class QTypeButton extends React.Component {
	static propTypes = {
		qtype: PropTypes.string.isRequired,
		onClick:  PropTypes.func.isRequired,
	}

	render() {
		const labels = {
			"sentaku": "選択問題",
			"ox": "◯✕問題",
			"jirei": jireiLabel,
		}
		const styles = {
			button: {
				color: common.white,
				backgroundColor: amber[800],
				width: 320,
				textAlign: 'center',
			},
		}
		return (
			<Button variant="contained"
				style={styles.button}
				onClick={this.props.onClick}
			>
				{labels[this.props.qtype]}
			</Button>
		)
	}
}


export class SubjectButton extends React.Component {
	static propTypes = {
		subject: PropTypes.string.isRequired,
		onClick:  PropTypes.func.isRequired,
	}

	render() {
		const styles = {
			button: {
				color: common.black,
				backgroundColor: grey[100],
				width: 370,
				textAlign: 'center',
			},
		}
		return (
			<Button variant="contained"
				style={styles.button}
				// labelColor={white}
				onClick={this.props.onClick}
			>
				{this.props.subject}
			</Button>
		)
	}
}


export class JireiButton extends React.Component {
	static propTypes = {
		subject: PropTypes.string.isRequired,
		onClick:  PropTypes.func.isRequired,
	}

	render() {
		const styles = {
			button: {
				marginTop: 2,
				backgroundColor: grey[200],
				width: 360,
				textAlign: 'center',
			},
		}
		return (
			<Button variant="contained"
				style={styles.button}
				onClick={this.props.onClick}
			>
				{this.props.subject}
			</Button>
		)
	}
}


export class LessonToolBar extends React.Component {
	static propTypes = {
		label: PropTypes.string.isRequired,
		onGoBack: PropTypes.func,
	}

	render() {
		return (
			<FkToolbar title={this.props.label} onGoBack={this.props.onGoBack}/>
		)
	}
}

// export class LessonToolBar extends React.Component {
// 	static propTypes = {
// 		label:		PropTypes.string.isRequired,
// 		onGoBack: PropTypes.func,
// 	}
//
// 	render() {
// 		const { onGoBack } = this.props
// 		const styles = {
// 			appBar: {
// 				marginTop: 10,
// 			},
// 			toolBar: {
// 				minHeight: 48,
// 				backgroundColor: blue[500],
// 			},
// 			toolBarTitle: {
// 				marginLeft: 20,
// 				color: common.white,
// 				whiteSpace: 'nowrap',
// 			},
// 		}
// 		const goBack = (!onGoBack) ? null : (
// 			<IconButton style={{color:'white'}} aria-label="戻る" onClick={onGoBack}>
// 				<ArrowBackIcon />
// 			</IconButton>
// 		)
// 		return (
// 			<AppBar style={styles.appBar} position="static">
// 				<Toolbar style={styles.toolBar} disableGutters={true}>
// 					{goBack}
// 					<div style={styles.toolBarTitle}>
// 						{this.props.label}
// 					</div>
// 				</Toolbar>
// 			</AppBar>
// 		)
// 	}
// }


export class SelectedBox extends React.Component {
	static propTypes = {
		leftContent:  PropTypes.any.isRequired,
		rightContent: PropTypes.any,
	}
	render() {
		const { leftContent } = this.props
		const rightContent = this.props.rightContent || null
		const styles = {
			selectedBox: {
				marginTop: 10,
			},
		}
		return (
			<Flex justify="center" style={styles.selectedBox}>
				<Box m={1} style={{width:326}}>
					{leftContent}
				</Box>
				<Box m={1} style={{width:326}}>
					{rightContent}
				</Box>
			</Flex>
		)
	}
}
