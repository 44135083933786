import Button from "@material-ui/core/Button";
import React from "react";


export default function BackToMoshiTopButton(props) {
	return (
		<Button
			variant="text"
			color="secondary"
			onClick={() => {
				props.history.push("/wm")
			}}
			style={{border:'1px solid #f50057'}}
		>
			模試TOPに戻る
		</Button>
	)
}