import React from 'react'
import PropTypes from 'prop-types'
import TopHeader from './TopHeader'
import Button from '@material-ui/core/Button';
import SlTextField from '../sonodalib/sl_mui/SlTextField'
import Grid from '@material-ui/core/Grid';
import {post} from '../sonodalib/sl_utils/Http'
import { TextInputState, FormState } from '../sonodalib/sl_utils/SlForms'
import InputDigitCode from '../parts/InputDigitCode'
import ConfirmPassword from '../parts/ConfirmPassword'
import CommandBox from '../parts/CommandBox'
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import {urlPref} from '../alt/constants'


class Apply extends React.Component {
	static propTypes = {
		onFinish: PropTypes.func.isRequired,
		onCancel: PropTypes.func.isRequired,
	}

	state = {
		uniqueIdState: new TextInputState({validations: "require"}),
		emailState: new TextInputState({validations: "require,email"}),
		inviteCodeState: new TextInputState({validations: "require,alpdigit"}),
	}

	handleSubmit = e => {
		e.preventDefault()
		let errorMessage;
		this.setState(FormState.validate(this.state), () => {
			if (FormState.isValid(this.state)) {
				const payload = {
					unique_id:	 this.state.uniqueIdState.getValue(),
					email:			 this.state.emailState.getValue(),
					invite_code: this.state.inviteCodeState.getValue(),
				}
				post("user/invite/apply", payload)
					.then(ret => {
						if (ret.status) {
							switch (ret.status) {
								// case 'already used email':
								// 	this.setState({
								// 		emailState: this.state.emailState.setError("登録済みのメールアドレスです"),
								// 	})
								// 	break;
								case 'already activated':
									this.setState({
										emailState: this.state.emailState.setError("登録済みのメールアドレスです"),
									})
									// this.setState({
									// 	uniqueIdState: this.state.uniqueIdState.setError("この利用者IDは招待登録済みです"),
									// })
									break;
								case 'invalid email':
									this.setState({
										emailState: this.state.emailState.setError("メールアドレスが間違っています"),
									})
									break;
								case 'bad unique_id':
									this.setState({
										uniqueIdState: this.state.uniqueIdState.setError("利用者IDが間違っています"),
									})
									break;
								default:
							}
						} else {
							ret.email = payload.email
							this.props.onFinish(ret)
						}
					})
					.catch(res => {
						if (res && res.statusCode === 401) {
							errorMessage = "招待コードが間違っています"
						} else {
							errorMessage = "不明なエラーが発生しました"
						}
						this.setState({
							inviteCodeState: this.state.inviteCodeState.setError(errorMessage),
						})
					})
			}
		})
	}

	render() {
		const styles = {
			loginBox: {
				marginTop: 20,
				width: 400,
			},
		  loginBtn: {
			  float: 'right',
			  marginLeft: 8,
		  },
		}
		return (
			<Grid container alignItems="center" direction="column">
				<CommandBox
					label="招待コードで登録する"
				/>
				<Grid style={styles.loginBox}>
					<form onSubmit={this.handleSubmit}>
						<SlTextField
							autoFocus
							label="メールアドレス"
							placeholder="email@example.com"
							state={this.state.emailState}
							onChange={(state) => {this.setState({emailState:state})}}
						/>
						<SlTextField
							label="ID"
							placeholder="半角数字"
							state={this.state.uniqueIdState}
							onChange={(state) => {this.setState({uniqueIdState:state})}}
						/>
						<SlTextField
							label="招待コード"
							placeholder="半角"
							state={this.state.inviteCodeState}
							onChange={(state) => {this.setState({inviteCodeState:state})}}
						/>
						<div className="pull-right" style={{marginTop: 12}}>
							<Button variant="contained"
								color="primary"
								style={styles.loginBtn}
								type="submit"
							>
								確認メールを受信
							</Button>
							<Button variant="contained"
								style={styles.loginBtn}
								onClick={() => {this.props.onCancel()}}
							>
								キャンセル
							</Button>
						</div>
					</form>
				</Grid>
			</Grid>
		)
	}
}

class InitialRegister extends React.Component {
	state = {
		passwordState: new TextInputState({validations:"require,password"})
	}

	handleSubmit = (event) => {
		event.preventDefault()
		this.setState({
			passwordState: this.state.passwordState.validate(),
		}, () => {
			if (this.state.passwordState.isValid()) {
				this.props.onFinish({
					password1: this.state.passwordState.getValue(),
				})
			}
		})
	}

	handleCancel = () => {
		this.props.onCancel()
	}

	render() {
		const styles = {
			loginBox: {
				marginTop: 20,
				width: 400,
			},
		  loginBtn: {
			  float: 'right',
			  marginLeft: 8,
		  },
		}
		return (
			<Grid container alignItems="center" direction="column">
				<CommandBox
					label="パスワードを設定"
					desc="本サイトにログインする際に、パスワードが必要です"
				/>
				<Grid style={styles.loginBox}>
					<form onSubmit={this.handleSubmit}>
						<SlTextField
							autoFocus
							type="password"
							label="パスワード"
							placeholder="英小文字と数字の組み合わせで8文字以上"
							state={this.state.passwordState}
							onChange={(state) => {this.setState({passwordState:state})}}
						/>
						<div>
							<Button variant="contained"
								color="primary"
								style={styles.loginBtn}
								type="submit"
							>
								次へ
							</Button>
							<Button variant="contained"
								style={styles.loginBtn}
								onClick={this.handleCancel}
							>
								キャンセル
							</Button>
						</div>
					</form>
				</Grid>
			</Grid>
		)
	}
}


export default class InvitePage extends React.Component {
	state = {
		page: "apply",
		// email: "",
		// errorMessage: "",
		completedDialogOpen: false,
		user_token: null,
		user_name: null,
	}

	password1 = null

  render() {
		let content = null
		switch (this.state.page) {
			case "apply":
				content =
					<Apply
						onFinish={ret => {
							this.digitsId = ret.digits_id
							this.email = ret.email
							this.setState({page: "inputDigitCode"})
						}}
						onCancel={() => {
							window.location.href = '/'
						}}
					/>
				break;

			case "inputDigitCode":
				content =
					<InputDigitCode
						digitsId={this.digitsId}
						expiredDesc={<span>お手数ですが、<a onClick={()=>{
							this.setState({
								page: "apply"
							})
						}}>招待登録</a> から再度ご登録下さい</span>}
						onFinish={() => {
							this.setState({page: "initialRegister"})
						}}
						onCancel={() => {
							this.setState({page: "apply"})
						}}
						appliedEmail={this.email}
					/>
				break;

			case "initialRegister":
				content =
					<InitialRegister
						onFinish={(ret) => {
							this.password1 = ret.password1
							this.setState({page: "confirmPassword"})
						}}
						onCancel={() => {
							this.setState({page: "apply"})
						}}
					/>
				break;

			case "confirmPassword":
				content =
					<ConfirmPassword
						passwordString={this.password1}
						onSubmit={(ret, onError) => {
							const payload = {
								digit_id: this.digitsId,
								email: this.email,
								password: ret.password2,
							}
							post("user/invite/initial_register", payload)
							.then(() => {
								this.setState({completedDialogOpen: true})
							})
							.catch(res => {
								let errorMessage;
								if (res && res.statusCode === 401) {
									errorMessage = "すでに登録されているため、処理は中断されました"
								} else {
									errorMessage = "不明なエラーが発生しました"
								}
								onError(errorMessage)
								// this.password2State.error(errorMessage)
							})
						}}
						onCancel={()=> {
							this.setState({page: "initialRegister"})
						}}
					/>
				break;
			default:
		}

    return (
      <div className="wrapper">
				<TopHeader/>
        <div className="content-wrapper">
          <div className="container" style={{marginTop:30}}>
						{content}
						<Dialog open={this.state.completedDialogOpen}>
							<DialogTitle>招待登録が完了しました</DialogTitle>
							<DialogActions>
								<Button href={urlPref+"/login"}>
									ログインページへ
								</Button>
							</DialogActions>
						</Dialog>
					</div>
				</div>
        <footer className="main-footer" style={{marginTop:100}}>
          <div className="container">
            <strong>Copyright © <a href="http://www.jc-edu.co.jp">JC Educational Institute, Inc.</a>.</strong> All rights reserved.
          </div>
        </footer>
      </div>
    )
	}
}
