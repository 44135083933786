import React from "react";
import Chart from "react-apexcharts";

export default function SubjectRaderChart(props) {
	const {series, width, height, labels, labelFontSize} = props

	const options = {
		chart: {
			animations: {
				enabled: false
			},
			toolbar: {
				show: false,
			},
			// height: 350,
			type: 'radar',
		},
		title: {
			text: "",
			margin: -25,
		},
		xaxis: {
			categories: labels,
			labels: {
				offsetY: -8,
				style: {
					colors: new Array(labels.length).fill("#333"),
					fontSize: labelFontSize || 16,
				}
			}
		},
		yaxis: [{
			show: true,
			max: 100,
			min: 0,
			tickAmount: 5,
			labels: {
				style: {
					fontSize: 12,
				}
			}
		}],
		legend: {
			show: true,
			offsetY: -40,
			// position: 'top',
			// // height: 10,
		},
	}
	// const options = {
	// 	chart: {
	// 		animations: {
	// 			enabled: true
	// 		},
	// 		background: 'white',
	// 		type: 'bar',
	// 		toolbar: {
	// 			show: false,
	// 		},
	// 		stacked: true,
	// 	},
	// 	dataLabels: {
	// 		enabled: false,
	// 	},
	// 	tooltip: {
	// 		enabled: false,
	// 	},
	// 	states: {
	// 		hover: {
	// 			filter: {
	// 				type: 'none',
	// 			}
	// 		}
	// 	},
	// 	colors: blue[400],
	// 	fill: {
	// 		opacity: 1,
	// 	},
	// 	title: {
	// 		text: "",
	// 		margin: -25,
	// 	},
	// 	labels,
	// 	xaxis: {
	// 		type: 'category',
	// 		labels: {
	// 			trim: false,
	// 			rotate: 0,
	// 			// rotateAlways: true,
	// 			minHeight: 46,
	// 			// hideOverlappingLabels: true,
	// 			offsetX: 0,
	// 			offsetY: -4,
	// 		},
	// 		// tickAmount: 5,
	// 		axisTicks: {
	// 			show: false,
	// 		},
	// 	},
	// 	yaxis: [{
	// 		show: false,
	// 		// max: maxValue,
	// 		tickAmount: 5,
	// 		// title: {
	// 		//   text: yaxisTitle,
	// 		// },
	// 	}],
	// 	stroke: {
	// 		show: false,
	// 		// width: [3],
	// 		// curve: ['smooth'],
	// 	},
	// 	legend: {
	// 		show: false,
	// 		// position: 'top',
	// 		// offsetY: 20,
	// 		// // height: 10,
	// 	},
	// 	// annotations: {
	// 	// 	xaxis: [{
	// 	// 		x: 0,
	// 	// 		x2: 1,
	// 	// 		fillColor: 'red',
	// 	// 		opacity: 1,
	// 	// 	}]
	// 	// },
	// }

	return (
		<Chart options={options}
					 series={series}
					 type="radar"
					 width={width}
					 height={height}
		/>
	)
}
