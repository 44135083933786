import React from 'react'
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Cookies from "js-cookie";
import {altName} from "../../alt/constants";
import Button from '@material-ui/core/Button';
// import Icon from "@material-ui/core/Icon";
// import { CircularProgress } from '@material-ui/core/Progress'
import SougouTab from './SougouTab'
import CategoryTab from './CategoryTab'
import {post_wm} from "../../sonodalib/sl_utils/Http";
import {updateMoshi} from "../MoshiAction";
import Moment from "moment";
import PrintSogoCat0 from './PrintSogoCat0'
import PrintSogo from './PrintSogo'
import PrintCat from './PrintCat'
import BackToMoshiTopButton from "../parts/BackToMoshiTopButton";
import {getPrintParams} from './PrintShindan'
// import Icon from "@material-ui/core/Icon";


const _styles = {
	frame: {
		marginTop: 20,
		marginBottom: 100,
		maxWidth: 700,
		marginLeft: "auto",
		marginRight: "auto",
	}
}


class ShindanPage extends React.Component {
	state = {
		tabValue: 0,
		data: 		null,
	}

	componentDidMount() {
		post_wm("shindan/fetchall", {
			user_token:  Cookies.get(altName),
			moshi_token: Cookies.get("wm_"+altName),
		})
		.then((ret) => {
			this.setState({
				data:	ret.data,
			})
		})
		.catch(() => {
			alert("エラーが発生しました")
			this.props.history.push("/wm")
		})
	}

	handleChangeTab = (event, value) => {
		this.setState({tabValue: value})
	}

	render_print() {
		const {data} = this.state
		if (!data) return null
		const {printMode} = this.props
		const printParams = getPrintParams(data)
		const currentTime = Moment(new Date()).format("YYYY年MM月DD日 HH時mm分")
		let content = null
		if (printMode === 1) {
			content = (printParams.categoryCount === 3) ? (
				<PrintSogoCat0 data={data}/>
			) : (
				<PrintSogo data={data}/>
			)
		} else {
			content = (printParams.categoryCount === 3) ? (
				<PrintCat data={data} cats={[1,2]}/>
			) : (
				<PrintCat data={data} cats={[0,1]}/>
			)
		}
		return (
			<div>
				<div>
					<img src={"/imgms/moshi/akamarulogo.gif"} role="presentation" alt="head-logo"/>
					<span style={{fontSize:18,marginLeft:10,top:6,position:'relative'}}>{data.service_name} {currentTime} 利用者ID：{data.user_id}</span>
				</div>
				<div>
					{content}
				</div>
			</div>
		)
	}

	render() {
		if (this.props.printMode) return this.render_print()

		const styles = {
			// container: {
			// 	marginLeft: 'auto',
			// 	marginRight: 'auto',
			// 	maxWidth: 700,
			// },
			tab: {
				color: 			'black',
				// backgroundColor: "rgb(236, 240, 245)",
				// background: "white",
				fontSize: 	16,
				fontWeight: 600,
			},
		}
		const {history, moshiState} = this.props
		const {tabValue, data} = this.state

		const bannerVd = (
			<div style={{marginBottom:20,fontSize:20,fontWeight:200,textAlign:'center',paddingTop:30,paddingBottom:30,borderTop:'1px solid #ccc',borderBottom:'1px solid #ccc'}}>
				<div>
					<span style={{fontSize:21, lineHeight:'30px',fontWeight:200}}>{moshiState.serviceName}</span>
				</div>
			</div>
		)

		if (!data) return (
			<div style={_styles.frame}>
				{bannerVd}
				<div style={{marginTop:100, textAlign:'center'}}>
					<img src={"/imgms/loading-grey.gif"} role="presentation" alt="loading" style={{width:64,height:64}}/>
					{/*<Icon style={{color:'#aaa'}}>cloud_download</Icon>*/}
				</div>
			</div>
		)

		const tabsVd = data.categories.map((category, index) => (
			<Tab
				key={"tab"+index}
				style={styles.tab}
				label={category.label}
				value={index+1}
			/>
		))
		tabsVd.unshift(
			<Tab
				key={"tab99"}
				style={styles.tab}
				label="総合成績"
				value={0}
			/>
		)
		const contentVd = (tabValue > 0) ? (
			<CategoryTab data={data.categories[tabValue-1]}/>
		) : (
			<SougouTab data={data}/>
		)

		const printParams = getPrintParams(data)
		const PrintButton = (props) => (
			<Button
				variant="text"
				color="secondary"
				onClick={() => {
					updateMoshi({
						printMode: props.index
					})
					window.setTimeout(() => {
						window.print()
					}, 1500)
				}}
				style={{border:'1px solid #f50057'}}
			>
				{printParams.printButtonLabel[props.index-1]}
			</Button>
		)
		const printButtonsVd = (
			<div style={{textAlign:'right', marginTop:25}}>
				<div style={{marginBottom:4}}>
					<PrintButton index={1}/>
				</div>
				<div>
					<PrintButton index={2}/>
				</div>
			</div>
		)

		return (
			<div style={_styles.frame}>
				{bannerVd}
				<div>
					<Tabs
						centered
						// fullWidth
						onChange={this.handleChangeTab}
						value={tabValue}
					>
						{tabsVd}
					</Tabs>
					<div style={{marginTop:30}}>
						{contentVd}
					</div>
					{printButtonsVd}
				</div>
				<div style={{textAlign:'center', marginTop:30}}>
					<BackToMoshiTopButton history={history}/>
				</div>
			</div>
		)
	}
}
export default connect(
	state => ({
		moshiState: state.moshi,
		printMode: state.moshi.printMode,
	})
)(withRouter(ShindanPage));
