import * as Action from './SlUsersAction';
import { Record } from 'immutable'
import Cookies from "js-cookie";
import {altName} from '../../alt/constants'

const _SlUsersStateRecord = Record({
  state: undefined,
  isAuthenticated: !!Cookies.get(altName),
})
class SlUsersState extends _SlUsersStateRecord {}

export default function sl_users(state = new SlUsersState(), action) {
  switch (action.type) {
    case Action.UPDATE_AUTH_STATE:
      return state.merge(action.payload)
    default:
      return state
  }
}

