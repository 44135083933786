// @flow
import React, {Component} from 'react'
import {urlPref} from '../alt/constants'

type Props = {
	jisshido: number,
}
export default class JisshidoBox extends Component<Props,{}> {
	render() {
		const getImageIndex = (value) => {
			return (value < 25) ? "1" :
				(value < 50) ? "2" :
					(value < 75) ? "3" :
						(value < 100) ? "4" : "5"
		}
		const {jisshido} = this.props
		const jisshidoImage = `url('${urlPref}/images/eval/flower${getImageIndex(jisshido)}_h40.gif')`
		return (
			<div>
				<div style={{
					height: 40,
					backgroundPosition: 'bottom 0px right 0px',
					backgroundImage: jisshidoImage,
					backgroundRepeat: 'no-repeat'
				}}>
					<span>{jisshido}%</span>
				</div>
			</div>
		)
	}
}
