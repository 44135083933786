import React from 'react'
import {withRouter} from 'react-router-dom'
import Cookies from "js-cookie";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import TableHead from '@material-ui/core/TableHead';
import FkToolbar from '../parts/FkToolbar'
import {post} from '../sonodalib/sl_utils/Http'
import _ from 'lodash'
import PropTypes from 'prop-types'
import Moment from 'moment'
import {withBbsDrawer} from '../parts/BbsDrawer'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import Icon from '@material-ui/core/Icon'
import {common} from '@material-ui/core/colors'
import {altName} from '../alt/constants'


class ContactThread extends React.Component {
	state = {
		threads: null,
	}

	componentWillMount() {
		const boardId = 9
		this.fetch_threads(boardId)
	}

	fetch_threads(boardId) {
		const self = this
		const payload = {
			user_token: Cookies.get(altName),
			board_id: boardId,
			ident: null,
		}
		post("bbs/fetch_threads", payload)
		.then(ret => {
			PropTypes.checkPropTypes({
				list: PropTypes.array.isRequired,
			}, ret)
			self.setState({
				threads: ret,
			})
		})
	}

	handleClickThread = thread => {
		this.props.history.push("/my/bbs/contact/posts/" + thread.uuid)
	}

	handleCreateThread = () => {
		const boardId = 9
		this.props.bbsDrawer.openToCreateThread(
			boardId,
			(threadId) => {
				this.fetch_threads(boardId)
				post("etc/contact-notify", {
					user_token: Cookies.get(altName),
					thread_id: threadId,
					alt_name: altName,
				})
			},
			0,
			0,
			"お客様",
		)
	}

	render() {
		const {threads} = this.state
		const threadList = (threads === null) ? (
			<TableRow>
				<TableCell colSpan={4}>
					読み込み中・・・
				</TableCell>
			</TableRow>
		) : (threads.list.length === 0) ? (
			<TableRow>
				<TableCell colSpan={4}>
					まだお問い合わせはありません
				</TableCell>
			</TableRow>
		) : _.map(
			threads.list, thread => (
				<TableRow hover key={thread.uuid} onClick={this.handleClickThread.bind(this, thread)}>
					<TableCell padding="dense">{thread.title} {(thread.read_date && thread.read_date < thread.updated) ?
						<Icon color="primary">sms_failed</Icon> : null}</TableCell>
					<TableCell padding="dense" numeric>{thread.comment_count}</TableCell>
					<TableCell padding="dense">{Moment(thread.updated).format("YYYY/MM/DD")}</TableCell>
				</TableRow>
			)
		)
		return (
			<div>
				<div>
					<span style={{color: 'red', fontWeight: 800, fontSize: 14}}>このページの内容は、他の利用者が閲覧することはできません</span>
				</div>
				<FkToolbar title={"お問い合わせ"} onGoBack={() => {
					this.props.history.goBack()
				}}/>
				<Table>
					<TableHead>
						<TableRow>
							<TableCell padding="dense">タイトル</TableCell>
							<TableCell padding="dense" numeric>返信数</TableCell>
							<TableCell padding="dense">更新日</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{threadList}
					</TableBody>
				</Table>
				<Grid container style={{marginTop: 10}}>
					<Grid item xs={6} style={{padding: 10}}>
						<Button
							style={{width: '100%', color: common.white, backgroundColor: "#229cfc"}}
							variant="contained"
							onClick={this.handleCreateThread.bind(this)}
						>
							新しいお問い合わせ
						</Button>
					</Grid>
				</Grid>
			</div>
		)
	}
}

export default withRouter(withBbsDrawer(ContactThread))
