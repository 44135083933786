// import * as Action from './TopAction';

const initialState = {
//	inviteModel: null,			// InviteModel
//	resetPassModel: null,		// ResetPassModel
//	trialRegister: null,		// TrialRegister
};

export default function inviteReducer(state = initialState, action) {
  switch (action.type) {
	  // case Action.UPDATE_INVITE_MODEL:
     //  return {...state, inviteModel: action.payload.inviteModel }
	  // case Action.UPDATE_RESET_PASS_MODEL:
     //  return {...state, resetPassModel: action.payload.resetPassModel }
	  // case Action.UPDATE_TRIAL_REGISTER:
			// const object = {...state.trialRegister, ...action.payload.trialRegister}
     //  return {...state, trialRegister: object }
    default:
      return state
  }
}

