import React from 'react'
import {withRouter} from "react-router-dom";
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import SlTextField from "../../sonodalib/sl_mui/SlTextField";
import {FormState, TextInputState} from "../../sonodalib/sl_utils/SlForms";
import {post} from '../../sonodalib/sl_utils/Http';
import {login} from '../../sonodalib/sl_users/SlUsersAction'
import UserCommon from './Common'
import Moshi from "../../moshi";


class LoginPage extends React.Component {
	state = {
		loginId:  new TextInputState({validations: ["required","alpdigit",{max:32},{min:5}]}),
		password: new TextInputState({validations: "required,password"}),
		serviceName: "",
	}

	componentDidMount() {
		UserCommon.validateServiceCode(this.props)
		.then(ret => {
			this.setState({
				serviceName: ret.name
			})
		})
	}

	handleSubmit = (event: any) => {
		const {history} = this.props
		event.preventDefault()
		let serviceCode
		try {
			serviceCode = parseInt(this.props.match.params.serviceCode)
		} catch (e) {
			location.href = "/"
			return
		}
		this.setState(FormState.validate(this.state), () => {
			if (FormState.isValid(this.state)) {
				const {loginId, password, serviceName} = this.state
				post("user/wm/signin", {
					service: serviceCode,
					login_id: loginId.getValue(),
					password: password.getValue(),
				})
				.then(ret => {
					if (ret.err) {
						if (ret.err === "service-is-invalid") {
							alert(serviceName + " は、現在ご利用になれません")
						} else {
							alert("ID または パスワードが間違っています")
						}
					} else {
						this.setState({
							loginId:  loginId.setValue(""),
							password: password.setValue(""),
						}, () => {
							Moshi.startSession(serviceCode, ret.token, () => {
								login(ret)
								history.push("/wm")
							}, () => {
								alert("Error")
							})
						})
					}
				})
			}
		})
	}


	render() {
		const {loginId, password, serviceName} = this.state
		return (
			<div style={{
				marginTop: 20,
				marginBottom: 100,
				maxWidth: 700,
				marginLeft: "auto",
				marginRight: "auto",
			}}>
				<div style={{marginLeft:8, marginRight:8, marginBottom:38}}>
					<div style={{marginBottom:20,textAlign:'center',borderTop:'1px solid #ccc',borderBottom:'1px solid #ccc'}}>
						<Box m={2}>
							<span style={{fontSize:26, fontWeight:'bold'}}>赤マル福祉 Web自動採点</span>
						</Box>
						<Box m={2}>
							<span style={{fontSize:22, fontWeight:200}}>{serviceName}</span>
						</Box>
					</div>
					<Box my={4} p={1} textAlign="center" fontSize={24} letterSpacing={2} style={{backgroundColor:'#eee'}}>
						ログイン
					</Box>
					<form onSubmit={this.handleSubmit}>
						<Box display="flex" flexDirection="column" alignItems="center" justifyContent="center">
							<Box width={0.7} height={90}>
								<SlTextField
									autoFocus
									name="login_id"
									label="ID　英小文字 または 数字 5〜32文字"
									state={loginId}
									onChange={(newState: TextInputState) => {
										this.setState({loginId: newState})
									}}
								/>
							</Box>
							<Box width={0.7} height={90}>
								<SlTextField
									name="password"
									type="password"
									label="パスワード"
									state={password}
									onChange={(newState: TextInputState) => {
										this.setState({password: newState})
									}}
								/>
							</Box>
							<Box width={0.4} height={100}>
								<Button variant="contained" type="submit" color="primary" style={{width: '100%'}}>
									ログイン
								</Button>
							</Box>
						</Box>
					</form>
				</div>
			</div>
		)
	}
}
export default withRouter(LoginPage);