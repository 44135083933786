import React from 'react'
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import CategoryTab from './CategoryTab'
import {updateMoshi} from "../MoshiAction";


export default class PrintCat extends React.Component {
	render() {
		const {data, cats} = this.props
		if (!data) return null
		return (
			<div style={{width:1010}}>
				<Grid container style={{width:"100%"}}>
					<Grid item style={{width:500,marginRight:10}}>
						<CategoryTab data={data.categories[cats[0]]}/>
					</Grid>
					<Grid item style={{width:500}}>
						<CategoryTab data={data.categories[cats[1]]}/>
					</Grid>
				</Grid>
				<div style={{textAlign:'right', marginTop:40}}>
					<Button
						variant="text"
						color="secondary"
						onClick={() => {
							updateMoshi({
								printMode: null
							})
						}}
						style={{border:'1px solid #f50057'}}
					>
						印刷画面を閉じる
					</Button>
				</div>
			</div>
		)
	}
}
