import React from 'react'
import BsLikeTextField from '../sonodalib/sl_mui/BsLikeTextField'
import Button from '@material-ui/core/Button'
import G from '../sonodalib/SlGlobal'
import {post} from '../sonodalib/sl_utils/Http'
import { updateSnackbarAC } from '../sonodalib/sl_utils/SlUtilsAction'
import { TextInputState } from '../sonodalib/sl_utils/SlForms'
import Cookies from "js-cookie";
import {altName} from '../alt/constants'
import Drawer from '@material-ui/core/Drawer';
import Grid from "@material-ui/core/Grid";
// import Dialog from '@material-ui/core/Dialog';
// import Icon from "@material-ui/core/Icon";
// import IconButton from "@material-ui/core/IconButton";


export const withMemoDrawer = WrappedComponent => class extends React.Component {
	mondaiId = null
	marubatsuId = null
	savedMemoText = null
	state = {
		open:			false,
		memoId: 	null,
		memoText: new TextInputState({validations:"required",disabled:true}),
		isActive: false,
	}

	fetch(mondaiId, marubatsuId) {
		const self = this
		this.mondaiId = mondaiId
		this.marubatsuId = marubatsuId
		const payload = {
			user_token: 	Cookies.get(altName),
			mondai_id: 		mondaiId,
			marubatsu_id: marubatsuId,
		}
		post("memo/fetch", payload, false)
			.then(ret => {
				self.setState({
					memoId: ret.memo_id,
					memoText: this.state.memoText.setValue(ret.memo),
					isActive: ret.memo.length !== 0,
				})
				self.savedMemoText = ret.memo
				// if (this.props.onChangeState)
				// 	this.props.onChangeState(state)
			})
	}

	load = id => {
		this.fetch(id.mondaiId, id.marubatsuId)
	}

	open = (id, onUpdate) => {
		this.onUpdateHandler = onUpdate
		if (id) {
			this.fetch(id.mondaiId, id.marubatsuId)
		}
		this.setState({
			open: true,
		})
	}

	onCancel =  () => {
		this.setState({
			open: false,
			memoText: this.state.memoText.setValue(this.savedMemoText),
		})
	}

	handleUpdateMemo = (event) => {
		const payload = {
			user_token: 	Cookies.get(altName),
			memo_id:			this.state.memoId,
			mondai_id: 		this.mondaiId,
			marubatsu_id: this.marubatsuId,
			memo:					this.state.memoText.getValue(),
		}
		post("memo/update", payload, false)
			.then(ret => {
				G.store.dispatch(updateSnackbarAC("保存しました"))
				this.fetch(this.mondaiId, this.marubatsuId)
				if (this.onUpdateHandler) {
					this.onUpdateHandler()
				}
				this.setState({
					open: false,
				})
			})
	}

	render() {
		const { memoText, isActive } = this.state
		const textInputVd = (memoText.isDisabled()) ? (
			<BsLikeTextField
				// label="メモ"
				type="text"
				multiline={true}
				rows="3"
				state={memoText}
				// value="読み込み中です..."
				disabled
				style={{height:80}}
			/>
		) : (
			<BsLikeTextField
				// label="メモ"
				type="text"
				multiline={true}
				rows="3"
				state={memoText}
				onChange={(newState) => {this.setState({memoText:newState})}}
				style={{height:80}}
			/>
		)
		return (
			<div>
				<WrappedComponent
					{...this.props}
					memoDrawer={this}
					memoDrawerStatus={isActive}
				/>
				<Drawer
					anchor="top"
					open={this.state.open}
					onClose={this.onCancel}
					BackdropProps={{
						style:{backgroundColor:'rgba(100,100,100,0.1)'}
					}}
					style={{height:40}}
					// ModalProps={{
					// 	hideBackdrop: true,
					// }}
					// onBackdropClick={this.onCancel}
				>
					<Grid container direction="row" justify="space-around" alignItems="flex-start" style={{padding: '8px 8px 0 8px', width:'100%'}}>
						<Grid item style={{width:40,color:'grey'}}>
							メモ
						</Grid>
						<Grid item style={{width:'calc(100% - 108px)'}}>
							{textInputVd}
						</Grid>
						<Grid item style={{width:66, marginLeft:2, marginTop:2}}>
							<Button
								variant="text"
								color="secondary"
								onClick={this.handleUpdateMemo.bind(this)}
								style={{border:'1px solid #f50057',marginBottom:2}}
								size="small"
							>
								保存
							</Button>
							{/*<IconButton color="primary" onClick={this.handleUpdateMemo.bind(this)}>*/}
							{/*	<Icon>check_circle</Icon>*/}
							{/*</IconButton>*/}
							{/*<IconButton onClick={this.onCancel.bind(this)}>*/}
							{/*	<Icon>clear</Icon>*/}
							{/*</IconButton>*/}
							<Button
								variant="text"
								onClick={this.onCancel.bind(this)}
								style={{border:'1px solid grey'}}
								size="small"
							>
								破棄
							</Button>
						</Grid>
					</Grid>
					{/*<div style={{padding: 14}}>*/}
					{/*	{textInputVd}*/}
					{/*	<div style={{marginTop:8}}>*/}
					{/*		<Button*/}
					{/*			variant="contained"*/}
					{/*			color="primary"*/}
					{/*			onClick={this.handleUpdateMemo.bind(this)}*/}
					{/*			style={{width: 120}}*/}
					{/*		>*/}
					{/*			保存*/}
					{/*		</Button>*/}
					{/*		<Button*/}
					{/*			variant="contained"*/}
					{/*			onClick={this.onCancel.bind(this)}*/}
					{/*			style={{width: 120, marginLeft: 10}}*/}
					{/*		>*/}
					{/*			キャンセル*/}
					{/*		</Button>*/}
					{/*	</div>*/}
					{/*</div>*/}
				</Drawer>
				{/*<Dialog*/}
				{/*	// anchor="bottom"*/}
				{/*	open={this.state.open}*/}
				{/*	onClose={this.onCancel}*/}
				{/*>*/}
				{/*	<div style={{padding: 14}}>*/}
				{/*		{textInputVd}*/}
				{/*		<div style={{marginTop:8}}>*/}
				{/*			<Button*/}
				{/*				variant="contained"*/}
				{/*				color="primary"*/}
				{/*				onClick={this.handleUpdateMemo.bind(this)}*/}
				{/*				style={{width: 120}}*/}
				{/*			>*/}
				{/*				保存*/}
				{/*			</Button>*/}
				{/*			<Button*/}
				{/*				variant="contained"*/}
				{/*				onClick={this.onCancel.bind(this)}*/}
				{/*				style={{width: 120, marginLeft: 10}}*/}
				{/*			>*/}
				{/*				キャンセル*/}
				{/*			</Button>*/}
				{/*		</div>*/}
				{/*	</div>*/}
				{/*</Dialog>*/}
			</div>
		)
	}
}
