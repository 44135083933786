import React from 'react'
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import Button from "@material-ui/core/Button";
import {updateSct} from "../SctAction";
import Test from './'
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Icon from "@material-ui/core/Icon";
import Dialog from "@material-ui/core/Dialog";
import {post_wm} from "../../sonodalib/sl_utils/Http";
import Cookies from "js-cookie";
import {altName} from "../../alt/constants";
import {snackbar} from "../../sonodalib/sl_utils/Utils";


class TestList extends React.Component {
	state = {
		openSendConf: false,
	}

	constructor() {
		super();
		this.postDisabled = false
	}

	componentDidMount() {
		const {sctState} = this.props
		const {qno, questions} = sctState
		if (!sctState.testSubjectCode || !questions || qno === null) {
			this.props.history.push("/sct")
		}
	}

	handleAcceptConf = () => {
		if (this.postDisabled) return
		this.postDisabled = true
		this.setState({
			openSendConf: false
		}, () => {
			const {sctState} = this.props
			const {testSubjectCode, answers} = sctState
			post_wm("test/post", {
				user_token:   Cookies.get(altName),
				moshi_token:  Cookies.get("wm_"+altName),
				subject_code: testSubjectCode,
				answers:			answers,
			})
			.then(ret => {
				if (ret.error) {
					alert(ret.error)
				} else {
					snackbar("送信しました")
					this.props.history.push("/sct")
				}
			})
			.catch(() => {
				// alert("エラーが発生しました")
			})
			.finally(() => {
				this.postDisabled = false
			})
		})
	}

	handleRetry = (index) => () => {
		const {sctState, history} = this.props
		const {questions} = sctState
		const perPageState = Test.createTestPerPageState(questions, index)
		updateSct({
			qno:						 index,
			liner:					 false,
			...perPageState
		})
		history.push("/sct/test/q")
	}

	render() {
		const {sctState} = this.props
		const {questions, choices, answers, orderno_resetindex} = sctState
		if (!questions || !choices) return null
		const colStyle = {width:180, textAlign:'center'}
		const colStyleHeader = {...colStyle, fontSize: 12, fontWeight: 600}
		const vd = questions.map((question, index) => {
			const bgColor = (index & 1) ? "#fefefe" : '#f6f6f6'
			return (
				<div key={`ans${index}`} style={{borderTop:'1px solid #ddd',backgroundColor:bgColor,padding:'6px 20px'}}>
					<Grid container alignItems="center">
						<Grid item style={colStyle}>
							{(orderno_resetindex) ? index+1 : question.orderno}
						</Grid>
						<Grid item style={colStyle}>
							{(answers[index] !== undefined) ? answers[index] : "未回答"}
						</Grid>
						<Grid item style={colStyle}>
							<Button
								size="small"
								variant="text"
								style={{border:'1px solid #ccc',width:80,padding:0}}
								onClick={this.handleRetry(index)}
							>
								<Icon>reply</Icon>
							</Button>
						</Grid>
					</Grid>
				</div>
			)
		})
		return (
			<div style={{
				marginTop: 20,
				marginBottom: 100,
				maxWidth: 800,
				marginLeft: "auto",
				marginRight: "auto",
			}}>
				<div style={{marginLeft: 8, marginRight: 8, fontSize:16}}>
					<div style={{marginBottom:20,paddingTop:30,paddingBottom:30,borderTop:'1px solid #ccc',borderBottom:'1px solid #ccc'}}>
						<span className="wf-roundedmplus1c" style={{fontSize:24,fontWeight:500,color:'#2080E4'}}>
							セルフチェックテスト
						</span>
					</div>
					<div>
						<Box my={4}>
							<span style={{fontSize:20, fontWeight:600, color:'#d37111'}}>■ {sctState.testSubjectName}</span>
						</Box>
					</div>
					<div style={{width:180*3+40,marginTop:40,marginLeft:(800-(180*3+40))/2}}>
						<div key="listhead" style={{padding:'2px 20px'}}>
							<Grid container alignItems="center">
								<Grid item style={colStyleHeader}>
									問題番号
								</Grid>
								<Grid item style={colStyleHeader}>
									あなたの解答
								</Grid>
								<Grid item style={colStyleHeader}>
									問題をもう一度解く
								</Grid>
							</Grid>
						</div>
						<div key="list" style={{marginTop:14,borderBottom:'1px solid #ddd'}}>
							{vd}
						</div>
						<div key="listfooter" style={{textAlign:'center', marginTop:30}}>
							<p>
								<a
									onClick={() => {
										this.setState({
											openSendConf: true
										})
									}}
									style={{cursor:'pointer'}}
								>
									<img src="/imgms/moshi/button_send.png" alt="解答送信"/>
								</a>
							</p>
							{/*<Button*/}
							{/*	variant="text"*/}
							{/*	color="primary"*/}
							{/*	style={{width:300, border:'1px solid #EF6C00'}}*/}
							{/*	onClick={() => {*/}
							{/*		this.setState({*/}
							{/*			openSendConf: true*/}
							{/*		})*/}
							{/*	}}*/}
							{/*>*/}
							{/*	解答送信*/}
							{/*	<Icon style={{marginLeft:10}}>send</Icon>*/}
							{/*</Button>*/}
						</div>
					</div>
				</div>
				<Dialog
					open={this.state.openSendConf}
					onClose={() => {
						this.setState({
							openSendConf: false
						})
					}}
				>
					<div style={{padding: 16}}>
						<div>
							解答送信後は訂正できません。送信してよろしいですか？
						</div>
						<div style={{marginTop:16, textAlign:'center'}}>
							<Button
								variant="contained"
								color="primary"
								onClick={this.handleAcceptConf}
								style={{width: 120}}
							>
								送信
							</Button>
							<Button
								variant="text"
								style={{width: 120, marginLeft: 10, border:'1px solid #888'}}
								onClick={() => {
									this.setState({
										openSendConf: false
									})
								}}
							>
								キャンセル
							</Button>
						</div>
					</div>
				</Dialog>
			</div>
		)
	}
}

export default connect(
	state => ({
		sctState: state.sct,
	})
)(withRouter(TestList));
