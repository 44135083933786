import React from 'react'
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import {post_wm} from "../../sonodalib/sl_utils/Http";
import {altName} from "../../alt/constants";
import Cookies from "js-cookie";
import './tab4print.css'
import Button from '@material-ui/core/Button'
import Box from '@material-ui/core/Box'
import {updatePrintModeAC} from '../../mypage/MyPageAction'

class SchedulePrint extends React.Component {
  state = {
    noSubject: false,
    data:    undefined
  }

  componentDidMount() {
    post_wm("sct/navi/schedule", {
			user_token:  Cookies.get(altName),
			moshi_token: Cookies.get("wm_"+altName),
		})
		.then((ret) => {
      if (ret.err && ret.err === "no-subject") {
        this.setState({
          noSubject: true
        })
      } else {
        this.setState({
          data: ret
        })
      }
		})
  }

	handlePrint = () => {
		this.props.print()
	}

  render() {
    const {data, noSubject} = this.state
    if (!data) return null

    const errorVd = (noSubject) ? (
      <>
        <Box mt={4} p={5} textAlign="center" fontSize={30}>
          <span>科目が選択されていません</span>
        </Box>
        <Box p={4} textAlign="center" fontSize={20}>
          <Button size="large" color="secondary" variant="outlined" onClick={onGoPlanning}>学習プランニングに戻る</Button>
        </Box>
      </>
    ) : null

    const subjectsVd = data.subjects.map((subject,i) => {
      return (
         <tr key={"subj"+i}>
           {(i & 1) ? (
             <>
               <td className="t-1 subj">{subject.name}</td>
               <td className="t-1">{subject.hantei}</td>
               <td className="t-1 t-10">{subject.period1}</td>
               <td className="t-1 t-10">{subject.dcount1}</td>
               <td className="t-1 t-12">{subject.period2}</td>
               <td className="t-1 t-12">{subject.dcount2}</td>
               <td className="t-1 t-10">{subject.period3}</td>
               <td className="t-1 t-10">{subject.dcount3}</td>
             </>
           ) : (
             <>
               <td className="subj">{subject.name}</td>
               <td>{subject.hantei}</td>
               <td className="t-11">{subject.period1}</td>
               <td className="t-11">{subject.dcount1}</td>
               <td className="t-13">{subject.period2}</td>
               <td className="t-13">{subject.dcount2}</td>
               <td className="t-11">{subject.period3}</td>
               <td className="t-11">{subject.dcount3}</td>
             </>
           )}
         </tr>
      )
    })

    return (
      <div id="gschedprint">
        <div style={{marginTop:30}}>
          <table>
            <tbody>
             <tr>
               <td colSpan="2" className="t-0">&nbsp;</td>
               <td colSpan="2" className="t-5">第1クール</td>
               <td colSpan="2" className="t-6">第2クール</td>
               <td colSpan="2" className="t-5">第3クール</td>
             </tr>
             <tr>
               <td className="t-2">科目名</td>
               <td className="t-2">評価</td>
               <td className="t-2">学習期間</td>
               <td className="t-2">日数</td>
               <td className="t-2">学習期間</td>
               <td className="t-2">日数</td>
               <td className="t-2">学習期間</td>
               <td className="t-2">日数</td>
             </tr>
              {subjectsVd}
              {(data.review_period) ? (
               <tr>
                 <td colSpan="2" className="t-8">※ 総復習期間</td>
                 <td colSpan="6" className="t-9">{data.review_period}</td>
               </tr>
                ) : null
              }
            </tbody>
          </table>
        </div>
        <Box p={3} className="endprintbtn" textAlign="center">
          <Button color="primary" onClick={() => {
            this.props.endPrint()
          }} variant="outlined">
            印刷を終了
          </Button>
        </Box>
        {errorVd}
      </div>
    )
  }
}

export default connect(
  state => ({
		printMode: state.mypage.printMode,
  }),
	dispatch => ({
		endPrint: function() {
			dispatch(updatePrintModeAC(false))
		}
	})
)(withRouter(SchedulePrint));