import React, {Component} from 'react'
import {Route, Switch} from 'react-router-dom'
// import {connect} from 'react-redux';
// import Button from '@material-ui/core/Button'
// import Cookies from "js-cookie";
// import Moshi from './'
// import {altName} from "../alt/constants";
// import BackToMoshiTopButton from './parts/BackToMoshiTopButton'
import RegisterPage from './user/RegisterPage'
import LoginPage from './user/LoginPage'


export default class MoshiUserFrame extends Component {

	render() {
		const content = (
			<Switch>
				<Route path="/wmu/signup/:serviceCode" component={RegisterPage}/>
				<Route path="/wmu/signin/:serviceCode" component={LoginPage}/>
			</Switch>
		)
		if (this.props.printMode) {
			return (
				<div>
					{content}
				</div>
			)
		} else {
			return (
				<div style={{margin:10, backgroundColor:'white'}}>
					<div>
						{content}
					</div>
					<footer className="main-footer" style={{marginTop: 100}}>
						<div className="container">
							<strong>Copyright © <a href="http://www.jc-edu.co.jp">JC Educational Institute, Inc.</a>.</strong> All
							rights reserved.
						</div>
					</footer>
				</div>
			)
		}
	}
}
