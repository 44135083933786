import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import Cookies from "js-cookie";
import {SubjectButton, JireiButton} from './CommonParts'
import PropTypes from 'prop-types'
import { LessonToolBar } from './CommonParts'
import Paper from '@material-ui/core/Paper'
import Immutable from 'immutable'
import { updateJireiQuestionsAC } from '../../question/QuestionAction'
import {post} from '../../sonodalib/sl_utils/Http'
import _ from 'lodash'
import {altName} from '../../alt/constants'


class _JireiQuery extends React.Component {
	static propTypes = {
		category: PropTypes.number.isRequired,
		qtype: 		PropTypes.string.isRequired,
		onGoBack: PropTypes.func.isRequired,
	}

	state = {
		nendoList: Immutable.fromJS([]),
	}

	componentWillMount() {
		const self = this
		const payload = {
			user_token: Cookies.get(altName),
			ShikakuID: 2,
		}
		post("q/fetch_jirei_menu", payload)
			.then(ret => {
				this.setState({
					nendoList: Immutable.fromJS(self.buildNendoList(ret.subjects)),
				})
			})
	}

	buildNendoList = subjects => {

		let distinctedNendo = _.reverse(_.sortBy(_.uniqBy(subjects, 'Nendo'), 'Nendo'))

		// お試し（license=="0"）の時は、２番目以外の年度を disabled にする
		const license = (this.props.userState) ? this.props.userState.license : null
		if (license && license === "0") {
			if (distinctedNendo.length > 2) {
				distinctedNendo = [distinctedNendo[1]]
			}
		}
		return _.map(distinctedNendo, nendo => {
			const jireiList = _.map(_.sortBy(_.filter(subjects, {Nendo: nendo.Nendo}), 'JireiID'), subject => {
				return {
					JireiID: subject.JireiID,
					label: subject.Jirei_Title,
				}
			})
			return {
				nendo: nendo.Nendo,
				label: nendo.Shikenkaisu + "（" + nendo.Nendo + "年度実施）",
				active: false,
				jireiList,
			}
		})
	}

	handleNendoToggle = nendo => {
		let nendoList = this.state.nendoList.map(_nendo => {
			const value = _nendo.get("nendo") === nendo.get("nendo")
			return _nendo.set("active", value)
		})
		this.setState({
			nendoList,
		})
	}

	handleJireiClick = jirei => {
		const self = this
		const payload = {
			jirei_id: jirei.get("JireiID")
		}
		post("q/jirei", payload)
			.then(ret => {
				self.props.updateJireiQuestions(ret)
				self.props.history.push("/q/jirei")
			})
	}

	render() {
		if (this.state.nendoList === null) return null

		const styles = {
			wrapBox: {
				width: 720,
				marginRight:"auto",
				marginLeft:"auto",
			},
			innerSubjectBox: {
				padding: 10,
			},
			queryOptionTitle: {
				fontSize: 12,
				fontWeight: 700,
				marginTop: 8,
				marginBottom: 8,
			},
			queryOptionBox: {
				marginTop: 8,
				marginBottom: 8,
			},
		}
		// const { category, qtype } = this.props

		const subjects = this.state.nendoList.map(nendo => {
			const subMenus = (!nendo.get("active")) ? null : (
				<div style={{marginTop: 6}}>
					{
						nendo.get("jireiList").map(jirei => {
							return (
								<JireiButton
									key={jirei.get("JireiID")}
									subject={jirei.get("label")}
									onClick={this.handleJireiClick.bind(this,jirei)}
								/>
							)
						})
					}
				</div>
			)
			return (
				<div key={_.uniqueId()} style={{padding: 4,width: 400,textAlign:'center', marginRight:"auto", marginLeft:"auto"}}>
					<SubjectButton
						subject={nendo.get("label")}
						onClick={this.handleNendoToggle.bind(this,nendo)}
					/>
					{subMenus}
				</div>
			)
		})

		return (
			<div>
				<LessonToolBar
					label="科目の選択"
					onGoBack={this.props.onGoBack}
				/>
				<div>
					{/*<SelectedBox*/}
						{/*leftContent={*/}
							{/*<div>*/}
								{/*選択したカテゴリー<br />*/}
								{/*<CategoryButton*/}
									{/*category={category}*/}
									{/*onClick={this.props.onGoBack.bind(this, category)}*/}
								{/*/>*/}
							{/*</div>*/}
						{/*}*/}
						{/*rightContent={*/}
							{/*<div>*/}
								{/*選択した問題形式<br />*/}
								{/*<QTypeButton*/}
									{/*qtype={qtype}*/}
									{/*onClick={this.props.onGoBack.bind(this, category)}*/}
								{/*/>*/}
							{/*</div>*/}
						{/*}*/}
					{/*/>*/}
					<div style={{marginRight:"auto", marginLeft:"auto", padding: 10}}>
						<Paper elevation={2} style={styles.wrapBox}>
							<div style={styles.innerSubjectBox}>
								{subjects}
							</div>
						</Paper>
					</div>
				</div>
			</div>
		)
	}
}
export default connect(
	state => ({
		queryCondition: state.question.queryCondition,
		userState: state.sl_users.state,
	}),
	dispatch => ({
		updateJireiQuestions: jireiQuestions => {
			dispatch(updateJireiQuestionsAC(jireiQuestions))
		}
	})
)(withRouter(_JireiQuery))
