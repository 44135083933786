import React, {Component} from 'react'
import {Route, Switch, withRouter} from "react-router-dom";
import MoshiMyPageTop from './MoshiMyPageTop'
import TestStart from './test/TestStart'
import TestQuestion from './test/TestQuestion'
import TestList from './test/TestList'
import MarkStart from './mark/MarkStart'
import MarkQuestion from './mark/MarkQuestion'
import ShindanPage from './shindan/ShindanPage'


class MoshiPage extends Component {
	render() {
		return (
			<Switch>
				<Route path="/wm/test/start/:subjectCode" component={TestStart}/>
				<Route path="/wm/test/q" component={TestQuestion}/>
				<Route path="/wm/test/list" component={TestList}/>
				<Route path="/wm/mark/start/:categoryId" component={MarkStart}/>
				<Route path="/wm/mark/q" component={MarkQuestion}/>
				<Route path="/wm/shindan" component={ShindanPage}/>
				<Route component={MoshiMyPageTop}/>
			</Switch>
		)
		// const {moshiState} = this.props
		// if (this.props.printMode) {
		// 	return (
		// 		<div>
		// 			{switcher}
		// 		</div>
		// 	)
		// } else {
		// 	// /wm/my/ 以下の共通の部分
		// 	return (
		// 		<div>
		// 			{switcher}
		// 		</div>
		// 	)
		// }
	}
}
export default withRouter(MoshiPage);
