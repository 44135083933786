import React from 'react'
import { green, orange } from '@material-ui/core/colors'
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Avatar from '@material-ui/core/Avatar';
import Divider from '@material-ui/core/Divider';
import Icon from '@material-ui/core/Icon';
import {isInvaildLicensed} from "../Common";
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";


class BbsTop extends React.Component {
	handleBbsBoard1 = () => {
		this.props.history.push("/my/bbs/board1")
	}

	handleBbsBoard = boardId => {
		this.props.history.push("/my/bbs/thread/" + boardId)
	}

	handleToiawase = () => {
		this.props.history.push("/my/bbs/contact")
	}

	render() {
		const license = (this.props.userState) ? this.props.userState.license : null
		if (!license) return null

		if (isInvaildLicensed(license)) {
			return (
				<Grid container direction="column" justify="center" alignItems="center" style={{marginTop:100}}>
					<Grid item style={{height:100}}>
						<span style={{fontSize:20,fontWeight:700}}>利用制限がかかっております。詳しくはお問い合わせ下さい。</span>
					</Grid>
					<Grid item style={{height:100}}>
						<Button variant="contained" color="secondary" onClick={this.handleToiawase}>
							お問い合わせ
						</Button>
					</Grid>
				</Grid>
			)
		}

		return (
			<div>
				<div style={{marginTop: 50}}>
					<h2>合格サポート掲示板</h2>
				</div>
				<div>
					<List>
						<Divider variant="inset" />
						<ListItem button onClick={this.handleBbsBoard1.bind(this)}>
							<Avatar style={{backgroundColor: orange[700], margin:20}}>
								<Icon>comment</Icon>
							</Avatar>
							<ListItemText primary="学習掲示板１" secondary="過去問トレーニング内の問題・解答解説についての質問・意見を扱う掲示板です。問題の解答結果をまとめて見る画面から、問題ごとに書き込めます。" />
						</ListItem>
						<Divider variant="inset" />
						<ListItem button onClick={this.handleBbsBoard.bind(this, 2)}>
							<Avatar style={{backgroundColor: orange[400], margin:20}}>
								<Icon>comment</Icon>
							</Avatar>
							<ListItemText primary="学習掲示板２" secondary="過去問トレーニング内の問題に限定せず、試験科目の内容全般についての質問・意見を扱う掲示板です。具体的なスレッド名をつけて書き込みを始めて下さい。" />
						</ListItem>
						<Divider variant="inset" />
						<ListItem button onClick={this.handleBbsBoard.bind(this, 3)}>
							<Avatar style={{backgroundColor: green[600], margin:20}}>
								<Icon>comment</Icon>
							</Avatar>
							<ListItemText primary="交流掲示板" secondary="試験対策法・勉強法などについて、赤マル福祉会員どうしで情報交換をおこない、モチベーションと学習効果を高めるための掲示板です。" />
						</ListItem>
						<Divider variant="inset" />
						<ListItem button onClick={this.handleBbsBoard.bind(this, 4)}>
							<Avatar style={{backgroundColor: 'red', margin:20}}>
								<Icon>comment</Icon>
							</Avatar>
							<ListItemText primary="よくある質問" secondary="掲示板への投稿やお問合せで、皆さんからよく質問される事項をピックアップしてまとめました。まず確認してから質問をして下さい。" />
						</ListItem>
						<Divider variant="inset" />
					</List>
				</div>
			</div>
		)
	}
}

export default connect(
	state => ({
		userState: state.sl_users.state,
	}),
)(withRouter(BbsTop));

