import React from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import {common, red, pink, deepPurple} from '@material-ui/core/colors';
import Icon from '@material-ui/core/Icon'
import {CSSTransition} from 'react-transition-group';
import IconButton from '@material-ui/core/IconButton';
import '../sonodalib/sl_utils/utils.css'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import {updateOxQuestionAC} from './QuestionAction'
import {post} from '../sonodalib/sl_utils/Http'
import {CommentaryBox} from './CommonParts'
import FkToolbar from '../parts/FkToolbar'
import {SubjectBox, QuestionBox} from '../Common'
import MemoIconButton from '../parts/MemoIconButton'
import {withMemoDrawer} from '../parts/MemoDrawer'
import {withBbsDrawer} from '../parts/BbsDrawer'
import {TestModeLastTime, TestModeDialog} from "./TestMode";
import './OxQuestion.css'
import FloatingIcon from '../parts/FloatingIcon'
import ForwardIcon from '@material-ui/icons/Forward'
import {login} from "../sonodalib/sl_users/SlUsersAction";
import Cookies from "js-cookie";
import {altName, urlPref} from '../alt/constants'


class OxQuestion extends React.Component {
	static propTypes = {
		onEnd: PropTypes.func.isRequired,
	}
	state = {
		timer: "",
		timeUp: false,
	}
	mondaiId = null
	marubatsuId = null

	componentWillMount() {
		this.updateMondai(this.props)
		this.interval = null
		if (this.isTest()) {
			this.interval = setInterval(this.tick.bind(this), 500)
		}
	}

	componentWillUnmount() {
		if (this.interval) {
			clearInterval(this.interval)
		}
	}

	componentWillReceiveProps(props) {
		return this.updateMondai(props)
	}

	isTest() {
		return (this.props.queryCondition && this.props.queryCondition.query_mode === "test")
	}

	tick() {
		if (!this.state.timeUp) {
			const {startedTime, totalTime} = this.props.oxQuestions
			const lastSec = Math.max(0, totalTime - (new Date() - startedTime) / 1000)
			const min = Math.floor(lastSec / 60)
			let sec = Math.floor(lastSec % 60)
			if (sec < 10) sec = "0" + sec
			const label = min + ":" + sec
			const timeUp = (lastSec <= 0)
			this.setState({
				timer: label,
				timeUp,
			})
		}
	}

	handleTimeUpEnd = () => {
		this.props.onEnd(5)
	}

	updateMondai(props) {
		if (this.mondaiId !== props.mondaiId || this.marubatsuId !== props.marubatsuId) {
			this.mondaiId = props.mondaiId
			this.marubatsuId = props.marubatsuId
			if (!this.isTest()) {
				props.memoDrawer.load({
					mondaiId: this.mondaiId,
					marubatsuId: this.marubatsuId,
				})
			}
		}
		return true
	}

	handleNext = result => {
		this.props.onEnd(
			result,			// 1=正解  2=不正解  3=未解答　4=中断
		)
	}

	postResult = (result, answer) => {
		const question = this.props.oxQuestion
		const payload = {
			user_token: Cookies.get(altName),
			mondai_id: question.MondaiID,
			marubatsu_id: question.MarubatsuID,
			answer,
			result,
			is_test: this.isTest(),
		}
		post("result/ox", payload, false)
		.then((ret) => {
			login(ret.jwt)
		}).catch(() => {
			// logout()
		})
	}

	handleAnswer = answer => {			// 1=O  2=X
		if (this.props.oxQuestion.showResult) return
		const showResult = (answer === this.props.oxQuestion.correctAnswer) ? 1 : 2
		this.postResult(showResult, answer)

		if (this.isTest()) {
			this.handleNext(showResult)
		} else {
			this.props.updateOxQuestion({
				showResult,
				answer,
			})
		}
	}

	render() {
		if (this.props.oxQuestion === null) return null

		const styles = {
			nextButton: {
				position: "fixed",
				bottom: "30%",
				right: 35,
				cursor: "pointer",
				zIndex: 999,
				color: common.white,
				backgroundColor: pink[700],
			},
			headingCorrect: {
				fontSize: 42,
				fontWeight: 700,
				color: pink[700],
			},
			headingIncorrect: {
				fontSize: 24,
				fontWeight: 700,
				color: deepPurple[700],
			},
			commandBox: {
				margin: 8,
				marginBottom: 18,
			},
			commandButton: {
				width: 200,
				marginRight: 10,
			},
			correctBorder: {
				borderStyle: 'solid',
				borderWidth: 3,
				borderColor: pink[500],
			},
			nullResultBorder: {
				borderStyle: 'solid',
				borderWidth: 3,
				borderColor: common.white,
			},
		}
		const oxQuestion = this.props.oxQuestion
		const {qIndex, showResult, answer, correctAnswer, commentaryText} = oxQuestion

		let toolbarIcons = null
		if (!this.isTest()) {
			toolbarIcons = (
				<div>
					<MemoIconButton
						handleOpenMemo={() => {
							this.props.memoDrawer.open(null)
						}}
						isMemoActive={this.props.memoDrawerStatus}
					/>
					<IconButton
						style={{color: 'white'}}
						aria-label="掲示板"
						onClick={() => {
							this.props.bbsDrawer.openToCreateThread(
								1,
								undefined,
								this.mondaiId,
								this.marubatsuId,
								"",
							)
						}}
					>
						<Icon>comment</Icon>
					</IconButton>
				</div>
			)
		}

		const nextButtonVd = (!showResult) ? null : (
			<FloatingIcon
				label="次"
				foreColor={common.white}
				backgroundColor={pink[400]}
				bottom={40}
				onClick={this.handleNext.bind(this, showResult)}
			>
				<ForwardIcon />
			</FloatingIcon>
		)

		const correctLabel = [null, "正解", "不正解"]
		const correctImage = [null, "seikai", "fuseikai"]
		const resultVd = (!showResult) ? null : (
			<Grid item>
				<Grid container alignItems="center" justify="center">
					<Grid item>
						<span style={styles.headingIncorrect}>{correctLabel[showResult]}</span>
						<img src={`${urlPref}/images/eval/${correctImage[showResult]}.png`} alt="result"/>
					</Grid>
				</Grid>
			</Grid>
		)

		const commandBox = (showResult) ? null : (
			<div style={styles.commandBox}>
				<Button variant="contained"
								onClick={this.handleNext.bind(this, 3)}
								style={{...styles.commandButton, color: common.white, backgroundColor: "#2196f3"}}
				>
					解答しない
				</Button>
				<Button variant="contained"
								onClick={this.handleNext.bind(this, 4)}
								style={{...styles.commandButton, color: common.white, backgroundColor: "#2196f3"}}
				>
					学習を中断
				</Button>
			</div>
		)

		const labels = [0, '', '']
		// const borders = [0, styles.nullResultBorder, styles.nullResultBorder]
		if (showResult) {
			// borders[correctAnswer] = styles.correctBorder
			if (showResult === 2) labels[correctAnswer] = '正解は'
		}
		let boxClasses = [0, "defaultBorder", "defaultBorder"]
		if (showResult) {
			boxClasses[(-answer) + 3] = "nullBorder"
			boxClasses[answer] = "fixedDefaultBorder"
		}

		const colors = [0, common.black, common.black]
		if (showResult) {
			colors[answer] = red[900]
		}

		const commentary = (!showResult) ? null : (
			<CommentaryBox
				text={commentaryText}
			/>
		)

		return (
			<div>
				<CSSTransition
					classNames="fade"
					appear={true}
					timeout={500}
				>
					<div>
						{nextButtonVd}
						<TestModeLastTime timer={this.state.timer}/>
					</div>
				</CSSTransition>

				<FkToolbar title={`◯✕問題 第${qIndex}問 ／ 全${this.props.oxQuestions.questions.length}問`}>
					{toolbarIcons}
				</FkToolbar>

				<SubjectBox label={oxQuestion.subjectLabel + "　" + oxQuestion.subLabel}/>
				<QuestionBox text={oxQuestion.qText}/>

				<div style={{maxWidth: 800,margin:'auto'}}>
					<div style={{marginRight: 80}}>
						<OxChoice
							correctLabel={correctLabel}
							labels={labels}
							// borders={borders}
							boxClasses={boxClasses}
							colors={colors}
							onAnswer={this.handleAnswer.bind(this)}
						>
							{resultVd}
						</OxChoice>
						{commandBox}
						{commentary}
					</div>
				</div>
				<TestModeDialog open={this.state.timeUp} onClick={this.handleTimeUpEnd}/>
			</div>
		)
	}
}

export default connect(
	state => ({
		oxQuestion: state.question.oxQuestion,
		oxQuestions: state.question.oxQuestions,
		queryCondition: state.question.queryCondition,
	}),
	dispatch => ({
		updateOxQuestion: entity => {
			dispatch(updateOxQuestionAC(entity))
		}
	})
)(withMemoDrawer(withBbsDrawer(OxQuestion)))


export class OxChoice extends React.Component {
	static propTypes = {
		children: PropTypes.any,
		labels: PropTypes.array.isRequired,
		boxClasses: PropTypes.array.isRequired,
		colors: PropTypes.array.isRequired,
		onAnswer: PropTypes.func,
	}

	handleAnswer = answer => {			// value: 1=O  2=X
		if (this.props.onAnswer) {
			this.props.onAnswer(answer)
		}
	}

	render() {
		const styles = {
			correctLabel: {
				marginTop: 8,
				fontSize: 16,
				color: pink[700],
				// backgroundColor: pink400,
				fontWeight: 600,
				textAlign: 'left',
				height: 26,
				padding: 0,
				margin: 0,
				lineHeight: "26px",
			},
		}
		const {children, labels, boxClasses, colors} = this.props
		const cursor = (this.props.onAnswer) ? 'pointer' : 'default'
		return (
			<Grid container spacing={2} alignItems="center" justify="flex-end">
				{children}
				<Grid item>
					<div style={styles.correctLabel}>{labels[1]}</div>
					<div className={boxClasses[1]}>
						<IconButton style={{top: 3, left: 3, width:58, height:58, cursor}} onClick={this.handleAnswer.bind(this, 1)}>
							<Icon style={{fontSize: 58, color: colors[1]}}>radio_button_unchecked</Icon>
						</IconButton>
					</div>
				</Grid>
				<Grid item>
					<div style={styles.correctLabel}>{labels[2]}</div>
					<div className={boxClasses[2]}>
						<IconButton style={{left: 0, width: 60,height:60, cursor}} onClick={this.handleAnswer.bind(this, 2)}>
							<Icon style={{fontSize: 64, color: colors[2]}}>close</Icon>
						</IconButton>
					</div>
				</Grid>
			</Grid>
		)
	}
}
