import React from 'react'
import PropTypes from 'prop-types'
import ClassNames from 'classnames'


export class OkCancelModal extends React.Component {
	static propTypes = {
		show: PropTypes.bool.isRequired,
		bodyText: PropTypes.string,
		okText: PropTypes.string,
		cancelText: PropTypes.string,
		color: PropTypes.string,
		onOk: PropTypes.func.isRequired,
		onCancel: PropTypes.func,
	}

	render() {
		const styles = {
			modal: {
				zIndex: 999999,
				display: 'block'
			}
		}

		const { show, bodyText, okText, cancelText, color, onOk, onCancel } = this.props
		if (!show) {
			return null
		}
		const _bodyText = bodyText || ""
		const _okText = okText || "OK"
		const _cancelText = cancelText || "CANCEL"
		const _color = color || "default"
		const modalClass = ClassNames({
			'modal': true,
			[`modal-${_color}`]: true,
		})
		let okBtnClass = "btn"
		let cancelBtnClass = "btn pull-left"
		if (_color === "default") {
			okBtnClass += " btn-primary"
			cancelBtnClass += " btn-default"
		} else {
			okBtnClass += " btn-outline"
			cancelBtnClass += " btn-outline"
		}
		const cancelButton = (!onCancel) ? null : (
			<button type="button" className={cancelBtnClass} onClick={onCancel}>{_cancelText}</button>
		)

		return (
			<div className={modalClass} style={styles.modal}>
				<div className="modal-dialog">
					<div className="modal-content">
						<div className="modal-body">
							<p>{_bodyText}</p>
						</div>
						<div className="modal-footer">
							{cancelButton}
							<button type="button" className={okBtnClass} onClick={onOk}>{_okText}</button>
						</div>
					</div>
				</div>
			</div>			
		)
	}
}

