import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import OxQuestion from './OxQuestion'
import { updateOxQuestionAC, updateOxResultsAC } from './QuestionAction'
import _ from 'lodash'
import {urlPref} from '../alt/constants'
import {gakushuFirstUrl} from '../alt/constants'


class OxSeries extends React.Component {
	state = {
		mondaiId: null,
		marubatsuId: null,
	}

	componentWillMount() {
		this.qIndex = 0
		this.results = []
		if (this.props.oxQuestions === null) {
			window.location.href = urlPref+"/my"
			return
		}
		this.updateOxQuestion(this.props.oxQuestions)
	}

	// 問題のシリーズから、今回の問題を抽出して、今回の問題をrenderするための store.question.oxQuestion を更新する
	updateOxQuestion = (oxQuestions) => {
		const question = oxQuestions.questions[this.qIndex]
		question.selectHistory = []
		question.showResult = 0
		_.forEach(question.choices, choice => {
			choice.selected = false
			choice.showCommentary = false
		})
		question.qIndex = this.qIndex + 1
		this.props.updateOxQuestion(question)
		this.setState({
			mondaiId: question.MondaiID,
			marubatsuId: question.MarubatsuID,
		})
	}

	// FP300からの戻り
	// result: 1=正解  2=不正解  3=未解答　4=中断
	onEndQuestion = result => {
		if (result === 5) {																															// 時間切れ
			while (this.qIndex < this.props.oxQuestions.questions.length) {
				this.results[this.qIndex++] = 3
				this.props.updateOxResults(this.results)
				this.props.history.replace("/q/ox/result")
			}
		} else if (result === 4) {																											// 中断
			if (this.qIndex === 0) {
				this.props.history.replace(gakushuFirstUrl)																		// 1問目で中断
			} else {
				this.props.updateOxResults(this.results)																		// 2問目以降で中断
				this.props.history.replace("/q/ox/result")
			}
		} else {
			this.results[this.qIndex++] = result 																					// 正解・不正解・未解答
			if (this.qIndex < this.props.oxQuestions.questions.length) {
				this.updateOxQuestion(this.props.oxQuestions)
			} else {
				this.props.updateOxResults(this.results)
				this.props.history.replace("/q/ox/result")
			}
		}
	}

	render() {
		return (
			<OxQuestion
				mondaiId={this.state.mondaiId}
				marubatsuId={this.state.marubatsuId}
				onEnd={this.onEndQuestion.bind(this)}
			/>
		)
	}
}

export default connect(
	state => ({
		oxQuestions: state.question.oxQuestions,
	}),
	dispatch => ({
		updateOxResults: entity => {
			dispatch(updateOxResultsAC(entity))
		},
		updateOxQuestion: entity => {
			dispatch(updateOxQuestionAC(entity))
		},
	})
)(withRouter(OxSeries))