// @flow
import React from 'react'
import {withRouter} from 'react-router-dom'
import {connect} from 'react-redux'
import {SentakuResultListItem} from './CommonParts'
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import _ from 'lodash'
import List from '@material-ui/core/List'
import {SentakuChoiceResult} from './CommonParts'
import FkToolbar from '../parts/FkToolbar'
import {withMemoDrawer} from '../parts/MemoDrawer'
import {withBbsDrawer} from '../parts/BbsDrawer'
import MemoIconButton from '../parts/MemoIconButton'
import {updatePrintModeAC} from "../mypage/MyPageAction";
import G from "../sonodalib/SlGlobal";
import {common} from "@material-ui/core/colors/index";
import SentakuDetailPrint from "./SentakuDetailPrint";
import {urlPref} from '../alt/constants'


type Props = {
	memoDrawer: any,
	memoDrawerStatus: any,
	bbsDrawer: any,
	history: any,
	match: any,
	sentakuQuestions: any,
	sentakuResults: any,
}
type State = {
}

class SentakuDetail extends React.Component<Props, State> {
	state = {}

	componentWillMount() {
		if (!this.props.sentakuQuestions) {
			window.location.href = urlPref+"/my"
			return
		}
		const question = this.getQuestion()
		this.props.memoDrawer.load({
			mondaiId: question.MondaiID,
			marubatsuId: 0,
		})
	}

	handleGoBack = () => {
		this.props.history.goBack()
	}

	getQuestion = () => {
		const {questions} = this.props.sentakuQuestions
		const index = parseInt(this.props.match.params.index, 10)
		return questions[index]
	}

	handlePrint = () => {
		G.store.dispatch(updatePrintModeAC(true))
		window.setTimeout(() => {
			window.print()
		}, 1500)
	}

	handleClosePrintPopout = () => {
		G.store.dispatch(updatePrintModeAC(false))
	}

	render() {
		const styles = {
			alert: {
				background: common.black,
				padding: 10,
			},
			alertP: {
				color: common.white,
				fontWeight: 'bold',
				margin: 0,
				padding: 0,
				fontSize: 14,
			},
			pullRight: {
				textAlign: 'right',
			},
			question: {
				border: '1px gray solid',
				padding: 8,
				margin: 8,
			},
			otherChoices: {
				marginTop: 10,
				marginBottom: 4,
			},
		}
		if (!this.props.sentakuQuestions) return null
		const index = parseInt(this.props.match.params.index, 10)
		const question = this.getQuestion()
		let choices = _.filter(question.choices, {correct: true, selected: true})
		choices = choices.concat(_.filter(question.choices, {correct: true, selected: false}))
		choices = choices.concat(_.filter(question.choices, {correct: false, selected: true}))
		const list1 = _.map(choices, choice => {
			choice.showCommentary = true
			return (
				<SentakuChoiceResult
					key={_.uniqueId("fk")}
					choice={choice}
					showResult={1}
				/>
			)
		})
		choices = _.filter(question.choices, {correct: false, selected: false})
		const list2 = _.map(choices, choice => {
			choice.showCommentary = true
			return (
				<div key={_.uniqueId("fk")} style={{marginBottom: 14}}>
					<SentakuChoiceResult
						choice={choice}
						showResult={1}
					/>
				</div>
			)
		})

		if (this.props.printMode) {
			return (
				<SentakuDetailPrint
					index={index}
					result={this.props.sentakuResults[index]}
					question={question}
					onClose={this.handleClosePrintPopout}
				/>
			)
		}
		return (
			<div>
				<FkToolbar title="学習結果　詳細" onGoBack={this.handleGoBack.bind(this)}>
					<IconButton style={{color: 'white'}} aria-label="印刷" onClick={this.handlePrint}>
						<Icon>printer</Icon>
					</IconButton>
					<MemoIconButton
						handleOpenMemo={() => {
							this.props.memoDrawer.open(null)
						}}
						isMemoActive={this.props.memoDrawerStatus}
					/>
					<IconButton
						style={{color: 'white'}}
						aria-label="掲示板"
						onClick={() => {
							this.props.bbsDrawer.openToCreateThread(
								1,
								undefined,
								question.MondaiID,
								0,
								"",
							)
						}}
					>
						<Icon>comment</Icon>
					</IconButton>
				</FkToolbar>
				<div style={{maxWidth: 800, marginLeft: 'auto', marginRight: 'auto'}}>
					<div>
						<div>
							<List>
								<SentakuResultListItem
									index={index}
									question={question}
								/>
							</List>
							<div style={styles.question}>
								{question.qText}
							</div>
						</div>
						<List style={{marginLeft: 12, marginRight: 12}}>
							{list1}
						</List>
						<div style={styles.otherChoices}>
							その他の選択肢
						</div>
						<List style={{marginLeft: 12, marginRight: 12}}>
							{list2}
						</List>
					</div>
				</div>
			</div>
		)
	}
}

export default connect(
	state => ({
		sentakuQuestions: state.question.sentakuQuestions,
		sentakuResults: state.question.sentakuResults,
		printMode: state.mypage.printMode,
	}),
	dispatch => ({})
)(withRouter(withMemoDrawer(withBbsDrawer(SentakuDetail))))
