import React, {Component} from 'react'
import {connect} from "react-redux";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import {withRouter} from "react-router-dom";
import Cookies from "js-cookie";
import {altName} from "../../alt/constants";
import {post_wm} from "../../sonodalib/sl_utils/Http";
import Box from "@material-ui/core/Box";
// import Button from "@material-ui/core/Button";
import Icon from "@material-ui/core/Icon";
import CategoryTab from './CategoryTab'
import Planning from './Planning'
import Schedule from './Schedule'
import SchedulePrint from './SchedulePrint'
import CategoryPrint from './CategoryPrint'


class NaviPage extends Component {
	state = {
		tabValue: 0,
    data: undefined,
	}

	componentDidMount() {
		this.fetch()
	}

	fetch = () => {
		post_wm("sct/navi/fetchall", {
			user_token:  Cookies.get(altName),
			moshi_token: Cookies.get("wm_"+altName),
		})
		.then((ret) => {
			this.setState({
				data: ret.data
			})
		})
	}

	fetchUpdatePlan = (onEnd) => {
		const {data} = this.state
		post_wm("sct/navi/update_plan", {
			user_token:  Cookies.get(altName),
			moshi_token: Cookies.get("wm_"+altName),
			plan:				 data.plan,
		})
		.then(ret => {
			if (!ret.err) {
				onEnd()
			}
		})
	}

	handleChangeTab = (event, value) => {
		const {data} = this.state
		switch (value) {
			case data.categories.length:
				this.handleGoPlanning()
				break;
			case data.categories.length + 1:
				this.handleGoSchedule()
				break;
			default:
				this.setState({tabValue: value})
		}
	}

	handleDownloadMondai = (category) => () => {
		const {sctState} = this.props
		window.open(`/files/${sctState.serviceCode}/Q_cat${category}.pdf`)
	}

	handleDownloadKaisetsu = (category) => () => {
		const {sctState} = this.props
		window.open(`/files/${sctState.serviceCode}/A_cat${category}.pdf`)
	}

	// ==== 学習プランニング ====
	handleChangeStartDate = (event) => {
		const {data} = this.state
		data.plan.start_date = event.currentTarget.value
		this.setState({data})
	}
	handleChangeKur = (event) => {
		const {data} = this.state
		data.plan.kur = parseInt(event.currentTarget.value,10)
		this.setState({data})
	}
	handleChangeReview = (event) => {
		const {data} = this.state
		data.plan.review = parseInt(event.currentTarget.value,10)
		this.setState({data})
	}
	handleChangePrio = (event) => {
		const {data} = this.state
		data.plan.prio = parseInt(event.currentTarget.value,10)
		this.setState({data})
	}
	handleChangeSubject = (event) => {
		const {data} = this.state
		const subjectCode = event.target.name
		const s = data.plan.subjects.trim()
		let subjects = []
		if (s.length) subjects = s.split(",")
		if (subjects.indexOf(subjectCode) === -1) {
			subjects.push(subjectCode)
		} else {
			subjects = subjects.filter(subject => (subject !== subjectCode))
		}
		data.plan.subjects = subjects.join(",")
		this.setState({data})
	}
	handleGoSchedule = () => {													// 学習スケジュールボタン押下
		const {data} = this.state
		this.fetchUpdatePlan(() => {
			this.setState({
				tabValue: data.categories.length + 1
			})
		})
	}
	// ==== /学習プランニング ====

	handleGoPlanning = () => {
		const {data} = this.state
		this.setState({
			tabValue: data.categories.length
		})
	}

	render() {
		if (this.props.printMode) { return this.renderPrintMode() }

		const styles = {
			tab: {
				// color: 			'#222',
				fontSize: 	16,
				fontWeight: 500,
				// backgroundColor: '#dbdbdb',
        marginLeft: 8,
			},
      tabSelected: {
				// color: 			'black',
				fontSize: 	16,
				fontWeight: 700,
				// backgroundColor: 'white',
        // borderLeft: '2px solid grey',
        // borderRight: '2px solid grey',
        // borderTop: '2px solid grey',
        // borderBottom: '2px solid white',
        marginLeft: 8,
      },
			tabPlanning: {
				// color: 			'black',
				fontSize: 	16,
				fontWeight: 500,
				// backgroundColor: '#dbdbdb',
        marginLeft: 8,
			},
			tabSchedule: {
				// color: 			'black',
				fontSize: 	16,
				fontWeight: 500,
				// backgroundColor: '#dbdbdb',
        marginLeft: 8,
			},
		}

    const {data, tabValue} = this.state
    if (!data) return <></>

		const tabsVd = data.categories.map((category, index) => (
			<Tab
				key={"tab"+index}
				style={(tabValue === index) ? styles.tabSelected : styles.tab}
				label={<span><Icon style={{position:'relative',top:6}}>chevron_right</Icon>{category.label}</span>}
				value={index}
			/>
		))
		tabsVd.push(
			<Tab
				key={"tabplan"}
				style={(tabValue === tabsVd.length) ? styles.tabSelected : styles.tabPlanning}
				label={<span><Icon style={{position:'relative',top:6}}>content_paste</Icon>学習プランニング</span>}
				value={tabsVd.length}
			/>
		)
		tabsVd.push(
			<Tab
				key={"tabschedule"}
				style={(tabValue === tabsVd.length) ? styles.tabSelected : styles.tabSchedule}
				label={<span><Icon style={{position:'relative',top:6}}>content_paste</Icon>スケジュール</span>}
				value={tabsVd.length}
			/>
		)

		let contentVd = null
		if (tabValue < data.categories.length) {
			contentVd = <CategoryTab data={data.categories[tabValue]} onDlMondai={this.handleDownloadMondai(tabValue)} onDlKaisetsu={this.handleDownloadKaisetsu(tabValue)}/>
		} else {
			if (tabValue === data.categories.length) {
				contentVd  = <Planning data={data}
															 categories={data.categories}
															 onChangeStartDate={this.handleChangeStartDate}
															 onChangeReview={this.handleChangeReview}
															 onChangeKur={this.handleChangeKur}
															 onChangePrio={this.handleChangePrio}
															 onChangeSubject={this.handleChangeSubject}
															 onGoSchedule={this.handleGoSchedule}
										 />
			} else {
				contentVd  = <Schedule onGoPlanning={this.handleGoPlanning}/>
			}
		}
		//data.categories[tabValue]
		// const contentVd = (tabValue === 0) ? <CategoryTab/> :
		// 									(tabValue === 1) ? <CategoryTab/> :

    return (
			<div style={{
				marginTop: 20,
				marginBottom: 100,
				maxWidth: 960,
				marginLeft: "auto",
				marginRight: "auto",
			}}>
        <Box my={2} fontSize={20} fontWeight={400}>
          <Box py={2}>
						<span className="wf-roundedmplus1c" style={{color:'#2080E4'}}>
							学習ナビ
						</span>
          </Box>
          <Box py={2} style={{borderTop:'1px solid #ccc'}}>
						<span className="wf-roundedmplus1c" style={{color:'#e4208f'}}>
							セルフチェックテスト結果
						</span>
          </Box>
        </Box>
        <Box>
					<Tabs
						centered
						onChange={this.handleChangeTab}
						value={tabValue}
						// style={{height:48}}
					>
						{tabsVd}
					</Tabs>

          <Box py={2}>
						{contentVd}
          </Box>
        </Box>
  		</div>
    )
  }

	renderPrintMode() {
    const {data} = this.state
    if (!data) return <></>


    return (
			<div style={{
				width: 1100,
				// pageBreakAfter: 'always',
			}}>
				<Box style={{backgroundColor:'#eee'}} height={50} lineHeight="50px" mb={2}>
					<span className="wf-roundedmplus1c" style={{color:'black', fontSize:26, paddingLeft:30}}>
						セルフチェックシート
					</span>
				</Box>
				<Box display="flex" flexWrap="wrap">
					{data.categories.map((category, index) => {
						return (
							<Box width={350} key={"cat"+index}>
								<CategoryPrint data={category}/>
							</Box>
						)
					})}
				</Box>
				<div style={{breakAfter: 'page'}}>
				</div>
        <Box>
					<SchedulePrint/>
        </Box>
  		</div>
    )
  }

}
export default connect(
	state => ({
		sctState: state.sct,
		printMode: state.mypage.printMode,
	}),
)(withRouter(NaviPage));