import React, {Component} from 'react'
// import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import Cookies from "js-cookie";
import {altName} from "../alt/constants";
import {post_wm} from "../sonodalib/sl_utils/Http";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import {cyan} from "@material-ui/core/colors";
// import Icon from "@material-ui/core/Icon";


const styles = {
	selfLi: {
		listStyle: 'none',
		paddingLeft: 28,
		marginBottom: 7,
		fontSize: 'medium',
		textAlign:'justify',
		background:'url(/imgms/sct/title_icon2.png) no-repeat left 4px',
		lineHeight:'1.6',
	},
}


class SctMyPageTop extends Component {
	state = {
		mypage: null,
	}

	componentDidMount() {
		this.fetch()
	}

	fetch = () => {
		post_wm("my/fetch", {
			user_token:  Cookies.get(altName),
			moshi_token: Cookies.get("wm_"+altName),
		})
		.then((ret) => {
			this.setState({
				mypage: ret.mypage
			})
		})
	}

	handleOpenNavi = () => {
		this.props.history.push("/sct/navi")
	}

	handleStartTest = (subject) => () => {
		this.props.history.push("/sct/test/start/"+subject.subject_code)
	}

	render() {
		// const {sctState} = this.props
		const {mypage} = this.state
		if (!mypage) return null

			const vd = Object.keys(mypage.categories).map((key) => {
			const category = mypage.categories[key]
			const subjectVd = category.subjects.map((subject, index) => {
				const bgColor = (index & 1) ? "#fefefe" : '#f6f6f6'
				return (
					<div key={`sr${key}${index}`} style={{borderTop:'1px solid #ddd',backgroundColor:bgColor,padding:'6px 20px'}}>
						<Grid container alignItems="center">
							<Grid item style={{width:550-40+100, fontSize:16}}>
								{subject.name}
							</Grid>
							<Grid item style={{width:130, fontSize:16, textAlign:'center'}}>
								{subject.haiten}
							</Grid>
							<Grid item style={{width:180,textAlign:'center'}}>
								{(!subject.submitted) ? (
									<Button
										variant="text"
										size="medium"
										style={{color:'white',backgroundColor:cyan[500], width:180, height:32}}
										onClick={this.handleStartTest(subject)}
									>
										受験する
									</Button>
								) : (
									<span style={{fontSize:16}}>{subject.submitted}</span>
								)}
							</Grid>
						</Grid>
					</div>
				)
			})
			return (
				<div key={`cat${key}`} style={{marginBottom:60}}>
					<Grid container alignItems="center">
						<Grid item style={{width:286,borderLeft:'7px solid #e3297d',padding:'.3em .8em',fontSize:16,color:'#666'}}>
							{category.label}
						</Grid>
					</Grid>
					<div style={{padding:'6px 0px', marginTop:12}}>
						<div style={{backgroundColor:cyan[700],padding:'0px 20px'}}>
							<Grid container alignItems="center" style={{color:'white', paddingTop:4, paddingBottom:4, marginBottom:2}}>
								<Grid item style={{width:550-40+100, fontSize:16, textAlign:'center'}}>
									科目名
								</Grid>
								<Grid item style={{width:130, fontSize:16, textAlign:'center'}}>
									配点
								</Grid>
								<Grid item style={{width:180,fontSize:16, textAlign:'center'}}>
									解答送信日時
								</Grid>
							</Grid>
						</div>
					</div>
					<div style={{borderBottom:'1px solid #ddd'}}>
						{subjectVd}
					</div>
				</div>
			)
		})

		return (
			<div style={{
				marginTop: 20,
				marginBottom: 100,
				maxWidth: 960,
				marginLeft: "auto",
				marginRight: "auto",
			}}>
				<div style={{marginLeft:8, marginRight:8, marginBottom:38}}>
					<div style={{marginBottom:20,lineHeight:1.4,textAlign:'center',borderTop:'1px solid #ccc',borderBottom:'1px solid #ccc',
						backgroundImage:'linear-gradient(0deg, #738BC3 50%, #3D4581  50%)',
						backgroundSize: '100% 6px'
					}}>
						<span className="wf-roundedmplus1c" style={{fontSize:40,fontWeight:600,color:'white'}}>
							セルフチェックテスト & 学習ナビ
						</span>
					</div>
				</div>
				<div>
					<div style={{width:838,marginLeft: "auto",marginRight: "auto"}}>
						<img src="/imgms/sct/sct_mypage_title1.png" alt="セルフチェックテスト"/>
						<ul style={{padding:'10px 20px 10px 20px', marginBottom:25,background:'url(/imgms/sct/mypage_bg1.png) no-repeat bottom center'}}>
							<li style={styles.selfLi}>主に、合格サポートを利用して学習をスタートする際、あるいは初期の学習を進めながら行う確認テストとして、科目ごとに受験をしていただくことができるテストです。</li>
							<li style={styles.selfLi}>テスト内容は、各科目の基本事項の理解度を測るもので、4択形式で、10から20問出題されます。</li>
							<li style={styles.selfLi}>テスト時間の目安は提示しますが、時間制限はありません。</li>
							<li style={styles.selfLi}>受験後、「解答送信」を行ったらやり直しはできません。</li>
						</ul>
						<img src="/imgms/sct/sct_mypage_title2.png" alt="学習ナビ"/>
						<ul style={{padding:'10px 20px 10px 20px', marginBottom:25,background:'url(/imgms/sct/mypage_bg2.png) no-repeat bottom center'}}>
							<li style={styles.selfLi}>セルフチェックテストの結果、評価を見ることができます。</li>
							<li style={styles.selfLi}>セルフチェックテストの問題と解答解説を確認できます。</li>
							<li style={styles.selfLi}>セルフチェックテストの結果をふまえて、今から国家試験までの大まかな学習スケジュールをプランニングできます。</li>
							<li style={styles.selfLi}>セルフチェックシートの印刷ができます。</li>
						</ul>
					</div>
				</div>
				{/*<div dangerouslySetInnerHTML={{__html: mypage.info_text}} style={{marginBottom:40}}></div>*/}
				<div style={{
					marginTop: 70,
					marginBottom: 100,
					maxWidth: 960,
					marginLeft: "auto",
					marginRight: "auto",
				}}>
					<div>
						{vd}
					</div>
					<div style={{textAlign:'center', marginBottom: 50}}>
						<Button
							variant="text"
							onClick={this.handleOpenNavi}
						>
							<img src="/imgms/sct/button_navi.png" alt="go_schedule"/>
						</Button>
					</div>
				</div>
			</div>
		)
	}
}
export default withRouter(SctMyPageTop)
// export default connect(
// 	state => ({
// 		sctState: state.sct,
// 	})
// )(withRouter(SctMyPageTop));
