import React, {Component} from 'react'
import {Route, Switch, withRouter} from "react-router-dom";
import SctMyPageTop from './SctMyPageTop'
import TestStart from './test/TestStart'
import TestQuestion from './test/TestQuestion'
import TestList from './test/TestList'
import NaviPage from './navi/NaviPage'


class SctPage extends Component {
	render() {
		return (
			<Switch>
				<Route path="/sct/test/start/:subjectCode" component={TestStart}/>
				<Route path="/sct/test/q" component={TestQuestion}/>
				<Route path="/sct/test/list" component={TestList}/>
				<Route path="/sct/navi" component={NaviPage}/>
				<Route component={SctMyPageTop}/>
			</Switch>
		)
	}
}
export default withRouter(SctPage);
