import React from 'react'
import {withRouter} from 'react-router-dom'
import {orange,green,indigo} from "@material-ui/core/colors/index";
import FkToolbar from '../parts/FkToolbar'
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Avatar from '@material-ui/core/Avatar';
import Icon from '@material-ui/core/Icon';
import {post} from '../sonodalib/sl_utils/Http'
import Cookies from "js-cookie";
import {altName} from '../alt/constants'


class Notifications extends React.Component {
	state = {
		board1: [],
		board2: [],
		board3: [],
		board9: [],
	}

	componentWillMount() {
		post("bbs/build_notifications", {
			user_token: Cookies.get(altName),
		})
		.then((ret) => {
			this.setState({
				board1: ret.board1,
				board2: ret.board2,
				board3: ret.board3,
				board9: ret.board9,
			})
		})
	}

	handleOpenThread = (row) => {
		this.props.history.push("/my/bbs/posts/"+row.thread_id)
	}

	render() {
		const board1 = (!this.state.board1.length) ? null : (
			<div>
				<div>
					学習掲示板１
				</div>
				<List>
					{this.state.board1.map(row => (
						<ListItem key={"board1"+row.uuid} button onClick={this.handleOpenThread.bind(this, row)}>
							<Avatar style={{backgroundColor: orange[700]}}>
								<Icon>comment</Icon>
							</Avatar>
							<ListItemText primary={row.title} secondary={row.handle_name}/>
						</ListItem>
					))}
				</List>
			</div>
		)
		const board2 = (!this.state.board2.length) ? null : (
			<div>
				<div>
					学習掲示板２
				</div>
				<List>
					{this.state.board2.map(row => (
						<ListItem key={"board2"+row.uuid} button onClick={this.handleOpenThread.bind(this, row)}>
							<Avatar style={{backgroundColor: orange[400]}}>
								<Icon>comment</Icon>
							</Avatar>
							<ListItemText primary={row.title} secondary={row.handle_name}/>
						</ListItem>
					))}
				</List>
			</div>
		)
		const board3 = (!this.state.board3.length) ? null : (
			<div>
				<div>
					交流掲示板
				</div>
				<List>
					{this.state.board3.map(row => (
						<ListItem key={"board3"+row.uuid} button onClick={this.handleOpenThread.bind(this, row)}>
							<Avatar style={{backgroundColor: green[600]}}>
								<Icon>comment</Icon>
							</Avatar>
							<ListItemText primary={row.title} secondary={row.handle_name}/>
						</ListItem>
					))}
				</List>
			</div>
		)
		const board9 = (!this.state.board9.length) ? null : (
			<div>
				<div>
					お問い合わせ
				</div>
				<List>
					{this.state.board9.map(row => (
						<ListItem key={"board9"+row.uuid} button onClick={this.handleOpenThread.bind(this, row)}>
							<Avatar style={{backgroundColor: indigo[600]}}>
								<Icon>mail_outline</Icon>
							</Avatar>
							<ListItemText primary={row.title} secondary={row.handle_name}/>
						</ListItem>
					))}
				</List>
			</div>
		)
		return (
			<div>
				<FkToolbar
					title="通知"
					onGoBack={() => {
						this.props.history.goBack()
					}}
				/>
				<div style={{margin: '16px auto', maxWidth: 400}}>
							{board1}
							{board2}
							{board3}
							{board9}
				</div>
			</div>
		)
	}
}

export default withRouter(Notifications)
