// @flow
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom'

type Props = {
  isAuthenticated: boolean,
  location: any,
}
class UnauthRoute extends Component<Props> {
  render() {
    const { from } = this.props.location.state || { from: { pathname: '/my' } }
    const { isAuthenticated } = this.props
    if (isAuthenticated) {
      return (
        <Redirect to={from}/>
      )
    } else {
      return null
    }
  }
}

export default connect(
  state => ({
    isAuthenticated: state.sl_users.isAuthenticated
  }),
  dispatch => ({
  })
)(UnauthRoute);
