// @flow
import React from 'react'
import {connect} from 'react-redux'
import {common} from '@material-ui/core/colors'
import '../sonodalib/sl_utils/utils.css'
import {post} from '../sonodalib/sl_utils/Http'
import {QuestionBox} from '../Common'
import Moment from 'moment'
import Button from '@material-ui/core/Button'
import {updatePrintModeAC} from "../mypage/MyPageAction";
import G from "../sonodalib/SlGlobal";
import {blue, green, red} from "@material-ui/core/colors/index";
import Cookies from "js-cookie";
import {altName, urlPref} from '../alt/constants'

type Props = {
	index: number,
	question: any,
	// oxResults: any,
	printMode: boolean,
	result: number,
	onClose: () => void,
}
type State = {
	total: number,
	feather_count: number,
}

class OxShowPrint extends React.Component<Props, State> {
	state = {
		total: 0,
		feather_count: 0,
	}

	componentWillMount() {
		const {question} = this.props
		post("result/fetch/ox/stats", {
			user_token: Cookies.get(altName),
			marubatsu_id: question.MarubatsuID,
		})
		.then(ret => {
			this.setState({
				total: ret.total,
				feather_count: ret.feather_count,
			})
		})
	}

	handlePrint = () => {
		G.store.dispatch(updatePrintModeAC(true))
		window.setTimeout(() => {
			window.print()
		}, 1500)
	}

	handleClosePrintPopout = () => {
		G.store.dispatch(updatePrintModeAC(false))
	}

	render() {
		const styles = {
			alert: {
				background: common.black,
				padding: 10,
			},
			alertP: {
				color: common.white,
				fontWeight: 'bold',
				margin: 0,
				padding: 0,
				fontSize: 14,
			},
			pullRight: {
				textAlign: 'right',
			},
			sideMargin: {
				margin: 20,
			},
			remark: {
				marginBottom: 10,
				fontWeight: 800,
			},
		}
		const {index, question} = this.props
		if (!question) return null
		const correctAnswerText = ["", "◯", "✕"]
		const currentTime = Moment(new Date()).format("YYYY-MM-DD HH:mm:ss")

		const judges = [
			{text: "正解", color: red[600]},
			{text: "不正解", color: blue[800]},
			{text: "未回答", color: green[800]},
		]
		const judge = judges[this.props.result - 1]
		const correctAnswer = correctAnswerText[question.correctAnswer]

		return (
			<div style={{backgroundImage: `url('${urlPref}/images/bg_print.jpg')`, width: 700}}>
				<div className="no-print">
					<Button color="secondary" variant="contained" onClick={this.props.onClose}>戻る</Button>
				</div>
				<div style={styles.alert}>
					<p style={styles.alertP}>※この印刷紙面の内容について、個人の学習以外の目的で使用すること、及び無断で複製・転載することを堅く禁止致します。株式会社ジェイシー教育研究所</p>
				</div>
				<div className="pull-right" style={styles.pullRight}>
					日時：{currentTime}
				</div>
				<div style={{marginTop: 12}}>
					<span style={{fontSize: 20, fontWeight: 700, color: judge.color}}>[{judge.text}]　</span>
					<span style={{
						fontSize: 18,
						fontWeight: 700
					}}>第{index + 1}問 {question.subLabel} 累計{this.state.total}回目 習熟度{this.state.feather_count}</span>
				</div>
				<QuestionBox text={question.qText}/>

				<div style={styles.sideMargin}>
					<div style={styles.remark}>［ 正解 ］</div>
					<span style={{marginLeft: 20, fontSize: 24, fontWeight: 700}}>{correctAnswer}</span>
				</div>
				<div style={styles.sideMargin}>
					<div style={styles.remark}>［解説］</div>
					{question.commentaryText}
				</div>

				<div style={{...styles.pullRight, marginTop: 30}}>
					Copyright © 2017 JC Educational Institute, Inc. All Rights Reserved.
				</div>
			</div>
		)
	}
}

export default connect(
	state => ({
		// oxResults: state.question.oxResults,
		printMode: state.mypage.printMode,
	}),
	dispatch => ({})
)(OxShowPrint)
