import React from 'react'

export default (props) => (
	<div className="box box-solid" style={{border: "2px #999 solid", width: "auto", marginBottom: 7}}>
		<div className="box-body">
			{props.children}
		</div>
	</div>
)
export const BoxTitle = (props) => (
	<h4 style={{backgroundColor: "#f7f7f7", fontSize: 18, textAlign: "center", padding: "7px 10px", marginTop: 0}}>
		{props.children}
	</h4>
)
