// @flow
import React from 'react'
import {nendoList} from "../alt/constants";

export class QueryHelpContent extends React.Component<{}> {
	render() {
		const styles = {
			help_midashi: {
				fontWeight: 800,
			}
		}
		const secondRowValue = nendoList[1].value
		return (
			<div>
				<div style={{marginBottom: 8, textAlign: 'center'}}>
					<span style={{fontWeight: 800}}>科目名を選ぶと問題トレーニングが始まります。</span>
				</div>
				<div>
					<div style={styles.help_midashi}>出題方式</div>
					○ランダム 全ての問題からランダム出題されます。<br/>
					○未解答 未だ解いていない問題が出題されます。<br/>
					○不正解 あなたが不正解だった問題が出題されます。<br/><br/>
					<div style={styles.help_midashi}>モード</div>
					○トレーニング 1問ずつ解いては解答解説を見て学習するモードです。<br/>
					○テスト 続けて問題を解いて、後から解答解説を確認するモードです。<br/><br/>
					<div style={styles.help_midashi}>出題数</div>
					1回のトレーニングで続けて出題される問題数です。<br/><br/>
					<div style={styles.help_midashi}>年度</div>
					□出題される問題の年度を指定できます。チェックの入った年度から出題されます。<br/>
					<span style={{color: 'red'}}>※「お試し」利用の方は{secondRowValue}年度のみ学習できます。</span><br/><br/>
					<div style={styles.help_midashi}>キーワード</div>
					□入力したキーワードが問題文・解説文に含まれる問題が出題されます。<br/>
					<span style={{color: 'red'}}>※設定不要な時は、空欄になっていることを確認して下さい。</span><br/><br/>
					<div style={styles.help_midashi}>すべての科目</div>
					□各カテゴリーの全科目が出題対象になります。<br/><br/>
					<div style={styles.help_midashi}>上の設定は、選択のカテゴリー内で保存され次回も有効となります。</div>
				</div>
			</div>
		)
	}
}