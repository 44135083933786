import React from 'react'
import {withRouter} from 'react-router-dom'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import Icon from '@material-ui/core/Icon'
import FkToolbar from '../parts/FkToolbar'
import {blue, purple, red, common} from '@material-ui/core/colors'
import {post} from '../sonodalib/sl_utils/Http'
import PropTypes from 'prop-types'
import Moment from 'moment'
import {withBbsDrawer} from '../parts/BbsDrawer'
import _ from 'lodash'
import Delete from '@material-ui/icons/Delete';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import {fetchJwt} from '../sonodalib/sl_users/SlUsersAction'
import Cookies from "js-cookie";
import {altName} from '../alt/constants'


class BbsPost extends React.Component {
	state = {
		topic: null,
		posts: null,
		openPostDelConfirm:false,
		delConfirmMessage: "",
	}

	componentWillMount() {
		this.fetch_posts(this.props.match.params.threadId)
	}

	fetch_posts(threadId) {
		const self = this
		const payload = {
			user_token: Cookies.get(altName),
			thread_id: threadId,
		}
		post("bbs/fetch_posts", payload)
		.then(ret => {
			PropTypes.checkPropTypes({
				topic: PropTypes.object.isRequired,
				posts: PropTypes.array.isRequired,
			}, ret)
			self.setState({
				topic: ret.topic,
				posts: ret.posts,
			})
			fetchJwt()
		})
	}

	handleReply = () => {
		this.props.bbsDrawer.openToPostComment(
			this.props.match.params.threadId,
			() => {
				this.fetch_posts(this.props.match.params.threadId)
			},
			"",
		)
	}

	handleShowQuestion = () => {
		const {mondai_id, marubatsu_id} = this.state.topic
		if (!marubatsu_id) {
			this.props.history.push("/q/sentaku/show/" + mondai_id)
		} else {
			this.props.history.push("/q/ox/show/" + marubatsu_id)
		}
	}

	handleEditTopic = (topic) => {
		this.props.bbsDrawer.openToEditPost(
			topic.post_id,
			topic.title,
			topic.handle_name,
			topic.text,
			() => {
				this.fetch_posts(this.props.match.params.threadId)
			},
			"",
		)
	}

	handleEditPost = (post) => {
		this.props.bbsDrawer.openToEditPost(
			post.uuid,
			post.title,
			post.handle_name,
			post.text,
			() => {
				this.fetch_posts(this.props.match.params.threadId)
			},
			"",
		)
	}

	handleDelThread = (topic) => {
		this.commentToDel = topic.post_id
		this.setState({
			delConfirmMessage: "スレッドを削除すると、このスレッドへの返信もすべて削除されます",
			openPostDelConfirm: true,
		})
	}

	handleDelPost = (comment) => {
		this.commentToDel = comment.uuid
		this.setState({
			delConfirmMessage: "",
			openPostDelConfirm: true,
		})
	}

	handleDelPostConfirmed = () => {
		post("bbs/update_post_hidden", {
			user_token: Cookies.get(altName),
			post_id: this.commentToDel,
			hidden: true,
		})
		.then((ret) => {
			// this.fetch_posts(this.props.match.params.threadId)
			this.props.history.goBack()
		})
	}

	render() {
		const {topic, posts} = this.state
		const ownerMenu = (!(topic && topic.is_owner)) ? null : (
			<Grid item xs={12}>
				<div className="pull-right">
					<Button
						style={{marginRight: 10}}
						variant="contained"
						color="secondary"
						onClick={this.handleDelThread.bind(this,topic)}
					>
						削除
						<Delete style={{fontSize: 20, marginLeft: 10}}/>
					</Button>
					<Button
						variant="contained"
						style={{color: common.white, backgroundColor: blue[600]}}
						onClick={this.handleEditTopic.bind(this,topic)}
					>
						修正
						<Icon style={{fontSize: 20, marginLeft: 10}}>mode_edit</Icon>
					</Button>
				</div>
			</Grid>
		)
		const topicVd = (!topic) ? null : (topic.board_id !== 4) ? (
			<div style={{backgroundColor: "#d4e3fc", padding: 16}}>
				<Grid container>
					<Grid item xs={6}>
						<span style={{fontSize: 16, fontWeight: 800, color: purple[700]}}>
							{topic.title}
						</span>
					</Grid>
					<Grid item xs={3}>
						<span style={{fontSize: 12, fontWeight: 600, color: purple[600]}}>
							{topic.handle_name}
						</span>
					</Grid>
					<Grid item xs={3}>
						<span style={{fontSize: 12, fontWeight: 600, color: common.black}}>
							{Moment(topic.updated).format("YYYY/MM/DD")}
						</span>
					</Grid>
					<Grid item xs={12}>
						<div dangerouslySetInnerHTML={{__html: topic.text.replace(/\n/g, "<br />")}}></div>
					</Grid>
					{ownerMenu}
				</Grid>
			</div>
		) : (
			<div style={{backgroundColor: "#d4e3fc", padding: 16}}>
				<Grid container>
					<Grid item xs={12}>
						<span style={{fontSize: 16, fontWeight: 800, color: purple[700]}}>
							{topic.title}
						</span>
					</Grid>
					<Grid item xs={12}>
						<div dangerouslySetInnerHTML={{__html: topic.text.replace(/\n/g, "<br />")}}></div>
					</Grid>
					{ownerMenu}
				</Grid>
			</div>
		)
		const commentsVd = (posts && posts.length) ? (
			<div>
				<div style={{textAlign: 'center'}}>
					<Icon>graphic_eq</Icon>
				</div>
				{_.map(_.sortBy(posts, "updated"), comment => {
					const ownerMenu = (!comment.is_owner) ? null : (
						<Grid item xs={12}>
							<div className="pull-right">
								<Button
									style={{marginRight: 10}}
									variant="contained"
									color="secondary"
									onClick={this.handleDelPost.bind(this,comment)}
								>
									削除
									<Delete style={{fontSize: 20, marginLeft: 10}}/>
								</Button>
								<Button
									variant="contained"
									style={{color: common.white, backgroundColor: blue[600]}}
									onClick={this.handleEditPost.bind(this,comment)}
								>
									修正
									<Icon style={{fontSize: 20, marginLeft: 10}}>mode_edit</Icon>
								</Button>
							</div>
						</Grid>
					)
					return (topic.board_id !== 4) ? (
						<div key={comment.uuid} style={{padding: 16, marginBottom: 4, backgroundColor: "#e8eaf6"}}>
							<Grid container>
								<Grid item xs={6}>
									<span style={{fontSize: 16, fontWeight: 800, color: red[700]}}>
										{comment.title}
									</span>
								</Grid>
								<Grid item xs={3}>
									<span style={{fontSize: 12, fontWeight: 600, color: common.minBlack}}>
										{comment.handle_name}
									</span>
								</Grid>
								<Grid item xs={3}>
									<span style={{fontSize: 12, fontWeight: 600, color: common.minBlack}}>
										{Moment(comment.updated).format("YYYY/MM/DD")}
									</span>
								</Grid>
								<Grid item xs={12}>
									<div dangerouslySetInnerHTML={{__html: comment.text.replace(/\n/g, "<br />")}}></div>
								</Grid>
								{ownerMenu}
							</Grid>
						</div>
					) : (
						<div key={comment.uuid} style={{padding: 16, marginBottom: 4, backgroundColor: "#e8eaf6"}}>
							<Grid container>
								<Grid item xs={12}>
									<span style={{fontSize: 16, fontWeight: 800, color: red[700]}}>
										{comment.title}
									</span>
								</Grid>
								<Grid item xs={12}>
									<div dangerouslySetInnerHTML={{__html: comment.text.replace(/\n/g, "<br />")}}></div>
								</Grid>
								{ownerMenu}
							</Grid>
						</div>
					)
				})}
			</div>
		) : (
			<div></div>
		)
		const openQuestionDetailButton = (topic && topic.board_id === 1) ? (
			<Button
				style={{width: 150, color: common.white, backgroundColor: "#229cfc", marginLeft: 8}}
				variant="contained"
				onClick={this.handleShowQuestion.bind(this)}
			>
				問題ページを開く
			</Button>
		) : null
		const replyButton = (topic && topic.board_id === 4) ? null : (
			<Button
				style={{width: 150, color: common.white, backgroundColor: "#229cfc"}}
				variant="contained"
				onClick={this.handleReply.bind(this)}
			>
				返信を投稿
			</Button>
		)
		return (
			<div>
				<FkToolbar title="スレッド" onGoBack={() => {
					this.props.history.goBack()
				}}/>
				<div style={{margin: 10}}>
					{topicVd}
					<div style={{marginTop: 10}}>
						{commentsVd}
					</div>
					<div style={{marginTop: 8}}>
						{replyButton}
						{openQuestionDetailButton}
					</div>
				</div>
				<Dialog open={this.state.openPostDelConfirm} disableBackdropClick>
					<DialogTitle>削除してよろしいですか？</DialogTitle>
					<DialogContent>
						{this.state.delConfirmMessage}
					</DialogContent>
					<DialogActions>
						<Button
							color="primary"
							onClick={this.handleDelPostConfirmed}
						>
							削除する
						</Button>
						<Button
							color="secondary"
							onClick={() => {
								this.setState({
									openPostDelConfirm: false,
								})
							}}
						>
							キャンセル
						</Button>
					</DialogActions>
				</Dialog>
			</div>
		)
	}
}

export default withBbsDrawer(withRouter(BbsPost))