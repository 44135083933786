import request from "superagent";
import G from '../SlGlobal'
import PromiseRetry from '../sl_utils/PromiseRetry'

// export function post(endPoint, payload) {
// 		return new Promise((resolve, reject) => {
// 			request
// 				.post(G.apiPath + endPoint)
// 				.send(payload)
// 				.set("Accept", "application/json;charset=UTF-8")
// 				.end((err, res) => {
// 					if (err) {
// 						reject(res);
// 					} else {
// 						resolve(res.body);
// 					}
// 				});
// 		});
// 	}

function _post(endPoint, payload) {
	return new Promise((resolve, reject) => {
		request
		.post(G.apiPath + endPoint)
		.send(payload)
		.set("Accept", "application/json;charset=UTF-8")
		.set("X-DB-NAME", G.apiDbName || "default")
		.end((err, res) => {
			if (err) {
				reject(res);
			} else {
				resolve(res.body);
			}
		});
	});
}

export function post(endPoint, payload) {
	return new PromiseRetry()
	.maxTimes(1)
	.interval(3000)
	.execute(() => {
		return _post(endPoint, payload)
	})
}

export function post_wm(endPoint, payload) {
	return new Promise((resolve, reject) => {
		request
		.post("/wm/fkapi/" + endPoint)
		.send(payload)
		.set("Accept", "application/json;charset=UTF-8")
		.set("X-DB-NAME", G.apiDbName || "default")
		.end((err, res) => {
			if (err) {
				reject(res);
			} else {
				resolve(res.body);
			}
		});
	});
}
