import React from 'react'
import Moshi from "../../moshi";
import Sct from "../../sct";


export default function MoshiLinks(props) {
	const {moshi_link_list, onError, onLaunch} = props
	return moshi_link_list.map((moshiLink, index) => {
		const onClick = () => {
			if (moshiLink.err_desc) {
				onError(moshiLink.err_desc)
			} else {
				if (moshiLink.sys === "wm") {
					Moshi.startSession(moshiLink.service, undefined, () => {
						onLaunch(moshiLink)
					}, () => {
						// onError("エラーが発生したため、模試を起動できません")
					})
				} else {	// sys === "sct"
					Sct.startSession(moshiLink.service, undefined, () => {
						onLaunch(moshiLink)
					}, () => {
						// onError("エラーが発生したため、模試を起動できません")
					})
				}
			}
		}
		switch (moshiLink.type) {
			case "webmoshi":
				return (
					<div key={"moshi-link"+index} onClick={onClick} className="small-box bg-purple" style={{marginBottom: 8, cursor: 'pointer',height:105}}>
						<div className="inner" style={{textAlign: 'left',height:79}}>
							<span style={{fontSize: 20}}>Web模擬試験</span>
							<p><span style={{fontSize: 14}}>{moshiLink.desc}</span></p>
						</div>
						<div className="icon">
							<i className="far fa-edit"></i>
						</div>
						<span className="small-box-footer"
									style={{cursor: 'pointer'}}>サイトに移動 <i className="fas fa-arrow-circle-right"></i></span>
					</div>
				)
			case "webjidousaiten":
				return (
					<div key={"moshi-link"+index} onClick={onClick} className="small-box bg-red" style={{marginBottom: 8, cursor: 'pointer',height:105}}>
						<div className="inner" style={{textAlign: 'left',height:79}}>
							<span style={{fontSize: 20}}>Web自動採点</span>
							<p><span style={{fontSize: 14}}>{moshiLink.desc}</span></p>
						</div>
						<div className="icon">
							<i className="far fa-edit"></i>
						</div>
						<span className="small-box-footer"
									style={{cursor: 'pointer'}}>サイトに移動 <i className="fas fa-arrow-circle-right"></i></span>
					</div>
				)
			case "originalmoshi":
				return (
					<div key={"moshi-link"+index} onClick={onClick} className="small-box bg-aqua" style={{marginBottom: 8, cursor: 'pointer',height:105}}>
						<div className="inner" style={{textAlign: 'left',height:79}}>
							<span style={{fontSize: 20}}>Web模擬試験</span>
							<p><span style={{fontSize: 14}}>{moshiLink.desc}</span></p>
						</div>
						<div className="icon">
							<i className="far fa-edit"></i>
						</div>
						<span className="small-box-footer"
									style={{cursor: 'pointer'}}>サイトに移動 <i className="fas fa-arrow-circle-right"></i></span>
					</div>
				)
			case "selfchecktest":
				return (
					<div key={"selfchecktest"+index} onClick={onClick} className="small-box bg-green" style={{marginBottom: 8, cursor: 'pointer',height:105}}>
						<div className="inner" style={{textAlign: 'left'}}>
							<span style={{fontWeight: 800, fontSize: 20}}>セルフチェックテスト</span>
							<span style={{fontWeight: 800, fontSize: 14}}>＆学習ナビ</span>
							<p><span style={{fontSize: 14}}>　</span></p>
						</div>
						<div className="icon">
							<i className="fas fa-chart-line"></i>
						</div>
						<span className="small-box-footer">サイトに移動 <i className="fas fa-arrow-circle-right"></i></span>
					</div>
				)
			default:
				return null
		}
	})
}
