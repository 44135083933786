import React from 'react'
import {withRouter} from 'react-router-dom'
import {connect} from 'react-redux'
import Button from "@material-ui/core/Button";
import {post_wm} from "../../sonodalib/sl_utils/Http";
import Cookies from "js-cookie";
import {altName} from "../../alt/constants";
import {updateMoshi} from "../MoshiAction";
import Mark from './'


class MarkStart extends React.Component {
	state = {
		categoryName: null,
	}

	componentDidMount() {
		const {categoryId} = this.props.match.params
		post_wm("mark/start", {
			user_token:  Cookies.get(altName),
			moshi_token: Cookies.get("wm_"+altName),
			category_id: categoryId,
		})
		.then((ret) => {
			if (!ret.subjects.length) {
				alert("問題がありません")
				this.props.history.push("/wm")
			} else {
				this.setState({
					categoryName: ret.category_name
				}, () => {
					updateMoshi({
						markCategoryId: 	categoryId,
						markSubjects:   	ret.subjects,
						qno:							0,
						markPerPageState: Mark.createPerPageState(ret.subjects[0]),
					})
				})
			}
		})
		.catch(() => {
			alert("エラーが発生しました")
			this.props.history.push("/wm")
		})
	}

	handleStart = () => {
		this.props.history.push("/wm/mark/q")
	}

	render() {
		const {moshiState} = this.props
		if (!moshiState) return null
		const {categoryName} = this.state
		if (!categoryName) return null
		return (
			<div style={{
				marginTop: 20,
				marginBottom: 100,
				maxWidth: 800,
				marginLeft: "auto",
				marginRight: "auto",
			}}>
				<div style={{marginLeft: 8, marginRight: 8, fontSize:16}}>
					<div style={{marginBottom:20,fontSize:20,fontWeight:200,textAlign:'center',paddingTop:30,paddingBottom:30,borderTop:'1px solid #ccc',borderBottom:'1px solid #ccc'}}>
						<div>
							<span style={{fontSize:31, lineHeight:'40px',fontWeight:'bold'}}>{categoryName}</span>
						</div>
						<div>
							<span style={{fontSize:21, lineHeight:'30px',fontWeight:200}}>{moshiState.serviceName}</span>
						</div>
					</div>
					<div style={{textAlign:'left', marginTop:40}}>
						解答送信は一度しかできませんのでご注意下さい。誤って送信した場合は、個別にご連絡下さい。<br />
						<div style={{marginTop:38,textAlign:'center'}}>
							<Button
								variant="contained"
								color="primary"
								onClick={this.handleStart}
							>
								解答送信画面へ
							</Button>
						</div>
					</div>
				</div>
			</div>
		)
	}
}
export default connect(
	state => ({
		moshiState: state.moshi
	})
)(withRouter(MarkStart))
