import {post, post_wm} from '../../sonodalib/sl_utils/Http';


function validateServiceCode(props) {
	return new Promise((resolve, reject) => {
		let serviceCode
		try {
			serviceCode = parseInt(props.match.params.serviceCode)
		} catch (e) {
			location.href = "/"
			return
		}
		post_wm("user/validation", {
			service_code: serviceCode,
		})
		.then(ret => {
			if (ret.err && ret.err === "invalid-service") {
				alert("このページは現在無効です")
				location.href = "/"
			} else {
				resolve(ret)
			}
		})
	})
}

export default {
	validateServiceCode,
}