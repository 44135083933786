import React from 'react'
import {connect} from 'react-redux'
import {withRouter} from 'react-router-dom'
import Button from '@material-ui/core/Button';
import IconSelector from '../sonodalib/sl_mui/IconSelector'
import Toolbar from '@material-ui/core/Toolbar';
import Grid from '@material-ui/core/Grid';
import AppBar from '@material-ui/core/AppBar';
import {logout} from '../sonodalib/sl_users/SlUsersAction';
import Badge from '@material-ui/core/Badge';
import Icon from '@material-ui/core/Icon'
import {headerColor} from '../alt/constants'
import {urlPref, switchContentMenuLabel, switchContentMenuUrl} from '../alt/constants'


class MyPageHeader extends React.Component {
	render() {
		const {userState} = this.props
		const notifyCount = (userState && userState.notify_count) ? userState.notify_count : 0
		const notifyIcon = (notifyCount) ? (
			<Badge badgeContent={notifyCount} color="primary">
				<Icon
					style={{color: 'black', cursor:'pointer'}}
					onClick={this.props.handleViewNorifications.bind(this)}
				>
					notifications
				</Icon>
			</Badge>
		) : (
			<Icon style={{color: 'black'}}>notifications_none</Icon>
		)
		// const license = (this.props.userState) ? this.props.userState.license : null
		const applyButton = (
			<Button
				href={urlPref+"/my/application"}
			>
				お申込
			</Button>
		)
		// const applyButton = (!license) ? null : (license === "0") ? (
		// 	<Button
		// 		href="/my/application"
		// 	>
		// 		お申込
		// 	</Button>
		// ) : (
		// 	<Button
		// 		href="/my/add-application"
		// 	>
		// 		お申込
		// 	</Button>
		// )

		const clientCount = (userState && userState.client_count) ? userState.client_count : 0
		const clientCountVd = (!clientCount) ? null : (
			<span style={{color:'black'}}>{clientCount}人 学習中</span>
		)
		return (
			<AppBar style={{backgroundColor: headerColor, boxShadow: 'none', marginBottom: 10}} position="static">
				<Toolbar>
					<a href={urlPref}>
						<img src={urlPref+"/images/common/head-logo.png"} width="192" height="54" role="presentation" style={{zIndex: 999}}
								 alt="head-logo"/>
					</a>
						<div style={{marginLeft:20,width:140}}>
							{clientCountVd}
						</div>
					<Grid container justify="flex-end" alignItems="center">
						<Grid item>
							{notifyIcon}
						</Grid>
						<Grid item>
							{applyButton}
						</Grid>
						<Grid item>
							<IconSelector
								menuList={[
									{id: 5, label: switchContentMenuLabel, onClick: () => {
											window.location.href = switchContentMenuUrl
										}},
									{id: 0, label: "ログアウト", onClick: this.props.handleLogout},
									{id: 1, label: "メールアドレス変更", onClick: this.props.handleChangeEmail.bind(this)},
									{id: 2, label: "個人情報", onClick: this.props.handleMyAccount.bind(this)},
									{id: 3, label: "パスワード変更", onClick: this.props.handleChangePassword.bind(this)},
									{id: 4, label: "お問い合わせ", onClick: this.props.handleGoContact.bind(this)},
								]}
							/>
						</Grid>
					</Grid>
				</Toolbar>
			</AppBar>
		)
	}
}

export default connect(
	state => ({
		userState: state.sl_users.state,
	}),
	dispatch => ({
		handleLogout: () => {
			logout(dispatch);
		},
		handleViewNorifications: function () {
			this.props.history.push("/my/notifications")
		},
		handleMyAccount: function () {
			this.props.history.push("/my/profile")
		},
		handleChangeEmail: function () {
			this.props.history.push("/my/change-email")
		},
		handleChangePassword: function () {
			this.props.history.push("/my/change-password")
		},
		handleGoContact: function () {
			this.props.history.push("/my/bbs/contact")
		},
	})
)(withRouter(MyPageHeader));
