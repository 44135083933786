import { combineReducers } from 'redux';
import top from './top/TopReducer';
import mypage from './mypage/MyPageReducer';
import moshi from './moshi/MoshiReducer';
import sct from './sct/SctReducer';
import question from './question/QuestionReducer';
import sl_users from './sonodalib/sl_users/SlUsersReducer';
import sl_utils from './sonodalib/sl_utils/SlUtilsReducer';

const reducers = combineReducers({
  top,
  mypage,
  moshi,
  sct,
  question,
  sl_users,
  sl_utils,
});

export default reducers;
