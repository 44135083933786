import React from 'react'
import FkToolbar from '../parts/FkToolbar'
import './history.css'
import { withMemoDrawer } from '../parts/MemoDrawer'
import HistoryDetail from './HistoryDetail'
import Cookies from "js-cookie";
import {altName, nendoList} from '../alt/constants'


class HistoryDetailPage extends React.Component {
	handleGoBack = () => {
		this.props.history.goBack()
	}

	handleShowSentaku = mondaiId => {
		this.props.history.push("/my/hist/show/sentaku/"+mondaiId)
	}

	handleShowOx = marubatsuId => {
		this.props.history.push("/my/hist/show/ox/"+marubatsuId)
	}

	handleOpenMemo = (onMemoUpdated, mondaiId, marubatsuId) => {
		this.props.memoDrawer.open({mondaiId, marubatsuId}, onMemoUpdated)
	}


	render() {
		const second_row_of_nendo_list = nendoList[1].value
		return (
			<div>
				<FkToolbar
					title="履歴の詳細"
					onGoBack={this.handleGoBack.bind(this)}
				/>
				<HistoryDetail
					mondaiType={this.props.match.params.mondaiType}
					onShowSentaku={this.handleShowSentaku}
					onShowOx={this.handleShowOx}
					onOpenMemo={this.handleOpenMemo}
					fetchUrl="result/fetch/history_detail"
					fetchPayload={{
						user_token: Cookies.get(altName),
						second_row_of_nendo_list,
						kamoku_id: this.props.match.params.kamokuId,
						mondai_type: this.props.match.params.mondaiType,
					}}
				/>
			</div>
		)
	}
}

export default withMemoDrawer(HistoryDetailPage)
