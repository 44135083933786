import React, {Component} from 'react'
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import Cookies from "js-cookie";
import {altName} from "../alt/constants";
import {post_wm} from "../sonodalib/sl_utils/Http";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "@material-ui/core/Button";
import Icon from "@material-ui/core/Icon";
import Grid from "@material-ui/core/Grid";
import {updateMoshi} from "./MoshiAction";


function getMenuButtonStyle(disabled) {
	return (disabled) ? {
		color:'#555',
		backgroundColor: '#eee',
		paddingLeft:18,
		paddingRight:18,
		border:'1px solid #ddd',
		width: 160,
	} : {
		color:'#333',
		backgroundColor: '#e6e6e6',
		paddingLeft:18,
		paddingRight:18,
		border:'1px solid #999',
		width: 160,
	}
}

class MoshiMyPageTop extends Component {
	state = {
		mypage: null,
		openDdMenu: null,
		anchorDdMenu: null,
	}

	componentDidMount() {
		this.fetch()
	}

	fetch = () => {
		post_wm("my/fetch", {
			user_token:  Cookies.get(altName),
			moshi_token: Cookies.get("wm_"+altName),
		})
		.then((ret) => {
			this.setState({
				mypage: ret.mypage
			})
		})
	}

	handleopenDdMenu = (categoryId) => (event) => {
		categoryId = parseInt(categoryId,10)
		let openDdMenu   = this.state.openDdMenu
		let anchorDdMenu = this.state.anchorDdMenu
		if (!openDdMenu) openDdMenu = Array(categoryId+1)
		else openDdMenu = openDdMenu.concat()
		openDdMenu[categoryId] = true
		if (!anchorDdMenu) anchorDdMenu = Array(categoryId+1)
		else anchorDdMenu = anchorDdMenu.concat()
		anchorDdMenu[categoryId] = event.currentTarget
		this.setState({
			openDdMenu,
			anchorDdMenu
		})
		// this.setState({ openDdMenu: true, anchorDdMenu: event.currentTarget });
	};

	handleCloseMenu1 = (categoryId) => () => {
		categoryId = parseInt(categoryId,10)
		let openDdMenu = this.state.openDdMenu.concat()
		openDdMenu[categoryId] = false
		this.setState({ openDdMenu });
	}

	handleSelectMenu1 = (menu, categoryId) => () => {
		const {moshiState} = this.props
		switch (menu.id) {
			case 'dl_paper':
				window.open("/files/"+moshiState.serviceCode+"/Q_cat" + categoryId + ".pdf")
				break
			case 'dl_marksheet':
				window.open("/files/"+moshiState.serviceCode+"/M_cat" + categoryId + ".pdf")
				break
			case 'dl_kaisetsu':
				window.open("/files/"+moshiState.serviceCode+"/A_cat" + categoryId +".pdf")
				break
			case 'submit_paper':
				updateMoshi({
					markCategoryId: parseInt(categoryId,10),
				})
				this.props.history.push("/wm/mark/start/" + categoryId)
				break
			default:
		}
		// window.open(window.location.protocol + "//" + host.substr(prefix.length) + urlPref + "/super-login/" + ret.token)
		this.handleCloseMenu1(categoryId)
	}

	handleStartTest = (subject) => () => {
		this.props.history.push("/wm/test/start/"+subject.subject_code)
	}

	handleOpenShindan = () => {
		this.props.history.push("/wm/shindan")
	}

	render() {
		const {moshiState} = this.props
		const {mypage, openDdMenu, anchorDdMenu} = this.state
		if (!mypage) return null
		const vd = Object.keys(mypage.categories).map((key) => {
			const category = mypage.categories[key]
			const subjectVd = category.subjects.map((subject, index) => {
				const bgColor = (index & 1) ? "#fefefe" : '#f6f6f6'
				return (
					<div key={`sr${key}${index}`} style={{borderTop:'1px solid #ddd',backgroundColor:bgColor,padding:'6px 20px'}}>
						<Grid container alignItems="center">
							<Grid item style={{width:550-40}}>
								{subject.name}
							</Grid>
							<Grid item style={{width:150,textAlign:'right'}}>
								{(!subject.submitted) ? (
									<Button
										variant="text"
										size="small"
										style={{color:'white',backgroundColor:'#00BCD4', width:110, height:32}}
										onClick={this.handleStartTest(subject)}
									>
										オンライン受験
									</Button>
								) : (
									<Button
										variant="text"
										size="small"
										style={{color:'black', width:110, height:32}}
										disabled={true}
									>
										<Icon style={{fontSize:18,marginRight:4}}>check_circle_outline</Icon>
										解答済み
									</Button>
								)}
							</Grid>
						</Grid>
					</div>
				)
			})
			return (
				<div key={`cat${key}`} style={{marginBottom:60}}>
					<Grid container alignItems="center">
						<Grid item style={{width:286,borderLeft:'7px solid #e3297d',padding:'.3em .8em',fontSize:16,color:'#666'}}>
							{category.label}
						</Grid>
						{(category.dropdown_menu.length > 1) ? (
							<Grid item>
								<Button
									variant="text"
									aria-label="More"
									aria-owns={openDdMenu && openDdMenu[key] ? 'menu' : null}
									aria-haspopup="true"
									onClick={this.handleopenDdMenu(key)}
									size="small"
									style={{backgroundColor:'#eee',paddingLeft:18,paddingRight:18,border:'1px solid #ddd'}}
								>
									紙で受験　<Icon>keyboard_arrow_down</Icon>
								</Button>
								{(anchorDdMenu) ? (
									<Menu
										id={"selector-menu-" + key}
										anchorEl={anchorDdMenu[key]}
										open={!!(openDdMenu && openDdMenu[key])}
										onClose={this.handleCloseMenu1(key)}
									>
										{category.dropdown_menu.map(menu => {
											return (
												<MenuItem
													key={menu.id}
													selected={false}
													onClick={this.handleSelectMenu1(menu, key)}
													disabled={menu.disabled}
												>
													{menu.label}
												</MenuItem>
											)})}
									</Menu>
								) : null}
							</Grid>
						) : (category.dropdown_menu.length === 1) ? (
							<Grid item>
								<Button
									variant="text"
									onClick={this.handleSelectMenu1(category.dropdown_menu[0], key)}
									size="small"
									style={getMenuButtonStyle(category.dropdown_menu[0].disabled)}
									disabled={category.dropdown_menu[0].disabled}
								>
									{category.dropdown_menu[0].label}
								</Button>
							</Grid>
						) : null}
					</Grid>
					<div style={{marginTop:14,borderBottom:'1px solid #ddd'}}>
						{subjectVd}
					</div>
				</div>
			)
		})
		const shindanButton = (mypage.shindan_btn) ? (
			<div style={{textAlign:'center', marginBottom: 50}}>
				<Button
					variant="text"
					color="primary"
					style={{width:250, fontSize:18, border:'1px solid #EF6C00'}}
					onClick={this.handleOpenShindan}
				>
					診　断　表
				</Button>
			</div>
		) : null
		return (
			<div style={{
				marginTop: 20,
				marginBottom: 100,
				maxWidth: 700,
				marginLeft: "auto",
				marginRight: "auto",
			}}>
				<div style={{marginLeft:8, marginRight:8, marginBottom:38}}>
					<div style={{marginBottom:20,fontSize:20,fontWeight:200,lineHeight:4,textAlign:'center',borderTop:'1px solid #ccc',borderBottom:'1px solid #ccc'}}>
						{moshiState.serviceName}
					</div>
				</div>
				<div dangerouslySetInnerHTML={{__html: mypage.info_text}} style={{marginBottom:40}}></div>
				{shindanButton}
				<div>
					{vd}
				</div>
				{shindanButton}
				{/*<Button*/}
				{/*	variant="text"*/}
				{/*	color="primary"*/}
				{/*	onClick={this.handleOpenShindan}*/}
				{/*	style={{width:250, fontSize: 16}}*/}
				{/*>*/}
				{/*	診　断　表*/}
				{/*</Button>*/}
			</div>
		)
	}
}
export default connect(
	state => ({
		moshiState: state.moshi,
		printMode:  state.moshi.printMode,
	})
)(withRouter(MoshiMyPageTop));