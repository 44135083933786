// @flow
import React, {Component} from 'react'
import PropTypes from 'prop-types';
import TopHeader from './TopHeader'
import Button from '@material-ui/core/Button';
import {orange, grey} from '@material-ui/core/colors';
import SlTextField from '../sonodalib/sl_mui/SlTextField'
import {FormState, TextInputState} from '../sonodalib/sl_utils/SlForms'
import {post} from '../sonodalib/sl_utils/Http'
import {loading} from '../sonodalib/sl_utils/Utils'
import {PageHeader} from '../Common'
import Grid from '@material-ui/core/Grid'
import InputDigitCode from '../parts/InputDigitCode'
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import {urlPref} from "../alt/constants"


let digitsId = null;
let appliedEmail = null
let password1 = null

const commandBox = (
	<div className="pull-right">
		<Button
			style={{color: grey[600]}}
			// hoverColor={grey[100]}
			href={urlPref+"/login"}
		>
			ログインに戻る
		</Button>
	</div>
)

const styles = {
	contentContainer: {
		maxWidth: 800,
		minWidth: 800,
	},
	loginBox: {
		width: 400,
	},
	digitCodeDesc: {
		fontSize: 13,
		color: grey[700],
	},
	digitCodeDescEmail: {
		color: orange[900],
		marginLeft: 8,
		marginRight: 8,
		fontWeight: 700,
	},
	loginBtn: {
		float: 'right',
		marginLeft: 8,
	},
	desc: {
		fontSize: 13,
		color: grey[700],
	},
}

class InputEmail extends React.Component<{ onFinish: () => void },
	{ emailState: TextInputState }> {
	static propTypes = {
		onFinish: PropTypes.func.isRequired,
	}
	state = {
		emailState: new TextInputState({validations: "require,email"}),
	}

	handleSubmit = e => {
		e.preventDefault()
		this.setState(FormState.validate(this.state), () => {
			if (FormState.isValid(this.state)) {
				const email = this.state.emailState.getValue()
				const payload = {
					email,
				}
				loading(true)
				post("user/resetpass/step1", payload)
				.then(ret => {
					loading(false)
					appliedEmail = email
					digitsId = ret.digits_id
					this.props.onFinish()
				})
				.catch(res => {
					loading(false)
					let errorMessage;
					if (res && res.statusCode === 401) {
						errorMessage = "メールアドレスが間違っています";
					} else {
						errorMessage = "不明なエラーが発生しました"
					}
					this.setState({
						emailState: this.state.emailState.setError(errorMessage)
					})
				})
			}
		})
	}

	render() {
		const {emailState} = this.state
		return (
			<Grid container alignItems="center">
				<Grid item xs={12}>
					<PageHeader label="パスワード再設定" mb={0}/>
					{commandBox}
					<span style={styles.desc}>まず、メールアドレスの確認を行います</span>
				</Grid>
				<Grid item xs={3}></Grid>
				<Grid item xs={6}>
					<form onSubmit={this.handleSubmit.bind(this)}>
						<div style={{marginBottom: 10}}>
							<SlTextField
								autoFocus
								type="email"
								label="メールアドレス"
								state={emailState}
								onChange={(state) => {
									this.setState({emailState: state})
								}}
							/>
						</div>
						<div>
							<Button variant="contained"
											type="submit"
											color="primary"
											style={styles.loginBtn}
											className="pull-right"
							>
								確認コードの発行
							</Button>
						</div>
					</form>
				</Grid>
				<Grid item xs={3}></Grid>
			</Grid>
		)
	}
}

class InputPassword extends React.Component<{
	onFinish: () => void,
	onCancel: () => void,
}, {
	passwordState: TextInputState,
}> {
	static propTypes = {
		onFinish: PropTypes.func.isRequired,
		onCancel: PropTypes.func.isRequired,
	}
	state = {
		passwordState: new TextInputState({validations: "require,password"}),
	}

	handleSubmit = e => {
		e.preventDefault()
		this.setState(FormState.validate(this.state), () => {
			if (FormState.isValid(this.state)) {
				password1 = this.state.passwordState.getValue()
				this.props.onFinish()
			}
		})
	}

	render() {
		const {passwordState} = this.state
		return (
			<Grid container alignItems="center">
				<Grid item xs={12}>
					<PageHeader label="新しいパスワード" mb={0}/>
					{commandBox}
					<span style={styles.desc}>新しいパスワードを入力して下さい</span>
				</Grid>
				<Grid item xs={3}></Grid>
				<Grid item xs={6}>
					<form onSubmit={this.handleSubmit.bind(this)}>
						<div style={{marginBottom: 10}}>
							<SlTextField
								autoFocus
								label="パスワード"
								placeholder="英小文字と数字の組み合わせで8文字以上"
								state={passwordState}
								onChange={(state) => {
									this.setState({passwordState: state})
								}}
								type="password"
							/>
						</div>
						<div>
							<Button variant="contained"
											color="primary"
											style={styles.loginBtn}
											type="submit"
							>
								次へ
							</Button>
							<Button variant="contained"
											style={styles.loginBtn}
											onClick={this.props.onCancel}
							>
								キャンセル
							</Button>
						</div>
					</form>
				</Grid>
				<Grid item xs={3}></Grid>
			</Grid>
		)
	}
}

class ConfirmPassword extends React.Component<{
	onFinish: () => void,
	onCancel: () => void,
}, {
	passwordState: TextInputState,
}> {
	static propTypes = {
		onFinish: PropTypes.func.isRequired,
		onCancel: PropTypes.func.isRequired,
	}
	state = {
		passwordState: new TextInputState({validations: "require,password"}),
	}

	handleSubmit = e => {
		e.preventDefault()
		this.setState(FormState.validate(this.state), () => {
			if (FormState.isValid(this.state)) {
				const password2 = this.state.passwordState.getValue()
				if (password1 !== password2) {
					this.setState({
						passwordState: this.state.passwordState.setError("パスワードが間違っています")
					})
				} else {
					const payload = {
						digit_id: digitsId,
						password: password1,
					}
					post("user/resetpass/step4", payload)
					.then(() => {
						this.props.onFinish()
					})
					.catch(res => {
						let errorMessage;
						if (res && res.statusCode === 401) {
							errorMessage = "この処理は無効です。"
						} else {
							errorMessage = "不明なエラーが発生しました"
						}
						this.setState({
							passwordState: this.state.passwordState.setError(errorMessage)
						})
					})
				}
			}
		})
	}

	render() {
		const {passwordState} = this.state
		return (
			<Grid container alignItems="center">
				<Grid item xs={12}>
					<PageHeader label="パスワード再入力" mb={0}/>
					{commandBox}
					<span style={styles.desc}>確認のため設定したパスワードを再入力して下さい</span>
				</Grid>
				<Grid item xs={3}></Grid>
				<Grid item xs={6}>
					<form onSubmit={this.handleSubmit.bind(this)}>
						<div style={{marginBottom: 10}}>
							<SlTextField
								autoFocus
								label="パスワード"
								placeholder="英小文字と数字の組み合わせで8文字以上"
								state={passwordState}
								onChange={(state) => {
									this.setState({passwordState: state})
								}}
								type="password"
							/>
						</div>
						<div>
							<Button variant="contained"
											color="primary"
											style={styles.loginBtn}
											type="submit"
							>
								次へ
							</Button>
							<Button variant="contained"
											style={styles.loginBtn}
											onClick={this.props.onCancel}
							>
								キャンセル
							</Button>
						</div>
					</form>
				</Grid>
				<Grid item xs={3}></Grid>
			</Grid>
		)
	}
}

/* =================================================================
		ResetPassPage
   ================================================================= */
type State = {
	page: string,
	completedDialogOpen: boolean,
}
export default class ResetPassPage extends Component<{}, State> {
	state = {
		page: "email",
		completedDialogOpen: false,
	}

	render() {
		const {page} = this.state
		const content =
			(page === "email") ?							// メールアドレスの入力
				<InputEmail
					onFinish={() => {
						this.setState({
							page: "digitCode",
						})
					}}
				/> :
				(page === "digitCode") ?					// DigitCodeの入力
					<InputDigitCode
						digitsId={digitsId}
						appliedEmail={appliedEmail}
						expiredDesc={<span>お手数ですが、<a onClick={() => {
							this.setState({
								page: "email",
							})
						}}>再度確認メールを受信</a> して下さい</span>}
						onFinish={() => {
							this.setState({page: "password1"})
						}}
						onCancel={() => {
							this.setState({
								page: "email",
							})
						}}
					/> :
					(page === "password1") ?					// 新しいパスワードの入力
						<InputPassword
							onFinish={() => {
								this.setState({
									page: "confirm",
								})
							}}
							onCancel={() => {
								this.setState({
									page: "email",
								})
							}}
						/> :
						<ConfirmPassword
							onFinish={() => {
								this.setState({
									completedDialogOpen: true,
								})
							}}
							onCancel={() => {
								this.setState({
									page: "password1",
								})
							}}
						/>

		return (
			<div className="wrapper">
				<TopHeader/>
				<div className="content-wrapper">
					<div className="container">
						{content}
					</div>
				</div>
				<Dialog open={this.state.completedDialogOpen} disableBackdropClick>
					<DialogTitle>パスワードの変更が完了しました</DialogTitle>
					<DialogActions>
						<Button
							color="primary"
							href={urlPref+"/login"}
						>
							ログインページへ
						</Button>
					</DialogActions>
				</Dialog>
				<footer className="main-footer">
					<div className="container">
						<strong>Copyright © <a href="http://www.jc-edu.co.jp">JC Educational Institute, Inc.</a>.</strong> All
						rights reserved.
					</div>
				</footer>
			</div>
		)
	}
}
