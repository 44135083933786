// @flow
import * as React from 'react'
// import PropTypes from 'prop-types'
import Selector from './Selector'
import type { MenuRow } from './Selector'
import Icon from '@material-ui/core/Icon'
import IconButton from '@material-ui/core/IconButton'

type Props = {
	menuList: Array<MenuRow>,
	value?: any,
	onChange?: (string|number)=>void,
}
// type _Props = Props & {
// 	iconName: string,
// }
const IconSelector = (props: Props) => {
	// if (!props.value) return null
	// if (!props.onChange) return null
	return (
		<Selector
			menuList={props.menuList}
			value={props.value}
			onChange={props.onChange}
			trigger={
				(selected?: MenuRow, handleOpen: (event: SyntheticEvent<HTMLButtonElement>) => void) => {
					const {menuList, value, ...other} = props 	// eslint-disable-line no-unused-vars
					const iconName = props.iconName || "more_vert"
					return (
						<IconButton
							aria-label="More"
							// aria-owns={this.state.open ? 'selector-menu' : null}
							aria-haspopup="true"
							onClick={handleOpen}
						>
							<Icon>{iconName}</Icon>
						</IconButton>
					)
				}
			}
		/>
	)
}

export default IconSelector
