import * as Action from './MyPageAction';
import { Record } from 'immutable'
import {urlPref} from '../alt/constants'

// アクティブなタブの初期インデクス
let myPageTabIndex = 0
if (window.location.pathname.indexOf(urlPref+"/my/lesson") === 0) {
	myPageTabIndex = 1
} else if (window.location.pathname.indexOf(urlPref+"/q") === 0) {
	myPageTabIndex = 1
} else if (window.location.pathname.indexOf(urlPref+"/my/hist") === 0) {
	myPageTabIndex = 2
} else if (window.location.pathname.indexOf(urlPref+"/my/bbs") === 0) {
	myPageTabIndex = 3
}

const _MyPageStateRecord = Record({
	printMode: false,
	myPageTabIndex: myPageTabIndex,
	searchResult: new Action.SearchResultState(),
	infoList: [],
	lastInfoFetched: null,
})
class MyPageState extends _MyPageStateRecord {}


// const initialState = {
// 	// currentMyPageTab: "mypage-top",
// 	myPageTabIndex: myPageTabIndex,
// 	searchResult: new Action.SearchResultEntity(),
// };

export default function myPageReducer(state = new MyPageState(), action) {
  switch (action.type) {
		case Action.UPDATE_CURRENT_MYPAGE_TAB:
			return state.set("myPageTabIndex", action.payload)
		case Action.UPDATE_SEARCH_RESULT:
			return state.set("searchResult", action.payload)
		case Action.UPDATE_PRINT_MODE:
			return state.set("printMode", action.payload)
		case Action.UPDATE_INFO_LIST:
			return state.set("infoList", action.payload)
		case Action.UPDATE_LAST_INFO_FETCHED:
			return state.set("lastInfoFetched", action.payload)
    default:
      return state
  }
}
