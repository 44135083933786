// @flow
import React, {Component} from 'react'
import {urlPref} from '../alt/constants'

type Props = {
	shujukudo: number,
	goukakudo: number,
}
export default class ShujukudoBox extends Component<Props,{}> {
	render() {
		const getImageIndex = (value) => {
			return (value < 25) ? "1" :
				(value < 50) ? "2" :
					(value < 75) ? "3" :
						(value < 100) ? "4" : "5"
		}
		const {shujukudo, goukakudo} = this.props
		const shujukudoImage = (goukakudo < 100) ? `url('${urlPref}/images/eval/fukurou_silver${getImageIndex(shujukudo)}_h40.gif')` : `url('${urlPref}/images/eval/fukurou_gold_h40.gif`
		return (
			<div>
				<div style={{
					height: 40,
					backgroundPosition: 'bottom 0px right 0px',
					backgroundImage: shujukudoImage,
					backgroundRepeat: 'no-repeat'
				}}>
					<span>{shujukudo}%</span>
				</div>
			</div>
		)
	}
}
