// @flow
import {createAction} from 'redux-actions'
import G from '../SlGlobal'
import {post} from '../sl_utils/Http'
import Cookies from "js-cookie";
import {loadingVisibilityAC, updateSnackbarAC} from "../sl_utils/SlUtilsAction";
import {altName} from '../../alt/constants'

export const UPDATE_AUTH_STATE = 'UPDATE_AUTH_STATE'
export const updateAuthStateAC = createAction(UPDATE_AUTH_STATE, authState => (authState));


export function login(loginEntity: any) {
	Cookies.set(altName, loginEntity.token, {expires:365})
	delete loginEntity.token
	G.store.dispatch(updateAuthStateAC({isAuthenticated: true, state: loginEntity}))
}

export function logout() {
	Cookies.remove(altName)
	G.store.dispatch(updateAuthStateAC({isAuthenticated: false, state: null}))
}

export function fetchLogin(email: string, password: string, role: string, onLogin?: () => void, onError?: (message: string) => void) {
	const payload = {
		email: email,
		password: password,
		role: role,
	}
	G.store.dispatch(loadingVisibilityAC(true))
	post("user/signin", payload)
	.then(ret => {
		G.store.dispatch(loadingVisibilityAC(false))
		login(ret)
		G.store.dispatch(updateSnackbarAC("ログインしました"))
		if (onLogin) onLogin()
	})
	.catch(res => {
		G.store.dispatch(loadingVisibilityAC(false))
		if (onError) {
			let message;
			if (res && res.statusCode === 401) {
				message = "ログインできませんでした";
			} else {
				message = "不明なエラーが発生しました"
			}
			onError(message)
		}
	})
}

/* ===================================================================
 * fetchLoginState            ログイン状態をサーバに確認する
 *                            @param dispatch dispatcher
 * ===================================================================
 */
// export function fetchLoginState(user_token: string): any {
// 	return new Promise((resolve, reject) => {
// 		post("user/auth_jwt", {user_token})
// 			.then(ret => {
// 				resolve(ret)
// 			}).catch(res => {
// 				localStorage.removeItem('sl_users');
// 				reject()
// 			});
// 	})
// }


export function fetchJwt() {
	const user_token = Cookies.get(altName);
	if (user_token) {
		post("user/auth_jwt", {user_token})
		.then((ret) => {
			login(ret)
		}).catch(() => {
			if (window.confirm("認証に失敗しました。ログアウトしますか？")) {
				logout()
			} else {
				location.reload()
			}
			// logout()
		})
	} else {
		logout()
	}
}
