// @flow
import React from 'react'
import ReactDOM from 'react-dom'
import _ from 'lodash'
import {loadingVisibilityAC, updateSnackbarAC} from './SlUtilsAction'
import G from '../SlGlobal'
import {animateScroll} from "react-scroll/modules/index";

export function loading(visibility:boolean) {
	G.store.dispatch(loadingVisibilityAC(visibility))
}

export function snackbar(text:string) {
	G.store.dispatch(updateSnackbarAC(text))
}

export function scrollToElWithName(name:string) {
	const el = document.querySelector(`[name="${name}"]`);
	if (el) {
		animateScroll.scrollTo(el.getBoundingClientRect().top + window.pageYOffset - 50)
	}
}


export function snakeToCamel(object:any) {
	let out = {}
	_.forOwn(object, (v,k) => {
		out[_.camelCase(k)] = v
	})
	return out
}

export function snakeToCamelDeep(object:any) {
	let out = {}
	_.forOwn(object, (v,k) => {
		if (typeof v === "object") v = snakeToCamel(v);
		out[_.camelCase(k)] = v
	})
	return out
}

export function camelToSnake(object:any) {
	let out = {}
	_.forOwn(object, (v,k) => {
		out[_.snakeCase(k)] = v
	})
	return out
}

export function camelToSnakeDeep(object:any) {
	let out = {}
	_.forOwn(object, (v,k) => {
		if (typeof v === "object") v = snakeToCamel(v);
		out[_.snakeCase(k)] = v
	})
	return out
}

export function normalizeRow(object:any) {
	let out = {}
	_.forOwn(object, (v,k) => {
		if (v === null) v = ""
		out[_.camelCase(k)] = v
	})
	return out
}

export function assert(value:boolean) {
	if (!value) {
		console.error("!!! assert")
		debugger
	}
}

export function scrollInto(ref:React$Component<any>) {
	const node:any = ReactDOM.findDOMNode(ref);
	if (node) {
		node.scrollIntoView();
	}
}

export function getUniqueStr(strength?:number) {
	strength = strength || 1000;
	return new Date().getTime().toString(16)  + Math.floor(strength*Math.random()).toString(16)
}

export function	lfToBr(text:string) {
	const regex = /(\n)/g
	return text.split(regex).map(function (line, index) {
		if (line.match(regex)) {
			return React.createElement("br", {key: index})
		} else {
			return line;
		}
	});
}

export function extractFileName(filePath:string) {
	return filePath.match(/(.*)(?:\.([^.]+$))/)
}

export function getBrowser() {
	const ua = window.navigator.userAgent.toLowerCase();
	const browsers = [
		{name: "IE", re: /(msie|trident)/},
		{name: "EDGE", re: /(edge)/},
		{name: "CHROME", re: /(chrome)/},
		{name: "SAFARI", re: /(safari)/},
		{name: "FIREFOX", re: /(firefox)/},
		{name: "OPERA", re: /(opera)/},
	]
	const browser = _.find(browsers, browser => {
		return (ua.match(browser.re) !== null)
	})
	if (browser) return browser.name
	return "UNKNOWN"
}