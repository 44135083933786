import React from 'react'
import { Route, Switch } from 'react-router-dom'
import BbsTop from './BbsTop';
import BbsBoard1 from './BbsBoard1';
import BbsThread1 from './BbsThread1';
import BbsThread from './BbsThread';
import BbsPost from './BbsPost';
import ContactThread from './ContactThread';
import ContactPost from './ContactPost';

export default class Bbs extends React.Component {
	render() {
		return (
			<div style={{height:'auto', marginTop: 20}}>
				<Switch>
					<Route exact path="/my/bbs" component={BbsTop}/>
					<Route exact path="/my/bbs/board1" component={BbsBoard1}/>
					<Route exact path="/my/bbs/thread1/:ident" component={BbsThread1}/>
					<Route exact path="/my/bbs/thread/:boardId" component={BbsThread}/>
					<Route exact path="/my/bbs/posts/:threadId" component={BbsPost}/>
					<Route exact path="/my/bbs/contact" component={ContactThread}/>
					<Route exact path="/my/bbs/contact/posts/:threadId" component={ContactPost}/>
				</Switch>
			</div>
		)
	}
}
