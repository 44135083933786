// @flow
import React from 'react'
import {withRouter} from 'react-router-dom'
import {connect} from 'react-redux'
import {Flex, Box} from 'reflexbox'
import {LessonToolBar, SubjectButton} from './CommonParts'
import Paper from '@material-ui/core/Paper'
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import CheckboxSet from '../../sonodalib/sl_mui/CheckboxSet'
import Selector from '../../sonodalib/sl_adminlte/Selector'
import {updateSearchResultAC} from '../MyPageAction'
import {updateQueryConditionAC} from '../../question/QuestionAction'
import {post} from '../../sonodalib/sl_utils/Http'
import _ from 'lodash'
import BsLikeTextField from '../../sonodalib/sl_mui/BsLikeTextField'
import {OkCancelModal} from "../../sonodalib/sl_adminlte/Modals";
import {TextInputState, CheckboxArrayState} from '../../sonodalib/sl_utils/SlForms'
import {questionTypeLabels, AuthError, createNendoList} from '../../Common'
import FormControl from '@material-ui/core/FormControl'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import {startOxQuestions} from '../../question/Question'
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import IsMobile from 'ismobilejs'
import ListSelector from '../../parts/ListSelector'
import {common, grey} from "@material-ui/core/colors/index";
import Cookies from "js-cookie";
import Collapse from "@material-ui/core/Collapse";
import {QueryHelpContent} from '../HelpContent'
import {altName, categoryLabels} from '../../alt/constants'
import Dialog from "@material-ui/core/Dialog";
import FcLabel from '../../parts/FcLabel'


type Props = {
	category: number,
	qtype: string,
	onGoBack: ()=>void,
	history: any,
	updateQueryCondition: any,
	startOxQuestions: any,
	updateSearchResult: any,
	queryCondition: any,
	userState: any,
}
type States = {
	pickType: string,
	queryMode: string,
	qCount: number,
	keyword: TextInputState,
	mondaiNum: TextInputState,
	subjects?: Array<any>,
	showErrorModal: boolean,
	nendoState: CheckboxArrayState,
	showAuthErrorModal: boolean,
	openPickType: boolean,
	openMode: boolean,
	openQCount: boolean,
	openHelp: boolean,
	openStartingDialog: boolean,
}
class OxQuery extends React.Component<Props,States> {
	// static propTypes = {
	// 	category: PropTypes.number.isRequired,
	// 	qtype: PropTypes.string.isRequired,
	// 	onGoBack: PropTypes.func.isRequired,
	// }

	state:States = {
		pickType: 'random',
		queryMode: 'training',
		qCount: 10,
		keyword: new TextInputState(),
		mondaiNum: new TextInputState(),
		subjects: undefined,
		showErrorModal: false,
		nendoState: null,
		showAuthErrorModal: false,
		openPickType: false,
		openMode: false,
		openQCount: false,
		openHelp: false,
		openStartingDialog: false,
	}

	componentDidMount() {
		// お試し（license=="0"）の時は、２番目以外の年度を disabled にする
		// const license = (this.props.userState) ? this.props.userState.license : null
		// if (license && license === "0") {
		// 	const list = this.state.nendoState.getList().map((row, index) => {
		// 		if (index === 1) return row
		// 		row.checked = false
		// 		row.disabled = true
		// 		return row
		// 	})
		// 	this.setState({
		// 		nendoState: new CheckboxArrayState({list})
		// 	})
		// }

		const self = this
		post("q/fetch_ox_query", {
			user_token: Cookies.get(altName),
			category: this.props.category,
			qtype: this.props.qtype,
		})
		.then(ret => {
			let newState:any = {
				subjects: ret.subjects,
			}
			newState.nendoState = new CheckboxArrayState({list:createNendoList(ret.nendo_limited)})
			if (ret.option) {
				const opt = JSON.parse(ret.option)
				newState.pickType = opt.pick_type
				newState.queryMode = opt.query_mode
				newState.qCount = opt.qcount
				newState.keyword = this.state.keyword.setValue(opt.keyword)
				newState.mondaiNum = this.state.mondaiNum.setValue(opt.mondaiNum)
				// const checked = opt.nendo.split(",").map(x => (parseInt(x,10))).filter((num) => {
				// 	const nendo = _.find(ret.nendo_list, {value:num})
				// 	return (nendo && !nendo.disabled)
				// })
				const checked = opt.nendo.split(",").map(x => (parseInt(x,10)))
				newState.nendoState = newState.nendoState.setCheckedByValues(checked, true)
				// newState.nendoState = this.state.nendoState.setCheckedByValues(opt.nendo.split(",").map(x => (parseInt(x,10))))
			}
			this.setState(newState)
		})
		.catch(ret => {
			if (ret.status === 401) {
				self.setState({
					showAuthErrorModal: true,
				})
			}
		})
	}

	handleChangeNendo = (index, checked) => {
		this.setState({
			nendoState: this.state.nendoState.setChecked(index, checked),
		})
	}

	handleSubjectClick = (subjectCsv:string) => {
		const self = this
		// --------------------------
		// トレーニングとテストの検索
		// --------------------------
		if (this.state.queryMode !== "search") {
			const nendo = this.state.nendoState.getValues()
			if (!nendo.length) {
				this.setState({
					showErrorModal: true,
				})
				return
			}
			let mondaiNum = this.state.mondaiNum.getValue()
			if (!(mondaiNum.length === 0 || isFinite(mondaiNum))) {
				mondaiNum = ""
				this.setState({mondaiNum: this.state.mondaiNum.setValue("")})
			}
			const keyword = this.state.keyword.getValue().trim()
			const payload = {
				user_token: Cookies.get(altName),
				subject: subjectCsv,												// 科目
				qtype: this.props.qtype,										// 0=選択 1=OX
				pick_type: this.state.pickType,							// random=ランダム newQuestion=未解答 failedQuestion=不正解
				query_mode: this.state.queryMode,						// training=トレーニング test=テスト
				qcount: this.state.qCount,									// 出題数
				nendo: nendo,																// 年度 e.g.) [2012,2010,2008]
				keyword: keyword,														// キーワード
				mondaiNum: mondaiNum,												// 問題番号
			}
			const option = {
				pick_type: this.state.pickType,							// random=ランダム newQuestion=未解答 failedQuestion=不正解
				query_mode: this.state.queryMode,						// training=トレーニング test=テスト
				qcount: this.state.qCount,									// 出題数
				nendo: nendo.join(","),											// 年度 e.g.) [2012,2010,2008]
				keyword: keyword,														// キーワード
				mondaiNum: mondaiNum,												// 問題番号
			}
			const opt_payload = {
				user_token: Cookies.get(altName),
				category: this.props.category,
				qtype: this.props.qtype,										// 0=選択 1=OX
				value: JSON.stringify(option)
			}

			// 検索条件を storeに保存
			this.props.updateQueryCondition(this.props.queryCondition.merge(payload))

			// payload.user_token = Cookies.get(altName)
			post("q/save_ox_option", opt_payload)
			.then(() => {
				this.setState({
					openStartingDialog: true,
				}, () => {
					post("q/search", payload)
					.then(ret => {
						if (ret.questions.length === 0) {
							this.setState({
								showErrorModal: true,
							})
						} else {
							self.props.startOxQuestions(self.props.history, ret.questions)
						}
					})
					.finally(() => {
						this.setState({
							openStartingDialog: false,
						})
					})
				})
			})

			// ----------
			// 過去問検索
			// ----------
		} else {
			// let mondaiNum = this.state.mondaiNum.getValue()
			// // debugger
			// if (!(mondaiNum.length === 0 || isFinite(mondaiNum))) {
			// 	mondaiNum = ""
			// 	this.setState({mondaiNum: this.state.mondaiNum.setValue("")})
			// }
			// const payload = {
			// 	subject: KamokuID,							// 科目
			// 	pick_type: this.state.pickType,		// random=ランダム newQuestion=未解答 failedQuestion=不正解
			// 	keyword: this.state.keyword.getValue(),		// キーワード
			// 	mondaiNum: mondaiNum,							// 問題番号
			// }
			// post("q/search_by_keyword", payload)
			// .then(ret => {
			// 	if (ret.result.length === 0) {
			// 		this.setState({
			// 			showErrorModal: true,
			// 		})
			// 	} else {
			// 		ret.subjectLabel = Kamokumei
			// 		this.props.updateSearchResult(Immutable.fromJS(ret))
			// 		self.props.history.push("/my/lesson/query/ox/result")
			// 	}
			// })
		}
	}

	handleOkErorModal = () => {
		this.setState({
			showErrorModal: false,
		})
	}

	handleSelectQCount = value => {
		this.setState({
			qCount: value,
		})
	}

	handlePickType = (event, value) => {
		this.setState({
			pickType: value,
		})
	}

	handleQueryMode = (event, value) => {
		this.setState({
			queryMode: value,
		})
	}

	render() {
		if (!this.state.subjects) return null
		return (IsMobile.any) ? this.renderMobile() : this.renderPC()
	}

	handleToggleHelp = () => {
		this.setState({
			openHelp: !this.state.openHelp,
		})
	}

	renderPC() {
		const styles = {
			wrapBox: {
				width: 720,
			},
			innerSubjectBox: {
				padding: 10,
			},
			queryOptionTitle: {
				fontSize: 12,
				fontWeight: 700,
				marginTop: 8,
				marginBottom: 8,
			},
			queryOptionBox: {
				marginTop: 8,
				marginBottom: 8,
			},
			radio: {
				height: 26,
			},
			button: {
				marginTop: 8,
				color: common.black,
				backgroundColor: grey[200],
				width: "100%",
				textAlign: 'center',
			},
		}
		// const {category, qtype} = this.props
		const {qCount, openHelp} = this.state

		const subjects = (this.state.subjects === null) ? null : _.map(this.state.subjects, subject => {
			return (
				<Box p={1} key={subject.KamokuID}>
					<SubjectButton
						subject={subject.Kamokumei}
						onClick={this.handleSubjectClick.bind(this, ""+subject.KamokuID)}
					/>
				</Box>
			)
		})

		const subjectCsv = (this.state.subjects) ? this.state.subjects.map((subject) => (subject.KamokuID)).join(",") : ""
		const optionBox = (this.state.queryMode !== "search") ? (
			<div>
				<div style={styles.queryOptionTitle}>出題数</div>
				<div style={styles.queryOptionBox}>
					<Selector
						className="form-control"
						onChange={this.handleSelectQCount}
						value={qCount}
					>
						<option value={10}>10問</option>
						<option value={20}>20問</option>
						<option value={40}>40問</option>
					</Selector>
				</div>
				<Divider/>
				<div style={styles.queryOptionTitle}>年度</div>
				<div style={styles.queryOptionBox}>
					<CheckboxSet
						state={this.state.nendoState}
						onChange={this.handleChangeNendo.bind(this)}
					/>
				</div>
				<div style={styles.queryOptionTitle}>キーワード（スペース区切り）</div>
				<BsLikeTextField
					type="text"
					className="form-control"
					id="keyword"
					placeholder="キーワード"
					state={this.state.keyword}
					onChange={(newState) => {
						this.setState({keyword: newState})
					}}
				/>
				<div style={styles.queryOptionTitle}>問題番号</div>
				<BsLikeTextField
					type="number"
					min={0}
					className="form-control"
					id="mondaiNum"
					placeholder="問題番号"
					state={this.state.mondaiNum}
					onChange={(newState) => {
						this.setState({mondaiNum: newState})
					}}
				/>
				<Button
					variant="contained"
					style={styles.button}
					onClick={this.handleSubjectClick.bind(this, subjectCsv)}
				>
					すべての科目
				</Button>
			</div>
		) : (
			<div>
			</div>
		)

		return (
			<div>
				<LessonToolBar
					label="科目の選択"
					onGoBack={this.props.onGoBack}
				/>
				<FormControl required fullWidth={true}>
					{/*<SelectedBox*/}
						{/*leftContent={*/}
							{/*<div>*/}
								{/*選択したカテゴリー<br/>*/}
								{/*<CategoryButton*/}
									{/*category={category}*/}
									{/*onClick={this.props.onGoBack}*/}
								{/*/>*/}
							{/*</div>*/}
						{/*}*/}
						{/*rightContent={*/}
							{/*<div>*/}
								{/*選択した問題形式<br/>*/}
								{/*<QTypeButton*/}
									{/*qtype={qtype}*/}
									{/*onClick={this.props.onGoBack}*/}
								{/*/>*/}
							{/*</div>*/}
						{/*}*/}
					{/*/>*/}
					<Collapse in={openHelp}>
						<Flex align="center" justify='space-around'>
							<Box pt={1}>
								<Paper elevation={2} style={{width:720,padding:10}}>
									<QueryHelpContent/>
								</Paper>
							</Box>
						</Flex>
					</Collapse>
					<Flex align="center" justify='space-around'>
						<Box pt={1}>
							<Paper elevation={2} style={styles.wrapBox}>
								<Flex style={styles.innerSubjectBox}>
									<Box col={8}>
										<Flex column>
											{subjects}
										</Flex>
									</Box>
									<Box col={4} pl={2} style={{position:'relative'}}>
										<Button variant="contained" color="secondary" onClick={this.handleToggleHelp} style={{position:'absolute',right:0,top:0}}>
											{(!openHelp) ? "使い方" : "閉じる"}
										</Button>
										<div style={styles.queryOptionTitle}>出題方式</div>
										<div style={styles.queryOptionBox}>
											<RadioGroup
												aria-label="出題方式"
												name="pickType"
												value={this.state.pickType}
												onChange={this.handlePickType.bind(this)}
											>
												<FormControlLabel value="random" control={<Radio style={styles.radio}/>} label={<FcLabel>ランダム</FcLabel>}/>
												<FormControlLabel value="newQuestion" control={<Radio style={styles.radio}/>} label={<FcLabel>未解答</FcLabel>}/>
												<FormControlLabel value="failedQuestion" control={<Radio style={styles.radio}/>} label={<FcLabel>不正解</FcLabel>}/>
											</RadioGroup>
										</div>
										<Divider/>
										<div style={styles.queryOptionTitle}>モード</div>
										<div style={styles.queryOptionBox}>
											<RadioGroup
												aria-label="モード"
												name="queryMode"
												value={this.state.queryMode}
												onChange={this.handleQueryMode.bind(this)}
											>
												<FormControlLabel value="training" control={<Radio style={styles.radio}/>} label={<FcLabel>トレーニング</FcLabel>}/>
												<FormControlLabel value="test" control={<Radio style={styles.radio}/>} label={<FcLabel>テスト</FcLabel>}/>
											</RadioGroup>
										</div>
										<Divider/>
										{optionBox}
									</Box>
								</Flex>
							</Paper>
						</Box>
					</Flex>
				</FormControl>
				<OkCancelModal
					show={this.state.showErrorModal}
					okText="OK"
					bodyText="条件を満たす問題は見つかりませんでした"
					onOk={this.handleOkErorModal.bind(this)}
				/>
				<AuthError
					open={this.state.showAuthErrorModal}
				/>
				<Dialog
					open={this.state.openStartingDialog}
				>
					<div style={{padding: 20}}>
						読み込み中...
					</div>
				</Dialog>
			</div>
		)
	}

	renderMobile() {
		const {openHelp} = this.state

		const pickTypeLabels = {
			random: "ランダム",
			newQuestion: "未解答",
			failedQuestion: "不正解",
		}
		const modeLabels = {
			training: "トレーニング",
			test: "テスト",
		}
		const countLabels = {
			"10": "10問",
			"20": "20問",
			"40": "40問",
		}

		if (this.state.openPickType) {
			return (
				<ListSelector
					title="出題方式"
					labelKv={pickTypeLabels}
					onCancel={() => {
						this.setState({
							openPickType: false,
						})
					}}
					onUpdate={(value:string) => {
						this.setState({
							openPickType: false,
							pickType: value,
						})
					}}
				/>
			)
		}
		if (this.state.openMode) {
			return (
				<ListSelector
					title="モード"
					labelKv={modeLabels}
					onCancel={() => {
						this.setState({
							openMode: false,
						})
					}}
					onUpdate={(value:string) => {
						this.setState({
							openMode: false,
							queryMode: value,
						})
					}}
				/>
			)
		}
		if (this.state.openQCount) {
			return (
				<ListSelector
					title="出題数"
					labelKv={countLabels}
					onCancel={() => {
						this.setState({
							openQCount: false,
						})
					}}
					onUpdate={(value:string) => {
						this.setState({
							openQCount: false,
							qCount: parseInt(value,10),
						})
					}}
				/>
			)
		}

		const styles = {
			queryOptionTitle: {
				fontSize: 12,
				fontWeight: 700,
				marginTop: 8,
				marginBottom: 8,
			},
			queryOptionBox: {
				marginTop: 8,
				marginBottom: 8,
			},
			button: {
				marginTop: 8,
				color: common.black,
				backgroundColor: grey[200],
				width: "100%",
				textAlign: 'center',
			},
		}
		const {category, qtype} = this.props
		// const {qCount} = this.state

		const subjects = (this.state.subjects === null) ? null : _.map(this.state.subjects, subject => {
			return (
				<ListItem key={subject.KamokuID} button onClick={this.handleSubjectClick.bind(this, ""+subject.KamokuID)}>
					<ListItemText primary={subject.Kamokumei}/>
				</ListItem>
			)
		})

		const subjectCsv = (this.state.subjects) ? this.state.subjects.map((subject) => (subject.KamokuID)).join(",") : ""
		const optionBox = (this.state.queryMode !== "search") ? (
			<div style={{marginLeft: 10}}>
				<div style={styles.queryOptionTitle}>年度</div>
				<div style={styles.queryOptionBox}>
					<CheckboxSet
						state={this.state.nendoState}
						onChange={this.handleChangeNendo.bind(this)}
					/>
				</div>
			</div>
		) : (
			<div>
				<div style={styles.queryOptionTitle}>キーワード（スペース区切り）</div>
				<BsLikeTextField
					type="text"
					className="form-control"
					id="keyword"
					placeholder="キーワード"
					state={this.state.keyword}
					onChange={(newState) => {
						this.setState({keyword: newState})
					}}
				/>
				<div style={styles.queryOptionTitle}>問題番号</div>
				<BsLikeTextField
					type="number"
					className="form-control"
					id="mondaiNum"
					placeholder="問題番号"
					state={this.state.mondaiNum}
					onChange={(newState) => {
						this.setState({mondaiNum: newState})
					}}
				/>
			</div>
		)

		return (
			<div>
				<LessonToolBar
					label="科目の選択"
					onGoBack={this.props.onGoBack}
				/>
				<Button fullWidth={true} color="secondary" onClick={this.handleToggleHelp}>
					{(!openHelp) ? "使い方を表示" : "使い方を閉じる"}
				</Button>
				<Collapse in={openHelp}>
					<div style={{margin:5,border:'2px dotted grey',padding:8}}>
						<QueryHelpContent/>
					</div>
				</Collapse>
				<FormControl required fullWidth={true}>
					<List
						component="nav"
						// subheader={<ListSubheader component="div">カテゴリーを選択して下さい</ListSubheader>}
					>
						<ListItem button onClick={this.props.onGoBack}>
							<ListItemText primary={categoryLabels[category]}/>
						</ListItem>
						<ListItem button onClick={this.props.onGoBack}>
							<ListItemText primary={questionTypeLabels[qtype]}/>
						</ListItem>
						<ListItem button onClick={() => {
							this.setState({openPickType: true})
						}}>
							<ListItemText primary="出題方式" secondary={pickTypeLabels[this.state.pickType]}/>
						</ListItem>
						<ListItem button onClick={() => {
							this.setState({openMode: true})
						}}>
							<ListItemText primary="モード" secondary={modeLabels[this.state.queryMode]}/>
						</ListItem>
						<ListItem button onClick={() => {
							this.setState({openQCount: true})
						}}>
							<ListItemText primary="出題数" secondary={countLabels[""+this.state.qCount]}/>
						</ListItem>
					</List>
					{optionBox}
					<Divider/>
					<List
						component="nav"
						subheader={<ListSubheader component="div">科目を選択して学習</ListSubheader>}
					>
						{subjects}
					</List>

					<div style={{marginLeft:8,marginRight:8}}>
						<div style={styles.queryOptionTitle}>キーワード（スペース区切り）</div>
						<BsLikeTextField
							type="text"
							className="form-control"
							id="keyword"
							placeholder="キーワード"
							state={this.state.keyword}
							onChange={(newState) => {
								this.setState({keyword: newState})
							}}
						/>
						<div style={styles.queryOptionTitle}>問題番号</div>
						<BsLikeTextField
							type="number"
							min={0}
							className="form-control"
							id="mondaiNum"
							placeholder="問題番号"
							state={this.state.mondaiNum}
							onChange={(newState) => {
								this.setState({mondaiNum: newState})
							}}
						/>
						<Button
							style={styles.button}
							onClick={this.handleSubjectClick.bind(this, subjectCsv)}
						>
							すべての科目
						</Button>
					</div>
				</FormControl>
				<OkCancelModal
					show={this.state.showErrorModal}
					okText="OK"
					bodyText="条件を満たす問題は見つかりませんでした"
					onOk={this.handleOkErorModal.bind(this)}
				/>
				<AuthError
					open={this.state.showAuthErrorModal}
				/>
				<Dialog
					open={this.state.openStartingDialog}
				>
					<div style={{padding: 20}}>
						読み込み中...
					</div>
				</Dialog>
			</div>
		)
	}
}

export default connect(
	state => ({
		queryCondition: state.question.queryCondition,
		userState: state.sl_users.state,
	}),
	dispatch => ({
		updateQueryCondition: queryCondition => {
			dispatch(updateQueryConditionAC(queryCondition))
		},
		startOxQuestions: (history, questions) => {
			startOxQuestions({
				dispatch,
				history,
				questions,
				repeat: true,
			})
		},
		updateSearchResult: searchResult => {
			dispatch(updateSearchResultAC(searchResult))
		},
	})
)(withRouter(OxQuery))
