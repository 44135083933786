// @flow weak
import React from 'react'
import {connect} from 'react-redux'
import _ from 'lodash'
import {red, blue, green, deepOrange, common} from '@material-ui/core/colors'
import '../sonodalib/sl_utils/utils.css'
import {QuestionBox} from '../Common'
import Moment from 'moment'
import Cookies from "js-cookie";
import {post} from '../sonodalib/sl_utils/Http'
import Button from '@material-ui/core/Button'
import {altName, urlPref} from '../alt/constants'

type Props = {
	onClose: () => void,
	oxQuestions: any,
	oxResults: Array<any>,
}
type State = {
	statsList: Array<any>,
}
export default connect(
	state => ({
		queryCondition: state.question.queryCondition,
		oxQuestions: state.question.oxQuestions,
		oxResults: state.question.oxResults,
	}),
	dispatch => ({})
)(class extends React.Component<Props, State> {
	state = {
		statsList: [],
	}
	resultList: Array<any>

	componentWillMount() {
		const {oxQuestions, oxResults} = this.props
		if (!oxQuestions || !oxResults) {
			window.location.href = urlPref+"/my"
			return
		}
		const {questions} = oxQuestions
		this.resultList = questions.slice(0, oxResults.length)
		const marubatsu_ids = _.map(this.resultList, result => (result.MarubatsuID))

		const payload = {
			user_token: Cookies.get(altName),
			marubatsu_ids,
		}
		post("result/fetch/multi/ox/stats", payload, false)
		.then(ret => {
			this.setState({
				statsList: ret.stats_list,
			})
		})
	}

	render() {
		const styles = {
			alert: {
				background: common.black,
				padding: 10,
			},
			alertP: {
				color: common.white,
				fontWeight: 'bold',
				margin: 0,
				padding: 0,
				fontSize: 14,
			},
			pullRight: {
				textAlign: 'right',
			},
			sideMargin: {
				marginLeft: 12,
				marginRight: 12,
			},
			remark: {
				marginBottom: 10,
				fontWeight: 800,
			},
		}
		const {statsList} = this.state
		const {oxQuestions, oxResults} = this.props
		if (!statsList) return null

		const {questions} = oxQuestions
		const resultCounts = _.countBy(oxResults, result => (result))
		const judges = [
			{text: "正解", color: red[600]},
			{text: "不正解", color: blue[800]},
			{text: "未回答", color: green[800]},
		]
		const list = _.map(this.resultList, (question, index) => {
			const stats = _.find(statsList, stats => (stats.marubatsu_id === question.MarubatsuID))
			if (!stats) return null
			const oxResult = oxResults[index] 		// 1=正解  2=不正解  3=未回答
			const correctAnswerText = ["", "◯", "✕"]
			const judge = judges[oxResult - 1]
			const vd = (oxResults[index] === 3) ? (
				<div style={styles.sideMargin}>
					問題が解かれていません
				</div>
			) : (
				<div>
					<QuestionBox text={question.qText}/>
					<div style={styles.sideMargin}>
						<div style={styles.remark}>［正答］</div>
						<div style={styles.remark}>
							<span style={{
								color: deepOrange[600],
								fontWeight: 800,
								fontSize: 20,
								marginLeft: 20
							}}>{correctAnswerText[question.correctAnswer]}</span>
						</div>
					</div>
					<div style={styles.sideMargin}>
						<div style={styles.remark}>［解説］</div>
						{question.commentaryText}
					</div>
				</div>
			)
			return (
				<div key={index} style={{marginTop: 30}}>
					<div style={{marginBottom: 10}}>
						<span style={{fontSize: 20, fontWeight: 700, color: judge.color}}>[{judge.text}]　</span>
						<span style={{
							fontSize: 18,
							fontWeight: 700
						}}>第{question.qIndex}問 {question.subLabel} 累計{stats.total}回目 習熟度{stats.feather_count}</span>
					</div>
					{vd}
				</div>
			)
		})

		const currentTime = Moment(new Date()).format("YYYY-MM-DD HH:mm:ss")
		const subjectVd = questions[0].subjectLabel

		return (
			<div style={{backgroundImage: `url('${urlPref}/images/bg_print.jpg')`, width: 700}}>
				<div className="no-print">
					<Button color="secondary" variant="contained" onClick={this.props.onClose}>戻る</Button>
				</div>
				<div style={styles.alert}>
					<p style={styles.alertP}>※この印刷紙面の内容について、個人の学習以外の目的で使用すること、及び無断で複製・転載することを堅く禁止致します。株式会社ジェイシー教育研究所</p>
				</div>
				<div>
					<span style={{fontSize: 24, fontWeight: 700}}>[{subjectVd}] 今回の回答結果</span>
				</div>
				<div className="pull-right" style={styles.pullRight}>
					日時：{currentTime} 正解{resultCounts[1] || 0}問 不正解{resultCounts[2] || 0}問 未回答{resultCounts[3] || 0}問
				</div>
				{list}

				<div style={{...styles.pullRight, marginTop: 30}}>
					Copyright © 2017 JC Educational Institute, Inc. All Rights Reserved.
				</div>
			</div>
		)
	}
})
