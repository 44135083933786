import React from 'react'
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import {post_wm} from "../../sonodalib/sl_utils/Http";
import {updateMoshi} from "../MoshiAction";
import Cookies from "js-cookie";
import {altName} from "../../alt/constants";
import Button from '@material-ui/core/Button';
import Test from './'


class TestStart extends React.Component {
	state = {
		count: null,
		timeLimit: null,
	}

	componentDidMount() {
		const subjectCode = this.props.match.params.subjectCode
		post_wm("test/start", {
			user_token:  Cookies.get(altName),
			moshi_token: Cookies.get("wm_"+altName),
			subject_code: subjectCode,
		})
		.then((ret) => {
			if (!ret.count) {
				alert("問題がありません")
				this.props.history.push("/wm")
			} else {
				this.setState({
					count: ret.count,
					timeLimit: ret.time_limit,
				}, () => {
					const perPageState = Test.createTestPerPageState(ret.questions, 0)
					updateMoshi({
						testSubjectCode: subjectCode,
						testSubjectName: ret.subject_name,
						questions:       ret.questions,
						qno:						 0,
						answers:				 [],
						liner:					 true,
						orderno_resetindex: ret.orderno_resetindex,
						...perPageState
					})
				})
			}
		})
		.catch(() => {
			alert("エラーが発生しました")
			this.props.history.push("/wm")
		})
	}

	handleStart = () => {
		this.props.history.push("/wm/test/q")
	}

	render() {
		const {moshiState} = this.props
		const {count, timeLimit} = this.state
		if (!count) return null
		return (
			<div style={{
				marginTop: 20,
				marginBottom: 100,
				maxWidth: 800,
				marginLeft: "auto",
				marginRight: "auto",
			}}>
				<div style={{marginLeft: 8, marginRight: 8, fontSize:16}}>
					<div style={{marginBottom:20,fontSize:20,fontWeight:200,textAlign:'center',paddingTop:30,paddingBottom:30,borderTop:'1px solid #ccc',borderBottom:'1px solid #ccc'}}>
						<div>
							<span style={{fontSize:31, lineHeight:'40px',fontWeight:'bold'}}>{moshiState.testSubjectName}</span>
						</div>
						<div>
							<span style={{fontSize:21, lineHeight:'30px',fontWeight:200}}>{moshiState.serviceName}</span>
						</div>
					</div>
					<div style={{textAlign:'center', marginTop:40}}>
						<div style={{fontWeight:'bold', letterSpacing:2}}>
							この科目のテストは全<span style={{fontSize:18,color:'#b00'}}>{count}</span>問、制限時間は<span style={{fontSize:18,color:'#b00'}}>{timeLimit}</span>分です。
						</div>
						<div style={{marginTop:8}}>
							※ 出題順に解答してください。「あとで」を選ぶと、最後にやり直すことができます。
						</div>
						<div style={{marginTop:28}}>
							準備ができたら、次のスタートボタンを押してテストを始めてください。
						</div>
						<div style={{marginTop:38,textAlign:'center'}}>
							<Button
								variant="contained"
								color="primary"
								onClick={this.handleStart}
							>
								スタート
							</Button>
						</div>
					</div>
				</div>
			</div>
		)
	}
}
export default connect(
	state => ({
		moshiState: state.moshi,
	})
)(withRouter(TestStart));
