import React, {Component} from 'react'
import {connect} from 'react-redux';
import {login} from '../sonodalib/sl_users/SlUsersAction'
import Button from '@material-ui/core/Button';
import {grey} from '@material-ui/core/colors';
import TopHeader from './TopHeader'
// import Grid from '@material-ui/core/Grid'
import {updateSnackbarAC} from '../sonodalib/sl_utils/SlUtilsAction'
import {post} from '../sonodalib/sl_utils/Http'
// import { Flex, Box } from 'reflexbox'
import {PageHeader} from '../Common'
import {TextInputState} from '../sonodalib/sl_utils/SlForms'
import SlTextField from '../sonodalib/sl_mui/SlTextField'
import {updateCurrentMyPageTabAC} from "../mypage/MyPageAction";
import {urlPref} from '../alt/constants'


class LoginContainer extends Component {

	state = {
		emailState: new TextInputState({validations: "required,email"}),
		passwordState: new TextInputState({validations: "required,password"}),
	}

	handleChangePassword = event => {
		const value = event.target.value
		this.setState({
			passwordState: this.state.passwordState.setValue(value)
		})
	}

	handleSubmit = (event) => {
		event.preventDefault()
		this.setState({
			emailState: this.state.emailState.validate(),
			passwordState: this.state.passwordState.validate(),
		}, () => {
			const {emailState, passwordState} = this.state
			if (emailState.isValid() && passwordState.isValid()) {
				this.props.login(this, emailState, passwordState)
			}
		})
	}

	render() {
		// const styles = {
		// 	contentContainer: {
		// 	maxWidth: 800,
		// 	},
		// 	loginBox: {
		// 	maxWidth: 400,
		// 	},
		// };

		return (
			<div className="wrapper">
				<TopHeader/>
				<div className="content-wrapper" style={{marginTop: 20, marginBottom: 40}}>
					<div style={{maxWidth: 800, width: "100%", marginLeft: 'auto', marginRight: 'auto'}}>
						<PageHeader label="ログイン" mb={0}/>
						<div className="pull-right">
							<Button
								style={{color: grey[600], hoverColor: grey[100]}}
								href={urlPref+"/invite"}
								// icon={<FontIcon className="material-icons">person_add</FontIcon>}
							>
								招待コードを利用して登録する
							</Button>
							<Button
								style={{color: grey[600], hoverColor: grey[100]}}
								href={urlPref+"/top/reset-password"}
								// icon={<FontIcon className="material-icons">help</FontIcon>}
							>
								パスワードを忘れた
							</Button>
						</div>
					</div>
					<div style={{maxWidth: 400, width: "100%", marginLeft: 'auto', marginRight: 'auto'}}>
						<form onSubmit={this.handleSubmit}>
							<SlTextField
								autoFocus
								label="メールアドレス"
								placeholder="jcedu@example.com"
								state={this.state.emailState}
								onChange={(state) => {
									this.setState({emailState: state})
								}}
							/>
							<SlTextField
								label="パスワード"
								placeholder="英小文字と数字の組み合わせで8文字以上"
								state={this.state.passwordState}
								type="password"
								onChange={(state) => {
									this.setState({passwordState: state})
								}}
							/>
							<Button variant="contained"
											type="submit"
											color="primary"
											className="pull-right"
							>
								ログイン
							</Button>
						</form>
					</div>
				</div>
				<footer className="main-footer">
					<div className="container">
						<strong>Copyright © <a href="http://www.jc-edu.co.jp">JC Educational Institute, Inc.</a>.</strong> All
						rights reserved.
					</div>
				</footer>
			</div>
		)
	}
}

export default connect(
	state => ({}),
	dispatch => ({
		login: (component, emailState, passwordState) => {
			const payload = {
				email: emailState.getValue(),
				password: passwordState.getValue(),
				role: "user",
			}
			post("user/signin", payload)
			.then(ret => {
				login(ret)
				dispatch(updateCurrentMyPageTabAC(0))
				dispatch(updateSnackbarAC("ログインしました"))
			})
			.catch(res => {
				let errorMessage;
				if (res && res.statusCode === 401) {
					errorMessage = "メールアドレスまたはパスワードが間違っています";
				} else {
					post("etc/log",{error:"[CLI]LoginContainer:login " + ((!res) ? "unknown" : res.toString())})
					errorMessage = "不明なエラーが発生しました"
				}
				component.setState({
					emailState: emailState.setError(errorMessage),
					passwordState: passwordState.setError(errorMessage),
				})
			})
		},
	})
)(LoginContainer)
