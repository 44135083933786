import React from 'react'
import SougouTab from './SougouTab'
import Button from "@material-ui/core/Button";
import {updateMoshi} from "../MoshiAction";


export default class PrintSogo extends React.Component {
	render() {
		const {data} = this.props
		if (!data) return null
		return (
			<div style={{width:600}}>
				<SougouTab data={data}/>
				<div style={{textAlign:'right', marginTop:40}}>
					<Button
						variant="text"
						color="secondary"
						onClick={() => {
							updateMoshi({
								printMode: null
							})
						}}
						style={{border:'1px solid #f50057'}}
					>
						印刷画面を閉じる
					</Button>
				</div>
			</div>
		)
	}
}
