import React from 'react'
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import {post_wm} from "../../sonodalib/sl_utils/Http";
import {updateSct} from "../SctAction";
import Cookies from "js-cookie";
import {altName} from "../../alt/constants";
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Test from './'
import {blue} from "@material-ui/core/colors";


class TestStart extends React.Component {
	state = {
		count: null,
		// timeLimit: null,
	}

	componentDidMount() {
		const subjectCode = this.props.match.params.subjectCode
		post_wm("test/start", {
			user_token:  Cookies.get(altName),
			moshi_token: Cookies.get("wm_"+altName),
			subject_code: subjectCode,
		})
		.then((ret) => {
			if (!ret.count) {
				alert("問題がありません")
				this.props.history.push("/sct")
			} else {
				this.setState({
					count: ret.count,
					// timeLimit: ret.time_limit,
				}, () => {
					const perPageState = Test.createTestPerPageState(ret.questions, 0)
					updateSct({
						testSubjectCode: subjectCode,
						testSubjectName: ret.subject_name,
						questions:       ret.questions,
						qno:						 0,
						answers:				 [],
						liner:					 true,
						orderno_resetindex: ret.orderno_resetindex,
						...perPageState
					})
				})
			}
		})
		.catch(() => {
			alert("エラーが発生しました")
			this.props.history.push("/sct")
		})
	}

	handleStart = () => {
		this.props.history.push("/sct/test/q")
	}

	render() {
		const {sctState} = this.props
		const {count} = this.state
		if (!count) return null
		return (
			<div style={{
				marginTop: 20,
				marginBottom: 100,
				maxWidth: 800,
				marginLeft: "auto",
				marginRight: "auto",
			}}>
				<div style={{marginLeft: 8, marginRight: 8, fontSize:16}}>
					<div style={{marginBottom:20,textAlign:'center',paddingTop:20,paddingBottom:20,borderTop:'1px solid #ccc',borderBottom:'1px solid #ccc'}}>
						<span className="wf-roundedmplus1c" style={{fontSize:34,fontWeight:400,color:'#2080E4'}}>
							セルフチェックテスト
						</span>
					</div>
					<div>
						<Box my={4}>
							<span style={{fontSize:20, fontWeight:600, color:'#d37111'}}>■ {sctState.testSubjectName}</span>
						</Box>
						<Box p={2} style={{backgroundColor:'#f5ecd4'}}>
							<Box m={1} fontSize={20}>
								この科目のテストは全 {count} 問です。
							</Box>
							<Box m={1} style={{color:'red'}} fontSize={14}>
								※出題順に解答してください。「あとで」を選ぶと、最後にやり直すことができます。
							</Box>
						</Box>
						<Box my={3} fontSize={20}>
							解答の目安時間は{count}分間ですが、特に時間制限は設けていません。<br/>
							準備ができたら、次のスタートボタンを押してテストを始めてください。
						</Box>
					</div>
					<div>
						<div style={{marginTop:42,textAlign:'center'}}>
							<Button
								variant="contained"
								style={{backgroundColor:blue[400], color:'white'}}
								onClick={this.handleStart}
								size="large"
							>
								スタート
							</Button>
						</div>
					</div>
				</div>
			</div>
		)
	}
}
export default connect(
	state => ({
		sctState: state.sct,
	})
)(withRouter(TestStart));
