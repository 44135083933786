import React from 'react'
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import { grey } from '@material-ui/core/colors';
import Grid from '@material-ui/core/Grid'
import { PageHeader } from '../Common'
import {urlPref} from '../alt/constants'


export default class CommandBox extends React.Component {
	static propTypes = {
		label: PropTypes.string.isRequired,
		desc: PropTypes.string,
	}

	render() {
		const styles = {
			contentContainer: {
				width: "90%",
			},
			desc: {
				fontSize: 13,
				color: grey[700],
		  },
		}
		const descVd = (!this.props.desc) ? null : (
			<span style={styles.desc}>{this.props.desc}</span>
		)
		return (
			<Grid style={styles.contentContainer}>
				<PageHeader label={this.props.label} mb={0}/>
				<div className="pull-right">
					<Button
						href={urlPref+"/login"}
						style={{color: grey[600]}}
					>
						ログインに戻る
					</Button>
					<Button
						href={urlPref+"/top/reset-password"}
						style={{color: grey[600]}}
					>
						パスワードを忘れた
					</Button>
				</div>
				{descVd}
			</Grid>
		)
	}
}

