import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Redirect, withRouter } from 'react-router-dom'
import {fetchJwt} from '../sl_users/SlUsersAction'
// import {urlPref} from '../../alt/constants'

class AuthRoute extends Component {
  static propTypes = {
    isAuthenticated: PropTypes.bool.isRequired,
    // unauthPath: PropTypes.string.isRequired,
  };

  componentWillMount() {
    fetchJwt()
  }

  render() {
    const { isAuthenticated } = this.props
    if (!isAuthenticated) {
      return (
        <Redirect to={{
          // pathname: unauthPath,
          pathname: "/",
          state: {from: this.props.location}
        }}/>
      )
    } else {
      return null
    }
  }
}

export default connect(
  state => ({
    isAuthenticated: state.sl_users.isAuthenticated
  }),
)(withRouter(AuthRoute));
