
export function getPrintParams(data) {
	const cs = data.categories
	const categoryCount = cs.length
	return (categoryCount === 2) ? {
		categoryCount: 2,
		printButtonLabel: [
			"総合成績を印刷する",
			"専門成績を印刷する",	// cs[0].label + "・" + cs[1].label + "を印刷する"
		]
	} : {
		categoryCount: 3,
		printButtonLabel: [
			"総合成績・" + cs[0].label + "を印刷する",
			"専門成績を印刷する",	// cs[1].label + "・" + cs[2].label + "を印刷する"
		]
	}
}

