import React from 'react'
import { Route, Switch } from 'react-router-dom'
import HistoryTop from './HistoryTop';
import HistoryDetailPage from './HistoryDetailPage';
import SentakuShow from '../question/SentakuShow'
import OxShow from '../question/OxShow'
import HistorySingleDay from "./HistorySingleDay";

export default class History extends React.Component {
	render() {
		return (
			<div style={{height:'auto', marginTop: 20}}>
				<Switch>
					<Route exact path="/my/hist" component={HistoryTop}/>
					<Route exact path="/my/hist/detail/:mondaiType/:kamokuId" component={HistoryDetailPage}/>
					<Route exact path="/my/hist/singleday/:year/:month/:day" component={HistorySingleDay}/>
					<Route exact path="/my/hist/show/sentaku/:mondaiId" component={SentakuShow}/>
					<Route exact path="/my/hist/show/ox/:marubatsuId" component={OxShow}/>
				</Switch>
			</div>
		)
	}
}
