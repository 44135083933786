// @flow
import React from 'react'
import {post} from '../../sonodalib/sl_utils/Http'
import Grid from '@material-ui/core/Grid'
import type {InfoText} from '../../shared/DbModel'
import type {EtcInfoFetchResult} from '../../shared/ServerInterface'
import {connect} from "react-redux";
import Moment from 'moment'
import {updateInfoListAC, updateLastInfoFetchedAC} from '../MyPageAction'
import G from "../../sonodalib/SlGlobal";
import Cookies from "js-cookie";
import {altName} from '../../alt/constants'


type Props = {
	lastInfoFetched: any,
	infoList: Array<InfoText>,
}
type States = {
	// list: Array<InfoText>,
}
class InfoList extends React.Component<Props, States> {
	// state = {
	// 	list: [],
	// }

	componentDidMount() {
		this.fetch()
	}

	fetch = () => {
		const {lastInfoFetched} = this.props
		if (lastInfoFetched === null || lastInfoFetched.isBefore(Moment().subtract(10,'minutes'))) {
			post("etc/info/fetch", {
				user_token: Cookies.get(altName),
			})
			.then((ret: EtcInfoFetchResult) => {
				G.store.dispatch(updateInfoListAC(ret.info_texts))
				G.store.dispatch(updateLastInfoFetchedAC(Moment()))
				// this.setState({
				// 	list: ret.info_texts,
				// })
			})
		}
	}

	render() {
		const {infoList} = this.props
		//const {list} = this.state
		const vd = infoList.map((row: any, index: number) => {
			return (
				<div key={index}>
					<Grid container direction="row" spacing={2}>
						<Grid item xs={12}>
							<div className={`box box-${row.subject_color} box-solid`} style={{marginBottom:0}}>
								<div className="box-header with-border">
									<span className="box-title" style={{fontSize:14,fontWeight:800}}>{row.subject}</span>
								</div>
								<div className="box-body" dangerouslySetInnerHTML={{__html: row.body.replace(/\n/g,"<br />")}}>
								</div>
							</div>
						</Grid>
					</Grid>
				</div>
			)
		})
		return (
			<div style={{marginBottom:10}}>
				{vd}
			</div>
		)
	}
}

export default connect(
	state => ({
		lastInfoFetched: state.mypage.lastInfoFetched,
		infoList: state.mypage.infoList,
	}),
)(InfoList)

