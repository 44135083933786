import { createAction } from 'redux-actions'
import { Record } from 'immutable'
import G from "../sonodalib/SlGlobal";
import {gakushuFirstUrl} from '../alt/constants'

const _SearchResultState = Record({
	subjectLabel:	null,			// 科目名
	result:				null,			// 検索結果
})
export class SearchResultState extends _SearchResultState {
}

export const changeTab = (name, history, path) => {
	const values = {
		"my": {value: 0, path: "/my"},
		"lesson": {value: 1, path: gakushuFirstUrl},
		"history": {value: 2, path: "/my/hist"},
		"bbs": {value: 3, path: "/my/bbs"},
	}
	const row = values[name]
	G.store.dispatch(updateCurrentMyPageTabAC(row.value))
	history.push(path || row.path)
}


// マイページのタブを切り替えた時
export const UPDATE_CURRENT_MYPAGE_TAB = 'UPDATE_CURRENT_MYPAGE_TAB'
export const updateCurrentMyPageTabAC = createAction(UPDATE_CURRENT_MYPAGE_TAB, index => (index));

// 検索結果
export const UPDATE_SEARCH_RESULT = 'UPDATE_SEARCH_RESULT'
export const updateSearchResultAC = createAction(UPDATE_SEARCH_RESULT, entity => (entity));

// 印刷モード
export const UPDATE_PRINT_MODE = 'UPDATE_PRINT_MODE'
export const updatePrintModeAC = createAction(UPDATE_PRINT_MODE, entity => (entity));

// InfoList
export const UPDATE_INFO_LIST = 'UPDATE_INFO_LIST'
export const updateInfoListAC = createAction(UPDATE_INFO_LIST, entity => (entity));

// LastInfoFetched
export const UPDATE_LAST_INFO_FETCHED = 'UPDATE_LAST_INFO_FETCHED'
export const updateLastInfoFetchedAC = createAction(UPDATE_LAST_INFO_FETCHED, entity => (entity));


