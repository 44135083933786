import React from 'react'
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import {post_wm} from "../../sonodalib/sl_utils/Http";
import {updateMoshi} from "../MoshiAction";
import './marksheet.css'
// import ClassNames from 'classnames';
import Mark from './'
import Cookies from "js-cookie";
import {altName} from "../../alt/constants";
import {snackbar} from "../../sonodalib/sl_utils/Utils";


const markStyle = {
	display: 'inline-block',
	width:  24,
	height: 24,
	marginLeft: 4,
}


class MarkQuestion extends React.Component {
	componentDidMount() {
		const {moshiState} = this.props
		const {qno, markPerPageState} = moshiState
		if (!markPerPageState || qno === null) {
			this.props.history.push("/wm")
		}
	}

	// 次へ進むフローティングアイコンをクリック
	// handleNext = () => {
	// 	const {moshiState, history} = this.props
	// 	const {qno} = moshiState
	// 	const newQno = qno + 1
	// 	if (!liner || newQno === questions.length) {
	// 		updateMoshi({
	// 			answers: answers,
	// 		})
	// 		history.push("/wm/test/list")
	// 	} else {
	// 		const perPageState = Mark.createPerPageState(subjects[newQno])
	// 		updateMoshi({
	// 			qno: 		 newQno,
	// 			answers: answers,
	// 			...perPageState
	// 		})
	// 	}
	// }

	// 選択肢をクリック
	handleClick = (state, index) => () => {
		const {moshiState} = this.props
		const {markPerPageState} = moshiState
		if (state.choices[index]) {
			state.selectHistory = state.selectHistory.filter((x) => (x !== index))
		} else {
			if (state.selectHistory.length >= state.question.anscount) {
				const index = state.selectHistory.shift()
				state.choices[index] = false
			}
			state.selectHistory.push(index)
		}
		state.choices[index] = !state.choices[index]
		updateMoshi({
			markPerPageState: markPerPageState.concat(),
		})
	}

	handleSubmit = () => {
		const {moshiState} = this.props
		const {markPerPageState, markSubjects, qno} = moshiState
		const answers = markPerPageState.map(state => (
			state.choices.map((choice,index) => (choice) ? (index+1) : 0).filter(x => (x !== 0)).join("")
		))
		post_wm("mark/post", {
			user_token:   Cookies.get(altName),
			moshi_token:  Cookies.get("wm_"+altName),
			subject_code: markSubjects[qno].subject_code,
			answers:			answers,
		})
		.then(ret => {
			if (ret.error) {
				alert(ret.error)
			} else {
				snackbar("送信しました")
				const newQno = qno + 1
				if (newQno === markSubjects.length) {
					this.props.history.push("/wm")
				} else {
					updateMoshi({
						qno:							newQno,
						markPerPageState: Mark.createPerPageState(markSubjects[newQno]),
					})
				}
			}
		})
		.catch(() => {
			alert("通信エラーが発生しました")
		})
	}

	render() {
		const {moshiState} = this.props
		const {qno, markSubjects, markPerPageState} = moshiState
		if (!markSubjects || !markPerPageState) return null
		// const {subjects} 	= markSubjects
		// const subject 		= subjects[qno]
		// const {questions} = subject

		const markVd = (markPerPageState.map((state, qindex) => {
			const question = state.question
			const marks = state.choices.map((choice, index) => {
				const bgx = (choice) ? -225 : index * -25
				const _style = {
					...markStyle,
					background: "url(/imgms/moshi/mark.png) " + bgx + "px 0px"
				}
				// const cln = (choice) ? "ck" : (index+1)
				// const cl = {}
				// cl["mark_" + cln] = true
				// const _cl = ClassNames(cl)
				return (
					<a
						key={"m"+qindex+"-"+index}
						onClick={this.handleClick(state, index)}
						// className={_cl}
						style={_style}>
					</a>
				)
			})
			return (
				<Grid container key={"q"+qindex} style={{height:32}}>
					<Grid item style={{width:48,height:32,lineHeight:'32px',textAlign:'center',backgroundColor:(qindex & 1) ? "#9999cc" : "#8181bd",color:'white',fontWeight:600}}>
						{question.bangou}
					</Grid>
					<Grid item style={{width:marks.length*28+20,paddingTop:4,paddingLeft:10,paddingRight:10,height:32,lineHeight:'32px',textAlign:'center',backgroundColor:(qindex & 1) ? "#fff" : "#eee"}}>
						{marks}
					</Grid>
				</Grid>
			)
		}))
		return (
			<div style={{
				marginTop: 20,
				marginBottom: 100,
				maxWidth: 700,
				marginLeft: "auto",
				marginRight: "auto",
			}}>
				<div style={{marginLeft: 8, marginRight: 8, fontSize:16}}>
					<div style={{marginBottom:20,fontSize:20,fontWeight:200,textAlign:'center',paddingTop:30,paddingBottom:30,borderTop:'1px solid #ccc',borderBottom:'1px solid #ccc'}}>
						<div style={{fontSize:22, lineHeight:'30px',fontWeight:600}}>
							{markSubjects[qno].subject_name}
						</div>
						<div style={{fontSize:12, marginTop:7}}>
							全{markPerPageState.length}問
						</div>
					</div>
					<Grid container justify="center" style={{marginTop:40}}>
						<Grid item style={{border:'1px solid #888'}}>
							{markVd}
						</Grid>
					</Grid>
					<div style={{textAlign:'center',marginTop:40}}>
						<p>解答送信後は訂正できません。送信してよろしいですか？</p>
						<p><a onClick={this.handleSubmit} style={{cursor:'pointer'}}>
							<img src="/imgms/moshi/button_send.png" alt="解答送信"/>
						</a></p>
					</div>
				</div>
			</div>
		)
	}
}

export default connect(
	state => ({
		moshiState: state.moshi,
	})
)(withRouter(MarkQuestion));
