import React from 'react'
import FkToolbar from '../parts/FkToolbar'
import Cookies from "js-cookie";
import { withRouter } from 'react-router-dom'
import Moment from 'moment'
import { common, grey } from '@material-ui/core/colors';
import _ from 'lodash'
import {post} from '../sonodalib/sl_utils/Http'
import {altName} from '../alt/constants'

class HistorySingleDay extends React.Component {
	state = {
		resultList: null,
	}

	componentWillMount() {
		this.fetch_history_list()
	}

	fetch_history_list() {
		const self = this
		const {year,month,day} = this.props.match.params
		post("result/fetch/history_singleday", {
			user_token: Cookies.get(altName),
			date: new Date(parseInt(year,10), parseInt(month,10)-1, parseInt(day,10)+1),
		})
			.then(ret => {
				self.setState({
					resultList: ret,
				})
			})
	}

	handleGoBack = () => {
		this.props.history.goBack()
	}

	render() {
		const styles = {
			tableHeader: {
				fontSize: 12,
				backgroundColor: grey[200],
			},
		}

		const correctVd = (
			<span className="label label-danger" style={{display:'inline-block',width:44}}>正解</span>
		)
		const incorrectVd = (
			<span className="label" style={{display:'inline-block',width:44,backgroundColor:"#888"}}>不正解</span>
		)

		const { resultList } = this.state
		let resultListVd = null
		if (resultList) {
			let list = this.state.resultList.results
			// list = _.reverse(_.sortBy(list, "updated"))
			const typeLabel = ["選択", "◯✕"]
			const resultLabel = [null, correctVd, incorrectVd]
			resultListVd = list.map(row => {
				const type = (row.MarubatsuID === 0) ? 0 : 1
				const updated = Moment(row.updated).format("YYYY-MM-DD HH:mm")
				return (
					<tr key={_.uniqueId("fk")}>
						<td>{updated}</td>
						<td>{typeLabel[type]}</td>
						<td>{row.Kamokumei}</td>
						<td>{resultLabel[row.result]}</td>
					</tr>
				)
			})
			if (!resultListVd.length) {
				resultListVd = (
					<tr key={_.uniqueId("fk")}>
						<td colSpan={4} style={{textAlign:'center'}}>学習履歴はありません</td>
					</tr>
				)
			}
		}

		const {year,month,day} = this.props.match.params

		return (
			<div style={{backgroundColor: common.white, padding: 10}}>
				<FkToolbar
					title={`${year}年 ${month}月 ${day}日の学習履歴`}
					onGoBack={this.handleGoBack.bind(this)}
				/>
				<div style={{border:'1px solid #ddd'}}>
					<table className="table table-hover table-striped table-bordered" style={{marginBottom: 0}}>
						<tbody>
							<tr style={styles.tableHeader}>
								<th>日時</th>
								<th>モード</th>
								<th>科目</th>
								<th>結果</th>
							</tr>
							{resultListVd}
						</tbody>
					</table>
				</div>
			</div>
		)
	}
}

export default withRouter(HistorySingleDay)
