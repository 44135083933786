import React from 'react'
import {withRouter} from 'react-router-dom'
import {connect} from 'react-redux'
import {common, blue, pink} from '@material-ui/core/colors';
import Icon from '@material-ui/core/Icon'
import IconButton from '@material-ui/core/IconButton';
import '../sonodalib/sl_utils/utils.css'
import Button from '@material-ui/core/Button'
import {post} from '../sonodalib/sl_utils/Http'
import FkToolbar from '../parts/FkToolbar'
import {SubjectBox, QuestionBox} from '../Common'
import MemoIconButton from '../parts/MemoIconButton'
import {withMemoDrawer} from '../parts/MemoDrawer'
import {updateCurrentMyPageTabAC, updatePrintModeAC} from '../mypage/MyPageAction'
import {startOxQuestions} from './Question'
import {OxChoice} from './OxQuestion'
import {CommentaryBox} from './CommonParts'
import OxShowPrint from './OxShowPrint'
import {withBbsDrawer} from "../parts/BbsDrawer";
import G from "../sonodalib/SlGlobal";


class OxShow extends React.Component {
	state = {
		showResult: false,
		question: null,
	}

	componentWillMount() {
		const payload = {
			marubatsu_id: this.props.match.params.marubatsuId,
		}
		post("q/fetch/ox", payload)
		.then(ret => {
			this.setState({
				question: ret.question,
			})
			this.props.memoDrawer.load({
				mondaiId: ret.question.MondaiID,
				marubatsuId: ret.question.MarubatsuID,
			})
		})
		.catch(ret => {
			this.props.history.push("/my")
		})
	}

	handleTryQuestion = () => {
		const self = this
		const payload = {
			marubatsu_id: this.state.question.MarubatsuID,						// OX問題ID
		}
		post("q/fetch/ox", payload)
		.then(ret => {
			self.props.startOxQuestions(self.props.history, [ret.question])
		})
	}

	handleAnswer = () => {
		this.setState({
			showResult: !this.state.showResult,
		})
	}

	handleGoBack = () => {
		if (this.state.showResult) {
			this.setState({
				showResult: false,
			})
		} else {
			this.props.history.goBack()
		}
	}

	handlePrint = () => {
		G.store.dispatch(updatePrintModeAC(true))
		window.setTimeout(() => {
			window.print()
		}, 1500)
	}

	handleClosePrintPopout = () => {
		G.store.dispatch(updatePrintModeAC(false))
	}

	render() {
		if (!this.state.question) return null

		const {question, showResult} = this.state

		const styles = {
			toolBar: {
				backgroundColor: blue[600],
				marginTop: 10,
				height: 38,
			},
			toolBarTitle: {
				marginLeft: 20,
				color: common.white,
			},
			commandBox: {
				margin: 8,
				marginBottom: 18,
			},
			commandButton: {
				width: 200,
				marginRight: 10,
			},
			correctBorder: {
				borderStyle: 'solid',
				borderWidth: 3,
				borderColor: pink[500],
			},
		}
		const commandBox = (
			<div style={styles.commandBox}>
				<Button variant="contained"
								onClick={this.handleTryQuestion.bind(this)}
								style={{...styles.commandButton, color: common.white, backgroundColor: "#2196f3"}}
				>
					問題を解く
				</Button>
				<Button variant="contained"
								onClick={this.handleAnswer.bind(this)}
								style={{...styles.commandButton, color: common.white, backgroundColor: "#2196f3"}}
				>
					解答・解説を {(showResult) ? "閉じる" : "開く"}
				</Button>
			</div>
		)

		// const answer = (!showResult) ? 0 : question.correctAnswer
		const labels = [0, '', '']
		const borders = [0, styles.nullResultBorder, styles.nullResultBorder]
		if (showResult) {
			borders[question.correctAnswer] = styles.correctBorder
			labels[question.correctAnswer] = '正解'
		}
		// const defaultBorder = (showResult) ? styles.nullBorder : styles.defaultBorder
		let boxClasses = [0, "defaultBorder", "defaultBorder"]
		if (showResult) {
			boxClasses[(-question.correctAnswer) + 3] = "nullBorder"
			boxClasses[question.correctAnswer] = "fixedDefaultBorder"
		}
		const colors = [0, common.black, common.black]
		// if (showResult) {
		// 	colors[answer] = red[900]
		// }

		const choiceVd = (showResult) ? (
			<OxChoice
				labels={labels}
				borders={borders}
				boxClasses={boxClasses}
				colors={colors}
			>
			</OxChoice>
		) : null

		const commentary = (!showResult) ? null : (
			<CommentaryBox
				text={question.commentaryText}
			/>
		)

		if (this.props.printMode) return <OxShowPrint marubatsuId={this.props.match.params.marubatsuId} onClose={this.handleClosePrintPopout}/>
		return (
			<div>
				<FkToolbar title="◯✕問題詳細" onGoBack={this.handleGoBack}>
					<MemoIconButton
						handleOpenMemo={() => {
							this.props.memoDrawer.open(null)
						}}
						isMemoActive={this.props.memoDrawerStatus}
					/>
					<IconButton
						style={{color: 'white'}}
						aria-label="掲示板"
						onClick={() => {
							this.props.bbsDrawer.openToCreateThread(
								1,
								undefined,
								this.state.question.MondaiID,
								this.state.question.MarubatsuID,
								"",
							)
						}}
					>
						<Icon>comment</Icon>
					</IconButton>

					<IconButton style={{color: 'white'}} aria-label="印刷" onClick={this.handlePrint}>
						<Icon>printer</Icon>
					</IconButton>
				</FkToolbar>
				<SubjectBox label={question.subjectLabel + "　" + question.subLabel}/>
				<QuestionBox text={question.qText}/>
				{choiceVd}
				{commentary}
				{commandBox}
			</div>
		)
	}
}

export default connect(
	state => ({
		printMode: state.mypage.printMode,
	}),
	dispatch => ({
		startOxQuestions: (history, questions) => {
			dispatch(updateCurrentMyPageTabAC(1))
			startOxQuestions({
				dispatch,
				history,
				questions,
				repeat: false,
			})
		},
	})
)(withRouter(withMemoDrawer(withBbsDrawer(OxShow))))
