import React from 'react'
import Button from '@material-ui/core/Button';
import IconMenu from '../sonodalib/sl_mui/IconMenu'
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar';
import common from '@material-ui/core/colors/common';
import { withRouter } from 'react-router-dom'
import {topHeaderColor, urlPref, switchContentMenuLabel, switchContentMenuUrl} from '../alt/constants'
// import { pink } from '@material-ui/core/colors'

class TopHeader extends React.Component {
	handleResetPassword = () => {
		this.props.history.push("/top/reset-password")
	}

	handleSwitchContent = () => {
		window.location.href = switchContentMenuUrl
	}

	render() {
		return (
			<AppBar style={{backgroundColor: topHeaderColor}} position="static">
        <Toolbar>
					<a href={urlPref}>
						<img src={urlPref+"/images/common/head-logo.png"} width="192" height="54" role="presentation" style={{zIndex: 999}} alt="head-logo"/>
					</a>
					<Grid container justify="flex-end" alignItems="center">
						<Grid item>
							<Button
								style={{color: common.white}}
								href={urlPref+"/invite"}
							>
								招待登録
							</Button>
							<Button
								style={{color: common.white}}
								href={urlPref+"/login"}
							>
								ログイン
							</Button>
						</Grid>
						<Grid item>
							<IconMenu iconColor={common.white}>
								<MenuItem onClick={this.handleSwitchContent}>{switchContentMenuLabel}</MenuItem>
								<MenuItem onClick={this.handleResetPassword}>パスワード忘れた</MenuItem>
							</IconMenu>
						</Grid>
					</Grid>
				</Toolbar>
			</AppBar>
		)
	}
}

export default withRouter(TopHeader)
