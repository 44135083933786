import React from 'react'
import {connect} from 'react-redux'
import {withRouter} from 'react-router-dom'
import Button from '@material-ui/core/Button';
import {logout} from '../../sonodalib/sl_users/SlUsersAction'
import {loading} from '../../sonodalib/sl_utils/Utils'
import {post} from '../../sonodalib/sl_utils/Http'
import {PageHeader} from '../../Common'
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import SlTextField from "../../sonodalib/sl_mui/SlTextField";
import {TextInputState} from "../../sonodalib/sl_utils/SlForms";
import Cookies from "js-cookie";
import {altName, urlPref} from '../../alt/constants'


class ResetPassword extends React.Component {
	state = {
		stepIndex: 0,
		digitCodeState: new TextInputState({validations: "required,integer"}),
		openDigitDialog: false,
		errorMessage: null,
		password1State: new TextInputState({validations: "required,password"}),
		password2State: new TextInputState({validations: "required,password"}),
	}

	handleSendConfirmEmail = () => {
		loading(true)
		post("user/resetpass/step1/logon", {
			user_token: Cookies.get(altName),
		})
		.then(ret => {
			loading(false)
			this.digitsId = ret.digits_id
			this.setState({
				openDigitDialog: true,
			})
		})
		.catch(res => {
			loading(false)
			alert("不明なエラーが発生しました")
		})
	}

	onCancelInputDigitCode = () => {
		this.setState({stepIndex: 0})
	}

	handleLogin = () => {
		logout()
		window.location.href = urlPref+"/login"
	}

	handleBack = () => {
		this.props.history.goBack()
	}

	// 'digitCode' page
	handleSubmitInputDigitCode = () => {
		post("user/resetpass/step4", {
			user_token: Cookies.get(altName),
			digit_id: this.digitsId,
		})
		.then(ret => {
			this.setState({stepIndex: 2})
		})
		.catch(ret => {
			alert("不明なエラーが発生しました")
		})
	}

	handleCancelInputDigitCode = () => {
		this.setState({
			openDigitDialog: false,
		})
	}

	handleSubmitDigitCode = (event) => {
		event.preventDefault()
		this.setState({
			digitCodeState: this.state.digitCodeState.validate(),
		}, () => {
			if (this.state.digitCodeState.isValid()) {
				post("user/auth_digitcode", {
					digit_id: this.digitsId,
					digit_code: this.state.digitCodeState.getValue(),
				})
				.then(ret => {
					if (ret && ret.status === 'expired') {
						this.setState({
							errorMessage: "ご入力の確認コードは期限が切れております",
						})
					} else {
						this.setState({
							openDigitDialog: false,
							stepIndex: 1,
						})
					}
				})
				.catch(res => {
					let errorText;
					if (res && res.statusCode === 401) {
						errorText = "確認コードが間違っているか、すでに使用済みです"
					} else {
						errorText = "不明なエラーが発生しました"
					}
					this.setState({
						errorMessage: errorText,
					})
				})
			}
		})
	}

	handleSubmitPassword1 = () => {
		this.setState({
			password1State: this.state.password1State.validate()
		}, () => {
			if (this.state.password1State.isValid()) {
				this.setState({
					stepIndex: 2,
				})
			}
		})
	}

	handleBackToPassword1 = () => {
		this.setState({
			stepIndex: 1,
		})
	}
	handleSubmitPassword2 = () => {
		this.setState({
			password2State: this.state.password2State.validate()
		}, () => {
			if (this.state.password2State.isValid()) {
				if (this.state.password1State.getValue() !== this.state.password2State.getValue()) {
					this.setState({
						password2State: this.state.password2State.setError("パスワードが間違っています")
					})
				} else {
					post("user/resetpass/step4", {
						digit_id: this.digitsId,
						password: this.state.password2State.getValue(),
					})
					.then(() => {
						this.setState({
							stepIndex: 3,
						})
					})
					.catch(res => {
						let errorMessage;
						if (res && res.statusCode === 401) {
							errorMessage = "この処理は無効です。"
						} else {
							errorMessage = "不明なエラーが発生しました"
						}
						this.setState({
							password2State: this.state.password2State.setError(errorMessage)
						})
					})
				}
			}
		})
	}

	render() {
		if (!this.props.userState) return null
		const {stepIndex} = this.state

		let content = null
		switch (stepIndex) {
			case 0:
				content = (
					<div>
						<div style={{textAlign: 'center', margin: '30px 10px'}}>
							<span style={{fontSize: 20, color: 'red', fontWeight: 600}}>{this.props.userState.email}</span>
						</div>
						<div className="pull-right">
							<Button
								onClick={this.handleSendConfirmEmail}
								variant="contained"
								type="submit"
								color="primary"
							>
								上記アドレスに、確認コードをメール送信
							</Button>
						</div>
					</div>
				)
				break

			case 1:
				content = (
					<div>
						<div style={{textAlign: 'center', margin: '30px 10px'}}>
							<SlTextField
								type="password"
								label="新しいパスワードを入力して下さい"
								state={this.state.password1State}
								onChange={(newState) => {
									this.setState({password1State: newState})
								}}
							/>
						</div>
						<div className="pull-right">
							<Button
								onClick={this.handleSubmitPassword1}
								variant="contained"
								color="primary"
							>
								次
							</Button>
						</div>
					</div>
				)
				break;

			case 2:
				content = (
					<div>
						<div style={{textAlign: 'center', margin: '30px 10px'}}>
							<SlTextField
								type="password"
								autoFocus
								label="確認のため、もう一度入力して下さい"
								state={this.state.password2State}
								onChange={(newState) => {
									this.setState({password2State: newState})
								}}
							/>
						</div>
						<div className="pull-right">
							<Button
								onClick={this.handleSubmitPassword2}
								variant="contained"
								color="primary"
							>
								決定
							</Button>
							<Button
								onClick={this.handleBackToPassword1}
								variant="contained"
								style={{marginLeft: 10}}
							>
								戻る
							</Button>
						</div>
					</div>
				)
				break;

			default:	// means 3
				content = (
					<div style={{textAlign: 'center'}}>
						<h3>
							変更手続きが完了しました
						</h3>
						<div style={{margin: '20px 0'}}>
							新しいパスワードで、再度ログインして下さい
						</div>
						<div>
							<Button variant="contained"
											color="primary"
											onClick={this.handleLogin}
							>
								ログイン
							</Button>
						</div>
					</div>
				)
				break
		}

		return (
			<div>
				<div style={{margin: '20px 20px'}}>
					<PageHeader label="パスワード再設定" mb={0}/>
					<div style={{maxWidth: 600, margin: 'auto'}}>
						{content}
					</div>
				</div>
				<Dialog
					open={this.state.openDigitDialog}
					aria-labelledby="alert-dialog-title"
					aria-describedby="alert-dialog-description"
				>
					<form onSubmit={this.handleSubmitDigitCode}>
						<DialogTitle id="alert-dialog-title">確認コードを入力して下さい</DialogTitle>
						<DialogContent>
							<SlTextField
								autoFocus
								type="number"
								label="確認コード"
								state={this.state.digitCodeState}
								placeholder="５ケタの数字"
								onChange={(newState) => {
									this.setState({digitCodeState: newState})
								}}
							/>
							{this.state.errorMessage}
						</DialogContent>
						<DialogActions>
							<Button variant="contained" onClick={this.handleCancelInputDigitCode}>
								キャンセル
							</Button>
							<Button variant="contained" type="submit" color="primary">
								決定
							</Button>
						</DialogActions>
					</form>
				</Dialog>
			</div>
		)
	}
}

export default connect(
	state => ({
		userState: state.sl_users.state,
	})
)(withRouter(ResetPassword))
