import {post} from '../sonodalib/sl_utils/Http'
import Cookies from "js-cookie";
import {altName} from "../alt/constants";
import {updateSct} from './SctAction'
import {logout} from '../sonodalib/sl_users/SlUsersAction';


function success(ret, onSuccess, onFail) {
	if (ret.status === "ok") {
		updateSct(ret.moshi_entity)
		Cookies.set("wm_"+altName, ret.moshi_token, {expires:365})
		if (onSuccess) onSuccess()
	} else {
		Cookies.remove("wm_"+altName)
		alert("セルフチェックテストは利用できません")
		if (onFail) onFail()
	}
}

function startSession(serviceCode, userToken, onSuccess, onFail) {
	post("moshi/start_session", {
		user_token: 	userToken || Cookies.get(altName),
		service_code: serviceCode,
	})
	.then((ret) => {
		success(ret, onSuccess, onFail)
	})
}

function restartSession(sct_token, onSuccess, onFail) {
	post("moshi/restart_session", {
		user_token: 	Cookies.get(altName),
		moshi_token:  sct_token
	})
	.then((ret) => {
		success(ret, onSuccess, onFail)
	})
}

function terminateSession() {
	Cookies.remove("wm_"+altName)
	logout()
}

export default {
	startSession,
	restartSession,
	terminateSession,
}