// @flow
import * as React from 'react'
import TextField from '@material-ui/core/TextField'
// import _ from 'lodash'
// import MenuItem from '@material-ui/core/Menu/MenuItem'
// import PropTypes from 'prop-types'
// import Selector from './Selector'
import type { MenuRow } from './Selector'
import {SelectorInputState} from "../sl_utils/SlForms";

export type Props = {
	// menuList: Array<MenuRow>,
	// value?: any,
	state: SelectorInputState,
	onChange?: (MenuRow)=>void,
	label?: string,
	// trigger: (selected?: MenuRow, (event: SyntheticEvent<HTMLButtonElement>)=>void) => ?React.Node
}
type States = {
//	open: boolean,
}
export default class TextFieldSelector extends React.Component<Props,States> {
	onChange = (event: SyntheticEvent<HTMLButtonElement>) => {
		const { onChange, state } = this.props
		const { menuList } = state
		const index = parseInt(event.currentTarget.value, 10)
		const menuRow = menuList[index]
		if (menuRow) {
			if (onChange) {
				onChange(state.setValue(menuRow.id))
			}
			if (menuRow.onClick) {
				menuRow.onClick()
			}
		}
	}

	render() {
		const {
			// value,
			state,
			// menuList,
			label,
			onChange,		// eslint-disable-line no-unused-vars
			...other
		} = this.props
		const index = state.get("index")
		const menuList = state.get("menuList")
		return (
			<TextField
				select
				label={label}
				value={index}
				error={state.isError()}
				helperText={state.getError()}
				onChange={this.onChange}
				margin="normal"
				SelectProps={{
					native: true,
				}}
				// aria-owns={this.state.open ? 'selector-menu' : null}
				// aria-haspopup="true"
				// onClick={handleOpen}
				// InputProps={{
				// 	readOnly: true,
				// }}
				{...other}
			>
				{menuList.map((menu:MenuRow, index:number) => (
					<option key={index} value={index}>
						{menu.label}
					</option>
				))}
			</TextField>
		)
	}
}
