import React from 'react'
import {withRouter} from 'react-router-dom'
import Cookies from "js-cookie";
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import Icon from '@material-ui/core/Icon'
import FkToolbar from '../parts/FkToolbar'
import {purple, red, common} from '@material-ui/core/colors'
import {post} from '../sonodalib/sl_utils/Http'
import PropTypes from 'prop-types'
import Moment from 'moment'
import {withBbsDrawer} from '../parts/BbsDrawer'
import _ from 'lodash'
import {altName} from '../alt/constants'


class ContactPost extends React.Component {
	state = {
		topic: null,
		posts: null,
	}

	componentWillMount() {
		this.fetch_posts(this.props.match.params.threadId)
	}

	fetch_posts(threadId) {
		const self = this
		const payload = {
			user_token: Cookies.get(altName),
			thread_id: threadId,
		}
		post("bbs/fetch_posts", payload)
		.then(ret => {
			PropTypes.checkPropTypes({
				topic: PropTypes.object.isRequired,
				posts: PropTypes.array.isRequired,
			}, ret)
			self.setState({
				topic: ret.topic,
				posts: ret.posts,
			})
		})
	}

	handleReply = () => {
		this.props.bbsDrawer.openToPostComment(
			this.props.match.params.threadId,
			(threadId) => {
				this.fetch_posts(this.props.match.params.threadId)
				/*	管理者と利用者すべての投稿について etc/contact-notify を呼び
						サーバ側で管理者ではない事を確認した上でメールを管理者に送信する	*/
				post("etc/contact-notify", {
					user_token: Cookies.get(altName),
					thread_id: threadId,
					alt_name: altName,
				})
			},
			"お客様",
		)
	}

	handleShowQuestion = () => {
		const {mondai_id, marubatsu_id} = this.state.topic
		if (!marubatsu_id) {
			this.props.history.push("/q/sentaku/show/" + mondai_id)
		} else {
			this.props.history.push("/q/ox/show/" + marubatsu_id)
		}
	}

	render() {
		const {topic, posts} = this.state
		const topicVd = (topic) ? (
			<div style={{backgroundColor: "#d4e3fc", padding: 16}}>
				<Grid container>
					<Grid item xs={6}>
						<span style={{fontSize: 16, fontWeight: 800, color: purple[700]}}>
							{topic.title}
						</span>
					</Grid>
					<Grid item xs={3}>
						<span style={{fontSize: 12, fontWeight: 600, color: purple[600]}}>
							{topic.handle_name}
						</span>
					</Grid>
					<Grid item xs={3}>
						<span style={{fontSize: 12, fontWeight: 600, color: common.black}}>
							{Moment(topic.updated).format("YYYY/MM/DD")}
						</span>
					</Grid>
					<Grid item xs={12}>
						<div dangerouslySetInnerHTML={{__html: topic.text.replace(/\n/g, "<br />")}}></div>
					</Grid>
				</Grid>
			</div>
		) : (
			<div>...</div>
		)
		const commentsVd = (posts && posts.length) ? (
			<div>
				<div style={{textAlign: 'center'}}>
					<Icon>graphic_eq</Icon>
				</div>
				{_.map(_.sortBy(posts, "updated"), comment => (
					<div key={comment.uuid} style={{padding: 16, marginBottom: 4, backgroundColor: "#e8eaf6"}}>
						<Grid container>
							<Grid item xs={6}>
								<span style={{fontSize: 16, fontWeight: 800, color: red[700]}}>
									{comment.title}
								</span>
							</Grid>
							<Grid item xs={3}>
								<span style={{fontSize: 12, fontWeight: 600, color: common.minBlack}}>
									{comment.handle_name}
								</span>
							</Grid>
							<Grid item xs={3}>
								<span style={{fontSize: 12, fontWeight: 600, color: common.minBlack}}>
									{Moment(comment.updated).format("YYYY/MM/DD")}
								</span>
							</Grid>
							<Grid item xs={12}>
								<div dangerouslySetInnerHTML={{__html: comment.text.replace(/\n/g, "<br />")}}></div>
							</Grid>
						</Grid>
					</div>
				))}
			</div>
		) : (
			<div></div>
		)
		const openQuestionDetailButton = (topic && topic.board_id === 1) ? (
			<Grid item xs={6} style={{padding: 10}}>
				<Button
					style={{width: '100%', color: common.white, backgroundColor: "#229cfc"}}
					variant="contained"
					onClick={this.handleShowQuestion.bind(this)}
				>
					問題ページを開く
				</Button>
			</Grid>
		) : null
		return (
			<div>
				<FkToolbar title="スレッド" onGoBack={() => {
					this.props.history.goBack()
				}}/>
				<div style={{margin: 10}}>
					{topicVd}
					<div style={{marginTop: 10}}>
						{commentsVd}
					</div>
					<Grid container style={{marginTop: 10}}>
						<Grid item xs={6} style={{padding: 10}}>
							<Button
								style={{width: '100%', color: common.white, backgroundColor: "#229cfc"}}
								variant="contained"
								onClick={this.handleReply.bind(this)}
							>
								返信を投稿
							</Button>
						</Grid>
						{openQuestionDetailButton}
					</Grid>
				</div>
			</div>
		)
	}
}

export default withBbsDrawer(withRouter(ContactPost))

