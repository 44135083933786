import _ from 'lodash'

export default class Validations {
	static validateParams(params, conditions) {
		_.forEach(conditions, (v,k) => {
			if (!params[k]) {
				throw new Error("validateParams: key '"+k+"' is required.")
			}
			_.forEach(v, type => {		// v = ['email','alpdigit',...]
				switch (type) {
					case 'email':
						if (!Validations.email(params[k])) {
							throw new Error("validateParams: value of "+k+" ("+params[k]+") must be email.")
						}
						break;
					default:
				}
			})
		})
	}

	static email(email) {
		// return email.match(/^[A-Za-z0-9.]+[\w-]*@[\w.-]+\.\w{2,}$/);
		return email.match(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);	//eslint-disable-line no-useless-escape
	}
	static url(url) {
		return url.match(/^https?:\/\//);
	}
	static telno(telno) {
		if (!telno.match(/^\d/) || !telno.match(/\d$/)) return false
		return telno.replace(/-/g,"").match(/^\d{9}$|^\d{10}$|^\d{11}$/) || telno === '999-999';
	}
	static zipcode(zipcode) {
		return zipcode.match(/^(\d{3}-\d{4})|\d{7}$/);
	}
	static integer(value) {
		return value.match(/^\d+$/);
	}
	static alpdigit(text) {
		return text.match(/^[A-Za-z\d]+$/);
	}
	static zenkaku(text) {
		return text.match(/^[^\x01-\x7E\xA1-\xDF]+$/);
	}
	static zenkaku2(text) {
		return text.match(/^[^\x01-\x7E\xA1-\xDFｧ-ﾝﾞﾟ]+$/)
	}
	// 強いパスワード（数値、アルファベット大文字、小文字が必要 8文字以上）
	static password_strong(passwd) {
		return passwd.match(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z]{8,}$/);
	}
	// 中程度のパスワード（数値、アルファベット小文字が必要 8文字以上）
	static password_medium(passwd) {
		return passwd.match(/^(?=.*\d)(?=.*[a-z])[0-9a-zA-Z]{8,}$/);
	}
}

