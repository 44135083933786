import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Route, Switch } from 'react-router-dom'
import SentakuSeries from './SentakuSeries'
import SentakuResult from './SentakuResult'
import SentakuDetail from './SentakuDetail'
import SentakuShow from './SentakuShow'
import SentakuShowAnswer from './SentakuShowAnswer'
import OxSeries from './OxSeries'
import OxResult from './OxResult'
import OxDetail from './OxDetail'
import OxShow from './OxShow'
import JireiSeries from './JireiSeries'
import { updateSentakuQuestionsAC, updateOxQuestionsAC } from './QuestionAction'


export default class Question extends Component {

//				<AppBar>
//					<Toolbar style={{backgroundColor: white}}>
//							<img src="/images/common/head-logo.png" width="160" height="45" role="presentation" alt="head-logo"/>
//					</Toolbar>
//				</AppBar>
	render() {
		return (
      <div className="wrapper">
				<div style={{height:'auto'}}>
					<Switch>
						<Route exact path="/q/sentaku" component={SentakuSeries} onUpdate={() => window.scrollTo(0, 0)}/>
						<Route exact path="/q/sentaku/result" component={SentakuResult}/>
						<Route exact path="/q/sentaku/detail/:index" component={SentakuDetail}/>
						<Route exact path="/q/sentaku/show/:mondaiId" component={SentakuShow}/>
						<Route exact path="/q/sentaku/show-answer/:mondaiId" component={SentakuShowAnswer}/>
						<Route exact path="/q/ox" component={OxSeries}/>
						<Route exact path="/q/ox/result" component={OxResult}/>
						<Route exact path="/q/ox/detail/:index" component={OxDetail}/>
						<Route exact path="/q/ox/show/:marubatsuId" component={OxShow}/>
						<Route exact path="/q/jirei" component={JireiSeries}/>
					</Switch>
        </div>
        <footer className="main-footer">
          <div className="container">
            <strong>Copyright © <a href="http://www.jc-edu.co.jp">JC Educational Institute, Inc.</a>.</strong> All rights reserved.
          </div>
        </footer>
      </div>
		)
	}
}


export function startSentakuQuestions(args) {
	const types = {
		dispatch:	 PropTypes.func.isRequired,
		history:	 PropTypes.object.isRequired,
		questions: PropTypes.array.isRequired,
		repeat:		 PropTypes.bool.isRequired,
	}
	PropTypes.checkPropTypes(types, args, "args", "startSentakuQuestions")

	const payload = {
		questions: args.questions,
		repeat:		 args.repeat,
		startedTime: new Date(),
		totalTime: args.questions.length * 60,
	}
	args.dispatch(updateSentakuQuestionsAC(payload))
//	if (window.location.href.endsWith("result")) {
	const s = window.location.href
	if (s.indexOf("result") === s.length - "result".length) {
		args.history.replace("/q/sentaku")
	} else {
		args.history.push("/q/sentaku")
	}
}

export function startOxQuestions(args) {
	const types = {
		dispatch:	 PropTypes.func.isRequired,
		history:	 PropTypes.object.isRequired,
		questions: PropTypes.array.isRequired,
		repeat:		 PropTypes.bool.isRequired,
	}
	PropTypes.checkPropTypes(types, args, "args", "startOxQuestions")
	window.scrollTo(0, 0)

	const payload = {
		questions: args.questions,
		repeat:		 args.repeat,
		startedTime: new Date(),
		totalTime: args.questions.length * 12,
	}
	args.dispatch(updateOxQuestionsAC(payload))
//	if (window.location.href.endsWith("result")) {
	const s = window.location.href
	if (s.indexOf("result") === s.length - "result".length) {
		args.history.replace("/q/ox")
	} else {
		args.history.push("/q/ox")
	}
}
