// @flow
import React, {Component} from 'react'
import Grid from '@material-ui/core/Grid'
import {urlPref} from '../../alt/constants'

// ===============================================================
// 実施度・習熟度
// ===============================================================
type Props = {
	iconVd: any,
	jisshido: number,
	shujukudo: number,
	goukakudo: number,
	onClick: ()=>void,
}
export default class EvalBox extends Component<Props,{}> {
	render() {
		const getImageIndex = (value) => {
			return (value < 25) ? "1" :
				(value < 50) ? "2" :
					(value < 75) ? "3" :
						(value < 100) ? "4" : "5"
		}
		const {iconVd, jisshido, shujukudo, goukakudo} = this.props
		const jisshidoImage = `url('${urlPref}/images/eval/flower${getImageIndex(jisshido)}_h40.gif')`
		const shujukudoImage = (goukakudo < 100) ? `url('${urlPref}/images/eval/fukurou_silver${getImageIndex(shujukudo)}_h40.gif')` : `url('${urlPref}/images/eval/fukurou_gold_h40.gif`
		return (
			<div className="info-box" style={{marginBottom: 8,cursor:'pointer',boxShadow:'0 1px 1px rgba(0, 0, 0, 0.3)'}} onClick={this.props.onClick}>
				{iconVd}
				<div className="info-box-content">
					<Grid container direction="row">
						<Grid item style={{width: '50%'}}>
							<div style={{
								height: 80,
								backgroundPosition: 'bottom 0px right 0px',
								backgroundImage: jisshidoImage,
								backgroundRepeat: 'no-repeat'
							}}>
								<span className="info-box-text">実施度</span>
								<span className="info-box-number">{jisshido}%</span>
							</div>
						</Grid>
						<Grid item style={{width: '50%'}}>
							<div style={{
								height: 80,
								backgroundPosition: 'bottom 0px right 0px',
								backgroundImage: shujukudoImage,
								backgroundRepeat: 'no-repeat'
							}}>
								<span className="info-box-text">習熟度</span>
								<span className="info-box-number">{shujukudo}%</span>
							</div>
						</Grid>
					</Grid>
				</div>
			</div>
		)
	}
}
