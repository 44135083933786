import React from 'react'
import PropTypes from 'prop-types'
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';

export default class MemoIconButton extends React.Component {
	static propTypes = {
		handleOpenMemo: PropTypes.func,
		isMemoActive: PropTypes.bool.isRequired,
	}

	handleOpenMemo = props => {
		if (props.handleOpenMemo)
			props.handleOpenMemo()
	}

	render() {
		const iconName = (this.props.isMemoActive) ? "bookmark" : "bookmark_border"
		return (
			<IconButton style={{color:'white'}} aria-label="メモ" onClick={this.handleOpenMemo.bind(this, this.props)}>
				<Icon>{iconName}</Icon>
			</IconButton>
		)
	}
}

