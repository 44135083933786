import React from 'react'
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import BunpuChart from './BunpuChart'


const styles = {
	shikenLabel: {
		marginTop:			 40,
		fontSize:				 20,
		fontWeight:			 'bold',
		color:					 '#666',
		paddingBottom:	 18,
		textAlign:			 'center',
	},
	tableHead: {
		backgroundColor: '#1e88e5',
		color: 					 'white',
		fontSize:				 12,
		whiteSpace:			 'nowrap',
	},
	cellHead: {
		paddingTop:			 6,
		paddingBottom:	 6,
		textAlign:			 'center',
	},
	tableRow: {
		whiteSpace:			 'nowrap',
	},
	cellRow: {
		paddingTop:			 14,
		paddingBottom:	 14,
		textAlign:			 'center',
		borderBottom:		 '1px solid #ccc',
	},
	cellRowHantei: {
		height:					 51,
		// paddingTop:			 5,
		// paddingBottom:	 5,
		textAlign:			 'center',
		borderBottom:		 '1px solid #ccc',
		fontWeight:			 'bold',
		fontSize:				 24,
		color:					 '#F22',
	},
}

class SougouTab extends React.Component {

	render() {
		const {data, printMode} = this.props
		if (!data) return null

		const {shikens} = data

		const cw = (!printMode) ? {
			catName: 100-22,
			hantei:  140-22,
			other:   80-8,
		} : {
			catName: 80,
			hantei:  100,
			other:   60,
		}
		const cellFontSize = (printMode) ? 14 : 16

		const shikenVd = shikens.map(shiken => {
			const catsHeadVd = (
				<Grid container style={styles.tableHead} alignItems="center">
					<Grid item style={{...styles.cellHead,width:cw.catName}}>　</Grid>
					<Grid item style={{...styles.cellHead,width:cw.other}}>配点</Grid>
					<Grid item style={{...styles.cellHead,width:cw.other}}>得点</Grid>
					<Grid item style={{...styles.cellHead,width:cw.other}}>偏差値</Grid>
					<Grid item style={{...styles.cellHead,width:cw.other}}>受験者数</Grid>
					<Grid item style={{...styles.cellHead,width:cw.other}}>平均点</Grid>
					<Grid item style={{...styles.cellHead,width:cw.other}}>最高点</Grid>
					<Grid item style={{...styles.cellHead,width:cw.other}}>最低点</Grid>
					<Grid item style={{...styles.cellHead,width:cw.hantei}}>実力判定</Grid>
				</Grid>
			)
			const catsVd = shiken.cats.map((cat) => (
				<Grid container key={"cat"+cat.shindan_index} style={{...styles.tableRow,fontSize:cellFontSize}} alignItems="center">
					<Grid item style={{...styles.cellRow,width:cw.catName,fontWeight:600}}>{cat.label}</Grid>
					<Grid item style={{...styles.cellRow,width:cw.other}}>{cat.haiten}</Grid>
					<Grid item style={{...styles.cellRow,width:cw.other}}>{cat.score}</Grid>
					<Grid item style={{...styles.cellRow,width:cw.other}}>{cat.std}</Grid>
					<Grid item style={{...styles.cellRow,width:cw.other}}>{cat.count}</Grid>
					<Grid item style={{...styles.cellRow,width:cw.other}}>{cat.avg}</Grid>
					<Grid item style={{...styles.cellRow,width:cw.other}}>{cat.max}</Grid>
					<Grid item style={{...styles.cellRow,width:cw.other}}>{cat.min}</Grid>
					<Grid item style={{...styles.cellRowHantei,width:cw.hantei}}>
						<span style={{display:'inline-block',height:40,marginTop:(cat.fukurou) ? 6 : 8}}>
							{cat.hantei}
							{(cat.fukurou) ? <img alt="fukurou" src={"/imgms/moshi/fukurou_"+cat.hantei+".gif"}/> : <span> </span>}
						</span>
					</Grid>
				</Grid>
			))
			const labels = []
			if (shiken.bunpu_visible && shiken.bunpu_list.length) {
				let v = 0
				while (v < shiken.bunpu_list.length) {
					labels.push((v % 10 === 0) ? v+"" : "")
					v++
				}
			}
			const bunpuVd = (shiken.bunpu_visible && shiken.bunpu_list.length) ? (
				<div style={{marginTop:40}}>
					<div>
						得点分布
					</div>
						<BunpuChart
							data={shiken.bunpu_list}
							width={(printMode) ? 600 : 700}
							height={160}
							labels={labels}
						/>
				</div>
			) : null
			const kohyoVd = (shiken.kohyo) ? (
				<div style={{border:'1px solid #3f51b5', padding:10}}>
					<div dangerouslySetInnerHTML={{__html: shiken.kohyo}}></div>
				</div>
			) : null
			return (
				<div key={shiken.index}>
					<div style={styles.shikenLabel}>
						{shiken.label}
					</div>
					<div>
						{catsHeadVd}
						{catsVd}
					</div>
					{bunpuVd}
					{kohyoVd}
				</div>
			)
		})
		return (
			<div>
				{shikenVd}
			</div>
		)
	}
}
export default connect(
	state => ({
		moshiState: state.moshi,
		printMode: state.moshi.printMode,
	})
)(withRouter(SougouTab));
