// 配信者月間サマリーに表示する １ヶ月分のチャート

import React from "react";
import Chart from "react-apexcharts";
import {blue} from "@material-ui/core/colors";

export default function BunpuChart(props) {
	const {data, width, height, labels} = props

	const options = {
		chart: {
			animations: {
				enabled: true
			},
			background: 'white',
			type: 'bar',
			toolbar: {
				show: false,
			},
			stacked: true,
		},
		dataLabels: {
			enabled: false,
		},
		tooltip: {
			enabled: false,
		},
		states: {
			hover: {
				filter: {
					type: 'none',
				}
			}
		},
		colors: blue[400],
		fill: {
			opacity: 1,
		},
		title: {
			text: "",
			margin: -25,
		},
		labels,
		xaxis: {
			type: 'category',
			labels: {
				trim: false,
				rotate: 0,
				// rotateAlways: true,
				minHeight: 46,
				// hideOverlappingLabels: true,
				offsetX: 0,
				offsetY: -4,
			},
			// tickAmount: 5,
			axisTicks: {
				show: false,
			},
		},
		yaxis: [{
			show: false,
			// max: maxValue,
			tickAmount: 5,
			// title: {
			//   text: yaxisTitle,
			// },
		}],
		stroke: {
			show: false,
			// width: [3],
			// curve: ['smooth'],
		},
		legend: {
			show: false,
			// position: 'top',
			// offsetY: 20,
			// // height: 10,
		},
		// annotations: {
		// 	xaxis: [{
		// 		x: 0,
		// 		x2: 1,
		// 		fillColor: 'red',
		// 		opacity: 1,
		// 	}]
		// },
	}

	return (
		<Chart options={options}
					 series={[{
						 name: "",
						 data,
					 }]}
					 type="bar"
					 width={width}
					 height={height}
		/>
	)
}
