// @flow

// export function slInit(initialObject:SlGlobal) {
// 	window.slGlobal = initialObject
// }
//
// export function get() {
//
// }


export default class SlGlobal {
	static store: any
	static apiPath: string
	static apiPath = "/f/"
	static logic = null
}
