import React, {Component} from 'react'
import {withRouter} from 'react-router-dom'
import Button from '@material-ui/core/Button'
import TopHeader from './TopHeader'
import {TextInputState} from '../sonodalib/sl_utils/SlForms'
import BsLikeTextField from '../sonodalib/sl_mui/BsLikeTextField'
import {Flex, Box} from 'reflexbox'
import {post} from '../sonodalib/sl_utils/Http'
import {loading} from '../sonodalib/sl_utils/Utils'
import {urlPref} from '../alt/constants'

const DANTAI_ID = '1801'

class TopContainerNFU extends Component {
	state = {
		emailState: new TextInputState({validations: "require,email"}),
		logininfo: [],
		disabled_otameshi_touroku: false,
	}

	componentWillMount(): void {
		post("etc/logininfo/fetch_client", {
			dantai_id: DANTAI_ID,
		})
		.then((ret) => {
			this.setState({
				logininfo: ret.logininfo_texts,
				disabled_otameshi_touroku: ret.disabled_otameshi_touroku,
			})
		})
	}

	handleSubmit = e => {
		e.preventDefault()
		this.setState({
			emailState: this.state.emailState.validate(),
		}, () => {
			const email = this.state.emailState.getValue()
			if (this.state.emailState.isValid()) {
				loading(true)
				post("user/trial_register_step1", {
					email,
					dantai_id: DANTAI_ID,
				})
				.then(ret => {
					loading(false)
					switch (ret.status) {
						case "success":
							this.props.history.push("/trial_register/" + email + "/" + ret.digits_id)
							break
						case "send_email_failer":
							this.setState({
								emailState: this.state.emailState.setError("メール送信に失敗しました"),
							})
							break
						default: // means "already_used_email"
							this.setState({
								emailState: this.state.emailState.setError("すでに登録済みのメールアドレスです"),
							})
					}
				})
				.catch(() => {
					loading(false)
					this.setState({
						emailState: this.state.emailState.setError("不明なエラーが発生しました"),
					})
				})
			}
		})
	}

	render() {
		const {logininfo, disabled_otameshi_touroku} = this.state
		const loginInfoVd = (!logininfo) ? null : logininfo.map((row) => (
			<div key={row.uuid} className="box box-danger">
				<div className="box-body" dangerouslySetInnerHTML={{__html: row.body.replace(/\n/g,"<br />")}}>
				</div>
			</div>
		))
		return (
			<div className="wrapper">
				<TopHeader/>
				<div style={{textAlign: 'center'}}>
					<img src={urlPref+"/images/top/NFU_topbanner.jpg"} alt="topbanner" style={{maxWidth: '100vw'}}/>
				</div>
				<form onSubmit={this.handleSubmit.bind(this)}>
					<Flex wrap justify="center" style={{height: 100, marginTop: 30}}>
						<Box style={{width: 320}}>
							<BsLikeTextField
                autoFocus
                type="email"
                placeholder="メールアドレス"
                state={this.state.emailState}
								margin="none"
                onChange={(newState) => {
                  this.setState({emailState: newState})
                }}
								disabled={disabled_otameshi_touroku}
							/>
						</Box>
						<Box>
							<Button variant="contained"
											type="submit"
											color="primary"
											className="pull-right"
											style={{marginLeft: 8}}
											disabled={disabled_otameshi_touroku}
							>
								無料でお試し
							</Button>
						</Box>
					</Flex>
				</form>
				<div style={{maxWidth:600,margin:'auto'}}>
					{loginInfoVd}
				</div>

				<footer className="main-footer">
					<div className="container">
						<strong>Copyright © <a href="http://www.jc-edu.co.jp">JC Educational Institute, Inc.</a>.</strong> All
						rights reserved.
					</div>
				</footer>
			</div>
		);
	}
}

export default withRouter(TopContainerNFU)
