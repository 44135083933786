import React from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import { LessonToolBar } from './CommonParts'
import { SubjectBox } from '../../Common'
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';


class SearchResult extends React.Component {
	static propTypes = {
		searchResult: PropTypes.object.isRequired,
	}

	handleGoBack = () => {
		this.props.history.goBack()
	}

	componentWillMount() {
		if (this.props.searchResult.get("subjectLabel") === null) {
			this.props.history.push("/my")
		}
	}

	handleClick = row => {
		this.props.history.push("/q/sentaku/show/" + row.get("MondaiID"))
	}

	render() {
		const { searchResult } = this.props
		const resultVd = searchResult.get("result").map(row => {
			// debugger
			const nendo = row.get("nendo")+"年"
			const kai   = row.get("Shikenkaisu")
			const num   = "問題"+row.get("MondaiNum")
			const text  = row.get("Mondaibun")
			return (
				<div
					style={{backgroundColor: "#e8eaf6", marginBottom: 4}}
					key={row.get("MondaiID")}
				>
					<ListItem button onClick={this.handleClick.bind(this, row)}>
						<ListItemText primary={nendo+" "+kai+" "+num} secondary={text}/>
					</ListItem>
				</div>
			)
		})
		return (
			<div>
				<LessonToolBar
					label="検索結果"
					onGoBack={this.handleGoBack.bind(this)}
				/>
				<SubjectBox label={searchResult.get("subjectLabel")}/>
				<List>
					{resultVd}
				</List>
			</div>
		)
	}
}

export default withRouter(SearchResult)
