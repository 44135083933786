import React, { Component } from 'react'
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Snackbar from '@material-ui/core/Snackbar';
import { updateSnackbarAC } from '../SlUtilsAction'

class SlSnackbar extends Component {

	render() {
		return (
			<Snackbar
				open={this.props.open}
				message={this.props.text}
				autoHideDuration={5000}
				onClose={this.props.handleClose}
			/>
		)
	}
}
SlSnackbar.propTypes = {
	open: PropTypes.bool.isRequired,
};
export default connect(
	state => ({
		open: state.sl_utils.snackbarOpen,
		text: state.sl_utils.snackbarText,
	}),
	dispatch => ({
		handleClose: () => {
			dispatch(updateSnackbarAC(false))
		}
	})
)(SlSnackbar);
