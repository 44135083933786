import React from 'react'
import PropTypes from 'prop-types'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox';

export default class CheckboxSet extends React.Component {
	static propTypes = {
		onChange: PropTypes.func,
		state: PropTypes.object,
	}

	// handleToggle = (index, event, checked) => {
	// 	// this.props.state.setChecked(index, checked)
	// 	// row.checked = checked
   //  if (this.props.onChange) {
   //    this.props.onChange(index, checked);
   //  }
	// }
	//
	handleChange = index => event => {
		const checked = event.target.checked
    if (this.props.onChange) {
      this.props.onChange(index, checked);
    }
		// this.setState({ [name]: checked });
  };

	render() {
    const {
			label,	 // eslint-disable-line no-unused-vars
			// name,		 // eslint-disable-line no-unused-vars
			state,	 // eslint-disable-line no-unused-vars
      // children, // eslint-disable-line no-unused-vars
      // className,	 // eslint-disable-line no-unused-vars
      // disabled,	 // eslint-disable-line no-unused-vars
      // errorStyle,	 // eslint-disable-line no-unused-vars
      // errorText, // eslint-disable-line no-unused-vars
      // fullWidth, // eslint-disable-line no-unused-vars
      // hintText,
      // hintStyle,	 // eslint-disable-line no-unused-vars
      id,	// eslint-disable-line no-unused-vars
      // inputStyle,	 // eslint-disable-line no-unused-vars
			multiline,		 // eslint-disable-line no-unused-vars
      onBlur, // eslint-disable-line no-unused-vars
      onChange, // eslint-disable-line no-unused-vars
      onFocus, // eslint-disable-line no-unused-vars
      // style,	 // eslint-disable-line no-unused-vars
      // ...other
    } = this.props;
		// const ref = (elem) => this.input = elem

		const checkboxes = state.getList().map((row, index) => {
			const disabled = (typeof row.disabled === 'boolean') ? row.disabled : false
			return (
				<FormControlLabel
					key={index}
          control={
            <Checkbox
              checked={row.checked}
              onChange={this.handleChange(index)}
              value="checkedA"
              style={{height: 24}}
							disabled={disabled}
            />
          }
					label={<span style={{fontSize:'1.4rem'}}>{row.label}</span>}
        />
			)
		})

		return (
			<div>
				{checkboxes}
			</div>
		)
	}
}
