// @flow
import React, { Component } from 'react';
import Icon from '@material-ui/core/Icon'
import IconButton from '@material-ui/core/IconButton'
import Menu from '@material-ui/core/Menu';
import common from '@material-ui/core/colors/common'

type Props = {
  iconColor?: string,
  children: any,
}
type States = {
  anchorEl?: any,
  open: boolean,
}
class IconMenu extends Component<Props, States> {
  state = {
    anchorEl: undefined,
    open: false,
  };

  handleClick = (event: any) => {
    this.setState({ open: true, anchorEl: event.currentTarget });
  };

  handleRequestClose = () => {
    this.setState({ open: false });
  };

  render() {
    const iconColor = this.props.iconColor || common.black
    return (
      <div style={{display:'inline-block'}}>
				<IconButton
          aria-label="More"
          aria-owns={this.state.open ? 'menu' : null}
          aria-haspopup="true"
          onClick={this.handleClick}
          style={{color: iconColor}}
        >
          <Icon>more_vert</Icon>
        </IconButton>
        <Menu
          id="menu"
          anchorEl={this.state.anchorEl}
          open={this.state.open}
          onClose={this.handleRequestClose}
        >
					{this.props.children}
        </Menu>
      </div>
    );
  }
}

export default IconMenu;
