import * as Action from './MoshiAction';
import { Record } from 'immutable'

const _MoshiStateRecord = Record({
	serviceCode: 				null,
	serviceName: 				null,
	testSubjectCode: 		null,
	testSubjectName: 		null,
	questions: 					null,
	qno: 								null,
	choices: 						null,
	selectHistory: 			null,
	answers: 						null,
	liner: 							null,
	orderno_resetindex: null,
	printMode: 					null,
	markCategoryId: 		null,
	markSubjects: 			null,
	markPerPageState:		null,		// markの各ページのstate
})
class MoshiState extends _MoshiStateRecord {}

export default function moshiReducer(state = new MoshiState(), action) {
	switch (action.type) {
		case Action.UPDATE_MOSHI:
			return state.merge(action.payload)
		default:
			return state
	}
}
