// @flow
import React from 'react'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox';
import {CheckboxInputState} from "../sl_utils/SlForms";

type Props = {
	label?: string,
	state: CheckboxInputState,
	onChange: (CheckboxInputState) => void,
}
export default class SlCheckbox extends React.Component<Props> {
	onChange = (event:SyntheticInputEvent<*>) => {
		const {onChange, state} = this.props
		onChange(state.setValue(event.target.checked))
	}

	render() {
		const {label, state, onChange, ...other} = this.props
		return (
			<div>
				<div>
					<FormControlLabel
						control={
							<Checkbox
								checked={state.getValue()}
								onChange={this.onChange}
								value="true"
								{...other}
							/>
						}
						label={label}
					/>
				</div>
				<span style={{color: 'red'}}>{state.getError()}</span>
			</div>
		)
	}
}
