import React from 'react'
import Moment from "moment/moment";
import { withRouter } from 'react-router-dom'
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Paper from '@material-ui/core/Paper'
import JisshidoBox from './JisshidoBox'
import ShujukudoBox from "./ShujukudoBox";
import {post} from "../sonodalib/sl_utils/Http";
import PropTypes from "prop-types";
import _ from 'lodash'
import {common} from "@material-ui/core/colors/index";
import Cookies from "js-cookie";
import {altName} from '../alt/constants'


class HistoryKamokuHyouka extends React.Component {
	state = {
		kamokuHyoukaList: null,
		tabIndex: 0,
	}

	componentWillMount() {
		this.fetch_kamoku_hyouka()
	}

	fetch_kamoku_hyouka() {
		const self = this
		const payload = {
			user_token:  Cookies.get(altName),
			// mondai_type: 2,
		}
		post("result/fetch/kamoku-hyouka", payload)
			.then(ret => {
				const propTypes = {
					results: PropTypes.array.isRequired,
				}
				PropTypes.checkPropTypes(propTypes, ret)
				self.setState({
					kamokuHyoukaList: {
						sentaku: _.filter(ret.results, {MondaiType: 2}),
						ox: 		 _.filter(ret.results, {MondaiType: 1}),
					}
				})
			})
	}

	handleSubject = row => {
		this.props.history.push("/my/hist/detail/"+row.MondaiType+"/"+row.KamokuID)
	}

	handleChangeTab = (event, value) => {
		this.setState({
			tabIndex: value,
		})
	}

	render() {
		const styles = {
			categorySelectBox: {
				marginTop: 50,
				// marginBottom: 18,
			},
			radioButton: {
				width: 150,
				// marginBottom: 16,
				// display: 'inline-block',
			},
			subjectCol: {
				whiteSpace: 'normal',
				// whiteSpace: 'nowrap',
				// overflow: 'hidden',
				// textOverflow: 'ellipsis',
			},
			daysCol: {
				whiteSpace: 'nowrap',
				textAlign: 'center',
			},
	    tab: {
			  color: common.black,
		    background: common.white,
	    },
		}
		const createKamokuHyoukaRow = row => {
			let updated = "未学習"
			if (row.updated) {
				const d = Moment(row.updated)
				updated = <span>{d.format("YYYY-MM-DD")}<br/>{d.format("HH:mm")}</span>
			}
			return (
				<tr onClick={this.handleSubject.bind(this, row)} style={{height: 36}} key={_.uniqueId("fk")}>
					<td style={styles.subjectCol}>{row.Kamokumei}</td>
					<td style={{textAlign:'center'}}>{updated}</td>
					<td style={styles.daysCol}>{row.dayCount}日</td>
					<td style={{width: 100}}>
						<JisshidoBox
							jisshido={row.Jisshido}
						/>
					</td>
					<td style={{width: 100}}>
						<ShujukudoBox
							shujukudo={row.Shujukudo}
							goukakudo={row.Goukakudo}
						/>
					</td>
				</tr>
			)
		}

		const { kamokuHyoukaList } = this.state
		const list = (!kamokuHyoukaList) ? null : (!this.state.tabIndex) ? kamokuHyoukaList.sentaku : kamokuHyoukaList.ox
		const kamokuHyoukaVd = (!kamokuHyoukaList) ? null : (
			<tbody>
				<tr style={styles.tableHeader}>
					<th style={{textAlign:'center'}}>科目</th>
					<th style={{textAlign:'center'}}>最終学習日時</th>
					<th style={styles.daysCol}>日数</th>
					<th style={{width: 100,textAlign:'center'}}>実施度</th>
					<th style={{width: 100,textAlign:'center'}}>習熟度</th>
				</tr>
				{(list.length) ? _.map(list, createKamokuHyoukaRow) : <tr><td colSpan="7" style={{textAlign:'center'}}>履歴はありません</td></tr>}
			</tbody>
		)
		return (
			<div style={{marginTop: 18}}>
				<Paper>
					<Tabs
						centered
						fullWidth
						onChange={this.handleChangeTab.bind(this)}
						value={this.state.tabIndex}
					>
						<Tab
							style={styles.tab}
							label="選択問題"
							value={0}
						>
						</Tab>
						<Tab
							style={styles.tab}
							label="◯✕問題"
							value={1}
						>
						</Tab>
					</Tabs>
					<table className="table table-hover table-striped table-bordered" style={{marginTop: 6,fontSize: 12,borderBottom: '1px #ccc solid'}}>
						{kamokuHyoukaVd}
					</table>
				</Paper>
			</div>
		)
	}
}

export default withRouter(HistoryKamokuHyouka)
