import React from 'react'
import { withRouter } from 'react-router-dom'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import { common } from '@material-ui/core/colors';
import HistoryKamokuHyouka from './HistoryKamokuHyouka'
import {isInvaildLicensed} from "../Common";
import {connect} from "react-redux";
// import Selector from '../sonodalib/sl_adminlte/Selector'
// import _ from 'lodash'
// import {post} from '../sonodalib/sl_utils/Http'
// import Moment from 'moment'
// import Cookies from "js-cookie";

class HistoryTop extends React.Component {
	state = {
		pullDown: 0,
		resultList: null,
	}

	// componentWillMount() {
	// 	const now 		= new Date()
	// 	let   year		= now.getFullYear()
	// 	let   year100 = year * 100
	// 	let   month   = now.getMonth()+1
	// 	this.setState({
	// 		pullDown: year100 + month,
	// 	}, () => {
	// 		this.fetch_history_list()
	// 	})
	// }

	// fetch_history_list() {
	// 	const self = this
	// 	const payload = {
	// 		user_token: Cookies.get(altName),
	// 		year_month: this.state.pullDown,
	// 	}
	// 	post("result/fetch/all", payload)
	// 		.then(ret => {
	// 			// const propTypes = {
	// 			// 	results: PropTypes.array.isRequired,
	// 			// }
	// 			// PropTypes.checkPropTypes(propTypes, ret)
	// 			if (ret.results.length > 100) {
	// 				ret.results.length = 100
	// 			}
	// 			self.setState({
	// 				resultList: ret,
	// 			})
	// 		})
	// }

	// handlePullDown = value => {
	// 	this.setState({
	// 		pullDown: value,
	// 	}, this.fetch_history_list)
	// }

	handleToiawase = () => {
		this.props.history.push("/my/bbs/contact")
	}

	render() {
		const license = (this.props.userState) ? this.props.userState.license : null
		if (!license) return null

		if (isInvaildLicensed(license)) {
			return (
				<Grid container direction="column" justify="center" alignItems="center" style={{marginTop:100}}>
					<Grid item style={{height:100}}>
						<span style={{fontSize:20,fontWeight:700}}>利用制限がかかっております。詳しくはお問い合わせ下さい。</span>
					</Grid>
					<Grid item style={{height:100}}>
						<Button variant="contained" color="secondary" onClick={this.handleToiawase}>
							お問い合わせ
						</Button>
					</Grid>
				</Grid>
			)
		}

		// const styles = {
		// 	tableHeader: {
		// 		fontSize: 12,
		// 		backgroundColor: grey[200],
		// 	},
		// }

		// const correctVd = (
		// 	<span className="label label-danger" style={{display:'inline-block',width:44}}>正解</span>
		// )
		// const incorrectVd = (
		// 	<span className="label" style={{display:'inline-block',width:44,backgroundColor:"#888"}}>不正解</span>
		// )

		// const { resultList } = this.state
		// let resultListVd = null
		// if (resultList) {
		// 	// let list = this.state.resultList.sentakuResults.concat(this.state.resultList.oxResults)
		// 	let list = this.state.resultList.results
		// 	if (this.state.pullDown === 0 && list.length > 20) {
		// 		list.length = 20
		// 	}
		// 	// list = _.reverse(_.sortBy(list, "updated"))
		// 	const typeLabel = ["選択", "◯✕"]
		// 	const resultLabel = [null, correctVd, incorrectVd]
		// 	resultListVd = _.map(list, row => {
		// 		// const type = ("MondaiID" in row) ? 0 : 1
		// 		const type = (row.MarubatsuID === 0) ? 0 : 1
		// 		const updated = Moment(row.updated).format("YYYY-MM-DD HH:mm")
		// 		return (
		// 			<tr key={_.uniqueId("fk")}>
		// 				<td>{updated}</td>
		// 				<td>{typeLabel[type]}</td>
		// 				<td>{row.Kamokumei}</td>
		// 				<td>{resultLabel[row.result]}</td>
		// 			</tr>
		// 		)
		// 	})
		// 	if (resultListVd.length === 0) {
		// 		resultListVd = (
		// 			<tr key={0}>
		// 				<td colSpan="4" style={{textAlign: 'center'}}>履歴はありません</td>
		// 			</tr>
		// 		)
		// 	}
		// }
		//
		// const now 		= new Date()
		// let   year		= now.getFullYear()
		// let   year100 = year * 100
		// let   month   = now.getMonth()+2
		// // const pullDownVd = [<option key={0} value={0}>最近の学習履歴</option>]
		// const pullDownVd = []
		// do {
		// 	month--
		// 	if (month === 0) {
		// 		month = 12
		// 		year--
		// 		year100 -= 100
		// 	}
		// 	pullDownVd.push(<option key={month} value={year100 + month}>{year}年{month}月</option>)
		// } while (month !== 4)

		return (
			<div style={{backgroundColor: common.white, padding: 10}}>
				{/*<Grid container style={{margin: 15}}>*/}
					{/*<Grid item xs></Grid>*/}
					{/*<Grid item xs style={{width:200}}>*/}
						{/*<Selector*/}
							{/*className="form-control"*/}
							{/*value={this.state.pullDown}*/}
							{/*onChange={this.handlePullDown.bind(this)}*/}
							{/*style={{width: 200}}*/}
						{/*>*/}
							{/*{pullDownVd}*/}
						{/*</Selector>*/}
					{/*</Grid>*/}
					{/*<Grid item xs></Grid>*/}
				{/*</Grid>*/}
				{/*<div style={{maxHeight: 280,overflowY: 'auto',border:'1px solid #ddd'}}>*/}
					{/*<table className="table table-hover table-striped table-bordered" style={{marginBottom: 0}}>*/}
						{/*<tbody>*/}
							{/*<tr style={styles.tableHeader}>*/}
								{/*<th>日時</th>*/}
								{/*<th>モード</th>*/}
								{/*<th>科目</th>*/}
								{/*<th>結果</th>*/}
							{/*</tr>*/}
							{/*{resultListVd}*/}
						{/*</tbody>*/}
					{/*</table>*/}
				{/*</div>*/}
				<div style={{textAlign:'center'}}>
					<span style={{color:'red'}}>科目名から「履歴の詳細」が開きます。選択問題／〇×問題ごとの切り替え表示です。</span>
				</div>
				<HistoryKamokuHyouka/>
			</div>
		)
	}
}

export default connect(
	state => ({
		userState: state.sl_users.state,
	}),
)(withRouter(HistoryTop));
