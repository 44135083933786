import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import { common, blue } from '@material-ui/core/colors';
import { SubjectBox, QuestionBox } from '../Common'
import List from '@material-ui/core/List'
import { SentakuChoiceResult } from './CommonParts'
import {post} from '../sonodalib/sl_utils/Http'
import _ from 'lodash'
import Button from '@material-ui/core/Button'
import { updateCurrentMyPageTabAC } from '../mypage/MyPageAction'
import FkToolbar from "../parts/FkToolbar";
import { startSentakuQuestions } from './Question'
import MemoIconButton from '../parts/MemoIconButton'
import { withMemoDrawer } from '../parts/MemoDrawer'
import { withBbsDrawer } from '../parts/BbsDrawer'
import SentakuPrint from './SentakuShowPrint'
import G from '../sonodalib/SlGlobal'
import {updatePrintModeAC} from '../mypage/MyPageAction'


class SentakuShow extends React.Component {
	state = {
		question: null,
	}

	componentWillMount() {
		const payload = {
			mondai_id: this.props.match.params.mondaiId,
		}
		post("q/fetch/sentaku", payload)
			.then(ret => {
				this.setState({
					question: ret.question,
				})
				this.props.memoDrawer.load({
					mondaiId: ret.question.MondaiID,
					marubatsuId: 0,
				})
			})
			.catch(ret => {
				this.props.history.push("/my")
			})
	}

	handleGoBack = () => {
		this.props.history.goBack()
	}

	handleTryQuestion = () => {
		const self = this
		const payload = {
			mondai_id: 	this.state.question.MondaiID,						// 問題ID
		}
		post("q/fetch/sentaku", payload)
			.then(ret => {
				self.props.startSentakuQuestions(self.props.history, [ret.question])
			})
	}

	handleAnswer = () => {
		this.props.history.push("/q/sentaku/show-answer/"+this.state.question.MondaiID)
	}

	handlePrint = () => {
		G.store.dispatch(updatePrintModeAC(true))
		window.setTimeout(() => {
			window.print()
		}, 1500)
	}

	handleClosePrintPopout = () => {
		G.store.dispatch(updatePrintModeAC(false))
	}

	render() {
		if (!this.state.question) return null

		const { question } = this.state

		const styles = {
			toolBar: {
				backgroundColor: blue[600],
				marginTop: 10,
				height: 38,
			},
			toolBarTitle: {
				marginLeft: 20,
				color: common.white,
			},
			commandBox: {
				margin: 8,
				marginBottom: 18,
			},
			commandButton: {
				width: 200,
				marginRight: 10,
			},
		}

		const choiceVd = _.map(question.choices, choice => {
			return (
				<SentakuChoiceResult
					button={false}
					key={_.uniqueId("fk")}
					choice={choice}
					showResult={0}
				/>
			)
		})

		const commandBox = (
			<div style={styles.commandBox}>
				<Button variant="contained"
					onClick={this.handleTryQuestion.bind(this)}
					style={{...styles.commandButton, color: common.white, backgroundColor: "#2196f3"}}
				>
					問題を解く
				</Button>
				<Button variant="contained"
					onClick={this.handleAnswer.bind(this)}
					style={{...styles.commandButton, color: common.white, backgroundColor: "#2196f3"}}
				>
					解答・解説
				</Button>
			</div>
		)

		if (this.props.printMode) return <SentakuPrint mondaiId={this.props.match.params.mondaiId} onClose={this.handleClosePrintPopout}/>
		return (
			<div>
				<FkToolbar title="問題詳細" onGoBack={this.handleGoBack.bind(this)}>
					<MemoIconButton
						handleOpenMemo={() => {this.props.memoDrawer.open(null)}}
						isMemoActive={this.props.memoDrawerStatus}
					/>
					<IconButton
						style={{color:'white'}}
						aria-label="掲示板"
						onClick={() => {
							this.props.bbsDrawer.openToCreateThread(
								1,
								undefined,
								this.props.match.params.mondaiId,
								0,
								"",
							)
						}}
					>
						<Icon>comment</Icon>
					</IconButton>
					<IconButton style={{color:'white'}} aria-label="印刷" onClick={this.handlePrint}>
						<Icon>printer</Icon>
					</IconButton>
				</FkToolbar>
				<SubjectBox label={question.subjectLabel+"　"+question.subLabel}/>
				<QuestionBox text={question.qText}/>
				<List style={{marginLeft:12, marginRight:12}}>
					{choiceVd}
				</List>
				{commandBox}
			</div>
		)
	}
}

export default connect(
	state => ({
		printMode: state.mypage.printMode,
	}),
	dispatch => ({
		startSentakuQuestions: (history, questions) => {
			dispatch(updateCurrentMyPageTabAC(1))
			startSentakuQuestions({
				dispatch,
				history,
				questions,
				repeat: false,
			})
		},
	})
)(withRouter(withMemoDrawer(withBbsDrawer(SentakuShow))))
