// @flow
import React from 'react'
import { deepOrange, common } from '@material-ui/core/colors'
import '../sonodalib/sl_utils/utils.css'
import {post} from '../sonodalib/sl_utils/Http'
import { SubjectBox, QuestionBox } from '../Common'
import Moment from 'moment'
import Button from '@material-ui/core/Button'
import {urlPref} from '../alt/constants'


type Props = {
	marubatsuId: string,
	onClose: () => void,
}
type State = {
	question: any,
}
export default class OxShowPrint extends React.Component<Props, State> {
	state = {
		question: null,
	}

	componentWillMount() {
		const { marubatsuId } = this.props
		post("q/fetch/ox", {marubatsu_id: marubatsuId})
			.then(ret => {
				this.setState({
					question: ret.question,
				})
			})
	}

	render() {
		const styles = {
			alert: {
				background: common.black,
				padding: 10,
			},
			alertP: {
				color: common.white,
				fontWeight: 'bold',
				margin: 0,
				padding: 0,
				fontSize: 14,
			},
			pullRight: {
				textAlign: 'right',
			},
			sideMargin: {
				marginLeft: 12,
				marginRight: 12,
			},
			remark: {
				marginBottom: 10,
				fontWeight: 800,
			},
		}
		const { question } = this.state
		if (!question) return null
		const correctAnswerText = ["", "◯", "✕"]
		const currentTime = Moment(new Date()).format("YYYY-MM-DD HH:mm:ss")

		return (
			<div style={{backgroundImage: `url('${urlPref}/images/bg_print.jpg')`, width: 700}}>
				<div className="no-print">
					<Button color="secondary" variant="contained" onClick={this.props.onClose}>戻る</Button>
				</div>
					<div style={styles.alert}>
						<p style={styles.alertP}>※この印刷紙面の内容について、個人の学習以外の目的で使用すること、及び無断で複製・転載することを堅く禁止致します。株式会社ジェイシー教育研究所</p>
					</div>
					<div className="pull-right" style={styles.pullRight}>
						日時：{currentTime}
					</div>
					<SubjectBox label={question.subjectLabel+"　"+question.subLabel}/>
					<QuestionBox text={question.qText}/>

					<div style={styles.sideMargin}>
						<div style={styles.remark}>［正答］</div>
						<div style={styles.remark}>
							<span style={{color: deepOrange[600], fontWeight: 800, fontSize: 20, marginLeft: 20}}>{correctAnswerText[question.correctAnswer]}</span>
						</div>
					</div>
					<div style={styles.sideMargin}>
						<div style={styles.remark}>［解説］</div>
						{question.commentaryText}
					</div>

					<div style={{...styles.pullRight, marginTop: 30}}>
						Copyright © 2017 JC Educational Institute, Inc. All Rights Reserved.
					</div>
				</div>
		)
	}
}
