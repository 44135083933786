import React from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import FkToolbar from '../parts/FkToolbar'
import {connect} from "react-redux";
import {isInvaildLicensed, isKyotsuLicensed, isShakaiLicensed, isSeishinLicensed} from "../Common";
import {categoryLabels} from "../alt/constants"


class SelectCategory extends React.Component {
	static propTypes = {
		onSelect: PropTypes.func.isRequired,
	}

	handleSubmit = (category) => {
		// this.props.history.push("/my/lesson/select-qtype/" + category)
		this.props.onSelect(category)
	}

	render() {
		// return this.renderPC()
		return this.renderMobile()
	}

	// renderPC() {
	// 	const styles = {
	// 		container: {
	// 			margin: 40,
	// 			textAlign: 'center',
	// 		},
	// 		guide: {
	// 			fontWeight: 'bold',
	// 			marginBottom: 30,
	// 			marginTop: 60,
	// 			// textAlign: 'center',
	// 		},
	// 	}
	// 	const license = (this.props.userState) ? this.props.userState.get("license") : null
	// 	if (!license) return null
	//
	// 	const kyotsuNode = (!isKyotsuLicensed(license)) ? null : (
	// 		<Grid item>
	// 			<CategoryButton
	// 				category={0}
	// 				onClick={this.handleSubmit.bind(this, 0)}
	// 			/>
	// 		</Grid>
	// 	)
	// 	const shakaiNode = (!isShakaiLicensed(license)) ? null : (
	// 		<Grid item>
	// 			<CategoryButton
	// 				category={1}
	// 				onClick={this.handleSubmit.bind(this, 1)}
	// 			/>
	// 		</Grid>
	// 	)
	// 	const seishinNode = (!isSeishinLicensed(license)) ? null : (
	// 		<Grid item>
	// 			<CategoryButton
	// 				category={2}
	// 				onClick={this.handleSubmit.bind(this, 2)}
	// 			/>
	// 		</Grid>
	// 	)
	// 	return (
	// 		<div>
	// 			<LessonToolBar
	// 				label="カテゴリー選択"
	// 			/>
	//
	// 			<Grid container direction="column" alignItems="center" spacing={16} style={{margin: 50}}>
	// 				<Grid item>
	// 					<div style={styles.guide}>
	// 						カテゴリーを選択して下さい
	// 					</div>
	// 				</Grid>
	// 				{kyotsuNode}
	// 				{shakaiNode}
	// 				{seishinNode}
	// 			</Grid>
	// 		</div>
	// 	)
	// }

	handleToiawase = () => {
		this.props.history.push("/my/bbs/contact")
	}

	renderMobile() {
		const license = (this.props.userState) ? this.props.userState.license : null
		if (!license) return null

		if (isInvaildLicensed(license)) {
			return (
				<Grid container direction="column" justify="center" alignItems="center" style={{marginTop:100}}>
					<Grid item style={{height:100}}>
						<span style={{fontSize:20,fontWeight:700}}>利用制限がかかっております。詳しくはお問い合わせ下さい。</span>
					</Grid>
					<Grid item style={{height:100}}>
						<Button variant="contained" color="secondary" onClick={this.handleToiawase}>
							お問い合わせ
						</Button>
					</Grid>
				</Grid>
			)
		}

		const kyotsuNode = (!isKyotsuLicensed(license)) ? null : (
			<ListItem button onClick={this.handleSubmit.bind(this, 0)}>
				<ListItemText primary={categoryLabels[0]} />
			</ListItem>
		)
		const shakaiNode = (!isShakaiLicensed(license)) ? null : (
			<ListItem button onClick={this.handleSubmit.bind(this, 1)}>
				<ListItemText primary={categoryLabels[1]} />
			</ListItem>
		)
		const seishinNode = (!isSeishinLicensed(license)) ? null : (
			<ListItem button onClick={this.handleSubmit.bind(this, 2)}>
				<ListItemText primary={categoryLabels[2]} />
			</ListItem>
		)
		return (
			<div>
				<FkToolbar
					title="カテゴリー選択"
				/>
				<List
					component="nav"
					// subheader={<ListSubheader component="div">カテゴリーを選択して下さい</ListSubheader>}
				>
					{kyotsuNode}
					{shakaiNode}
					{seishinNode}
				</List>
			</div>
		)
	}
}

export default connect(
	state => ({
		userState: state.sl_users.state,
	}),
)(withRouter(SelectCategory));
