import React from 'react'


export const ContractText = (props) => (
	<div style={{display: 'block'}}>
		<div className="text-box01 clear" style={{fontSize: 12}}>
			<p>以下の赤マル福祉ｅラーニングサービス利用規約（以下、「本規約」といいます）をよくお読みください。本規約は、株式会社ジェイシー教育研究所（以下、「当社」といいます）の赤マル福祉合格サポートサービス（以下、「本サービス」といいます）をご利用いただくにあたって、当社と利用者の間で締結する契約内容になります。</p>
			<div style={{fontSize: 12, textAlign:'right'}}>制定年月日：平成21年10月8日<br/>
				最終改定年月日：平成25年10月
			</div>
		</div>

		<h3>第１条 （本サービスの適用及び変更）</h3>
		<div className="entry-space" style={{margin: "0 0 30px 40px"}}>
			<ol>
				<li style={{listStyleType: "decimal"}}>本サービスにおける利用者とは以下のいずれかに該当する者をいい、当社が利用を承認した時点で、利用者は本規約の内容を全て承諾しているものとみなします。<br/>
					(1) 当社に対し本サービスへの利用を申し込み、当社がそれを承認した者。<br/>
					(2) 当社が別途定める方法により本サービスの利用を承認した者。
				</li>
				<li style={{listStyleType: "decimal"}}>本規約は、当社がインターネット上で運営する本サービスを、利用者が利用されるにあたっての一切の行為に適用されます。</li>
				<li style={{listStyleType: "decimal"}}>本サービスの利用申込者及び利用者は、当社が指定する方法で本規約の内容への同意を表明し、承諾の上で本サービスを利用することができます。</li>
				<li style={{listStyleType: "decimal"}}>本サービス上又は当社が適当と判断する方法により、当社が利用者へ提示する取り決めや注意事項は、それぞれ本規約の一部を構成します。</li>
				<li
					style={{listStyleType: "decimal"}}>当社は利用者の了承を得ることなく本規約を変更することがあります。この場合、本サービスの利用条件は変更後の規約に従うものとします。変更後の規約は、前項の規定により利用者へ通知するものとします。
				</li>
			</ol>
		</div>

		<h3>第２条 （本サービスの提供方法等）</h3>
		<div className="entry-space" style={{margin: "0 0 30px 40px"}}>
			<ol>
				<li
					style={{listStyleType: "decimal"}}>当社は利用者に対し、本サービスをご利用いただくのに必要なアカウント情報として「ユーザーID」及び「パスワード」（以下、合わせて「アカウント情報」という）を、当社が別途指定する方法により交付します。
				</li>
				<li style={{listStyleType: "decimal"}}>一つのユーザーIDで利用可能な利用者数は一個人であり、一つのユーザーIDを複数人で利用することはできません。</li>
				<li
					style={{listStyleType: "decimal"}}>当社より交付されたアカウント情報の管理及び使用については、利用者の責任において適切に行い、使用上の過誤又は第三者による不正使用時については、当社は一切その責任を負わないものとします。
				</li>
				<li style={{listStyleType: "decimal"}}>利用者は、アカウント情報を紛失又は盗難された場合は、速やかに当社にその旨を届け出るものとします。</li>
			</ol>
		</div>

		<h3>第３条 （利用者設備）</h3>
		<div className="entry-space" style={{margin: "0 0 30px 20px"}}>
			<ol>
				<li>利用者は、本サービスの利用にあたって、当社指定の動作環境等を事前に確認して、動作に必要となるパソコン、ソフト、通信機器、及びインターネット接続に関する機器や費用等お客様の設備（以下、総称して「利用者設備」といいます）として必要なものは、お客様の費用と責任においてご用意するものとします。</li>
			</ol>
		</div>

		<h3>第４条 （本サービスの提供）</h3>
		<div className="entry-space" style={{margin: "0 0 30px 40px"}}>
			<ol>
				<li style={{listStyleType: "decimal"}}>当社は、利用者に対しインターネット又はその他の手段を通じてｅラーニングサービス及びそれに付帯するサービスを提供します。</li>
				<li
					style={{listStyleType: "decimal"}}>利用者は、本サービスを利用するにあたり当社が別途定める利用料を別途定める期間内に支払うものとします。その支払方法は当社が指定した方法より利用者が選択するものとします。
				</li>
				<li style={{listStyleType: "decimal"}}>当社は、利用者に対するアカウント情報の発行をもって本サービスの利用を当該利用者に対し、別途定められた利用期間において許諾するものとします。
				</li>
				<li style={{listStyleType: "decimal"}}>当社は、利用者に対するアカウント情報の発行、利用期間の通知ならびに本サービスの利用許諾をｅメール又は当社が適当と判断する方法により通知します。
				</li>
			</ol>
		</div>

		<h3>第５条 （本サービスの中断及び終了）</h3>
		<div className="entry-space" style={{margin: "0 0 30px 40px"}}>
			<ol>
				<li
					style={{listStyleType: "decimal"}}>当社は、お客様への事前の通知や承諾を得ることなしに、保守作業、停電及び天災等の不可抗力その他の理由により、本サービスの提供を一定期間中断することがあります。
				</li>
				<li style={{listStyleType: "decimal"}}>当社は、前項により、お客様が被ったいかなる損害についても、一切その責任を負わず、利用料金の減額及び返金も一切行なわないものとします。</li>
				<li
					style={{listStyleType: "decimal"}}>当社は、利用者に対し通知した利用期間を経過した時点で、当該利用者に対するサービスを終了するものとします。この際、当該利用者向けに発行したアカウント情報は抹消されます。
				</li>
			</ol>
		</div>

		<h3>第６条 （権利の帰属）</h3>
		<div className="entry-space" style={{margin: "0 0 30px 20px"}}>
			<ol>
				<li>本サービスを構成するすべてのプログラム、教材、ヘルプやマニュアル等のドキュメント類、ノウハウ、商標、商号及びそれに付随する全ての知的財産権は当社あるいは正当な権利を持つ第三者に帰属し、著作権法及び関連法規により保護されるものであり、利用者はこれらの権利を侵害する行為を一切行なってはなりません。</li>
			</ol>
		</div>

		<h3><b>第７条 （禁止行為）</b></h3>
		<div className="entry-space" style={{margin: "0 0 30px 20px"}}>
			利用者は、本サービス上で以下の行為を行なわないものとします。
			<div style={{marginLeft: 30}}>
				<ol>
					<li style={{listStyleType: "lower-roman"}}>アカウント情報を第三者に利用させるなど不正に使用する行為。</li>
					<li style={{listStyleType: "lower-roman"}}>コンテンツの全部又は一部の修正及びコンテンツを基にした二次的制作物を作成する行為。</li>
					<li style={{listStyleType: "lower-roman"}}>本サービスの内容を変更、翻訳、解析及びリバースエンジニアリングをする行為。</li>
					<li style={{listStyleType: "lower-roman"}}>本サービスにコンピューターウィルス等の有害なプログラムを使用もしくは提供する行為。</li>
					<li style={{listStyleType: "lower-roman"}}>当社を含む第三者を誹謗中傷する行為又は名誉もしくは信用を毀損する行為。</li>
					<li style={{listStyleType: "lower-roman"}}>当社の知的財産権を侵害する行為、又は侵害するおそれのある行為。</li>
					<li
						style={{listStyleType: "lower-roman"}}>本サービスを通じて入手したデータ、情報、文章、ソフトウェア等に関し、著作権法で認められた私的利用の範囲を超えて複製、販売、出版等をする行為。
					</li>
					<li style={{listStyleType: "lower-roman"}}>犯罪的行為又は犯罪的行為に結びつく行為、もしくはそのおそれのある行為。</li>
					<li style={{listStyleType: "lower-roman"}}>性風俗、宗教、政治に関する行為。</li>
					<li style={{listStyleType: "lower-roman"}}>上記各号のいずれかに該当する行為を助長する行為。</li>
				</ol>
			</div>
		</div>

		<h3>第８条 （本サービスの利用停止及び契約の解除）</h3>
		<div className="entry-space" style={{margin: "0 0 30px 40px"}}>
			<ol>
				<li
					style={{listStyleType: "decimal"}}>利用者が前条の項目のいずれかに該当する行為をした場合、当社は事前に通知することなく直ちに当該利用者への本サービスの利用を停止することができるものとします。
				</li>
				<li
					style={{listStyleType: "decimal"}}>前項により利用資格が取り消された場合、当社は利用者に対して利用料金の減額及び返金も一切行なわないものとします。また、当該利用者が本サービスを利用できず、これにより損害が発生したとしてもいかなる責任も負わないものとします。
				</li>
			</ol>
		</div>

		<h3><b>第９条 （個人情報の保護）</b></h3>
		<div className="entry-space"
				 style={{margin: "0 0 30px 20px"}}>本規約において個人情報とは、利用者が本サービス利用申込時に当社に届け出た利用者の氏名、メールアドレス、住所等の情報及び本サービス利用において送受信される学習情報をいいます。利用者は下記の当社の個人情報の取扱いについて同意の上で本サービスを利用するものとします。
			<div style={{marginLeft: 30}}>
				<ol>
					<li style={{listStyleType: "lower-roman"}}><b>個人情報の取得事業者及び個人情報保護管理者</b><br/>
						株式会社ジェイシー教育研究所<br/>
						千葉県千葉市中央区新田町10-15誠寿ビル2F<br/>
						TEL：043-241-5501<br/>
						Mail：privacy＠jc-edu.co.jp<br/>
						個人情報保護担当 PMS事務局長
					</li>
					<li style={{listStyleType: "lower-roman"}}><b>利用目的</b><br/>
						当社はご提供いただいた個人情報を、アカウント情報の発行、代金の決済処理、お問合せへの対応、本サービスに関するご案内、アカウント情報お問合せ時の本人確認、景品等の発送等、本サービスを提供する上で合理的な目的を遂行するために利用させて頂きます。<br/>
						ただし、次に示すいずれかに該当する場合は、ご本人の同意を得ることなく利用することがあります。<br/>
						<table>
							<tbody>
							<tr>
								<td style={{verticalAlign:'top'}}>a)</td>
								<td style={{verticalAlign:'top'}}>法令に基づく場合</td>
							</tr>
							<tr>
								<td style={{verticalAlign:'top'}}>b)</td>
								<td style={{verticalAlign:'top'}}>人の生命、身体又は財産の保護のために必要がある場合であって、ご本人の同意を得ることが困難である時</td>
							</tr>
							<tr>
								<td style={{verticalAlign:'top'}}>c)</td>
								<td style={{verticalAlign:'top'}}>公衆衛生の向上又は児童の健全な育成の推進のために必要がある場合であって、ご本人の同意を得ることが困難である時</td>
							</tr>
							<tr>
								<td style={{verticalAlign:'top'}}>d)</td>
								<td
									style={{verticalAlign:'top'}}>国の機関若しくは地方公共団体又はその委託を受けたものが法令の定める事務を遂行することに対して協力する必要がある場合であって、ご本人の同意を得ることによって当該事務の遂行に支障を及ぼすおそれがある時
								</td>
							</tr>
							</tbody>
						</table>
					</li>
					<li style={{listStyleType: "lower-roman"}}><b>個人情報の委託について</b><br/>
						当社は利用申込者の個人情報について、本人が代金決済処理の方法としてコンビニ決済等を選択した場合に限り、氏名、電話番号等の必要な情報を決済代行業者へ委託します。
					</li>
					<li style={{listStyleType: "lower-roman"}}><b>個人情報の提供について</b><br/>
						団体申し込みの場合は、当該団体の管理者に対し、学習履歴等の情報を提供します。
					</li>
					<li style={{listStyleType: "lower-roman"}}><b>開示等請求について</b><br/>
						お客様ご自身の個人情報について利用目的の通知、開示、内容の訂正、追加又は削除、利用の停止、第三者への提供の停止に関する権利を保有しています（法令に定めがある場合を除く）。これらの権利行使については、当同意書「１．個人情報の取得事業者及び個人情報保護管理者」に記載の問い合わせ先に請求ください。また、個人情報に関する苦情及び相談等についても同様の窓口となります。
					</li>
					<li style={{listStyleType: "lower-roman"}}><b>個人情報を与えることの任意性</b><br/>
						ご自身の個人情報を当社に提供することについては任意です。ただし、メールアドレス、氏名、電話番号、住所等の個人情報を与えなかった場合、当社は前述の利用目的を遂行できず、本サービスを利用することができなくなります。
					</li>
				</ol>
			</div>
			※当社の個人情報の取扱いに関する取り組みについては、<br/>
			http://www.jc-edu.co.jp/privacy/index.htmにも記載しているのでご参照ください。
		</div>
		<h3>第１０条 （免責）</h3>
		<div className="entry-space" style={{margin: "0 0 30px 40px"}}>
			<ol>
				<li style={{listStyleType: "decimal"}}>当社は、利用者設備の不具合、又は、動作環境等が適合しないことによる利用不能について、一切の責任を負わないものとします。</li>
				<li style={{listStyleType: "decimal"}}>利用者は、前項の事由をもって本サービスの利用契約を解除することはできず、利用者は自らの責任で利用者設備や動作環境等を整備するものとします。
				</li>
				<li style={{listStyleType: "decimal"}}>当社は、本サービスの使用効果について、一切の責任を負わないものとします。</li>
				<li style={{listStyleType: "decimal"}}>当社は、本サービスについて、その完全性、確実性及び有用性を保証いたしません。</li>
				<li style={{listStyleType: "decimal"}}>当社は、利用者に対して、当社の責に帰すことができない事由について、損害賠償責任を負いません。</li>
				<li
					style={{listStyleType: "decimal"}}>当社が当社の責に帰すべき事由により、本規約に基づく債務を履行しなかった場合で、当社が利用者に対して損害賠償責任を負う場合、賠償額の上限は当該損害の直接の原因となった本サービスの利用料金相当額を限度とします。
				</li>
			</ol>
		</div>

		<h3>第１１条 （その他）</h3>
		<div className="entry-space" style={{margin: "0 0 30px 40px"}}>
			<ol>
				<li style={{listStyleType: "decimal"}}>本サービスに関連して利用者と当社との間で紛争が生じた場合には、当該当事者がともに誠意をもって協議するものとします。</li>
				<li style={{listStyleType: "decimal"}}>利用者と当社の間で訴訟の必要が生じた場合、千葉地方裁判所を利用者と当社の第一審の専属合意管轄裁判所とします。</li>
				<li style={{listStyleType: "decimal"}}>本規約に関する準拠法は日本法とします。</li>
			</ol>
		</div>
	</div>
)

