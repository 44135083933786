// @flow
import React from 'react'
import Cookies from "js-cookie";
import BsLikeTextField from '../sonodalib/sl_mui/BsLikeTextField'
import {TextInputState, FormState} from '../sonodalib/sl_utils/SlForms'
// import Drawer from '@material-ui/core/Drawer'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import G from '../sonodalib/SlGlobal'
import {post} from '../sonodalib/sl_utils/Http'
import {updateSnackbarAC} from '../sonodalib/sl_utils/SlUtilsAction'
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import {altName} from '../alt/constants'

type State = {
	open: boolean,
	hideNickname: boolean,					// true=ニックネーム入力欄非表示
	isActive: boolean,
	bbsTitle: TextInputState,
	bbsHandleName: TextInputState,
	bbsText: TextInputState,
	openConfirmBox: boolean,
}
export const withBbsDrawer = (WrappedComponent: any) => class extends React.Component<{}, State> {
	state = {
		open: false,
		hideNickname: false,
		isActive: false,
		bbsTitle: new TextInputState({validations: "require"}),
		bbsHandleName: new TextInputState({validations: ["require",{max:16}]}),
		bbsText: new TextInputState({validations: "require"}),
		openConfirmBox: false,
	}
	boardId: ?number
	threadId: ?string
	mondaiId: ?number
	marubatsuId: ?number
	postId: ?string
	onPosted: ?(string) => void
	method: ?'new-thread' | 'new-post' | 'edit-post'

	constructor() {
		super()
		this.boardId = null
		this.threadId = undefined
		this.mondaiId = null
		this.marubatsuId = null
		this.postId = null
		this.onPosted = null
		this.method = null
	}

	open = (fixedNickname: string) => {
		this.setState({
			open: true,
			hideNickname: !!fixedNickname,
			bbsTitle: this.state.bbsTitle.setValue(""),
			bbsHandleName: this.state.bbsHandleName.setValue(fixedNickname),
			bbsText: this.state.bbsText.setValue(""),
		})
	}

	openToCreateThread = (boardId: number, onPosted: (string) => void, mondaiId: number, marubatsuId: number, fixedNickname: string) => {
		this.boardId = boardId
		this.method = "new-thread"
		this.threadId = undefined
		this.onPosted = onPosted
		this.mondaiId = mondaiId
		this.marubatsuId = marubatsuId
		this.open(fixedNickname)
	}

	openToPostComment = (threadId: string, onPosted: (string) => void, fixedNickname: string) => {
		this.boardId = null
		this.method = "new-post"
		this.threadId = threadId
		this.onPosted = onPosted
		this.mondaiId = null
		this.marubatsuId = null
		this.open(fixedNickname)
	}

	openToEditPost = (postId: string, title: string, handleName: string, text: string, onPosted: (string) => void) => {
		this.onPosted = onPosted
		this.postId = postId
		this.method = "edit-post"
		this.setState({
			open: true,
			hideNickname: (handleName.length===0),
			bbsTitle: this.state.bbsTitle.setValue(title),
			bbsHandleName: this.state.bbsHandleName.setValue(handleName),
			bbsText: this.state.bbsText.setValue(text),
		})
	}

	onCancel() {
		this.setState({
			open: false,
		})
	}

	handleConfirm = () => {
		const newState = FormState.validate(this.state)
		this.setState(newState, () => {
			if (FormState.isValid(this.state)) {
				this.setState({
					openConfirmBox: true,
				})
			}
		})
	}

	handlePost = () => {
		this.setState({
			openConfirmBox: false,
		})
		const self = this
		switch (this.method) {
			case 'new-thread':		// 新規スレッド作成
				post("bbs/create_thread", {
					user_token: Cookies.get(altName),
					board_id: this.boardId,
					mondai_id: this.mondaiId,
					marubatsu_id: this.marubatsuId,
					title: this.state.bbsTitle.value,
					handle_name: this.state.bbsHandleName.value,
					text: this.state.bbsText.value,
				})
				.then((ret: any) => {
					G.store.dispatch(updateSnackbarAC("投稿しました"))
					self.setState({
						open: false,
						// openConfirmBox: false,
					})
					if (self.onPosted) self.onPosted(ret.thread_id)
				})
				break;

			case 'new-post':		// コメント返信
				const threadId: ?string = this.threadId
				if (threadId) {

					post("bbs/post_comment", {
						user_token: Cookies.get(altName),
						thread_id: threadId,
						title: this.state.bbsTitle.value,
						handle_name: this.state.bbsHandleName.value,
						text: this.state.bbsText.value,
					})
					.then((ret: any) => {
						G.store.dispatch(updateSnackbarAC("返信を投稿しました"))
						self.setState({
							open: false,
							// openConfirmBox: false,
						})
						if (self.onPosted) self.onPosted(threadId)
					})
				}
				break;

			case 'edit-post':		// Post編集
				post("bbs/update_post", {
					user_token: Cookies.get(altName),
					post_id: this.postId,
					title: this.state.bbsTitle.value,
					handle_name: this.state.bbsHandleName.value,
					text: this.state.bbsText.value,
				})
				.then((ret) => {
					G.store.dispatch(updateSnackbarAC("保存しました"))
					self.setState({
						open: false,
						// openConfirmBox: false,
					})
					if (self.onPosted) self.onPosted("null")
				})
				break;

			default:
		}
	}

	handleCancelPost = () => {
		this.setState({
			openConfirmBox: false,
		})
	}

	render() {
		const {bbsTitle, bbsHandleName, bbsText, isActive, hideNickname} = this.state
		const handleNameVd = (hideNickname) ? null : (
			<BsLikeTextField
				label="ハンドルネーム"
				type="text"
				state={bbsHandleName}
				onChange={(newState) => {
					this.setState({bbsHandleName: newState})
				}}
				reserveErrorTextArea={true}
				maxLength={16}
			/>
		)
		const textInputVd = (
			<Grid container spacing={2}>
				<Grid item xs={6}>
					<BsLikeTextField
						label="タイトル"
						type="text"
						state={bbsTitle}
						onChange={(newState) => {
							this.setState({bbsTitle: newState})
						}}
						reserveErrorTextArea={true}
					/>
				</Grid>
				<Grid item xs={6}>
					{handleNameVd}
				</Grid>
				<Grid item xs={12}>
					<BsLikeTextField
						label="内容"
						type="text"
						multiline={true}
						rows="6"
						state={bbsText}
						onChange={(newState) => {
							this.setState({bbsText: newState})
						}}
						reserveErrorTextArea={true}
					/>
				</Grid>
			</Grid>
		)
		return (
			<div>
				<WrappedComponent
					{...this.props}
					bbsDrawer={this}
					bbsDrawerStatus={isActive}
				/>
				<Dialog
					// anchor="bottom"
					open={this.state.open}
					onClose={this.onCancel.bind(this)}
				>
					<div style={{padding: 14}}>
						{textInputVd}
						<div style={{marginTop:8}}>
							<Button
								variant="contained"
								color="primary"
								onClick={this.handleConfirm.bind(this)}
								style={{width: 120}}
							>
								投稿
							</Button>
							<Button
								variant="contained"
								onClick={this.onCancel.bind(this)}
								style={{width: 120, marginLeft: 10}}
							>
								キャンセル
							</Button>
						</div>
					</div>
				</Dialog>
				<Dialog open={this.state.openConfirmBox}>
					<DialogTitle>投稿しても<br/>よろしいですか？</DialogTitle>
					<DialogActions>
						<Button onClick={this.handlePost.bind(this)}>
							投稿する
						</Button>
						<Button onClick={this.handleCancelPost.bind(this)}>
							キャンセル
						</Button>
					</DialogActions>
				</Dialog>
			</div>
		)
	}
}
