// @flow
import React from 'react'
import Cookies from "js-cookie";
import {common} from '@material-ui/core/colors'
import '../sonodalib/sl_utils/utils.css'
import {post} from '../sonodalib/sl_utils/Http'
import {QuestionBox} from '../Common'
import Moment from 'moment'
import Button from '@material-ui/core/Button'
import _ from "lodash";
import {blue, green, red} from "@material-ui/core/colors/index";
import {altName} from '../alt/constants'


type Props = {
	question: any,
	result: number,
	index: number,
	onClose: () => void,
}
type State = {
	total: number,
	feather_count: number,
}
export default class SentakuDetailPrint extends React.Component<Props, State> {
	state = {
		total: 0,
		feather_count: 0,
	}

	componentWillMount() {
		const self = this
		post("result/fetch/sentaku/stats", {
			user_token: Cookies.get(altName),
			mondai_id: this.props.question.MondaiID,
		})
		.then(ret => {
			self.setState({
				total: ret.total,
				feather_count: ret.feather_count,
			})
		})
	}

	render() {
		const styles = {
			alert: {
				background: common.black,
				padding: 10,
			},
			alertP: {
				color: common.white,
				fontWeight: 'bold',
				margin: 0,
				padding: 0,
				fontSize: 14,
			},
			pullRight: {
				textAlign: 'right',
			},
			sideMargin: {
				marginLeft: 12,
				marginRight: 12,
			},
			remark: {
				marginTop: 20,
				marginBottom: 10,
				fontWeight: 800,
			},
		}
		const {question} = this.props
		if (!question) return null
		const currentTime = Moment(new Date()).format("YYYY-MM-DD HH:mm:ss")

		// const choiceVd = _.map(question.choices, (choice, index) => (
		// 	<div key={_.uniqueId("fk")} style={{marginBottom: 10}}>
		// 		{(index + 1)}．{choice.text}
		// 	</div>
		// ))

		const commentaryVd = _.map(question.choices, (choice, index) => (
			<div key={_.uniqueId("fk")} style={{marginBottom: 10}}>
				{(index + 1)}．{choice.commentary}
			</div>
		))

		const judges = [
			{text: "正解", color: red[600]},
			{text: "不正解", color: blue[800]},
			{text: "未回答", color: green[800]},
		]
		const judge = judges[this.props.result-1]
		const yourChoise = _(question.choices).filter({selected: true}).map(x => (x.index)).value()
		const yourChoiseVd = (yourChoise.length === 0) ? "なし" : yourChoise.join("，")

		const correctAnswer = _(question.choices).filter({correct: true}).map(x => (x.index)).value().join("，")

		return (
			<div>
				<div style={{backgroundImage: "url('/images/bg_print.jpg')", width: 700}}>
					<div className="no-print">
						<Button color="secondary" variant="contained" onClick={this.props.onClose}>戻る</Button>
					</div>
					<div style={styles.alert}>
						<p style={styles.alertP}>※この印刷紙面の内容について、個人の学習以外の目的で使用すること、及び無断で複製・転載することを堅く禁止致します。株式会社ジェイシー教育研究所</p>
					</div>
					<div className="pull-right" style={styles.pullRight}>
						日時：{currentTime}
					</div>
					<div style={{marginTop:12}}>
						<span style={{fontSize: 20, fontWeight: 700, color: judge.color}}>[{judge.text}]　</span>
						<span style={{
							fontSize: 18,
							fontWeight: 700
						}}>第{this.props.index + 1}問 {question.subLabel} 累計{this.state.total}回目 習熟度{this.state.feather_count}</span>
					</div>
					<QuestionBox text={question.qText}/>
					<div style={styles.sideMargin}>
						<div style={styles.remark}>［ 解説 ］</div>
						{commentaryVd}
					</div>
					<div style={styles.sideMargin}>
						<div style={styles.remark}>［ あなたの回答 ］</div>
						{yourChoiseVd}
					</div>
					<div style={styles.sideMargin}>
						<div style={styles.remark}>［ 正解 ］</div>
						{correctAnswer}
					</div>
				</div>
				<div style={{...styles.pullRight, marginTop: 30}}>
					Copyright © 2017 JC Educational Institute, Inc. All Rights Reserved.
				</div>
			</div>
		)
	}
}
